import AboutKeys from '../translationKeys/AboutPageKeys';

export const AboutPageTranslations = {
  [AboutKeys.Side_Popup.Btn.creator]: `Tôi là nhà sáng tạo`,
  [AboutKeys.Side_Popup.Btn.client]: `Doanh nghiệp/Thương hiệu`,
  [AboutKeys.Side_Popup.Btn1]: 'Đi đến trang chính',

  // Part 1
  [AboutKeys.Part1.Creator.title]: `
    Cùng cộng tác với  <br>
    các doanh nghiệp <br>
    hàng đầu thế giới`,
  [AboutKeys.Part1.Creator.subtitle]: `
    Tạo hồ sơ miễn phí từ 1-2 phút để <br>
    gặp gỡ các thương hiệu toàn cầu!`,
  [AboutKeys.Part1.Creator.btn]: 'Đăng ký làm nhà sáng tạo',

  [AboutKeys.Part1.Client.title]: `
    Nền tảng marketing <br>
    toàn diện - cộng tác <br>
    với các influencer`,
  [AboutKeys.Part1.Client.subtitle]: `
    Hợp tác toàn cầu dễ dàng <br>
    Tiếp thị đơn giản và hiệu quả`,
  [AboutKeys.Part1.Client.btn]: 'Đặt hàng với các influencer',

  // Part 2
  [AboutKeys.Part2.Creator.title]:
    'Cơ hội cộng tác dành cho nhà sáng tạo với các thương hiệu toàn cầu',

  [AboutKeys.Part2.Creator.Section1.Image.description1]:
    'Hợp tác với các thương hiệu Việt, Mỹ, Hàn, Nhật!',
  [AboutKeys.Part2.Creator.Section1.Image.description2]:
    'Tự động quảng bá kênh với các nhà quảng cáo',
  [AboutKeys.Part2.Creator.Section1.Image.description3]:
    'Hệ thống tin nhắn dành riêng cho sự hợp tác',
  [AboutKeys.Part2.Creator.Section1.text1]:
    'Hợp tác với các công ty toàn cầu \\n đã chứng nhận trên 40+ quốc gia',
  [AboutKeys.Part2.Creator.Section1.text2]:
    'Nền tảng SNS đa dạng · cung cấp \\n hệ thống dịch tự động',
  [AboutKeys.Part2.Creator.Section1.text3]:
    'Giao tiếp 1:1 không giới hạn theo thời gian thực với nhà quảng cáo',

  [AboutKeys.Part2.Creator.Section2.Image.description1]:
    'Dễ dàng tạo và bán các gói của riêng bạn',
  [AboutKeys.Part2.Creator.Section2.Image.description2]:
    'Đánh giá đề xuất hợp lý · Thanh toán dễ dàng',
  [AboutKeys.Part2.Creator.Section2.text1]:
    'Tự động tạo và bán các gói phù \\n hợp với kênh của bạn',
  [AboutKeys.Part2.Creator.Section2.text2]:
    'Kiểm tra và chấp nhận ưu đãi chỉ \\n bằng một click chuột',
  [AboutKeys.Part2.Creator.Section2.text3]:
    'Bảng điều khiển thông minh · \\n Thanh toán dễ dàng',

  [AboutKeys.Part2.Creator.Section3.Image.description1]:
    'Truy cập dữ liệu toàn diện · số liệu thống kê',
  [AboutKeys.Part2.Creator.Section3.Image.description2]:
    'Phân tích · thống kê SNS mạnh mẽ và đa dạng',
  [AboutKeys.Part2.Creator.Section3.text1]:
    'Giao diện người dùng đơn giản và trực quan',
  [AboutKeys.Part2.Creator.Section3.text2]:
    'Phân tích dữ liệu chi tiết về \\n kênh và nội dung',

  [AboutKeys.Part2.Client.title]:
    'Công cụ Marketing Influencer toàn diện mạnh mẽ.',

  [AboutKeys.Part2.Client.Section1.Image.description1]:
    'Tìm kiếm nhà sáng tạo Việt Nam và trên toàn cầu',
  [AboutKeys.Part2.Client.Section1.Image.description2]:
    'Hệ thống tin nhắn dành riêng cho sự hợp tác',
  [AboutKeys.Part2.Client.Section1.text1]:
    'Nhà sáng tạo toàn cầu sẵn sàng \\n cộng tác',
  [AboutKeys.Part2.Client.Section1.text2]:
    'Nền tảng SNS đa dạng · cung cấp \\n hệ thống dịch tự động',
  [AboutKeys.Part2.Client.Section1.text3]:
    'Giao tiếp 1:1 không giới hạn theo thời gian thực với nhà quảng cáo',

  [AboutKeys.Part2.Client.Section2.Image.description1]:
    'Các gói do nhà sáng tạo trực tiếp cung cấp',
  [AboutKeys.Part2.Client.Section2.Image.description2]:
    'Quản lý nội dung và đặt hàng dễ dàng, tiện lợi',
  [AboutKeys.Part2.Client.Section2.text1]:
    'Phân phối đề xuất dễ dàng · Đơn \\n đặt hàng quảng cáo',
  [AboutKeys.Part2.Client.Section2.text2]:
    'Đặt quảng cáo trên toàn thế giới \\n chỉ cần một cú click chuột',
  [AboutKeys.Part2.Client.Section2.text3]:
    'Bảng điều khiển thông minh · \\n Thanh toán dễ dàng',

  [AboutKeys.Part2.Client.Section3.Image.description1]:
    'Truy cập dữ liệu toàn diện · số liệu thống kê',
  [AboutKeys.Part2.Client.Section3.Image.description2]:
    'Phân tích · thống kê SNS mạnh mẽ và đa dạng',
  [AboutKeys.Part2.Client.Section3.text1]:
    'Giao diện người dùng đơn giản và trực quan',
  [AboutKeys.Part2.Client.Section3.text2]:
    'Phân tích dữ liệu chi tiết về \\n kênh và nội dung',
  [AboutKeys.Part2.Client.Section3.text2_sub]:
    '* Loại trừ một số loại nội dung',

  // Part 3

  // Part 4

  // Part 5
  [AboutKeys.Part5.title]: 'Lợi ích và Điểm EVO',
  [AboutKeys.Part5.text1]: 'Hoàn thành nhiệm vụ để nhận EVO',
  [AboutKeys.Part5.text2]: 'Lợi ích và sản phẩm đặc biệt dành cho nhà sáng tạo',
  [AboutKeys.Part5.text3]:
    'Sự kiện Offline kết nối nhà sáng tạo với các fan và doanh nghiệp',

  // Feedback
  [AboutKeys.Feedback.Creator1.name]: '50k+ Nhà sáng tạo YouTube',
  [AboutKeys.Feedback.Creator1.comment]:
    'Tôi đã tìm hiểu kỹ về MegaEvolution và thấy đây là một nền tảng tuyệt vời! Trước đây tôi rất khó để kết nói với các nhà quảng cáo nhưng sau khi sử dụng nền tảng tôi đã có thể giao tiếp thoải mái!',
  [AboutKeys.Feedback.Creator2.name]: '1.5M+ Nhà sáng tạo TikTok · Instagram',
  [AboutKeys.Feedback.Creator2.comment]:
    'MegaEvolution là nền tảng tốt nhất mà tôi từng dùng so với các nền tảng khác. Với tư cách là nhà sáng tạo có trên 3 năm kinh nghiệm và sử dụng nhiều nền tảng kết nối giữa người tạo nội dung và thương hiệu, nhưng trong số đó với MegaEvolution là sự trải nghiệm tốt nhất. Không chỉ giao diện rất thân thiện và linh hoạt mà tôi còn thấy sự tiện lợi khi có thể cung cấp cho các thương hiệu nhiều lựa chọn khác nhau thông qua hệ thống gói dành riêng cho từng cấp độ. Rất vui vì mình đã khám phá ra được nền tảng này, nhưng thật sự ước gì mình đã biết sớm hơn 😄',
  [AboutKeys.Feedback.Creator3.name]:
    '50k+ Streamer YouTube · Nhà sáng tạo Instagram',
  [AboutKeys.Feedback.Creator3.comment]:
    'MegaEvolution thực sự là một không gian cần thiết đối với những người sáng tạo như tôi. Đặc biệt là với những tân binh chưa có đất dụng võ hoặc thậm chí những kênh đã đăng ký từ lâu mà chưa có đơn quảng cáo nào! Tôi rất vui khi nhiều nhà sáng tạo có thể nhận được sự giúp đỡ thông qua MegaEvolution!',
  [AboutKeys.Feedback.Creator4.name]: '150k+ Nhà sáng tạo YouTube',
  [AboutKeys.Feedback.Creator4.comment]:
    'Là một Youtuber, tôi thấy rằng nền tảng MegaEvolution mang lại cơ hội hợp tác rất dễ dàng với các doanh nghiệp bằng cách tạo một hồ sơ của bạn tại nền tảng',
  [AboutKeys.Feedback.Creator5.name]: '100k+ Nhà sáng tạo Youtube · Instagram',
  [AboutKeys.Feedback.Creator5.comment]:
    'Sự giúp đỡ mà MegaEvolution đã mang lại cho tôi trong năm vừa rồi đã tăng cường sự tự tin của tôi, từ đó tôi bắt đầu xây dựng lại YouTube. Và hãy nhìn xem tôi đã đạt được điều gì! (Kể từ đó, kênh của tôi đã phát triển gấp 3 lần!)',
  [AboutKeys.Feedback.Creator6.name]: 'Youtube gaming',
  [AboutKeys.Feedback.Creator6.comment]:
    'Tôi rất ấn tượng với dịch vụ dành cho những người tạo nội dung ở đây, và tôi chắc chắn rằng cả những người hiện đang sử dụng nền tảng và những người sẽ sử dụng nó trong tương lai sẽ hưởng lợi lớn từ các dịch vụ do MegaEvolution cung cấp!',
  [AboutKeys.Feedback.Creator7.name]: '30+ Youtube công nghệ · Youtube gaming',
  [AboutKeys.Feedback.Creator7.comment]:
    'Dịch vụ được xác nhận bởi Google và kết nối trực tiếp với các doanh nghiệp toàn cầu mà không cần hợp đồng phức tạp là những điều đặc biệt thu hút với tôi!',
  [AboutKeys.Feedback.Creator8.name]: '50k+ Nhà sáng tạo YouTube',
  [AboutKeys.Feedback.Creator8.comment]:
    'Tôi đã nghe nói về nền tảng này từ một người bạn của mình và sau đó tôi được biết từ những nhà sáng tạo khác rằng Megaevolution là một nền tảng rất thành công!',
  [AboutKeys.Feedback.Creator9.name]:
    '70k+ Nhà sáng tạo sắc đẹp Youtube · Instagram · Tiktok',
  [AboutKeys.Feedback.Creator9.comment]:
    'Tôi rất hào hứng với MegaEvolution! Đây là nền tảng không những tuyệt vời cho những nhà sáng tạo mà tôi còn thực sự thích cách bố trí từng phần của nền tảng!',
};
