export const SurpriseEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M12.1875 22.5C12.1875 20.9467 13.4467 19.6875 15 19.6875C16.5533 19.6875 17.8125 20.9467 17.8125 22.5C17.8125 24.0533 16.5533 25.3125 15 25.3125C13.4467 25.3125 12.1875 24.0533 12.1875 22.5Z"
      fill="#BB1D80"
    />
    <path
      d="M9.84375 17.8125C12.1737 17.8125 14.0625 15.9237 14.0625 13.5938C14.0625 11.2638 12.1737 9.375 9.84375 9.375C7.5138 9.375 5.625 11.2638 5.625 13.5938C5.625 15.9237 7.5138 17.8125 9.84375 17.8125Z"
      fill="white"
    />
    <path
      d="M20.1562 17.8125C22.4862 17.8125 24.375 15.9237 24.375 13.5938C24.375 11.2638 22.4862 9.375 20.1562 9.375C17.8263 9.375 15.9375 11.2638 15.9375 13.5938C15.9375 15.9237 17.8263 17.8125 20.1562 17.8125Z"
      fill="white"
    />
    <path
      d="M7.56506 7.48838C7.00921 8.01608 6.67306 8.65101 6.53857 9.05448C6.4567 9.30008 6.19124 9.43284 5.94565 9.35094C5.70005 9.26908 5.56732 9.00362 5.64918 8.75802C5.82718 8.22399 6.24104 7.45267 6.91958 6.80849C7.60607 6.15675 8.57629 5.625 9.84388 5.625C10.1027 5.625 10.3126 5.83487 10.3126 6.09375C10.3126 6.35263 10.1027 6.5625 9.84388 6.5625C8.86147 6.5625 8.11293 6.96825 7.56506 7.48838Z"
      fill="#402A32"
    />
    <path
      d="M11.25 15.9375C12.2856 15.9375 13.125 15.0981 13.125 14.0625C13.125 13.0269 12.2856 12.1875 11.25 12.1875C10.2144 12.1875 9.375 13.0269 9.375 14.0625C9.375 15.0981 10.2144 15.9375 11.25 15.9375Z"
      fill="#402A32"
    />
    <path
      d="M18.75 15.9375C19.7856 15.9375 20.625 15.0981 20.625 14.0625C20.625 13.0269 19.7856 12.1875 18.75 12.1875C17.7144 12.1875 16.875 13.0269 16.875 14.0625C16.875 15.0981 17.7144 15.9375 18.75 15.9375Z"
      fill="#402A32"
    />
    <path
      d="M23.4615 9.05448C23.3271 8.65101 22.9909 8.01608 22.435 7.48838C21.8872 6.96825 21.1387 6.5625 20.1562 6.5625C19.8973 6.5625 19.6875 6.35263 19.6875 6.09375C19.6875 5.83487 19.8973 5.625 20.1562 5.625C21.4238 5.625 22.3941 6.15675 23.0805 6.80849C23.7591 7.45267 24.173 8.22399 24.3509 8.75802C24.4328 9.00362 24.3001 9.26908 24.0545 9.35094C23.8088 9.43284 23.5434 9.30008 23.4615 9.05448Z"
      fill="#402A32"
    />
  </svg>
);
