import React, { useEffect } from 'react';
import Modal from '../Modal';
import Stack from './Stack';
import {
  AutoTranslationIcon,
  CheckRoundedIcon,
  MessageTranslationIcon,
} from 'assets/icons';
import styled from 'styled-components';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import IndigoSwitch from '../Buttons/IndigoSwitch';
import { useTranslation } from 'react-i18next';
import languages from 'constants/translationLanguages';
import AutoTranslationKeys from 'translations/translationKeys/AutoTranslationKeys';
import useUser from 'hooks/useUser';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';

const LanguageModal = ({ isOpen, onClose }) => {
  const {
    autoTranslate,
    toggleAutoTranslate,
    shouldReloadPageOnLanguageChange,
    quotaTranslateState,
    toggleQuotaTranslateState,
    translationQuota,
  } = useAutoTranslation();
  const { i18n, t } = useTranslation();
  const { isLoggedIn, user } = useUser();

  useEffect(() => {
    if (!isOpen) return;
    const event = new CustomEvent('toggle-patch-note-modal', {
      detail: 'close',
    });
    window.dispatchEvent(event);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Stack gap="1.5rem" width="100%" margin="0 0 0.75rem">
          <Stack direction="row" gap="1.25rem" align="center">
            <Stack direction="row">
              <AutoTranslationIcon />
              <h5 className="h5">{t(AutoTranslationKeys.Dropdown.title)}</h5>
            </Stack>
            <IndigoSwitch
              id="footer-switch"
              checked={autoTranslate}
              onChange={() => {
                toggleAutoTranslate();
                shouldReloadPageOnLanguageChange();
              }}
              size={isLoggedIn ? 'mmd' : 'lg'}
            />
          </Stack>
          {isLoggedIn ? (
            <Stack
              direction="row"
              align="center"
              width="100%"
              justify="space-between"
              margin="0.75rem 0 0"
            >
              <Stack direction="row" gap="1.25rem" align="center">
                <Stack direction="row">
                  <MessageTranslationIcon />
                  <h5 className="h5">
                    {t(AutoTranslationKeys.Dropdown.order_message_title)}
                  </h5>
                </Stack>
                <IndigoSwitch
                  id="footer-message-switch"
                  checked={quotaTranslateState}
                  onChange={toggleQuotaTranslateState}
                  size="mmd"
                />
              </Stack>
              <h4
                className="h7 text-indigo-200"
                style={{ marginRight: '-42px' }}
              >
                <Trans
                  values={{
                    number:
                      translationQuota !== undefined
                        ? translationQuota?.toLocaleString()
                        : '',
                    date: dayjs(user?.clientPlan?.renewalDate).format(
                      dayjs.Ls[i18n.language].dateFormat.dash_date
                    ),
                  }}
                >
                  {t(AutoTranslationKeys.Dropdown.order_message_description)}
                </Trans>
              </h4>
            </Stack>
          ) : (
            <p>{t(AutoTranslationKeys.Dropdown.description)}</p>
          )}
        </Stack>
      }
      closeIconSize={1.29}
      size="lg"
      wrapperStyles={{ padding: '1.875rem 2.25rem', width: '856px' }}
    >
      <ItemsWrapper>
        {languages.map((language) => (
          <Item
            key={language.value}
            onClick={() => {
              i18n.changeLanguage(language.value);
              onClose();
              shouldReloadPageOnLanguageChange();
            }}
            disabled={language.disabled}
            selected={language.value === i18n.language}
          >
            {language.value === i18n.language && <CheckRoundedIcon />}
            {language.label}
          </Item>
        ))}
      </ItemsWrapper>
    </Modal>
  );
};

export default LanguageModal;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 2.25rem;
  padding: 1.5rem 0 0;
  margin-bottom: 0.625rem;
`;

const Item = styled.button.attrs({
  className: 'h5',
})`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ selected }) =>
    selected ? 'var(--color-indigo-500)' : 'var(--color-indigo-100)'};
  width: 100%;
  text-align: left;
  display: flex;
  gap: 0.625rem;
  align-items: center;

  &:hover {
    background: var(--color-indigo-75);
  }

  &:disabled {
    color: var(--color-indigo-25);
  }
`;
