export const ArrowRightIcon = ({ size = 1 }) => (
  <svg
    width={size * 16}
    height={size * 10}
    viewBox="0 0 16 10"
    fill="currentColor"
  >
    <path d="M0.997174 4.00014H12.5872L10.2872 1.7124C10.1939 1.61925 10.12 1.50867 10.0695 1.38697C10.0191 1.26527 9.99308 1.13483 9.99308 1.0031C9.99308 0.871371 10.0191 0.740932 10.0695 0.61923C10.12 0.497528 10.1939 0.386948 10.2872 0.293801C10.3804 0.200655 10.4911 0.126767 10.6129 0.0763567C10.7347 0.0259462 10.8653 -1.96291e-09 10.9972 0C11.129 1.96291e-09 11.2596 0.0259462 11.3814 0.0763567C11.5032 0.126767 11.6139 0.200655 11.7072 0.293801L15.7072 4.28985C15.7982 4.38486 15.8696 4.49689 15.9172 4.61952C16.0172 4.86274 16.0172 5.13555 15.9172 5.37877C15.8696 5.5014 15.7982 5.61344 15.7072 5.70845L11.7072 9.70449C11.6142 9.79813 11.5036 9.87245 11.3818 9.92317C11.2599 9.97389 11.1292 10 10.9972 10C10.8652 10 10.7345 9.97389 10.6126 9.92317C10.4907 9.87245 10.3801 9.79813 10.2872 9.70449C10.1934 9.61162 10.1191 9.50113 10.0683 9.37939C10.0175 9.25765 9.99138 9.12708 9.99138 8.9952C9.99138 8.86331 10.0175 8.73274 10.0683 8.611C10.1191 8.48926 10.1934 8.37877 10.2872 8.2859L12.5872 5.99816H0.997174C0.731957 5.99816 0.477603 5.89291 0.290067 5.70556C0.10253 5.5182 -0.00282574 5.2641 -0.00282574 4.99915C-0.00282574 4.73419 0.10253 4.48009 0.290067 4.29274C0.477603 4.10539 0.731957 4.00014 0.997174 4.00014Z" />
  </svg>
);
