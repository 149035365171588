import { useLocation, useNavigate } from 'react-router-dom';
import {
  EnvelopeIcon,
  LogoutIcon,
  ProfileIcon,
  SupportIcon,
  SettingsIcon,
  BillingIcon,
  GlobeIcon,
  ProposalIcon,
} from 'assets/icons';
import { useMemo } from 'react';
import useUser from 'hooks/useUser';
import { useAuth } from 'hooks/useAauth';
import CustomDropdown from './CustomDropdown';
import styled from 'styled-components';
import LazyImage from 'components/Basics/LazyImage';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import UserType from 'constants/userType';
import LanguageModal from 'components/Basics/Layout/LanguageModal';
import { useToggle } from 'react-use';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';

const MySpaceButton = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, currentType, navigateToProfile } = useUser();
  const { utils } = useAuth();
  const [isLanguageModalOpen, toggleLanguageModal] = useToggle(false);

  const { shouldReloadPageOnLanguageChange, getTranslatedText } =
    useAutoTranslation();
  const menuOptions = useMemo(
    () => [
      {
        label: t(GeneralKeys.profile),
        icon: <ProfileIcon />,
        onClick: () => navigateToProfile(),
        onMouseDown: (e) => {
          // on middle click
          if (e.button === 1) {
            navigateToProfile(true);
          }
        },
      },
      {
        label: t(GeneralKeys.proposals),
        icon: <ProposalIcon />,
        onClick: () => {
          if (pathname === '/proposals') return;
          navigate('/proposals');
        },
        hidden: currentType !== UserType.Client,
      },
      {
        label: t(GeneralKeys.messages),
        icon: <EnvelopeIcon />,
        onClick: () => {
          if (pathname === '/messages') return;
          navigate('/messages');
        },
      },
      {
        label: t(GeneralKeys.billing),
        icon: <BillingIcon />,
        onClick: () => {
          if (pathname === '/billing') return;
          navigate('/billing');
        },
      },
      {
        divider: true,
      },
      {
        label: t(GeneralKeys.settings),
        icon: <SettingsIcon size={0.8} />,
        onClick: () => navigate('/settings'),
        onMouseDown: (e) => {
          // on middle click
          if (e.button === 1) {
            window.open('/myspace/settings', '_blank');
          }
        },
      },
      {
        label: t(GeneralKeys.help_center),
        icon: <SupportIcon />,
        onClick: () => navigate('/help-center'),
        onMouseDown: (e) => {
          // on middle click
          if (e.button === 1) {
            window.open('/myspace/help-center', '_blank');
          }
        },
      },
      {
        label: t(GeneralKeys.language),
        icon: <GlobeIcon size={0.8} />,
        onClick: () => {
          toggleLanguageModal();
        },
      },
      {
        label: t(GeneralKeys.logout),
        icon: <LogoutIcon />,
        onClick: async () => {
          await utils.signOut();

          if (pathname === '/profile-edit') {
            navigate(`/${user?.handle ? `@${user?.handle}` : user?.id}`);
          }
          shouldReloadPageOnLanguageChange();
        },
      },
    ],
    [
      navigate,
      utils,
      pathname,
      toggleLanguageModal,
      t,
      navigateToProfile,
      currentType,
      user?.id,
      user?.handle,
      shouldReloadPageOnLanguageChange,
    ]
  );

  return (
    <>
      <CustomDropdown
        className="dropdown"
        top="-26px"
        trigger={(_, setIsOpen) => (
          <ImageContainer
            onClick={() => {
              navigate('/my-space');
            }}
            onMouseDown={(e) => {
              // on middle click
              if (e.button === 1) {
                window.open('/my-space', '_blank');
              }
            }}
            className="cursor-pointer"
            onMouseEnter={
              user
                ? () => {
                    setIsOpen(true);
                  }
                : null
            }
            onMouseLeave={
              user
                ? () => {
                    setIsOpen(false);
                  }
                : null
            }
          >
            <LazyImage
              src={user?.pic}
              lazySrc={user?.pic ? user?.pic + '_min' : null}
              alt={getTranslatedText(user?.name)}
              circle
            />
          </ImageContainer>
        )}
        options={menuOptions}
        optionStyle={{
          fontFamily: "'Space Grotesk', sans-serif",
        }}
        margin="20px 0px 0px 0px"
        closeByHover={true}
        shadow={true}
        position="center"
      />
      <LanguageModal
        isOpen={isLanguageModalOpen}
        onClose={toggleLanguageModal}
      />
    </>
  );
};

export default MySpaceButton;

const ImageContainer = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
`;
