import {
  AutoTranslationIcon,
  CheckRoundedIcon,
  MessageTranslationIcon,
} from 'assets/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import languages from 'constants/translationLanguages';
import styled from 'styled-components';
import IndigoSwitch from 'components/Basics/Buttons/IndigoSwitch';
import Stack from '../../Stack';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import AutoTranslationKeys from 'translations/translationKeys/AutoTranslationKeys';
import useUser from 'hooks/useUser';
import { Trans } from 'react-i18next';
import dayjs from 'dayjs';

const SidebarLanguagesView = ({ toggleVisible }) => {
  const { i18n, t } = useTranslation();
  const {
    autoTranslate,
    toggleAutoTranslate,
    shouldReloadPageOnLanguageChange,
    quotaTranslateState,
    toggleQuotaTranslateState,
    translationQuota,
  } = useAutoTranslation();
  const { isLoggedIn, user } = useUser();

  const selected = useMemo(
    () =>
      languages.find(
        ({ value }) => value === i18n.language || i18n.language.includes(value)
      ),
    [i18n.language]
  );

  return (
    <>
      <Stack gap="0.75rem" width="100%">
        <Stack
          direction="row"
          gap="1.875rem"
          align="start"
          justify="space-between"
          wrap="nowrap"
        >
          <Stack direction="row" wrap="nowrap">
            <AutoTranslationIcon size={0.67} />
            <h5 className="sbh7 text-wrap">
              {t(AutoTranslationKeys.Dropdown.title)}
            </h5>
          </Stack>
          <span style={{ paddingTop: '2px' }}>
            <IndigoSwitch
              id="footer-switch"
              checked={autoTranslate}
              onChange={() => {
                toggleAutoTranslate();
                shouldReloadPageOnLanguageChange();
              }}
              size="sm"
            />
          </span>
        </Stack>
        {isLoggedIn ? (
          <Stack>
            <Stack
              direction="row"
              wrap="nowrap"
              justify="space-between"
              margin="0.75rem 0 0"
            >
              <Stack direction="row" wrap="nowrap">
                <span className="d-flex" style={{ paddingTop: '0.125rem' }}>
                  <MessageTranslationIcon size={0.73} />
                </span>
                <h5 className="sbh7">
                  <Trans>
                    {t(AutoTranslationKeys.Dropdown.order_message_title_mobile)}
                  </Trans>
                </h5>
              </Stack>
              <IndigoSwitch
                id="footer-message-switch"
                checked={quotaTranslateState}
                onChange={() => {
                  toggleQuotaTranslateState();
                  shouldReloadPageOnLanguageChange();
                }}
                size="sm"
              />
            </Stack>
            <UserPlanDetails>
              <Trans
                values={{
                  number:
                    translationQuota !== undefined
                      ? translationQuota?.toLocaleString()
                      : '',
                  date: dayjs(user?.clientPlan?.renewalDate).format(
                    dayjs.Ls[i18n.language].dateFormat.dash_date
                  ),
                }}
              >
                <Trans>
                  {t(
                    AutoTranslationKeys.Dropdown
                      .order_message_description_mobile
                  )}
                </Trans>
              </Trans>
            </UserPlanDetails>
          </Stack>
        ) : (
          <p className="h9">{t(AutoTranslationKeys.Dropdown.description)}</p>
        )}
      </Stack>

      <Divider />

      {languages.map((language, index) => (
        <Item
          key={index}
          onClick={() => {
            i18n.changeLanguage(language.value);
            toggleVisible();
            shouldReloadPageOnLanguageChange();
          }}
          disabled={language.disabled}
          selected={language.value === selected.value}
        >
          {selected.value === language.value && <CheckRoundedIcon size={0.8} />}
          {language.label}
        </Item>
      ))}
    </>
  );
};
export default SidebarLanguagesView;

const Item = styled.button.attrs({
  className: 'mh13',
})`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ selected }) =>
    selected ? 'var(--color-indigo-500)' : 'var(--color-indigo-100)'};
  width: 100%;
  text-align: left;
  display: flex;
  gap: 0.625rem;
  align-items: center;

  &:hover {
    background: var(--color-indigo-75);
  }

  &:disabled {
    color: var(--color-indigo-25);
  }
`;

const Divider = styled.div`
  width: 180px;
  height: 1px;
  background: var(--color-indigo-25);
`;

const UserPlanDetails = styled.div`
  font-size: 0.625rem;
  margin-left: 26px;
  color: var(--color-indigo-200);
  font-weight: 400;
`;
