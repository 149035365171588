export const MessageTranslationIcon = ({ size = 1 }) => (
  <svg
    width={22 * size}
    height={21 * size}
    viewBox="0 0 22 21"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M17.2726 12.4644C17.1507 12.4327 17.0237 12.4254 16.8989 12.443C16.7742 12.4605 16.6541 12.5025 16.5456 12.5666C16.4371 12.6306 16.3423 12.7155 16.2666 12.8163C16.191 12.9171 16.136 13.0318 16.1047 13.1539C15.7386 14.5985 14.9018 15.88 13.7263 16.7957C12.5509 17.7114 11.1039 18.2092 9.61411 18.2103H4.26271L4.8754 17.607C5.0537 17.4275 5.15378 17.1848 5.15378 16.9318C5.15378 16.6788 5.0537 16.4361 4.8754 16.2567C4.03687 15.4212 3.43448 14.3785 3.12949 13.2346C2.8245 12.0907 2.82779 10.8864 3.13902 9.74415C3.45025 8.60192 4.05832 7.56251 4.90139 6.73164C5.74446 5.90077 6.79246 5.30807 7.93881 5.01382C8.06075 4.98238 8.17531 4.92722 8.27595 4.85149C8.37658 4.77576 8.46132 4.68094 8.52533 4.57245C8.58933 4.46396 8.63135 4.34392 8.64898 4.21919C8.66661 4.09445 8.6595 3.96747 8.62808 3.84548C8.5646 3.59911 8.40589 3.38806 8.18686 3.25875C8.07841 3.19472 7.95842 3.15269 7.83373 3.13506C7.70904 3.11742 7.58209 3.12453 7.46015 3.15597C6.06917 3.51877 4.79104 4.22396 3.74218 5.20729C2.69333 6.19063 1.90709 7.42086 1.45509 8.78591C1.00309 10.151 0.899688 11.6075 1.15431 13.0227C1.40893 14.438 2.01349 15.767 2.9129 16.8887L1.27589 18.488C1.14305 18.6227 1.05307 18.7937 1.01729 18.9795C0.981509 19.1652 1.00154 19.3575 1.07485 19.5319C1.14667 19.7067 1.26863 19.8565 1.42536 19.9621C1.58209 20.0678 1.76658 20.1247 1.95558 20.1256H9.61411C11.5252 20.1268 13.3824 19.4924 14.8936 18.3222C16.4048 17.152 17.4841 15.5125 17.9619 13.6614C17.9979 13.5376 18.0086 13.4077 17.9931 13.2796C17.9777 13.1515 17.9366 13.0278 17.8722 12.916C17.8079 12.8042 17.7216 12.7066 17.6185 12.629C17.5155 12.5513 17.3978 12.4954 17.2726 12.4644Z" />
    <path d="M20.6748 4.52098C20.6748 4.52098 20.6748 4.52098 20.6748 4.4934C20.2865 3.43223 19.5819 2.51604 18.6564 1.86877C17.7309 1.2215 16.6291 0.87439 15.5 0.87439C14.3709 0.87439 13.2691 1.2215 12.3436 1.86877C11.4181 2.51604 10.7135 3.43223 10.3252 4.4934C10.3252 4.4934 10.3252 4.4934 10.3252 4.52098C9.89159 5.71847 9.89159 7.03031 10.3252 8.2278C10.3252 8.2278 10.3252 8.2278 10.3252 8.25538C10.7135 9.31655 11.4181 10.2327 12.3436 10.88C13.2691 11.5273 14.3709 11.8744 15.5 11.8744C16.6291 11.8744 17.7309 11.5273 18.6564 10.88C19.5819 10.2327 20.2865 9.31655 20.6748 8.25538C20.6748 8.25538 20.6748 8.25538 20.6748 8.2278C21.1084 7.03031 21.1084 5.71847 20.6748 4.52098ZM11.2235 7.47761C11.0324 6.75457 11.0324 5.99421 11.2235 5.27117H12.2485C12.1604 6.004 12.1604 6.74478 12.2485 7.47761H11.2235ZM11.6754 8.58083H12.4469C12.5763 9.07277 12.7612 9.54838 12.998 9.99846C12.4576 9.62995 12.0058 9.14571 11.6754 8.58083ZM12.4469 4.16795H11.6754C12.001 3.60481 12.447 3.1207 12.9815 2.75032C12.7503 3.20112 12.571 3.6767 12.4469 4.16795ZM14.9379 10.6218C14.261 10.1246 13.7856 9.40009 13.5987 8.58083H14.9379V10.6218ZM14.9379 7.47761H13.3618C13.2589 6.74573 13.2589 6.00305 13.3618 5.27117H14.9379V7.47761ZM14.9379 4.16795H13.5987C13.7856 3.34869 14.261 2.62418 14.9379 2.127V4.16795ZM19.3025 4.16795H18.531C18.4017 3.67601 18.2168 3.2004 17.9799 2.75032C18.5204 3.11883 18.9722 3.60307 19.3025 4.16795ZM16.0401 2.127C16.7169 2.62418 17.1923 3.34869 17.3792 4.16795H16.0401V2.127ZM16.0401 10.6218V8.58083H17.3792C17.1923 9.40009 16.7169 10.1246 16.0401 10.6218ZM17.6162 7.47761H16.0401V5.27117H17.6162C17.719 6.00305 17.719 6.74573 17.6162 7.47761ZM17.9965 9.99846C18.2333 9.54838 18.4182 9.07277 18.5475 8.58083H19.3191C18.9887 9.14571 18.5369 9.62995 17.9965 9.99846ZM19.7544 7.47761H18.7294C18.7742 7.11159 18.7963 6.74314 18.7955 6.37439C18.7961 6.00564 18.7741 5.6372 18.7294 5.27117H19.7544C19.9455 5.99421 19.9455 6.75457 19.7544 7.47761Z" />
  </svg>
);
