import { createContext, useState, useContext, useMemo, useRef } from 'react';
import {
  mainFlow,
  flowSteps,
  authPlatform,
  authUIKey,
} from 'constants/authFlow';
import ConnectWalletModal from 'components/components/auth/ConnectWalletModal';
import EnterEmailModal from 'components/components/auth/EnterEmailModal';
import TermsAndConditionsModal from 'components/components/auth/TermsAndConditionsModal';
import VerifyEmailModal from 'components/components/auth/VerifyEmailModal';
import ThankyouModal from 'components/components/auth/ThankyouModal';
import ResetPasswordModal from 'components/components/auth/ResetPasswordModal';
import WelcomeModal from 'components/components/auth/WelcomeModal';
import ReactivateModal from 'components/components/auth/ReactivateModal';
import LoginModal from 'components/components/auth/LoginPopup';
import SignupModal from 'components/components/auth/SignUpPopup';
import FacebookEmailNotPresentModal from 'components/components/auth/FacebookEmailNotPresentModal';
import { useCallback } from 'react';
import useUser from 'hooks/useUser';

const { LOCAL } = authPlatform;
const { SIGN_IN, RESET_PASSWORD, REACTIVATE, EMAILNOTPRESENT } = mainFlow;
const {
  LOGIN_MODAL,
  SIGN_UP_MODAL,
  CONNECT_WALLET,
  ENTER_EMAIL,
  TERMS,
  VERIFY_EMAIL,
  THANK_YOU,
  WELCOME,
  RESET_PW,
  REACTIVATE_MODAL,
  EMAILlNOTPRESENT_MODAL,
} = authUIKey;
const AuthFlowManagerContext = createContext();

export const AuthFlowManagerProvider = ({
  children,
  initialFlow,
  initialMethod,
  initialStep,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainFlow, setMainFlow] = useState(initialFlow ?? SIGN_IN);
  const [authMethod, setAuthMethod] = useState(initialMethod ?? LOCAL);
  const [authSubMethod, setSubAuthMethod] = useState('');
  const [currentStep, setCurrentStep] = useState(initialStep ?? 0);
  const [sharedValue, setSharedValue] = useState({});
  const [fbState, setFbState] = useState();

  const onCloseRef = useRef();
  const onClose = onCloseRef.current;
  const setOnClose = useCallback(
    (fn) => {
      onCloseRef.current = fn;
    },
    [onCloseRef]
  );

  const data = useMemo(
    () => ({
      mainFlow,
      setMainFlow,
      authMethod,
      setAuthMethod,
      currentStep,
      setCurrentStep,
      sharedValue,
      setSharedValue,
      isModalOpen,
      setIsModalOpen,
      fbState,
      setFbState,
      authSubMethod,
      setSubAuthMethod,
      onClose,
      setOnClose,
    }),
    [
      mainFlow,
      authMethod,
      currentStep,
      sharedValue,
      isModalOpen,
      fbState,
      authSubMethod,
      onClose,
      setOnClose,
    ]
  );

  return (
    <AuthFlowManagerContext.Provider value={data}>
      {children}
    </AuthFlowManagerContext.Provider>
  );
};

export const useAuthFlowManager = () => {
  const contextValue = useContext(AuthFlowManagerContext);
  const { user } = useUser();
  if (!contextValue) {
    throw new Error('AuthflowContext not defined');
  }
  const {
    mainFlow,
    setMainFlow,
    authMethod,
    setAuthMethod,
    currentStep,
    setCurrentStep,
    sharedValue,
    setSharedValue,
    isModalOpen,
    setIsModalOpen,
    fbState,
    setFbState,
    authSubMethod,
    setSubAuthMethod,
    onClose,
    setOnClose,
  } = contextValue;

  function forwardStep() {
    if (mainFlow === RESET_PASSWORD) {
      if (flowSteps[mainFlow].length - 1 > currentStep) {
        setCurrentStep((current) => current + 1);
      }
    } else {
      if (flowSteps[mainFlow][authMethod].length - 1 > currentStep) {
        setCurrentStep((current) => current + 1);
      }
    }
  }
  function backwardStep() {
    if (currentStep > 0) {
      setCurrentStep((current) => {
        if (current > 0) {
          return current - 1;
        }
      });
    }
  }

  function navigateFlow(navigateObj) {
    const { flow, method, step, state, subMethod } = navigateObj;
    if (navigateObj.hasOwnProperty('flow')) {
      setMainFlow(flow);
    }
    if (navigateObj.hasOwnProperty('method')) {
      setAuthMethod(method);
    }
    if (navigateObj.hasOwnProperty('step')) {
      setCurrentStep(step);
    }
    if (navigateObj.hasOwnProperty('state')) {
      setFbState(state);
    }
    if (navigateObj.hasOwnProperty('subMethod')) {
      setSubAuthMethod(subMethod);
    }
  }

  function onBackgroundClick() {
    if (setCurrentStep === 0) {
      closeModal();
    } else {
      backwardStep();
    }
  }
  function flushFlow() {
    setMainFlow(SIGN_IN);
    setAuthMethod(LOCAL);
    setCurrentStep(0);
  }

  /**
   * @typedef {object} defaultPortal
   * @property {string?} flow
   * @property {string?} method
   * @property {number?} step
   *
   */
  function openModal(defaultPortal = null, onClose = null) {
    if (defaultPortal) {
      navigateFlow(defaultPortal);
    } else {
      flushFlow();
    }
    setIsModalOpen(true);
    if (onClose && typeof onClose === 'function') {
      setOnClose(onClose);
    }
  }
  function closeModal() {
    flushFlow();
    setIsModalOpen(false);
    if (onClose) {
      onClose(JSON.parse(localStorage.getItem('user'))?.id, user);
    }
  }
  const UIMapper = useMemo(() => {
    return {
      [LOGIN_MODAL]: <LoginModal />,
      [SIGN_UP_MODAL]: <SignupModal />,
      [CONNECT_WALLET]: <ConnectWalletModal />,
      [ENTER_EMAIL]: <EnterEmailModal />,
      [TERMS]: <TermsAndConditionsModal />,
      [VERIFY_EMAIL]: <VerifyEmailModal />,
      [THANK_YOU]: <ThankyouModal />,
      [WELCOME]: <WelcomeModal />,
      [RESET_PW]: <ResetPasswordModal />,
      [REACTIVATE_MODAL]: <ReactivateModal />,
      [EMAILlNOTPRESENT_MODAL]: <FacebookEmailNotPresentModal />,
    };
  }, []);

  const getAuthModal = () => {
    return isModalOpen ? (
      <>
        {
          UIMapper[
            mainFlow === RESET_PASSWORD ||
            mainFlow === REACTIVATE ||
            mainFlow === EMAILNOTPRESENT
              ? flowSteps[mainFlow][currentStep]
              : flowSteps[mainFlow][authMethod][currentStep]
          ]
        }
      </>
    ) : null;
  };

  return {
    mainFlow,
    setMainFlow,
    authMethod,
    setAuthMethod,
    forwardStep,
    backwardStep,
    navigateFlow,
    currentStep,
    setCurrentStep,
    onBackgroundClick,
    sharedValue,
    setSharedValue,
    flushFlow,
    closeModal,
    openModal,
    isModalOpen,
    getAuthModal,
    fbState,
    setFbState,
    authSubMethod,
    setSubAuthMethod,
  };
};
