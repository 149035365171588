import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import {
  InstagramGrayIcon,
  InstagramLiveIcon,
  InstagramReelsIcon,
  InstagramStoryIcon,
  TikTokIcon,
  TikTokLiveIcon,
  YoutubeLightIcon,
  YoutubeLiveIcon,
  YoutubeShortsIcon,
} from 'assets/icons';
import PlatformIcon from 'components/Basics/PlatformIcon';
import Platform from './platform';

export const contentTypeNames = {
  YouTubeVideo: 'youtubeVideo',
  YouTubeShorts: 'youtubeShorts',
  YouTubeLive: 'youtubeLive',
  TwitterTweet: 'tweets',
  InstagramPost: 'instagramPost',
  InstagramStory: 'instagramStory',
  InstagramReel: 'instagramReel',
  InstagramLive: 'instagramLive',
  TikTokVideo: 'tiktokVideo',
  TikTokLive: 'tiktokLive',
};

export const nonAnalyzableContents = [
  contentTypeNames.InstagramLive,
  contentTypeNames.InstagramStory,
  contentTypeNames.TikTokLive,
  contentTypeNames.TwitterTweet,
];

export const nonDraftContents = [
  contentTypeNames.YouTubeLive,
  contentTypeNames.InstagramLive,
  contentTypeNames.TikTokLive,
];

const contentTypes = {
  youtube: [
    {
      label: ProfileKeys.Edit.Package.Contents.YouTube.video,
      value: contentTypeNames.YouTubeVideo,
      icon: <YoutubeLightIcon />,
      mobileIcon: <YoutubeLightIcon size={0.73} />,
      min: 0,
      max: 5,
    },
    {
      label: ProfileKeys.Edit.Package.Contents.YouTube.shorts,
      value: contentTypeNames.YouTubeShorts,
      icon: <YoutubeShortsIcon />,
      mobileIcon: <YoutubeShortsIcon size={0.89} />,
      min: 0,
      max: 5,
    },
    {
      label: ProfileKeys.Edit.Package.Contents.YouTube.live,
      value: contentTypeNames.YouTubeLive,
      icon: <YoutubeLiveIcon />,
      mobileIcon: <YoutubeLiveIcon size={0.73} />,
      min: 0,
      max: 5,
    },
  ],
  twitter: [
    {
      label: ProfileKeys.Edit.Package.Contents.Twitter.post,
      value: contentTypeNames.TwitterTweet,
      icon: <PlatformIcon platform={Platform.Twitter} variant="size5" />,
      mobileIcon: <PlatformIcon platform={Platform.Twitter} variant="size5" />,
      min: 0,
      max: 5,
    },
  ],
  instagram: [
    {
      label: ProfileKeys.Edit.Package.Contents.Instagram.instagram_post,
      value: contentTypeNames.InstagramPost,
      icon: <InstagramGrayIcon />,
      mobileIcon: <InstagramGrayIcon size={0.8} />,
      min: 0,
      max: 5,
    },
    {
      label: ProfileKeys.Edit.Package.Contents.Instagram.instagram_story,
      value: contentTypeNames.InstagramStory,
      icon: <InstagramStoryIcon />,
      mobileIcon: <InstagramStoryIcon size={0.8} />,
      min: 0,
      max: 5,
    },
    {
      label: ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels,
      value: contentTypeNames.InstagramReel,
      icon: <InstagramReelsIcon />,
      mobileIcon: <InstagramReelsIcon size={0.8} />,
      min: 0,
      max: 5,
    },
    {
      label: ProfileKeys.Edit.Package.Contents.Instagram.instagram_live,
      value: contentTypeNames.InstagramLive,
      icon: <InstagramLiveIcon />,
      mobileIcon: <InstagramLiveIcon size={0.53} />,
      min: 0,
      max: 5,
    },
  ],
  tiktok: [
    {
      label: ProfileKeys.Edit.Package.Contents.Tiktok.video,
      value: contentTypeNames.TikTokVideo,
      icon: <TikTokIcon />,
      mobileIcon: <TikTokIcon size={0.94} />,
      min: 0,
      max: 5,
    },
    {
      label: ProfileKeys.Edit.Package.Contents.Tiktok.live,
      value: contentTypeNames.TikTokLive,
      icon: <TikTokLiveIcon />,
      mobileIcon: <TikTokLiveIcon size={0.73} />,
      min: 0,
      max: 5,
    },
  ],
};

// a function that takes in a content type and returns the platform using contentTypes object
export const contentToPlatform = (contentType) => {
  for (const platform in contentTypes) {
    if (contentTypes[platform].find((type) => type.value === contentType)) {
      return platform;
    }
  }
  return null;
};

export default contentTypes;
