import { useRef, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Tool } from '..';
import InsertImageModal from '../plugins/ImagePlugin/InsertImageModal';
import { useEvent, useClickAway } from 'react-use';

const modalMap = {
  [Tool.IMAGE]: InsertImageModal,
};

const ToolModal = ({ name, className, shortKey, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalRef = useRef(null);
  useClickAway(modalRef, () => {
    setIsModalOpen(false);
  });

  useEvent('keydown', (event) => {
    if (event.key === 'Escape') {
      setIsModalOpen(false);
    }

    // Open modal with shortcut key (Ctrl + shortKey)
    if (shortKey && event.ctrlKey && event.key === shortKey) {
      setIsModalOpen(true);
    }
  });

  const ModalComponent = modalMap[name];

  return (
    <Wrapper>
      <Trigger
        className={className}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        {children}
      </Trigger>

      <AnimatePresence>
        {isModalOpen && (
          <Backdrop>
            <Modal ref={modalRef} onClick={(event) => event.stopPropagation()}>
              <ModalComponent onClose={() => setIsModalOpen(false)} />
            </Modal>
          </Backdrop>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default ToolModal;

const Wrapper = styled.div`
  position: relative;
`;

const Trigger = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: 0;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const Backdrop = styled(motion.div).attrs({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
})`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const Modal = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    scale: 0.95,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.95,
  },
})`
  padding: 1rem;
  z-index: 10;
`;
