import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {
  BillingIcon1,
  DollarIcon,
  EnvelopeIcon,
  HomeIcon,
  InfoIcon,
  ProfileIcon,
  ProposalSquareIcon,
  QuestionMarkIcon,
  SettingsIcon,
  ThreeDBox1,
  WalletIcon1,
} from 'assets/icons';
import useUser from 'hooks/useUser';
import device from 'styles/device';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { useOfficialLaunchModal } from 'contexts/OfficialLaunchModal';
import Heading from 'components/Basics/Typography/Heading';
import useAuth from 'hooks/useAuth';
import Image from 'components/Basics/Image';
import Stack from './../../Stack';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import Box from './Box';
import SidebarLanguagesView from './SidebarLanguagesDropdown';
import { mainFlow, authPlatform } from 'constants/authFlow';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import UserType from 'constants/userType';
import { useEffect, useMemo, useState } from 'react';
import languages from 'constants/translationLanguages';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';

const { LOCAL } = authPlatform;
const { SIGN_UP } = mainFlow;

/**
 * @param {string} viewType - which view is the sidebar being rendered in - navigation or languages
 */

const Sidebar = ({ visible, toggleVisible, viewType = 'navigation' }) => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, user, currentType, navigateToProfile } = useUser();
  const { height } = useWindowDimensions();
  const { openModal } = useAuthFlowManager();
  const openOfficialLaunchModal = useOfficialLaunchModal();
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveScreen();
  const [defaultViewType, setDefaultViewType] = useState(viewType);
  const { getTranslatedText } = useAutoTranslation();

  const selected = useMemo(
    () =>
      languages.find(
        ({ value }) => value === i18n.language || i18n.language.includes(value)
      ),
    [i18n.language]
  );

  useEffect(() => {
    setDefaultViewType(viewType);

    return () => {
      setDefaultViewType(viewType);
    };
  }, [viewType]);

  return (
    <>
      {isMobile && (
        <>
          <Backdrop
            open={visible}
            onClick={() => {
              toggleVisible();
              // after 100ms to avoid the sidebar animation
              setTimeout(() => setDefaultViewType('navigation'), 100);
            }}
          />

          <Wrapper sidebarVisible={visible} height={height}>
            {defaultViewType === 'navigation' ? (
              <>
                {isLoggedIn ? (
                  <User
                    gap="0.75rem"
                    align="center"
                    justify="flex-start"
                    direction="row"
                    onClick={() => navigateToProfile(false)}
                  >
                    <Image
                      src={user?.pic}
                      maxWidth="36px"
                      maxHeight="36px"
                      circle
                    />
                    <Heading variant="sbh6" className="truncate w-auto">
                      {getTranslatedText(user?.name)}
                    </Heading>
                  </User>
                ) : (
                  <div
                    onClick={() => {
                      openModal();
                      toggleVisible();
                    }}
                  >
                    <Heading variant="SBH5">{t(GeneralKeys.login)}</Heading>
                  </div>
                )}

                <Box
                  toggleVisible={toggleVisible}
                  icon={<HomeIcon />}
                  label={t(GeneralKeys.home)}
                  to="/"
                />
                <Box
                  toggleVisible={toggleVisible}
                  icon={<InfoIcon />}
                  label={t(GeneralKeys.about)}
                  to="/about"
                />
                <Box
                  toggleVisible={toggleVisible}
                  icon={<DollarIcon />}
                  label={t(GeneralKeys.pricing)}
                  to="/pricing"
                />
                <Box
                  toggleVisible={toggleVisible}
                  icon={<WalletIcon1 />}
                  label={t(GeneralKeys.evo_tokens)}
                  to="/evo-points"
                />

                <Divider />

                {isLoggedIn && (
                  <>
                    <Box
                      toggleVisible={toggleVisible}
                      icon={<ProfileIcon size={0.75} />}
                      label={t(GeneralKeys.profile)}
                      to={'/' + (user?.handle ? `@${user?.handle}` : user?.id)}
                    />

                    <Box
                      toggleVisible={toggleVisible}
                      icon={<ThreeDBox1 />}
                      label={t(GeneralKeys.my_orders)}
                      onClick={openOfficialLaunchModal}
                    />
                    {currentType === UserType.Client && (
                      <Box
                        toggleVisible={toggleVisible}
                        icon={<ProposalSquareIcon />}
                        label={t(GeneralKeys.proposals)}
                        onClick={openOfficialLaunchModal}
                      />
                    )}
                    <Box
                      toggleVisible={toggleVisible}
                      icon={<EnvelopeIcon small size={0.75} />}
                      label={t(GeneralKeys.messages)}
                      to="/messages"
                    />

                    <Divider />

                    <Box
                      toggleVisible={toggleVisible}
                      icon={<BillingIcon1 />}
                      label={t(GeneralKeys.billing)}
                      onClick={openOfficialLaunchModal}
                    />

                    <Box
                      toggleVisible={toggleVisible}
                      icon={<SettingsIcon size={0.6} />}
                      label={t(GeneralKeys.settings)}
                      to="/settings"
                    />
                  </>
                )}

                <Box
                  toggleVisible={toggleVisible}
                  icon={<QuestionMarkIcon size={0.67} />}
                  label={t(GeneralKeys.help_center)}
                  to="/help-center"
                />

                <Stack
                  direction="row"
                  align="center"
                  justify="center"
                  gap="0.5rem"
                  onClick={() => setDefaultViewType('languages')}
                >
                  <Image src={selected.flag} width="12px" height="9px" />
                  <LanguageLabel variant="SBH7">{selected.label}</LanguageLabel>
                </Stack>

                {isLoggedIn ? (
                  <Box
                    toggleVisible={toggleVisible}
                    label={t(GeneralKeys.logout)}
                    labelColor="var(--color-indigo-100)"
                    onClick={async () => {
                      await signOut();
                      navigate('/');
                    }}
                  />
                ) : (
                  <Box
                    toggleVisible={toggleVisible}
                    label={t(LoginKeys.Login_Form.sign_up)}
                    onClick={() =>
                      openModal({
                        flow: SIGN_UP,
                        method: LOCAL,
                        step: 0,
                      })
                    }
                  />
                )}
              </>
            ) : (
              <SidebarLanguagesView
                toggleVisible={() => {
                  toggleVisible();
                  // after 100ms to avoid the sidebar animation
                  setTimeout(() => setDefaultViewType('navigation'), 100);
                }}
              />
            )}
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Sidebar;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: ${(props) => (props.open ? 'block' : 'none')};
  height: 100vh;
  z-index: 1002;
`;

const Wrapper = styled.div.attrs({
  className: 'shadow-card-subtle',
})`
  background: #fff;
  width: 240px;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  align-items: start;
  justify-content: flex-start;
  padding: 1.5rem 1.875rem 1.875rem;
  position: fixed;
  top: 0;
  right: 0%;
  z-index: 1002;
  border-radius: 12px 0 0 12px;
  overflow: hidden;
  max-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  transform: ${({ sidebarVisible }) =>
    sidebarVisible ? 'translateX(0)' : 'translateX(110%)'};
  transition: transform 0.25s ease-in-out;

  @media ${device.md} {
    display: none;
  }
`;

const User = styled(Stack)`
  max-width: 167px;
  flex-wrap: nowrap;
`;

const Divider = styled.div`
  width: 180px;
  height: 1px;
  background: var(--color-indigo-25);
`;

const LanguageLabel = styled(Heading)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
`;
