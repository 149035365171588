export const HeadphoneIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 18}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.5053 6.19246H15.8558C15.6782 6.19246 15.6723 6.07018 15.6661 6.00933C15.3256 2.63649 12.603 0 9.30564 0H8.69436C5.42138 0 2.71475 2.59755 2.3418 5.93459C2.33228 6.02007 2.32729 6.19246 2.17772 6.19246H1.49469C1.0464 6.19246 0 6.68833 0 9.19254V10.5068C0 12.3641 1.0464 12.7924 1.49469 12.7924H3.66777C4.11606 12.7924 4.48282 12.4069 4.48282 11.9356V7.04924C4.48282 6.578 4.11606 6.19246 3.66777 6.19246C3.66777 6.19246 3.56546 6.18302 3.59153 5.99146C3.95012 3.35412 6.09094 1.31036 8.69436 1.31036H9.30564C11.8914 1.31036 14.0379 3.32393 14.4013 5.93773C14.413 6.02201 14.4567 6.19246 14.3322 6.19246C13.8839 6.19246 13.5172 6.578 13.5172 7.04924V11.9356C13.5172 12.4069 13.8839 12.7924 14.3322 12.7924H14.7403C14.8525 12.7924 14.8334 12.8867 14.8234 12.9341C14.491 14.512 13.7446 16.3019 12.0693 16.3019H10.8283C10.7017 16.3019 10.6704 16.2044 10.6463 16.1582C10.4453 15.7721 10.0554 15.5102 9.60719 15.5102H8.46376C7.8097 15.5102 7.27945 16.0675 7.27945 16.7551C7.27945 17.4427 7.8097 18 8.46376 18H9.60719C10.0615 18 10.4559 17.731 10.6545 17.3364C10.6718 17.3019 10.6861 17.2302 10.8201 17.2302H12.0693C13.2072 17.2302 14.73 16.6358 15.5225 13.8038C15.6069 13.502 15.6754 13.2013 15.7312 12.9115C15.7389 12.8716 15.7437 12.7925 15.8621 12.7925H16.5053C16.9536 12.7925 18 12.3641 18 10.5068V9.19259C18 6.72402 16.9535 6.19246 16.5053 6.19246Z" />
  </svg>
);
