const lexicalTheme = {
  root: 'editor-root',
  paragraph: 'editor-text',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    underline: 'editor-text-underline',
    highlight: 'editor-text-highlight',
  },
  list: {
    ol: 'editor-list-ol',
    olDepth: [
      'editor-list-ol-depth-1',
      'editor-list-ol-depth-2',
      'editor-list-ol-depth-3',
    ],
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
    nested: {
      listitem: 'editor-nested-listitem',
    },
  },
  heading: {
    h1: 'bh1 editor-h1',
    h2: 'sbh2 editor-h2',
    h3: 'bh2.5 editor-h3',
    h4: 'sbh3 editor-h4',
    h5: 'bh4 editor-h5',
    h6: 'bh5 editor-h6',
  },
  image: 'editor-image',
};

export default lexicalTheme;
