import styled from 'styled-components';

/**
 * @param {string} [variant] - The variant of the button.
 * - filled
 * - outline
 * @param {string} [as] - The tag name of the button. (optional)
 * - button
 * - a
 * - Link
 * @param {Node} children - The content of the button.
 * @param {string} [padding] - The padding of the button.
 * @param {string} [className] - The additional class name of the button.
 * @param {string} [minWidth] - The minimum width of the button. (default: 12.5rem)
 * @additional The rest of the props are passed to the underlying component.
 */
const IndigoButton = ({
  variant = 'filled',
  as = 'button',
  children,
  padding,
  radius,
  height,
  minWidth,
  className,
  disabled,
  ...rest
}) => {
  return (
    <Button
      as={as}
      variant={variant}
      className={className}
      padding={padding}
      radius={radius ? radius : undefined}
      height={height}
      minWidth={minWidth}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default IndigoButton;

const Button = styled.button.attrs({
  className: 'btn2',
})`
  border: ${({ variant }) =>
    variant === 'filled' ? 'none' : '1px solid var(--color-indigo-500)'};
  background-color: ${({ variant }) =>
    variant === 'filled' ? 'var(--color-indigo-500)' : 'var(--color-white)'};
  padding: ${({ padding }) => (padding ? padding : '0 1rem')};
  color: ${({ variant }) =>
    variant === 'filled' ? 'var(--color-white)' : 'var(--color-indigo-500)'};
  min-width: ${({ minWidth }) => minWidth || '12.5rem'};
  height: ${({ height, padding }) =>
    height ? height : padding ? 'auto' : '4rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ radius }) => (radius ? radius : '0.5rem')};
  box-shadow: var(--shadow-indigo-btn);
  text-decoration: none;

  transition: all 150ms ease-in-out;

  &:hover {
    ${({ variant }) =>
      variant === 'filled'
        ? 'background-color: var(--color-indigo-300)'
        : 'color: var(--color-indigo-300)'};
    border: ${({ variant }) =>
      variant === 'filled' ? 'none' : '1px solid var(--color-indigo-300)'};
  }

  &:active {
    outline: none;
    ${({ variant }) =>
      variant === 'filled'
        ? 'background-color: var(--color-indigo-300)'
        : 'color: var(--color-indigo-400)'};
    border: ${({ variant }) =>
      variant === 'filled' ? 'none' : '1px solid var(--color-indigo-400)'};
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &:disabled:hover {
    ${({ variant }) =>
      variant === 'filled'
        ? 'background-color: var(--color-indigo-100)'
        : 'color: var(--color-indigo-100)'};
    border: 1px solid var(--color-indigo-100);

    cursor: default;
    box-shadow: none;
  }
`;
