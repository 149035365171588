const AutoTranslationKeys = {
  Dropdown: {
    title: 'AutoTranslationKeys.Dropdown.title',
    description: 'AutoTranslationKeys.Dropdown.description',
    order_message_title: 'AutoTranslationKeys.Dropdown.order_message_title',
    order_message_title_mobile:
      'AutoTranslationKeys.Dropdown.order_message_title_mobile',
    order_message_description:
      'AutoTranslationKeys.Dropdown.order_message_description',
    order_message_description_mobile:
      'AutoTranslationKeys.Dropdown.order_message_description_mobile',
  },
  Applying_Translation: {
    title: 'AutoTranslationKeys.Applying_Translation.title',
    btn: 'AutoTranslationKeys.Applying_Translation.btn',
    message: 'AutoTranslationKeys.Applying_Translation.message',
  },
  Displaying_Original: {
    title: 'AutoTranslationKeys.Displaying_Original.title',
    btn: 'AutoTranslationKeys.Displaying_Original.btn',
    message: 'AutoTranslationKeys.Displaying_Original.message',
  },
};

export default AutoTranslationKeys;
