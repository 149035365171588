import { useCallback, useEffect, useState } from 'react';
import AxiosClient from 'components/utilities/AxiosClient';
import { useSocket } from 'contexts/SocketContext';
import { useNotificationSound } from 'contexts/NotificationSoundContext';
import { useTranslation } from 'react-i18next';

const PAGE_SIZE = 10;

const getNotifications = async (cursor) => {
  const res = await AxiosClient.get('/notifications', {
    params: {
      pageSize: PAGE_SIZE,
      cursor,
    },
  });

  return res.data;
};

const useNotifications = () => {
  const socket = useSocket();
  const [cursor, setCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [haveMore, setHaveMore] = useState(true);
  const [items, setItems] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const { playSound } = useNotificationSound();
  const { i18n } = useTranslation();

  const loadMore = useCallback(async () => {
    if (fetching || !haveMore) return;
    setFetching(true);
    const {
      notifications,
      cursor: nextCursor,
      unreadCount,
      haveMore: nextHaveMore,
    } = await getNotifications(cursor);
    setCursor(nextCursor);
    setItems((prev) => [...prev, ...notifications]);
    setUnreadCount(unreadCount);
    setHaveMore(nextHaveMore);
    setFetching(false);
  }, [fetching, cursor, haveMore]);

  useEffect(() => {
    setLoading(true);
    setFetching(true);
    getNotifications()
      .then(({ notifications, unreadCount, cursor, haveMore }) => {
        setCursor(cursor);
        setItems(notifications);
        setUnreadCount(unreadCount);
        setHaveMore(haveMore);
      })
      .finally(() => {
        setLoading(false);
        setFetching(false);
      });
  }, [i18n.language]);

  const readNotification = useCallback((id) => {
    setUnreadCount((prev) => prev - 1);
    setItems((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isRead: true,
          };
        }
        return item;
      })
    );
    AxiosClient.put('/notifications', {
      id,
    }).catch((err) => {
      setUnreadCount((prev) => prev + 1);
      setItems((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              isRead: false,
            };
          }
          return item;
        })
      );
    });
  }, []);

  const readAllNotifications = useCallback(() => {
    setUnreadCount(0);
    setItems((prev) =>
      prev.map((item) => {
        return {
          ...item,
          isRead: true,
        };
      })
    );

    AxiosClient.put('/notifications').catch((err) => {});
  }, [setUnreadCount, setItems]);

  useEffect(() => {
    const catchNewNotification = (newItem) => {
      if (document.hidden) playSound();
      setItems((prev) => [newItem, ...prev]);
      setUnreadCount((prev) => prev + 1);
    };

    socket.on('notification', catchNewNotification);

    return () => {
      socket.off('notification', catchNewNotification);
    };
  }, [socket, playSound]);

  return {
    loading,
    fetching,
    items,
    unreadCount,
    readNotification,
    readAllNotifications,
    loadMore,
  };
};

export default useNotifications;
