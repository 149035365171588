import Loading from 'components/components/Common/Loading';
import { mainFlow } from 'constants/authFlow';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import useUser from 'hooks/useUser';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const { SIGN_IN } = mainFlow;

const AuthRedirectRoute = ({ children }) => {
  const { isLoggedIn, isLoading } = useUser();
  const navigate = useNavigate();
  const { openModal } = useAuthFlowManager();
  const redirectUrl = useRef();

  useEffect(() => {
    if (!isLoggedIn && !isLoading) {
      redirectUrl.current = window.location.pathname;
      navigate('/');
      openModal({ flow: SIGN_IN }, (loggedInSuccessfully) => {
        if (loggedInSuccessfully) {
          navigate(redirectUrl.current);
        }
      });
    }
  }, [isLoggedIn, isLoading, navigate, openModal]);

  if (isLoading || !isLoggedIn) {
    return <Loading />;
  }

  return children;
};

export default AuthRedirectRoute;
