import { useMemo } from 'react';
import styled from 'styled-components';

const textToHTML = (text) => {
  const lines = text?.split('\n') || [];

  return lines.map((line, index) => {
    return (
      <>
        <span key={index}>{line}</span>
        {index !== lines.length - 1 && <br />}
      </>
    );
  });
};

const PlainText = ({ value, snippetData, className }) => {
  const descriptionText = useMemo(() => {
    try {
      const parsed = JSON.parse(value);
      let text = parsed.text;

      // if there is any text in {{variable}} then replace it with the value from snippetData
      const snippetRegex = /{{(.*?)}}/g;
      if (snippetRegex.test(text) && snippetData) {
        const variables = text.match(snippetRegex);
        variables.forEach((variable) => {
          const key = variable.replace(/{{|}}/g, '');
          text = text.replace(variable, snippetData[key] || '');
        });
      }

      return text;
    } catch (e) {
      return value;
    }
  }, [value, snippetData]);

  return <Text className={className}>{textToHTML(descriptionText)}</Text>;
};

export default PlainText;

const Text = styled.p`
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
`;
