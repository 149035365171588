import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';

import { StateSyncPlugin } from './plugins/StateSyncPlugin';
import { ListMaxIndentLevelPlugin } from './plugins/ListMaxIndentLevelPlugin';
import { MaxLengthPlugin } from './plugins/MaxCharCountPlugin';
import { MarkdownPlugin } from './plugins/MarkdownShortcutPlugin';
import AutocompletePlugin from './plugins/AutocompletePlugin';

import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { AutocompleteNode } from './plugins/AutocompletePlugin/AutocompleteNode';

import styled from 'styled-components';

import { EditorContextProvider } from './EditorContext';
import { SharedAutocompleteContext } from './plugins/AutocompletePlugin/SharedAutocompleteContext';

import Content from './Content';
import Toolbar from './Toolbar';
import ToolButton from './Toolbar/ToolButton';
import Tool from './Toolbar/Tool';
import CharCount from './CharCount';
import { useEditorContext } from './EditorContext';
import { ExtendedTextNode } from './nodes/ExtendedTextNode';
import { TextNode } from 'lexical';
import { SnippetValuesProvider } from './plugins/SnippetPlugin/SnippetValuesContext';
import SnippetPlugin from './plugins/SnippetPlugin';
import { SnippetNode } from './plugins/SnippetPlugin/SnippetNode';
import PlainText from './PlainText';
import ToolOptions from './Toolbar/ToolOptions';
import ToolModal from './Toolbar/ToolModal';
import ImagesPlugin from './plugins/ImagePlugin';
import { ImageNode } from './plugins/ImagePlugin/ImageNode';
import lexicalTheme from './lexicalTheme';

const LexicalEditor = ({
  name,
  value,
  onChange,
  className,
  children,
  maxCharCount,
  readOnly,
  contextData,
  snippetData,
  showImageCaption,
  enableHeadings,
}) => {
  return (
    <Container className={className}>
      <SharedAutocompleteContext>
        <SnippetValuesProvider values={snippetData} name={name}>
          <LexicalComposer
            initialConfig={{
              namespace: name,
              editable: !readOnly,
              theme: lexicalTheme,
              onError: (error) => console.error(error),
              nodes: [
                ExtendedTextNode,
                {
                  replace: TextNode,
                  with: (node) => new ExtendedTextNode(node.__text, node.__key),
                },
                ListItemNode,
                ListNode,
                AutocompleteNode,
                SnippetNode,
                ImageNode,
                ...(enableHeadings ? [HeadingNode] : []),
              ],
            }}
          >
            <EditorContextProvider showImageCaption={showImageCaption}>
              {children}
              <StateSyncPlugin state={value} setState={onChange} />
              <HistoryPlugin />
              <ListPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <TabIndentationPlugin />
              <AutocompletePlugin
                contextData={contextData}
                gptConfig={{
                  priorPrompt: name,
                  returnTokens: 50,
                  temperature: 0.6,
                  topP: 1,
                }}
                maxCharCount={maxCharCount}
              />
              <MarkdownPlugin enableHeadings={enableHeadings} />
              <SnippetPlugin />
              <ImagesPlugin />
              {maxCharCount && <MaxLengthPlugin maxLength={maxCharCount} />}
            </EditorContextProvider>
          </LexicalComposer>
        </SnippetValuesProvider>
      </SharedAutocompleteContext>
    </Container>
  );
};

LexicalEditor.Content = Content;
LexicalEditor.PlainText = PlainText;
LexicalEditor.Toolbar = Toolbar;
LexicalEditor.ToolButton = ToolButton;
LexicalEditor.ToolOptions = ToolOptions;
LexicalEditor.ToolModal = ToolModal;
LexicalEditor.CharCount = CharCount;

export default LexicalEditor;
export { Tool, Content, Toolbar, ToolButton, CharCount, useEditorContext };

const Container = styled.div`
  position: relative;
  font-family: Kumbh Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.15px;

  .editor-root {
    width: 100%;
    height: 100%;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  .editor-text {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-text-highlight,
  mark {
    background: #fff1be;
    margin: 0;
    padding: 0;
  }

  .editor-list-ol,
  .editor-list-ul,
  ul,
  ol {
    margin: 0;
  }

  .editor-list-ul li,
  ul li {
    list-style-type: '•  ';
  }

  .editor-list-ol-depth-2 {
    list-style-type: lower-alpha;
  }

  .editor-list-ol-depth-3 {
    list-style-type: lower-roman;
  }

  .editor-nested-listitem,
  li:has(> ul),
  li:has(> ol) {
    list-style-type: none !important;
  }

  .editor-image {
    cursor: default;
    display: inline-block;
    position: relative;
    user-select: none;
  }
`;
