import LoginKeys from '../translationKeys/LoginPageKeys';

export const LoginTranslations = {
  // Google
  [LoginKeys.Google.sign_in]: 'Google로 로그인',

  // Facebook
  [LoginKeys.Facebook.sign_in]: 'Facebook으로 로그인',
  [LoginKeys.Facebook.sign_up]: 'Facebook으로 가입하기',

  // Wallet
  [LoginKeys.Wallet.sign_in]: '지갑으로 로그인',
  [LoginKeys.Wallet.sign_up]: '지갑으로 가입하기',

  // Password
  [LoginKeys.Password.forgot]: '비밀번호를 잊으셨나요?',

  [LoginKeys.Password.Rule.title]: '비밀번호는 다음을 포함해야 합니다:',
  [LoginKeys.Password.Rule.l1]: '· 최소 8자 이상',
  [LoginKeys.Password.Rule.l2]: '· 알파벳 문자 1개 이상',
  [LoginKeys.Password.Rule.l3]: '· 숫자 1개 이상',
  [LoginKeys.Password.Rule.l4]: '· 특수 문자 1개 이상 (!,@,#,$,...)',

  // Login Form
  [LoginKeys.Login_Form.Placeholder.email]: '이메일 입력',
  [LoginKeys.Login_Form.Placeholder.password]: '비밀번호 입력',

  [LoginKeys.Login_Form.no_account]: '계정이 없으신가요?',
  [LoginKeys.Login_Form.create_account]: '계정 만들기',

  [LoginKeys.Login_Form.sign_up]: '가입하기',

  // Sign Up Form
  [LoginKeys.Sign_Up_Form.Placeholder.email]: '이메일로 가입하기',
  [LoginKeys.Sign_Up_Form.Placeholder.password]: '비밀번호 입력',

  [LoginKeys.Sign_Up_Form.Terms_of_Service.title]: '이용 약관에 동의해주세요',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all]: '모든 약관에 동의하기',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description]:
    '필수 약관과 선택 약관에 모두 동의하는 것을 포함합니다. 선택 약관 중 어떤 것에도 동의하지 않더라도 서비스를 이용할 수 있습니다.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.age]: '14세 이상입니다.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions]: '이용 약관',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing]:
    '이벤트 및 마케팅 정보 수신',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing_description]:
    '이메일과 앱을 통해 MegaEvolution에서 제공하는 유용한 뉴스레터 및 다양한 정보, 이벤트 및 혜택을 받을 수 있습니다.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies]:
    '개인 정보 보호 정책',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies]: '결제 정책',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.optional]: '(선택 사항)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.required]: '(필수)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree]: '동의하고 가입하기',

  [LoginKeys.Sign_Up_Form.go_back]: '로그인으로 돌아가기',

  [LoginKeys.Sign_Up_Form.password_updated]: '비밀번호가 업데이트되었습니다!',
  [LoginKeys.Sign_Up_Form.thank_you_for_signing_up]: '가입을 축하드립니다!',

  // Error
  [LoginKeys.Error.network]: '네트워크 오류 - 나중에 다시 시도해주세요.',
  [LoginKeys.Error.incorrect]: '잘못된 이메일 또는 비밀번호입니다.',
  [LoginKeys.Error.recently_deleted]:
    '이 계정은 최근에 삭제되었습니다. 24시간 후에 다시 시도해주세요.',
  [LoginKeys.Error.login_failed]:
    '로그인에 실패했습니다. 나중에 다시 시도해주세요.',
  [LoginKeys.Error.already_in_use]: '해당 이메일은 이미 사용 중입니다.',
  [LoginKeys.Error.wallet_not_verified]: '지갑을 확인할 수 없습니다.',
  [LoginKeys.Error.already_in_use_or_connect_another_method]:
    '이미 사용중인 이메일이거나 다른 방법으로 가입되어 있습니다.',
  [LoginKeys.Error.currently_deactiated_reset_password]:
    '이 계정은 현재 비활성화되어 있습니다. 다시 활성화시키려면 비밀번호를 재설정해주세요.',
  [LoginKeys.Error.currently_deactiated_login]:
    '이 계정은 현재 비활성화되어 있습니다. 다시 활성화시키려면 로그인해주세요.',
  [LoginKeys.Error.enter_valid_email]: '유효한 이메일을 입력해주세요.',
  [LoginKeys.Error.invalid_request]: '잘못된 요청입니다.',
  [LoginKeys.Error.too_many_request]:
    '너무 많은 요청이 요청되었습니다. 나중에 다시 시도해주세요.',
  [LoginKeys.Error.wallet_already_registered]:
    '이 지갑은 이미 등록되어있습니다.',
  [LoginKeys.Error.try_sign_in_social_app]: '소셜 계정으로 로그인해주세요.',
  [LoginKeys.Error.password_used]: '이미 사용된 비밀번호입니다.',

  // Modal
  [LoginKeys.Modal.Connect_Wallet.title]: '지갑 연결',
  [LoginKeys.Modal.Connect_Wallet.description]:
    '사용 가능한 지갑 중 하나를 선택하거나 먼저 지갑을 생성해주세요.',
  [LoginKeys.Modal.Reset_Password.reset]: '비밀번호 재설정',

  [LoginKeys.Modal.Enter_Email.title]: '이메일을 입력해주세요',
  [LoginKeys.Modal.Enter_Email.description]: `
    계정을 생성하려면 이메일을 입력해주세요. <br>
    중요한 주문 알림을 이곳에서 받으실 수 있습니다.`,
  [LoginKeys.Modal.Enter_Email.Placeholder]: '이메일 입력',
  [LoginKeys.Modal.Enter_Email.mobile_placeholder]: 'abcd@gmail.com',

  [LoginKeys.Modal.Reset_Password.description]:
    '비밀번호가 만료되었습니다. 새로운 비밀번호를 선택해주세요.',
  [LoginKeys.Modal.Reset_Password.expired]: '비밀번호가 만료되었습니다!',
  [LoginKeys.Modal.Reset_Password.reset]: '비밀번호 재설정',
  [LoginKeys.Modal.Reset_Password.update_success]:
    '비밀번호가 업데이트되었습니다!',

  [LoginKeys.Modal.Reset_Password.Error.valid_password]:
    '유효한 비밀번호를 입력해주세요.',

  [LoginKeys.Modal.Useful_Reward.title]:
    '구독하시면 유용한 보상 정보를 받으실 수 있습니다!',
  [LoginKeys.Modal.Useful_Reward.description]: `
    언제든지 원클릭으로 구독을 취소하실 수 있습니다! <br>
    구독하지 않으셔도 중요한 주문 알림을 받으실 수 있습니다.`,
  [LoginKeys.Modal.Useful_Reward.button]: '네, 해볼게요!',

  [LoginKeys.Modal.Verify_Email.title]: '이메일을 인증해주세요',
  [LoginKeys.Modal.Verify_Email.description]: `
  {{email}} 이메일을 확인하고 계정을 인증하세요.`,

  [LoginKeys.Modal.Check_Email.title]: '이메일을 확인해주세요',
  [LoginKeys.Modal.Check_Email.description]: `
  비밀번호를 재설정하려면 이메일을 확인해주세요.`,
  [LoginKeys.Modal.Check_Email.button]: '이메일 재전송',

  [LoginKeys.Modal.VIP_Influencer.description]: `
    크리에이터이신가요? 크리에이터로 <br>
    계정을 변경하여 협업을 시작하세요!`,
  [LoginKeys.Modal.VIP_Influencer.im_creator]: '크리에이터입니다!',
  [LoginKeys.Modal.VIP_Influencer.no_im_not]: '건너뛰기 ',

  [LoginKeys.Modal.Welcome_On_Board.description]: `
    환영합니다! <br>
    MEGA에서 선물을 받아보세요!`,
  [LoginKeys.Modal.Welcome_On_Board.hi_mega]: '안녕하세요, MEGA',

  [LoginKeys.Modal.Use_Another_Method.title]: '다른 가입 방법을 사용해주세요',
  [LoginKeys.Modal.Use_Another_Method.description]:
    '페이스북 계정에 연결된 이메일이 없는 것 같습니다. 페이스북을 사용하여 플랫폼에 가입하거나 로그인하려면 이메일 필요합니다. 계정을 생성하기 위해 다른 가입 방법을 사용해주세요.',

  [LoginKeys.Modal.Enter_Your_Email.title]: '이메일을 입력해주세요',
  [LoginKeys.Modal.Enter_Your_Email.description]:
    '비밀번호 재설정 링크를 받으려면 이메일을 입력해주세요.',
  [LoginKeys.Modal.Enter_Your_Email.placeholder]: '이메일을 입력해주세요',

  [LoginKeys.Modal.Reactivate.title]: '현재 계정이 비활성화되었습니다',
  [LoginKeys.Modal.Reactivate.description]: '계정을 활성화하시겠습니까?',
};
