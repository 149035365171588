const Platform = {
  YouTube: 'youtube',
  Instagram: 'instagram',
  Twitter: 'twitter',
  TikTok: 'tiktok',
};

const platformMap = {
  [Platform.YouTube]: 'YouTube',
  [Platform.Instagram]: 'Instagram',
  [Platform.Twitter]: 'X',
  [Platform.TikTok]: 'TikTok',
};

const idRegex = {
  [Platform.YouTube]:
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?(?=.*v=[\w-]+)(?:\S+)?v=|embed\/|v\/|live\/|shorts\/)|youtu\.be\/|m\.youtube\.com\/shorts\/|youtube.com\/shorts\/)([\w-]{11}).*$/,
  [Platform.Instagram]:
    /^https?:\/\/(?:www\.)?instagram\.com\/(?:p|reel)\/([\w-]+)\/?/,
  [Platform.Twitter]:
    /^https?:\/\/twitter\.com\/(?:#!\/)?\w+\/status(?:es)?\/(\d+)/,
  [Platform.TikTok]:
    /https:\/\/(?:(?:www\.)?tiktok\.com\/(?:@[\w.-]+\/video\/|v\/)(\d+)|(?:vt|vm)\.tiktok\.com\/(\w+))/,
};

export default Platform;
export { idRegex, platformMap };
