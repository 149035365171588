export const ShareIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16 * size}
    height={15 * size}
    viewBox="0 0 16 15"
    fill="currentColor"
  >
    <path d="M16 6.69719L8.85931 0.890625V3.85334C1.89925 3.91009 -0.68256 9.10009 0.150815 14.1018C1.67256 10.9927 4.83566 9.76816 8.85931 9.62869V12.5038L16 6.69719Z" />
  </svg>
);
