import LoginKeys from '../translationKeys/LoginPageKeys';

export const LoginTranslations = {
  // Google
  [LoginKeys.Google.sign_in]: 'Iniciar sesión con Google',

  // Facebook
  [LoginKeys.Facebook.sign_in]: 'Iniciar sesión con Facebook',
  [LoginKeys.Facebook.sign_up]: 'Registrarse con Facebook',

  // Wallet
  [LoginKeys.Wallet.sign_in]: 'Iniciar sesión con Wallet',
  [LoginKeys.Wallet.sign_up]: 'Registrarse con Wallet',

  // Password
  [LoginKeys.Password.forgot]: '¿Olvidaste la contraseña?',

  [LoginKeys.Password.Rule.title]: 'Su contraseña debe contener:',
  [LoginKeys.Password.Rule.l1]: '· Al menos 8 caracteres',
  [LoginKeys.Password.Rule.l2]: '· Al menos 1 carácter alfabético',
  [LoginKeys.Password.Rule.l3]: '· Al menos 1 número',
  [LoginKeys.Password.Rule.l4]:
    '· Al menos 1 carácter especial (!, @, #, $, ...)',

  // Login Form
  [LoginKeys.Login_Form.Placeholder.email]: 'Ingresar correo electrónico',
  [LoginKeys.Login_Form.Placeholder.password]: 'Ingresar contraseña',

  [LoginKeys.Login_Form.no_account]: '¿No tienes una cuenta?',
  [LoginKeys.Login_Form.create_account]: 'Crear una cuenta',

  [LoginKeys.Login_Form.sign_up]: 'Registrarse',

  // Sign Up Form
  [LoginKeys.Sign_Up_Form.Placeholder.email]:
    'Registrarse con correo electrónico',
  [LoginKeys.Sign_Up_Form.Placeholder.password]: 'Ingresar contraseña',

  [LoginKeys.Sign_Up_Form.Terms_of_Service.title]:
    'Por favor acepta los Términos del Servicio',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all]:
    'Aceptar todos los términos',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description]:
    'Esto incluye la aceptación de todos los términos obligatorios y opcionales. Puede optar por aceptar o rechazar las condiciones individuales. Puede utilizar el servicio aunque no acepte ninguno de los términos opcionales.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.age]: 'Tengo 14 años o más.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions]:
    'Términos y Condiciones',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing]:
    'Recibir información de eventos y marketing',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing_description]:
    'Puede recibir boletines útiles e información variada, como eventos y beneficios ofrecidos por MegaEvolution a través de correo y la app.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies]:
    'Políticas de privacidad',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies]:
    'Políticas de pago',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.optional]: '(opcional)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.required]: '(requerido)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree]: 'Aceptar y unirse',

  [LoginKeys.Sign_Up_Form.go_back]: 'Volver a iniciar sesión',

  [LoginKeys.Sign_Up_Form.password_updated]:
    '¡Tu contraseña se ha actualizado!',
  [LoginKeys.Sign_Up_Form.thank_you_for_signing_up]:
    '¡Gracias por registrarse!',

  // Error
  [LoginKeys.Error.network]:
    'Error de red - Por favor, inténtalo de nuevo luego.',
  [LoginKeys.Error.incorrect]:
    'Dirección de correo electrónico o contraseña incorrecta.',
  [LoginKeys.Error.recently_deleted]:
    'Esta cuenta fue eliminada recientemente. Por favor, vuelve a intentarlo después de 24 horas.',
  [LoginKeys.Error.login_failed]:
    'Error en el inicio de sesión. Por favor, inténtalo de nuevo luego.',
  [LoginKeys.Error.already_in_use]: 'Este correo electrónico ya está en uso.',
  [LoginKeys.Error.wallet_not_verified]: 'No se pudo verificar la billetera.',
  [LoginKeys.Error.already_in_use_or_connect_another_method]:
    'Este correo electrónico ya está en uso o está conectado a otro método de inicio de sesión.',
  [LoginKeys.Error.currently_deactiated_reset_password]:
    'Esta cuenta está actualmente desactivada. Por favor, restablece la contraseña para reactivarla.',
  [LoginKeys.Error.currently_deactiated_login]:
    'Esta cuenta está actualmente desactivada. Por favor, intenta iniciar sesión para reactivarla.',
  [LoginKeys.Error.enter_valid_email]: 'Ingresa una correo electrónico válida.',
  [LoginKeys.Error.invalid_request]: 'Solicitud inválida.',
  [LoginKeys.Error.too_many_request]:
    'Se realizaron demasiadas solicitudes. Inténtalo de nuevo luego.',
  [LoginKeys.Error.wallet_already_registered]:
    'Esta billetera ya está registrada.',
  [LoginKeys.Error.try_sign_in_social_app]:
    'Por favor, intenta iniciar el sesión con tu app social.',
  [LoginKeys.Error.password_used]: 'Esta contraseña fue utilizada previamente.',

  // Modal
  [LoginKeys.Modal.Connect_Wallet.title]: 'Conectar Billetera',
  [LoginKeys.Modal.Connect_Wallet.description]:
    'Elige uno de los proveedores de billetera disponibles o crea una billetera primero.',
  [LoginKeys.Modal.Reset_Password.reset]: 'Restablecer Contraseña',

  [LoginKeys.Modal.Enter_Email.title]:
    'Por favor ingresa tu correo electrónico',
  [LoginKeys.Modal.Enter_Email.description]: `
    Por favor ingresa tu correo electrónico para crear una cuenta. <br>
    Recibirás notificaciones importantes sobre tus pedidos aquí.`,
  [LoginKeys.Modal.Enter_Email.Placeholder]: 'Ingresa correo electrónico',
  [LoginKeys.Modal.Enter_Email.mobile_placeholder]: 'abcd@gmail.com',

  [LoginKeys.Modal.Reset_Password.description]:
    'Tu contraseña ha expirado. Por favor, elige una nueva contraseña.',
  [LoginKeys.Modal.Reset_Password.expired]: '¡Tu contraseña ha expirado!',
  [LoginKeys.Modal.Reset_Password.reset]: 'Restablecer tu contraseña',
  [LoginKeys.Modal.Reset_Password.update_success]:
    '¡Tu contraseña ha sido actualizada!',

  [LoginKeys.Modal.Reset_Password.Error.valid_password]:
    'Ingresa una contraseña válida',

  [LoginKeys.Modal.Useful_Reward.title]:
    '¡Recibirás información útil de recompensas si te suscribes!',
  [LoginKeys.Modal.Useful_Reward.description]: `
    ¡Puedes darte de baja en un solo clic cuando quieras! <br>
    Seguirás recibiendo notificaciones importantes sobre tus <br>
    pedidos incluso si no te suscribes a nuestro boletín`,
  [LoginKeys.Modal.Useful_Reward.button]: '¡De acuerdo, lo intentaré!',

  [LoginKeys.Modal.Verify_Email.title]:
    'Por favor verifica tu correo electrónico',
  [LoginKeys.Modal.Verify_Email.description]: `
    Revise su correo electrónico en {{email}} y verifique su cuenta.`,

  [LoginKeys.Modal.Check_Email.title]:
    'Por favor verifica tu correo electrónico',
  [LoginKeys.Modal.Check_Email.description]: `
    Por favor, verifique su correo electrónico para restablecer su contraseña.`,
  [LoginKeys.Modal.Check_Email.button]: 'Reenviar Correo Electrónico',

  [LoginKeys.Modal.VIP_Influencer.description]: `
  ¿Eres un creador? ¡Cambia a una <br>
  cuenta de creador para comenzar <br>
  a colaborar!`,
  [LoginKeys.Modal.VIP_Influencer.im_creator]: 'Soy un creador!',
  [LoginKeys.Modal.VIP_Influencer.no_im_not]: 'No soy un creador',

  [LoginKeys.Modal.Welcome_On_Board.description]: `
    ¡Bienvenido a bordo! <br>
    ¡MEGA tiene un regalo para ti!`,
  [LoginKeys.Modal.Welcome_On_Board.hi_mega]: 'Hola, MEGA',

  [LoginKeys.Modal.Use_Another_Method.title]:
    'Por favor utiliza otro método de registro',
  [LoginKeys.Modal.Use_Another_Method.description]:
    'Parece que tu cuenta de Facebook no tiene una dirección de correo electrónico conectada. Para registrarte o iniciar sesión en nuestra plataforma utilizando Facebook, se requiere una dirección de correo electrónico. Por favor utiliza otro método de registro para crear una cuenta.',

  [LoginKeys.Modal.Enter_Your_Email.title]: 'Ingresa tu correo electrónico',
  [LoginKeys.Modal.Enter_Your_Email.description]:
    'Por favor ingresa tu dirección de correo electrónico para recibir un enlace para restablecer tu contraseña.',
  [LoginKeys.Modal.Enter_Your_Email.placeholder]:
    'Ingresa tu correo electrónico',

  [LoginKeys.Modal.Reactivate.title]: 'Cuenta actualmente desactivada',
  [LoginKeys.Modal.Reactivate.description]: '¿Deseas reactivar tu cuenta?',
};
