import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const profilePageRegex = /^\/(@[a-zA-Z0-9_.-]+|[0-9]+)$/;

const useFooterStyles = (
  { defaultBackground, defaultIsVisible } = {
    defaultBackground: undefined,
    defaultIsVisible: undefined,
  }
) => {
  const { pathname } = useLocation();
  const { isMobile } = useResponsiveScreen();

  const isWhite = useMemo(() => {
    return false;
  }, []);

  const isVisible = useMemo(() => {
    if (defaultIsVisible !== undefined) return defaultIsVisible;

    if (
      (isMobile &&
        (pathname.includes('/profile-edit') ||
          profilePageRegex.test(pathname))) ||
      pathname.includes('submit-proposal') ||
      pathname.includes('/proposals') ||
      (pathname.includes('/orders/') && !pathname.includes('/admin/'))
    )
      return false;
    if (pathname.includes('unsubscribe')) return false;
    if (pathname.includes('messages')) return false;

    return true;
  }, [isMobile, pathname, defaultIsVisible]);

  const isBottomNavBarVisible = useMemo(() => {
    if (isMobile && pathname.includes('profile-edit')) return false;
    if (isMobile && new RegExp('messages/[^/]+').test(pathname)) return false;
    // /platforms/:platform/auth/:req_type
    if (/platforms\/[a-zA-Z]+\/auth\/[a-zA-Z]+/.test(pathname)) return false;

    return true;
  }, [isMobile, pathname]);

  const background = useMemo(() => {
    if (defaultBackground !== undefined) return defaultBackground;

    if (
      pathname === '/' ||
      (isMobile &&
        (pathname.includes('/profile-edit') ||
          profilePageRegex.test(pathname) ||
          pathname.includes('/help-center')))
    )
      return 'white';
    if (pathname === '/about') return 'var(--color-pink-100)';
    if (pathname.includes('/profile-edit') || profilePageRegex.test(pathname))
      return 'var(--color-pink-75)';
    if (pathname.includes('/search')) return 'var(--color-white)';
    if (pathname === '/creator-registration' && isMobile)
      return 'var(--color-white)';

    return 'transparent';
  }, [pathname, isMobile, defaultBackground]);

  return {
    isWhite,
    background,
    isVisible,
    isBottomNavBarVisible,
  };
};

export default useFooterStyles;
