export const TikTokIcon = ({ size = 1 }) => (
  <svg
    width={17 * size}
    height={18 * size}
    viewBox="0 0 17 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41271 0.785337H11.7309C11.9543 1.96436 12.6704 2.97604 13.6624 3.60812C13.6627 3.60852 13.6631 3.60893 13.6634 3.60933C14.354 4.04917 15.1774 4.30579 16.0614 4.30579V4.99466L16.0615 7.39987C14.4195 7.39987 12.898 6.88653 11.6562 6.01535V12.3041C11.6562 15.4449 9.04171 18 5.82798 18C4.5862 18 3.43461 17.6173 2.48802 16.9677C2.48751 16.9672 2.487 16.9666 2.4864 16.966C0.984354 15.9347 0 14.2299 0 12.3035C0 9.16288 2.61445 6.60765 5.82818 6.60765C6.09482 6.60765 6.35619 6.62911 6.61352 6.66322V7.39632L6.61341 9.82299C6.3648 9.74697 6.102 9.70273 5.82798 9.70273C4.36035 9.70273 3.16635 10.8698 3.16635 12.3041C3.16635 13.303 3.7462 14.1708 4.59338 14.6065C4.96277 14.7965 5.38237 14.9054 5.82788 14.9054C7.26169 14.9054 8.43129 13.7908 8.48454 12.402L8.4896 0H11.6561C11.6561 0.268258 11.6826 0.530442 11.7309 0.785337H9.41271Z"
    />
  </svg>
);
