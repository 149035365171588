import Platform from 'constants/platform';

import youtubeSize1 from './youtube/size1';
import youtubeSize2 from './youtube/size2';
import youtubeSize3 from './youtube/size3';
import youtubeSize4 from './youtube/size4';
import youtubeSize5 from './youtube/size5';
import youtubeLink1 from './youtube/link1';
import youtubeLink2 from './youtube/link2';
import twitterSize1 from './twitter/size1';
import twitterSize2 from './twitter/size2';
import twitterSize3 from './twitter/size3';
import twitterSize4 from './twitter/size4';
import twitterSize5 from './twitter/size5';
import twitterSize1White from './twitter/white/size1';
import twitterSize2White from './twitter/white/size2';
import twitterSize3White from './twitter/white/size3';
import twitterSize4White from './twitter/white/size4';
import twitterSize5White from './twitter/white/size5';
import twitterLink1 from './twitter/link1';
import twitterLink2 from './twitter/link2';
import tiktokSize1 from './tiktok/size1';
import tiktokSize2 from './tiktok/size2';
import tiktokSize3 from './tiktok/size3';
import tiktokSize4 from './tiktok/size4';
import tiktokSize5 from './tiktok/size5';
import tiktokLink1 from './tiktok/link1';
import tiktokLink2 from './tiktok/link2';
import instagramSize1 from './instagram/size1';
import instagramSize2 from './instagram/size2';
import instagramSize3 from './instagram/size3';
import instagramSize4 from './instagram/size4';
import instagramSize5 from './instagram/size5';
import instagramLink1 from './instagram/link1';
import instagramLink2 from './instagram/link2';

import IconButton from '../Buttons/IconButton';

const platformIcon = {
  [Platform.YouTube]: {
    size1: youtubeSize1,
    size2: youtubeSize2,
    size3: youtubeSize3,
    size4: youtubeSize4,
    size5: youtubeSize5,
    link1: youtubeLink1,
    link2: youtubeLink2,
  },
  [Platform.Twitter]: {
    size1: twitterSize1,
    size2: twitterSize2,
    size3: twitterSize3,
    size4: twitterSize4,
    size5: twitterSize5,
    link1: twitterLink1,
    link2: twitterLink2,
    size1white: twitterSize1White,
    size2white: twitterSize2White,
    size3white: twitterSize3White,
    size4white: twitterSize4White,
    size5white: twitterSize5White,
    link1white: twitterLink1,
    link2white: twitterLink2,
  },
  [Platform.TikTok]: {
    size1: tiktokSize1,
    size2: tiktokSize2,
    size3: tiktokSize3,
    size4: tiktokSize4,
    size5: tiktokSize5,
    link1: tiktokLink1,
    link2: tiktokLink2,
  },
  [Platform.Instagram]: {
    size1: instagramSize1,
    size2: instagramSize2,
    size3: instagramSize3,
    size4: instagramSize4,
    size5: instagramSize5,
    link1: instagramLink1,
    link2: instagramLink2,
  },
};

const getURL = (id, platform) => {
  return {
    [Platform.YouTube]: `https://www.youtube.com/channel/${id}`,
    [Platform.Twitter]: `https://twitter.com/intent/user?user_id=${id}`,
    [Platform.TikTok]: `https://www.tiktok.com/@${id}`,
    [Platform.Instagram]: `https://www.instagram.com/${id}`,
  }[platform];
};

const PlatformIcon = ({ platform, variant, platformUid }) => {
  if (!platform || !variant) return null;
  // throw new Error('PlatformIcon requires platform and variant props');

  const Icon = platformIcon[platform][variant];

  if (platformUid) {
    const url = getURL(platformUid, platform);

    return (
      <IconButton onClick={() => window.open(url, '_blank')}>
        <Icon />
      </IconButton>
    );
  }

  return <Icon />;
};

PlatformIcon.Variants = {
  SIZE1: 'size1',
  SIZE2: 'size2',
  SIZE3: 'size3',
  SIZE4: 'size4',
  SIZE5: 'size5',
  LINK1: 'link1',
  LINK2: 'link2',
  SIZE1WHITE: 'size1white',
  SIZE2WHITE: 'size2white',
  SIZE3WHITE: 'size3white',
  SIZE4WHITE: 'size4white',
  SIZE5WHITE: 'size5white',
  LINK1WHITE: 'link1white',
  LINK2WHITE: 'link2white',
};

export default PlatformIcon;
