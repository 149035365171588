import { forwardRef } from 'react';
import AxiosClient from '../AxiosClient';
import { useState } from 'react';
import { useSocket } from 'contexts/SocketContext';
import Modals from './Modals';
import { useCallback } from 'react';
import { useImperativeHandle } from 'react';

const InstagramVerifier = forwardRef(
  (
    {
      reqType = 'permanent',
      onVerify,
      onError,
      setShowPopupDisclaimer,
      errorModalPrimaryButtonClick,
    },
    ref
  ) => {
    const socket = useSocket();

    const [error, setError] = useState({
      code: null,
      message: null,
    });
    const [loading, setLoading] = useState(false);

    const addLink = useCallback(
      async (res) => {
        if (res.status?.toString()?.startsWith('4') || res.status === 500) {
          setError({
            code: res.status,
            message: res.message,
          });
          onError(res.status);
        } else {
          onVerify(res.message);
        }
        setLoading(false);

        socket.off('addLink', addLink);
      },
      [onVerify, socket, onError]
    );

    const redirectTokenRequestUrl = useCallback(
      async (isBasic) => {
        setLoading(true);
        const { data: tokenRequestUrl } = await AxiosClient.get(
          '/platforms/generateAuthUrl',
          {
            params: {
              platform: 'instagram',
              isBasic: true,
              reqType,
            },
          }
        );
        const popup = window.open(
          tokenRequestUrl,
          'Instagram Verification',
          'height=600,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
        );

        if (!popup) {
          setShowPopupDisclaimer(true);
        }

        socket.off('addLink');
        socket.on('addLink', addLink);
      },
      [reqType, socket, addLink, setShowPopupDisclaimer]
    );

    useImperativeHandle(ref, () => {
      return {
        verify: redirectTokenRequestUrl,
        loading,
      };
    });

    return (
      <Modals
        error={error}
        onClose={() => {
          setError({
            code: null,
            message: null,
          });
          onError({ openReconnectionModal: true });
        }}
        instagram
        onPrimaryButtonClick={errorModalPrimaryButtonClick}
      />
    );
  }
);

export default InstagramVerifier;
