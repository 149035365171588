import withAuthHOC from 'components/components/auth/withAuthHOC';
import RegistrationCompleteBanner from 'components/Basics/Banners/RegistrationCompleteBanner1';

const WelcomeModal = ({ flowManager }) => {
  const { forwardStep, closeModal } = flowManager;
  
  return (
    <RegistrationCompleteBanner
      isOpen={true}
      onClose={forwardStep}
      closeModal={closeModal}
    />
  );
};

export default withAuthHOC(WelcomeModal);
