import { createContext, useCallback, useContext, useState } from 'react';
import AxiosClient from 'components/utilities/AxiosClient';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSocket } from './SocketContext';
import { useMemo } from 'react';
import { useNotificationSound } from './NotificationSoundContext';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const updateUserLanguage = async (selectedLanguage) => {
  try {
    await AxiosClient.patch('/updateLanguage', {
      language: selectedLanguage,
    });
  } catch (err) {
    console.log(err);
  }
};

const updateTimezone = async (timeZone) => {
  try {
    await AxiosClient.patch('/updateTimeZone', {
      timeZone,
    });
  } catch (err) {
    console.log(err);
  }
};

const CurrentUserContext = createContext({
  user: {
    id: '',
    pic: '',
    banner: '',
    name: '',
    bio: null,
    dateJoined: '',
    lastSeen: '',
    lastUpdate: '',
    accountType: '',
    bannerShade: 0,
    handle: '',
    languages: [],
    countries: [],
    categories: [],
    links: [],
    availablePlatforms: [],
    numClients: 0,
    fullInformation: true,
    orders: [],
    reviews: {
      averageRating: 0,
      count: 0,
    },
    company: '',
    companyLogo: '',
    website: '',
    timeZone: '',
    clientPlan: {
      currentPlan: '',
      nextPlan: '',
      startDate: '',
      renewedDate: '',
      renewalDate: '',
      remainingPriceUnlocks: 0,
    },
    evoBalance: 0,
    activeMissions: {
      update_profile: [],
      tv_easter_egg: [],
      visit_main_tabs: [],
      refer_10_early_vips: [],
      first_990_creator_claimed: [],
      referral_ranking: [],
      complete_1_special: [],
    },
  },
  isLoggedIn: false,
  updateUser: () => {},
  clearUser: () => {},
  refetchUser: () => {},
  refetch: () => {},
  currentType: null,
  setCurrentType: () => {},
  error: null,
  isFetching: false,
  isLoading: false,
  navigateToProfile: () => {},
});

const fetchUser = async () => {
  const res = await AxiosClient.get('/getUser');
  if (!res?.data?.id) {
    throw new Error('No User');
  }

  if (res?.data?.isDeactivated === 1) {
    await AxiosClient.get('/logout');
    throw new Error('Deactivated User');
  }

  return res?.data;
};

// /platforms/:platform/auth/:req_type
const disabledPathsRegex = /^\/(platforms\/[a-zA-Z0-9]+\/auth\/[a-zA-Z0-9]+)/;

export const CurrentUserProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const socket = useSocket();
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { reloadSetting } = useNotificationSound();
  const [currentType, setCurrentType] = useState(
    localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).type
      : null
  );

  const [isLoading, setLoading] = useState(true);
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState();

  const getUser = useCallback(
    async (refetching) => {
      if (disabledPathsRegex.test(pathname)) return;
      if (!refetching && user) return;

      if (!refetching) {
        setLoading(true);
        setError(false);
      }
      setFetching(true);

      try {
        const data = await fetchUser();

        setUser(data);
        localStorage.setItem(
          'user',
          JSON.stringify({
            id: data.id,
            type: data.accountType,
          })
        );
        socket.disconnect();
        socket.connect();

        setCurrentType(data.accountType);
      } catch (err) {
        console.log(err);
        setUser(null);
        localStorage.removeItem('user');
        setCurrentType(null);
      }

      if (refetching) {
        reloadSetting();
      }

      setLoading(false);
      setFetching(false);
    },
    [socket, pathname, user, reloadSetting]
  );

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    updateUserLanguage(i18n.language);
  }, [i18n.language]);

  const updateUser = setUser;
  const refetchUser = useCallback(() => getUser(true), [getUser]);
  const refetch = useCallback(() => getUser(true), [getUser]);
  const clearUser = useCallback(() => setUser(null), []);

  // function to navigate to the profile page of the user
  const navigateToProfile = useCallback(
    (_blank = false) => {
      const path = `/${user?.handle ? `@${user?.handle}` : user?.id}`;

      if (_blank) {
        window.open(path, '_blank');
      } else {
        if (path !== pathname)
          navigate(`/${user?.handle ? `@${user?.handle}` : user?.id}`);
      }
    },
    [user, navigate, pathname]
  );

  // if current time zone is not the same as get from backend then update timezone
  useEffect(() => {
    if (user) {
      const currentTimezone = dayjs.tz.guess();
      if (user.timeZone !== currentTimezone) {
        updateTimezone(currentTimezone);
        updateUser({
          ...user,
          timeZone: currentTimezone,
        });
      }
    }
  }, [user, updateUser]);

  const data = useMemo(
    () => ({
      user,
      isLoggedIn: !!user || !!localStorage.getItem('user'),
      updateUser,
      clearUser,
      refetchUser,
      refetch,
      currentType,
      setCurrentType,
      error,
      isFetching,
      isLoading,
      navigateToProfile,
    }),
    [
      user,
      updateUser,
      clearUser,
      refetchUser,
      refetch,
      currentType,
      setCurrentType,
      error,
      isFetching,
      isLoading,
      navigateToProfile,
    ]
  );

  return (
    <CurrentUserContext.Provider value={data}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);
