export const authPlatform = {
  LOCAL: 'local',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  METAMASK: 'metamask',
  COINBASE: 'coinbase',
};

const { GOOGLE, FACEBOOK, METAMASK, COINBASE, LOCAL } = authPlatform;

export const mainFlow = {
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  RESET_PASSWORD: 'reset_password',
  REACTIVATE: 'reactivate',
  EMAILNOTPRESENT: 'fb_mail_not_present',
  CREATORINVITATION: 'creator_invitation',
};
const { SIGN_IN, SIGN_UP, RESET_PASSWORD, REACTIVATE, EMAILNOTPRESENT } =
  mainFlow;

export const authUIKey = {
  LOGIN_MODAL: 'login_modal',
  CONNECT_WALLET: 'connect_wallet',
  SIGN_UP_MODAL: 'signup_modal',
  ENTER_EMAIL: 'enter_email',
  TERMS: 'terms',
  VERIFY_EMAIL: 'verify_email',
  THANK_YOU: 'thank_you',
  WELCOME: 'welcome',
  RESET_PW: 'reset_password',
  REACTIVATE_MODAL: 'reactivate',
  EMAILlNOTPRESENT_MODAL: 'fb_mail_not_present',
};
const {
  LOGIN_MODAL,
  SIGN_UP_MODAL,
  CONNECT_WALLET,
  ENTER_EMAIL,
  TERMS,
  VERIFY_EMAIL,
  THANK_YOU,
  WELCOME,
  RESET_PW,
  CHECK_EMAIL,
  REACTIVATE_MODAL,
  EMAILlNOTPRESENT_MODAL,
} = authUIKey;
export const flowSteps = {
  [SIGN_IN]: {
    [LOCAL]: [LOGIN_MODAL],
    [COINBASE]: [LOGIN_MODAL, CONNECT_WALLET],
    [METAMASK]: [LOGIN_MODAL, CONNECT_WALLET],
    [FACEBOOK]: [LOGIN_MODAL],
    [GOOGLE]: [LOGIN_MODAL],
  },
  [SIGN_UP]: {
    [LOCAL]: [SIGN_UP_MODAL, TERMS, VERIFY_EMAIL, WELCOME, THANK_YOU],
    [COINBASE]: [
      SIGN_UP_MODAL,
      CONNECT_WALLET,
      ENTER_EMAIL,
      TERMS,
      VERIFY_EMAIL,
      WELCOME, THANK_YOU,
    ],
    [METAMASK]: [
      SIGN_UP_MODAL,
      CONNECT_WALLET,
      ENTER_EMAIL,
      TERMS,
      VERIFY_EMAIL,
      WELCOME, THANK_YOU,
    ],
    [FACEBOOK]: [SIGN_UP_MODAL, TERMS, WELCOME, THANK_YOU],
    [GOOGLE]: [SIGN_UP_MODAL, TERMS, WELCOME, THANK_YOU],
  },
  [RESET_PASSWORD]: [
    LOGIN_MODAL,
    ENTER_EMAIL,
    VERIFY_EMAIL,
    RESET_PW,
    THANK_YOU,
  ],
  [REACTIVATE]: [REACTIVATE_MODAL],
  [EMAILNOTPRESENT]: [EMAILlNOTPRESENT_MODAL],
};
