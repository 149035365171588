import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: 'saving',
  [ProposalKeys.changes_saved]: 'changes saved',

  [ProposalKeys.New_Proposal.text]: 'New proposal',
  [ProposalKeys.New_Proposal.toast]:
    'You have reached the max number of proposals you can create.',

  [ProposalKeys.Clear.text]: 'Clear All',
  [ProposalKeys.Clear.toast]: `
    Successfully cleared. <button>Click here</button> to undo this change.`,

  [ProposalKeys.Modal.Copy.title]:
    'Are you sure you want to overwrite the proposal?',
  [ProposalKeys.Modal.Copy.description]: `
    The current proposal will be overwritten by the selected proposal. <br>
    This action cannot be undone. Do you want to continue?`,

  [ProposalKeys.Basic_Info.Project_Title.title]: 'Title',
  [ProposalKeys.Basic_Info.Project_Title.placeholder]: 'Enter project title',
  [ProposalKeys.Basic_Info.Project_Title.error]: 'Max 100 characters',

  [ProposalKeys.Basic_Info.Website.title]: 'Product · Brand Website',
  [ProposalKeys.Basic_Info.Website.placeholder]: 'Enter a URL',
  [ProposalKeys.Basic_Info.Website.error]: 'Max 400 characters',

  [ProposalKeys.Basic_Info.Description.title]: 'Description & Files',
  [ProposalKeys.Basic_Info.Description.placeholder]: `
    Please describe the details of project and attach your files here. <br>
    Creators may look for information regarding, <br><br><br>
    <ul>
    <li>Project/Product description</li>
    <li>Campaign goals and target audience</li>
    <li>Detailed content guidelines</li>
    </ul>`,
  [ProposalKeys.Basic_Info.Description.Snippet.title]:
    'Select a snippet to add',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name]:
    'Creator’s social channel name',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_username]:
    'Creator’s username',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_main_country]:
    'Creator’s main country',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories]:
    'Creator’s top categories',

  [ProposalKeys.Basic_Info.Description.Error.file_format]: `
    Invalid file format. (Please use {{fileFormats}})`,
  [ProposalKeys.Basic_Info.Description.Error.file_size_limit]: `
    Total file size limit is max {{maxSize}}`,
  [ProposalKeys.Basic_Info.Description.Error.max_file]: `
    You can send max {{maxFiles}} files`,

  [ProposalKeys.caution]: `
    Upon ordering a package, all applicable <strong>requirements and content missions</strong> will be <strong>automatically applied</strong> to each content. <strong>Incomplete</strong> or <strong>non-applicable</strong> ones to the ordered content will not be applied.`,

  [ProposalKeys.Side_btn.text]: 'Applicable Contents',
  [ProposalKeys.Side_btn.title]: 'Applicable Contents : ',

  [ProposalKeys.Product_Delivery.title]: 'Send a Product to the Creator',

  [ProposalKeys.Product_Delivery.Delivery.title]: 'Send a sponsored product',
  [ProposalKeys.Product_Delivery.Delivery.tooltip]: `
    This option is incomplete and will not <br>
    be applied to the proposals being sent.`,

  [ProposalKeys.Product_Delivery.Delivery.Image.title]:
    'Delivery Images (Optional)',
  [ProposalKeys.Product_Delivery.Delivery.Image.description]:
    'Add up to 5 images files in JPG, JPEG, PNG, GIF format. Max 100 MB.',

  [ProposalKeys.Product_Delivery.Delivery.Info.title]:
    'Delivery Information (Required)',
  [ProposalKeys.Product_Delivery.Delivery.Info.placeholder]:
    'Please provide information about the product and shipping.',

  [ProposalKeys.Product_Delivery.Delivery.caution_due_date]:
    'You will receive the creator’s mailing address for product delivery. Content creation will begin upon arrival of the product, and the due date will be updated accordingly',
  [ProposalKeys.Product_Delivery.Delivery.caution_return_process]:
    'We do not process returns for delivered products. If your order was canceled after the product was shipped, you will need to discuss the return directly with the creator.',
  [ProposalKeys.Product_Delivery.Delivery.caution_international_delivery]: `
    We help track international delivery, but do not guarantee or cover shipping issues such as mis-delivery.
    <Tooltip>Domestic delivery</Tooltip> is trackable for only a few countries but you can still include it in the order.`,
  [ProposalKeys.Product_Delivery.Delivery
    .caution_international_delivery_tooltip]: `
    We support domestic tracking for major carriers <br>
    in limited countries, including the US and Canada. <br>
    We currently do not support domestic tracking <br>
    for other countries.`,

  [ProposalKeys.Requirements.title]: 'Requirements',
  [ProposalKeys.Requirements.applied]: '{{number}} applied',

  [ProposalKeys.Requirements.Promotion.title]: 'Indicate paid promotion',
  [ProposalKeys.Requirements.Promotion.description_1]:
    'One of the following hashtags: #Ad, #Advertisement, or #Sponsored will be placed at the beginning of the list of hashtags by the creator to clearly indicate paid promotion.',
  [ProposalKeys.Requirements.Promotion.description_2]:
    'Add the "Includes paid promotion" label provided by YouTube inside the video.',
  [ProposalKeys.Requirements.Promotion.description_3]:
    'Add the"Paid partnership" label provided by Instagram inside the Instagram Post/Story/Reels.',

  [ProposalKeys.Requirements.Terms_Conditions.placeholder]:
    'Please enter your terms and conditions here.',
  [ProposalKeys.Requirements.Terms_Conditions.description]:
    "The creator will be required to adhere to your Terms and Conditions for the provision of the service. Revision or deactivation of this option may be requested if it contradicts the creator's service.",

  [ProposalKeys.Checklist.title]: 'Checklist',

  [ProposalKeys.Content_Missions.title]: 'Content Missions',

  [ProposalKeys.Content_Missions.Include_Link.title]: 'Include a Link',
  [ProposalKeys.Content_Missions.Include_Link.placeholder]: 'Enter a URL',
  [ProposalKeys.Content_Missions.Include_Link.description]:
    'The provided link will be included in the post or video description. Use this to bring more traffic to a website or app.',

  [ProposalKeys.Content_Missions.Include_Hashtags.title]: 'Include Hashtags',
  [ProposalKeys.Content_Missions.Include_Hashtags.placeholder]: 'EnterHashtag',
  [ProposalKeys.Content_Missions.Include_Hashtags.btn]: 'Add this Hashtag',
  [ProposalKeys.Content_Missions.Include_Hashtags.description]:
    'At least one or more of the hashtags from the left will be included in the post or in the video description.',

  [ProposalKeys.Content_Missions.Tag_Location.title]: 'Tag a location',
  [ProposalKeys.Content_Missions.Tag_Location.placeholder]: 'Enter a location',
  [ProposalKeys.Content_Missions.Tag_Location.description]:
    'The provided location will be tagged in the post or video. Effective for promoting a specific location or venue.',

  [ProposalKeys.Content_Missions.Tag_Account.title]: 'Tag our account',
  [ProposalKeys.Content_Missions.Tag_Account.placeholder]: 'YourHandle',
  [ProposalKeys.Content_Missions.Tag_Account.description]:
    'The account @handle will be included in the post or video description. (Only the handles corresponding to the platform of the ordered content will be applied.)',

  [ProposalKeys.Content_Missions.Pin_Comment_Account.title]:
    'Pin a comment with our account',
  [ProposalKeys.Content_Missions.Pin_Comment_Account.description]:
    'A comment including the provided account @handle will be posted and pinned on the content. (Only the handles corresponding to the platform of the ordered content will be applied.)',

  [ProposalKeys.Content_Missions.Pin_Comment_Link.title]:
    'Pin a comment with a link',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.placeholder]: 'Enter a URL',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.description]:
    'A comment including the provided link will be posted and pinned on the content.',

  [ProposalKeys.Content_Missions.Include_Img.title]: 'Include specific images',
  [ProposalKeys.Content_Missions.Include_Img.description]:
    'The creator will be guided to include at least one or more of the provided images in the content.',

  [ProposalKeys.Content_Missions.Tag_Video.title]: 'Add tags for videos',
  [ProposalKeys.Content_Missions.Tag_Video.placeholder]: 'Enter a video tag',
  [ProposalKeys.Content_Missions.Tag_Video.btn]: 'Add this Tag',
  [ProposalKeys.Content_Missions.Tag_Video.description]:
    'At least one or more of the tags from the left will be added to the YouTube video for search optimization.',

  // View
  [ProposalKeys.View.copied_to_clipboard]: 'Copied to clipboard',
  [ProposalKeys.View.Top.visit_website]: 'Visit Website',
  [ProposalKeys.View.ProductDelivery.title]: 'Product Delivery',
  [ProposalKeys.View.ProductDelivery.Physical.title]:
    'Physical delivery included',
  [ProposalKeys.View.ProductDelivery.Physical.addressRequired]:
    'Mailing address required',
  [ProposalKeys.View.ProductDelivery.Physical.waitForProduct]:
    'Upload contents after product arrival',
  [ProposalKeys.View.Requirement.Tooltip.creator]: `
    Please adhere to the required paid <br />
    promotion disclosure and terms and <br />
    conditions as indicated. Failure to comply <br />
    may result in revision or refund requests.`,
  [ProposalKeys.View.Requirement.Tooltip.client]: `
    Only the applicable requirements <br /> 
    to the order will automatically <br />
    appear here.`,
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_creator]: 'your',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_client]: 'the',
  [ProposalKeys.View.Requirement.Ad.Checklist.sponsored]: `
    Clearly mark {{pronoun}} content as sponsored.`,
  [ProposalKeys.View.Requirement.Ad.Checklist.useFollowingMethod]:
    'Use one of the following hashtags, #Ad, #Advertisement, or platform-provided methods.',
  [ProposalKeys.View.Requirement.Ad.Checklist.applicableContents]: `
    Applicable to {{number}} contents`,
  [ProposalKeys.View.Requirement.Ad.All.description]: `
    <strong>All platforms</strong> : Use one of the following hashtags:
    #Ad, #Advertisement, or #Sponsored to clearly indicate paid promotion.
    Place it at the beginning of the list of hashtags and ensure it
    remains visible without needing to click the "more" button of the
    post.`,
  [ProposalKeys.View.Requirement.Ad.YouTube.description]: `
    <strong>YouTube</strong> : Add the "Includes paid promotion" label in
    the video. To add this, go to YouTube Studio > Upload videos >
    Details > Paid Promotion > Select the “Add a message to inform
    viewers of paid promotion” option.`,
  [ProposalKeys.View.Requirement.Ad.Instagram.description]: `
    <strong>Instagram</strong> : Add the"Paid partnership" label when
    posting. Go to Settings > Creator/Business > Branded Content
    > Set up branded content tools to enable this feature. Once
    enabled, you can use the label when uploading a Instagram
    Post/Story/Reels.`,
  [ProposalKeys.View.Requirement.Ad.TikTok.description]: `
    <strong>TikTok</strong> : Add the"Paid partnership" label when
    uploading a video. Select a video to post > More options >
    Branded content and ads > Toggle “Branded content” as activated.`,
  [ProposalKeys.View.Requirement.Terms_Conditions.title]:
    'Terms and Conditions',
  [ProposalKeys.View.Requirement.Terms_Conditions.Checklist
    .complyTermsConditions]: `
    Please comply with the Terms and Conditions provided by {{user}}.`,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.creator]: `
    Please complete the checklist <br />
    applicable to each piece of content. <br />
    Failure to do so may result in a revision <br />
    or refund requests.`,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.client]: `
    Only the applicable checklist options <br /> 
    to the order will automatically <br />
    appear here.`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link.copy]:
    'Copy Link',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_creator]: `
    <li>Include the provided link in your post or video description.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_client]: `
    <li>Include the provided link in the post or video description.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.copy]:
    'Copy Hashtags',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_creator]: `
    <li>Include at least one or more of the listed hashtags from the left in your post or video description.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_client]: `
    <li>Include at least one or more of the listed hashtags from the left in the post or video description.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location.copy]:
    'Copy Location',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_creator]: `
      <li>Tag the provided location to your post or video.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_client]: `
      <li>Tag the provided location to the post or video.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_creator]: `
    <li>Include the provided @handle in your post or video description.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_client]: `
    <li>Include the provided @handle in the post or video description.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_creator]: `
    <li>Post and pin a comment including the provided account @handle on your content.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_client]: `
    <li>Post and pin a comment including the provided account @handle on the content.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Link
    .description]: `
    <li>Post and pin a comment including the provided link on your content.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Img.download]:
    'Download Images',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.copy]: 'Copy Tags',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.description]: `
    <li>Add at least one or more of the listed tags from the left to your YouTube video.</li>
    <li>Go to YouTube Studio > Upload videos > Details > Tags to add tags.</li>`,
};
