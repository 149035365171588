import GeneralKeys from '../translationKeys/GeneralKeys';

export const GeneralTranslations = {
  [GeneralKeys.continue]: '계속하기',
  [GeneralKeys.submit]: '제출하기',
  [GeneralKeys.cancel]: '취소',
  [GeneralKeys.close]: '닫기',
  [GeneralKeys.return]: '돌아가기',
  [GeneralKeys.account]: '계정',
  [GeneralKeys.coinbase]: 'CoinBase',
  [GeneralKeys.password]: '비밀번호',
  [GeneralKeys.update]: '업데이트',
  [GeneralKeys.coming_soon]: '준비중',
  [GeneralKeys.all]: '전체',
  [GeneralKeys.filter]: '필터',
  [GeneralKeys.contents]: '콘텐츠',
  [GeneralKeys.myspace]: '마이 스페이스',
  [GeneralKeys.myproposal]: '제안서',
  [GeneralKeys.proposals]: '제안서',
  [GeneralKeys.billing]: '결제',
  [GeneralKeys.settings]: '설정',
  [GeneralKeys.profile]: '프로필',
  [GeneralKeys.my_orders]: 'MY 주문',
  [GeneralKeys.orders]: '주문',
  [GeneralKeys.message]: '메시지',
  [GeneralKeys.support]: '지원',
  [GeneralKeys.help_center]: '고객 센터',
  [GeneralKeys.creator_faq]: '크리에이터 FAQ',
  [GeneralKeys.company_faq]: '기업 · 브랜드 FAQ',
  [GeneralKeys.myCart]: '내 장바구니',
  [GeneralKeys.platform]: '플랫폼',
  [GeneralKeys.sort_by]: '정렬 기준',
  [GeneralKeys.country]: '국가',
  [GeneralKeys.categories]: '카테고리',
  [GeneralKeys.price]: '가격',
  [GeneralKeys.subscribers]: '구독자',
  [GeneralKeys.avg_views]: '평균 조회수',
  [GeneralKeys.audience_gender]: '시청자 성별',
  [GeneralKeys.audience_age]: '시청자 연령',
  [GeneralKeys.youtube]: '유튜브',
  [GeneralKeys.twitter]: 'X (트위터)',
  [GeneralKeys.instagram]: '인스타그램',
  [GeneralKeys.tiktok]: '틱톡',
  [GeneralKeys.delivery]: '소요 기한',
  [GeneralKeys.type_length]: '유형/길이',
  [GeneralKeys.day_singular]: `{{number}}일`,
  [GeneralKeys.day_plural]: `{{number}}일`,
  [GeneralKeys.loading]: '로딩 중',
  [GeneralKeys.company]: '소속',
  [GeneralKeys.bio]: '소개',
  [GeneralKeys.subs]: '구독자',
  [GeneralKeys.followers]: '팔로워',
  [GeneralKeys.videos]: '동영상',
  [GeneralKeys.x_posts]: 'X 게시물',
  [GeneralKeys.posts_reels]: '게시물 및 릴스',
  [GeneralKeys.tiktok_videos]: '틱톡 동영상',
  [GeneralKeys.since]: '가입일 {{date}}',
  [GeneralKeys.countries]: '국가',
  [GeneralKeys.reviews]: '리뷰',
  [GeneralKeys.demographics]: '성별 · 나이 통계',
  [GeneralKeys.others]: '기타',
  [GeneralKeys.check_plan]: '요금제 확인',
  [GeneralKeys.login]: '로그인',
  [GeneralKeys.login_upper_case]: '로그인',
  [GeneralKeys.total]: '전체',
  [GeneralKeys.likes]: '좋아요',
  [GeneralKeys.comments]: '댓글',
  [GeneralKeys.reposts]: 'X 리포스트',
  [GeneralKeys.search]: '검색',
  [GeneralKeys.contact]: '문의',
  [GeneralKeys.price_sign]: '-- 원',
  [GeneralKeys.unlocking]: '잠금 해제',
  [GeneralKeys.click_to_unlock]: '클릭해서 잠금 해제',
  [GeneralKeys.add_to_cart]: '장바구니에 추가',
  [GeneralKeys.show_less]: '간략히 보기',
  [GeneralKeys.show_more]: '더보기',
  [GeneralKeys.go_back]: '뒤로 가기',
  [GeneralKeys.load_more]: '더 보기',
  [GeneralKeys.metamask]: 'MetaMask',
  [GeneralKeys.coinbase_wallet]: 'Coinbase 지갑',
  [GeneralKeys.send]: '보내기',
  [GeneralKeys.email]: '이메일',
  [GeneralKeys.or_upper_case]: '또는',
  [GeneralKeys.agree]: '동의',
  [GeneralKeys.client]: '브랜드',
  [GeneralKeys.influencer]: '크리에이터',
  [GeneralKeys.marketing]: '마케팅',
  [GeneralKeys.creator_marketing]: '크리에이터 마케팅',
  [GeneralKeys.partnerships]: '제휴 · 파트너',
  [GeneralKeys.evo_tokens]: 'EVO 포인트',
  [GeneralKeys.information]: '자료 · 리소스',
  [GeneralKeys.website_credits]: '웹사이트 크레딧',
  [GeneralKeys.home]: '홈',
  [GeneralKeys.about]: '서비스 소개',
  [GeneralKeys.pricing]: '요금제',
  [GeneralKeys.proposal]: '제안서',
  [GeneralKeys.logout]: '로그아웃',
  [GeneralKeys.view_more]: '더보기',
  [GeneralKeys.view_less]: '접기',
  [GeneralKeys.title]: '제목',
  [GeneralKeys.preview]: '프리뷰',
  [GeneralKeys.upload_a_file]: '파일 업로드',
  [GeneralKeys.description]: '패키지 설명',
  [GeneralKeys.type]: '유형',
  [GeneralKeys.category]: '카테고리',
  [GeneralKeys.select]: '선택',
  [GeneralKeys.unlimited_revisions]: '무제한 수정',
  [GeneralKeys.unlimited]: '무제한',
  [GeneralKeys.revision]: '{{number}}회 수정 가능',
  [GeneralKeys.revisions]: '{{number}}회 수정 가능',
  [GeneralKeys.number_revision]: '수정 횟수',
  [GeneralKeys.optional]: '선택 사항',
  [GeneralKeys.username]: '사용자명',
  [GeneralKeys.social_network]: '소셜 네트워크',
  [GeneralKeys.unlink]: '연결 해제',
  [GeneralKeys.unlink_platform]: '{{platform}} 연결 해제',
  [GeneralKeys.language]: '언어',
  [GeneralKeys.languages]: '언어',
  [GeneralKeys.package]: '패키지',
  [GeneralKeys.save]: '저장',
  [GeneralKeys.creator]: '크리에이터',
  [GeneralKeys.basic]: '베이직',
  [GeneralKeys.standard]: '베이직',
  [GeneralKeys.premium]: '스탠다드',
  [GeneralKeys.per_day]: '/일',
  [GeneralKeys.per_month]: '/월',
  [GeneralKeys.summary]: '요약',
  [GeneralKeys.general]: '일반',
  [GeneralKeys.register]: '등록 완료',
  [GeneralKeys.click]: '클릭',
  [GeneralKeys.connect]: '연결',
  [GeneralKeys.reconnect]: '재연결',
  [GeneralKeys.connecting]: '연결중',
  [GeneralKeys.notifications]: '알림',
  [GeneralKeys.messages]: '메시지',
  [GeneralKeys.reminders]: '알림',
  [GeneralKeys.newsletters]: '뉴스레터',
  [GeneralKeys.packages]: '패키지',
  [GeneralKeys.real_time]: '실시간',
  [GeneralKeys.unsubscribe]: '구독 취소',
  [GeneralKeys.referral_code]: '추천 코드',
  [GeneralKeys.total_invites]: '총 {{number}} 초대',
  [GeneralKeys.submitted]: '제출됨',
  [GeneralKeys.here]: '여기',
  [GeneralKeys.confirm]: '확인',
  [GeneralKeys.deactivate]: '비활성화',
  [GeneralKeys.delete]: '삭제',
  [GeneralKeys.wallet]: '지갑',
  [GeneralKeys.yes]: '예',
  [GeneralKeys.privacy_policy]: '개인정보 처리방침',
  [GeneralKeys.payment_policy]: '결제 정책',
  [GeneralKeys.reactivate]: '활성화',
  [GeneralKeys.analytics]: '통계 · 분석',
  [GeneralKeys.user_info]: '내 정보',
  [GeneralKeys.enterprise]: '프리미엄',
  [GeneralKeys.details]: '자세히',
  [GeneralKeys.claim]: '수령하기',
  [GeneralKeys.in_progress]: '진행중',
  [GeneralKeys.free]: '무료',
  [GeneralKeys.report]: '신고',
  [GeneralKeys.link_copied]: '링크가 복사되었습니다.',
  [GeneralKeys.contents_lowercase]: '콘텐츠 분석',
  [GeneralKeys.channel_lowercase]: '채널 분석',
  [GeneralKeys.audience_lowercase]: '시청자 분석',
  [GeneralKeys.my_url]: 'My URL',
  [GeneralKeys.username_lowercase]: 'username',
  [GeneralKeys.shorts]: '쇼츠',
  [GeneralKeys.x_post]: 'X 게시물',
  [GeneralKeys.repost]: 'X 리포스트',
  [GeneralKeys.website]: 'MY 웹사이트',
  [GeneralKeys.banner]: '배너',
  [GeneralKeys.profile_lowercase]: '프로필',
  [GeneralKeys.congratulations]: '축하드립니다!',
  [GeneralKeys.skip]: '스킵하기',
  [GeneralKeys.back]: '돌아가기',
  [GeneralKeys.shares]: '공유',
  [GeneralKeys.posts]: '포스트',
  [GeneralKeys.client_company]: '브랜드 · 기업',
  [GeneralKeys.contents_in]:
    '{{categories}} 콘텐츠를 {{countries}} 시청자분들께 제공합니다.',
  [GeneralKeys.videos_lowercase]: '동영상',
  [GeneralKeys.tweets_lowercase]: 'X 게시물',
  [GeneralKeys.posts_lowercase]: '게시물',
  [GeneralKeys.rating]: '평점',
  [GeneralKeys.users]: '유저',
  [GeneralKeys.browse_files]: '파일 찾기',
  [GeneralKeys.upload_failed]: '업로드 실패',
  [GeneralKeys.downloading]: '다운로드 중...',
  [GeneralKeys.package_summary]: '패키지 요약',
  [GeneralKeys.contents_plural]: '{{number}}개의 콘텐츠',
  [GeneralKeys.contents_singular]: '{{number}}개의 콘텐츠',
  [GeneralKeys.creator_brand_help_center]: '고객 센터 & FAQ',
  [GeneralKeys.main]: '대표',

  // Category
  [GeneralKeys.Category.digital]: '디지털',
  [GeneralKeys.Category.entertainment]: '엔터테인먼트',
  [GeneralKeys.Category.society]: '사회',
  [GeneralKeys.Category.life_style]: '라이프 스타일',

  [GeneralKeys.Category.tech_science]: '테크 · 과학',
  [GeneralKeys.Category.video_games]: '비디오 게임',
  [GeneralKeys.Category.metaverse]: '메타버스',
  [GeneralKeys.Category.crypto_nft]: '크립토 · NFT',
  [GeneralKeys.Category.finance]: '경제',
  [GeneralKeys.Category.movies]: '영화',
  [GeneralKeys.Category.anime_cartoon]: '애니메이션 · 카툰',
  [GeneralKeys.Category.music]: '음악',
  [GeneralKeys.Category.dance]: '춤 · 댄스',
  [GeneralKeys.Category.sports]: '스포츠',
  [GeneralKeys.Category.comedy]: '코미디',
  [GeneralKeys.Category.politics_society]: '정치 · 사회',
  [GeneralKeys.Category.people_blogs]: '인물 · 블로그',
  [GeneralKeys.Category.fashion]: '패션',
  [GeneralKeys.Category.beauty]: '뷰티',
  [GeneralKeys.Category.food_cooking]: '음식 · 요리',
  [GeneralKeys.Category.youth_infant]: '영 · 유아',
  [GeneralKeys.Category.animals_pets]: '동물 · 펫',
  [GeneralKeys.Category.travel_event]: '여행 · 문화',
  [GeneralKeys.Category.auto_vehicles]: '자동차 · 오토',
  [GeneralKeys.Category.unboxing]: '언박싱',
  [GeneralKeys.Category.education]: '교육',
  [GeneralKeys.Category.art_crafts]: '예술 · 공예',
  [GeneralKeys.Category.diy_home]: 'DIY · 인테리어',
  [GeneralKeys.Category.virtual_vtuber]: '버츄얼 · VTuber',
  [GeneralKeys.Category.ai_automation]: 'AI · 자동화',
  [GeneralKeys.Category.gym_fitness]: '헬스 · 피트니스',

  // Country
  [GeneralKeys.Country.are]: '아랍에미리트',
  [GeneralKeys.Country.arg]: '아르헨티나',
  [GeneralKeys.Country.aus]: '오스트레일리아',
  [GeneralKeys.Country.aut]: '오스트리아',
  [GeneralKeys.Country.bel]: '벨기에',
  [GeneralKeys.Country.bra]: '브라질',
  [GeneralKeys.Country.can]: '캐나다',
  [GeneralKeys.Country.che]: '스위스',
  [GeneralKeys.Country.chn]: '중국',
  [GeneralKeys.Country.deu]: '독일',
  [GeneralKeys.Country.dnk]: '덴마크',
  [GeneralKeys.Country.eeu]: '유럽',
  [GeneralKeys.Country.esp]: '스페인',
  [GeneralKeys.Country.fin]: '핀란드',
  [GeneralKeys.Country.fra]: '프랑스',
  [GeneralKeys.Country.gbr]: '영국',
  [GeneralKeys.Country.glb]: '글로벌',
  [GeneralKeys.Country.hkg]: '홍콩',
  [GeneralKeys.Country.idn]: '인도네시아',
  [GeneralKeys.Country.ind]: '인도',
  [GeneralKeys.Country.irn]: '이란',
  [GeneralKeys.Country.isr]: '이스라엘',
  [GeneralKeys.Country.ita]: '이탈리아',
  [GeneralKeys.Country.jpn]: '일본',
  [GeneralKeys.Country.kor]: '대한민국',
  [GeneralKeys.Country.mex]: '멕시코',
  [GeneralKeys.Country.mys]: '말레이시아',
  [GeneralKeys.Country.nld]: '네덜란드',
  [GeneralKeys.Country.nor]: '노르웨이',
  [GeneralKeys.Country.nzl]: '뉴질랜드',
  [GeneralKeys.Country.pak]: '파키스탄',
  [GeneralKeys.Country.phl]: '필리핀',
  [GeneralKeys.Country.pol]: '폴란드',
  [GeneralKeys.Country.prt]: '포르투갈',
  [GeneralKeys.Country.sau]: '사우디아라비아',
  [GeneralKeys.Country.sgp]: '싱가포르',
  [GeneralKeys.Country.swe]: '스웨덴',
  [GeneralKeys.Country.tha]: '태국',
  [GeneralKeys.Country.tur]: '터키',
  [GeneralKeys.Country.twn]: '대만',
  [GeneralKeys.Country.usa]: '미국',
  [GeneralKeys.Country.vnm]: '베트남',

  // Client
  [GeneralKeys.Country.abw]: '아루바',
  [GeneralKeys.Country.afg]: '아프가니스탄',
  [GeneralKeys.Country.ago]: '앙골라',
  [GeneralKeys.Country.aia]: '앵귈라',
  [GeneralKeys.Country.ala]: '올란드제도',
  [GeneralKeys.Country.alb]: '알바니아',
  [GeneralKeys.Country.and]: '안도라',
  [GeneralKeys.Country.arm]: '아르메니아',
  [GeneralKeys.Country.asm]: '아메리칸사모아',
  [GeneralKeys.Country.ata]: '남극 대륙',
  [GeneralKeys.Country.atf]: '프랑스 남부와 남극 지역',
  [GeneralKeys.Country.atg]: '앤티가 및 바부다',
  [GeneralKeys.Country.aze]: '아제르바이잔',
  [GeneralKeys.Country.bdi]: '부룬디',
  [GeneralKeys.Country.ben]: '베냉',
  [GeneralKeys.Country.bes]: '보네르, 신트 유스타티우스, 사바',
  [GeneralKeys.Country.bfa]: '부르키나 파소',
  [GeneralKeys.Country.bgd]: '방글라데시',
  [GeneralKeys.Country.bgr]: '불가리아',
  [GeneralKeys.Country.bhr]: '바레인',
  [GeneralKeys.Country.bhs]: '바하마',
  [GeneralKeys.Country.bih]: '보스니아 헤르체고비나',
  [GeneralKeys.Country.blm]: '생 바르텔레미',
  [GeneralKeys.Country.blr]: '벨라루스',
  [GeneralKeys.Country.blz]: '벨리즈',
  [GeneralKeys.Country.bmu]: '버뮤다',
  [GeneralKeys.Country.bol]: '볼리비아 (다민족국가)',
  [GeneralKeys.Country.brb]: '바베이도스',
  [GeneralKeys.Country.brn]: '브루나이 다루살람',
  [GeneralKeys.Country.btn]: '부탄',
  [GeneralKeys.Country.bvt]: '부베 섬',
  [GeneralKeys.Country.bwa]: '보츠와나',
  [GeneralKeys.Country.caf]: '중앙 아프리카 공화국',
  [GeneralKeys.Country.cck]: '코코스 (킬링) 제도',
  [GeneralKeys.Country.chl]: '칠레',
  [GeneralKeys.Country.civ]: '코트디부아르',
  [GeneralKeys.Country.cmr]: '카메룬',
  [GeneralKeys.Country.cod]: '콩고 민주 공화국',
  [GeneralKeys.Country.cog]: '콩고',
  [GeneralKeys.Country.cok]: '쿡 아일랜드',
  [GeneralKeys.Country.col]: '콜롬비아',
  [GeneralKeys.Country.com]: '코모로',
  [GeneralKeys.Country.cpv]: '카보베르데',
  [GeneralKeys.Country.cri]: '코스타리카',
  [GeneralKeys.Country.cub]: '쿠바',
  [GeneralKeys.Country.cuw]: '쿠라 사오',
  [GeneralKeys.Country.cxr]: '크리스마스 섬',
  [GeneralKeys.Country.cym]: '케이맨 아일랜드',
  [GeneralKeys.Country.cyp]: '키프로스',
  [GeneralKeys.Country.cze]: '체코',
  [GeneralKeys.Country.dji]: '지부티',
  [GeneralKeys.Country.dma]: '도미니카',
  [GeneralKeys.Country.dom]: '도미니카 공화국',
  [GeneralKeys.Country.dza]: '알제리',
  [GeneralKeys.Country.ecu]: '에콰도르',
  [GeneralKeys.Country.egy]: '이집트',
  [GeneralKeys.Country.eri]: '에리트레아',
  [GeneralKeys.Country.esh]: '서부 사하라',
  [GeneralKeys.Country.est]: '에스토니아',
  [GeneralKeys.Country.eth]: '에티오피아',
  [GeneralKeys.Country.fji]: '피지',
  [GeneralKeys.Country.flk]: '포클랜드 아일랜드 (말비나스)',
  [GeneralKeys.Country.fro]: '페로 아일랜드',
  [GeneralKeys.Country.fsm]: '미크로네시아 (연방)',
  [GeneralKeys.Country.gab]: '가봉',
  [GeneralKeys.Country.geo]: '조지아',
  [GeneralKeys.Country.ggy]: '건지 섬',
  [GeneralKeys.Country.gha]: '가나',
  [GeneralKeys.Country.gib]: '지브롤터',
  [GeneralKeys.Country.gin]: '기니',
  [GeneralKeys.Country.glp]: '과들루프',
  [GeneralKeys.Country.gmb]: '감비아',
  [GeneralKeys.Country.gnb]: '기니비사우',
  [GeneralKeys.Country.gnq]: '적도 기니',
  [GeneralKeys.Country.grc]: '그리스',
  [GeneralKeys.Country.grd]: '그레나다',
  [GeneralKeys.Country.grl]: '그린란드',
  [GeneralKeys.Country.gtm]: '과테말라',
  [GeneralKeys.Country.gum]: '괌',
  [GeneralKeys.Country.guy]: '가이아나',
  [GeneralKeys.Country.hmd]: '허드 섬 및 맥도널드 아일랜드',
  [GeneralKeys.Country.hnd]: '온두라스',
  [GeneralKeys.Country.hrv]: '크로아티아',
  [GeneralKeys.Country.hti]: '아이티',
  [GeneralKeys.Country.hun]: '헝가리',
  [GeneralKeys.Country.imn]: '맨 섬',
  [GeneralKeys.Country.iot]: '영국 인도양 지역',
  [GeneralKeys.Country.irl]: '아일랜드',
  [GeneralKeys.Country.irq]: '이라크',
  [GeneralKeys.Country.isl]: '아이슬란드',
  [GeneralKeys.Country.jam]: '자메이카',
  [GeneralKeys.Country.jey]: '저지',
  [GeneralKeys.Country.jor]: '요르단',
  [GeneralKeys.Country.kaz]: '카자흐스탄',
  [GeneralKeys.Country.ken]: '케냐',
  [GeneralKeys.Country.kgz]: '키르기스스탄',
  [GeneralKeys.Country.khm]: '캄보디아',
  [GeneralKeys.Country.kir]: '키리바시',
  [GeneralKeys.Country.kna]: '세인트키츠 네비스',
  [GeneralKeys.Country.kwt]: '쿠웨이트',
  [GeneralKeys.Country.lao]: '라오스 인민 민주 공화국',
  [GeneralKeys.Country.lbn]: '레바논',
  [GeneralKeys.Country.lbr]: '라이베리아',
  [GeneralKeys.Country.lby]: '리비아',
  [GeneralKeys.Country.lca]: '세인트루시아',
  [GeneralKeys.Country.lie]: '리히텐슈타인',
  [GeneralKeys.Country.lka]: '스리랑카',
  [GeneralKeys.Country.lso]: '레소토',
  [GeneralKeys.Country.ltu]: '리투아니아',
  [GeneralKeys.Country.lux]: '룩셈부르크',
  [GeneralKeys.Country.lva]: '라트비아',
  [GeneralKeys.Country.mac]: '마카오',
  [GeneralKeys.Country.maf]: '세인트 마틴 (프랑스령)',
  [GeneralKeys.Country.mar]: '모로코',
  [GeneralKeys.Country.mco]: '모나코',
  [GeneralKeys.Country.mda]: '몰도바',
  [GeneralKeys.Country.mdg]: '마다가스카르',
  [GeneralKeys.Country.mdv]: '몰디브',
  [GeneralKeys.Country.mhl]: '마샬 아일랜드',
  [GeneralKeys.Country.mkd]: '북마케도니아 공화국',
  [GeneralKeys.Country.mli]: '말리',
  [GeneralKeys.Country.mlt]: '몰타',
  [GeneralKeys.Country.mmr]: '미얀마',
  [GeneralKeys.Country.mne]: '몬테네그로',
  [GeneralKeys.Country.mng]: '몽골',
  [GeneralKeys.Country.mnp]: '북마리아나 아일랜드',
  [GeneralKeys.Country.moz]: '모잠비크',
  [GeneralKeys.Country.mrt]: '모리타니',
  [GeneralKeys.Country.msr]: '몬트세라트',
  [GeneralKeys.Country.mtq]: '마르티니크',
  [GeneralKeys.Country.mus]: '모리셔스',
  [GeneralKeys.Country.mwi]: '말라위',
  [GeneralKeys.Country.myt]: '마요트',
  [GeneralKeys.Country.nam]: '나미비아',
  [GeneralKeys.Country.ncl]: '뉴칼레도니아',
  [GeneralKeys.Country.ner]: '니제르',
  [GeneralKeys.Country.nfk]: '노퍽 섬',
  [GeneralKeys.Country.nga]: '나이지리아',
  [GeneralKeys.Country.nic]: '니카라과',
  [GeneralKeys.Country.niu]: '니우에',
  [GeneralKeys.Country.npl]: '네팔',
  [GeneralKeys.Country.nru]: '나우루',
  [GeneralKeys.Country.omn]: '오만',
  [GeneralKeys.Country.pan]: '파나마',
  [GeneralKeys.Country.pcn]: '핏케언',
  [GeneralKeys.Country.per]: '페루',
  [GeneralKeys.Country.plw]: '팔라우',
  [GeneralKeys.Country.png]: '파푸아 뉴기니',
  [GeneralKeys.Country.pri]: '푸에르토리코',
  [GeneralKeys.Country.prk]: '북한',
  [GeneralKeys.Country.pry]: '파라과이',
  [GeneralKeys.Country.pse]: '팔레스타인 자치정부',
  [GeneralKeys.Country.qat]: '카타르',
  [GeneralKeys.Country.reu]: '레위니옹',
  [GeneralKeys.Country.rou]: '루마니아',
  [GeneralKeys.Country.rus]: '러시아',
  [GeneralKeys.Country.rwa]: '르완다',
  [GeneralKeys.Country.sdn]: '수단',
  [GeneralKeys.Country.sen]: '세네갈',
  [GeneralKeys.Country.sgs]: '사우스조지아 사우스샌드위치 아일랜드',
  [GeneralKeys.Country.shn]: '세인트헬레나, 어센션 및 트리스탄다쿠냐',
  [GeneralKeys.Country.sjm]: '스발바르 얀마옌',
  [GeneralKeys.Country.slb]: '솔로몬 아일랜드',
  [GeneralKeys.Country.sle]: '시에라리온',
  [GeneralKeys.Country.slv]: '엘살바도르',
  [GeneralKeys.Country.smr]: '산마리노',
  [GeneralKeys.Country.som]: '소말리아',
  [GeneralKeys.Country.spm]: '생피에르 미클롱',
  [GeneralKeys.Country.srb]: '세르비아',
  [GeneralKeys.Country.ssd]: '남수단',
  [GeneralKeys.Country.stp]: '상투메 프린시페',
  [GeneralKeys.Country.sur]: '수리남',
  [GeneralKeys.Country.svk]: '슬로바키아',
  [GeneralKeys.Country.svn]: '슬로베니아',
  [GeneralKeys.Country.swz]: '에스와티니',
  [GeneralKeys.Country.sxm]: '세인트 마틴',
  [GeneralKeys.Country.syc]: '세이셸',
  [GeneralKeys.Country.syr]: '시리아 아랍 공화국',
  [GeneralKeys.Country.tca]: '터크스 케이커스 아일랜드',
  [GeneralKeys.Country.tcd]: '차드',
  [GeneralKeys.Country.tgo]: '토고',
  [GeneralKeys.Country.tjk]: '타지키스탄',
  [GeneralKeys.Country.tkl]: '토켈라우',
  [GeneralKeys.Country.tkm]: '투르크메니스탄',
  [GeneralKeys.Country.tls]: '동티모르',
  [GeneralKeys.Country.ton]: '통가',
  [GeneralKeys.Country.tto]: '트리니다드 토바고',
  [GeneralKeys.Country.tun]: '튀니지',
  [GeneralKeys.Country.tuv]: '투발루',
  [GeneralKeys.Country.tza]: '탄자니아 연합 공화국',
  [GeneralKeys.Country.uga]: '우간다',
  [GeneralKeys.Country.ukr]: '우크라이나',
  [GeneralKeys.Country.umi]: '미국령 군소 아일랜드',
  [GeneralKeys.Country.ury]: '우루과이',
  [GeneralKeys.Country.uzb]: '우즈베키스탄',
  [GeneralKeys.Country.vat]: '바티칸시국',
  [GeneralKeys.Country.vct]: '세인트빈센트 그레나딘',
  [GeneralKeys.Country.ven]: '베네수엘라 볼리바르 공화국',
  [GeneralKeys.Country.vgb]: '영국령 버진아일랜드',
  [GeneralKeys.Country.vir]: '미국령 버진아일랜드',
  [GeneralKeys.Country.vut]: '바누아투',
  [GeneralKeys.Country.wlf]: '왈리스 퓌티나',
  [GeneralKeys.Country.wsm]: '사모아',
  [GeneralKeys.Country.yem]: '예멘',
  [GeneralKeys.Country.zaf]: '남아프리카 공화국',
  [GeneralKeys.Country.zmb]: '잠비아',
  [GeneralKeys.Country.zwe]: '짐바브웨',
  [GeneralKeys.Country.asc]: '어센션 섬',
  [GeneralKeys.Country.guf]: '프랑스 기아나',
  [GeneralKeys.Country.pyf]: '프랑스 폴리네시아',
  [GeneralKeys.Country.xkx]: '코소보',
  [GeneralKeys.Country.taa]: '트리스탄다쿠냐',

  // 2-Char
  [GeneralKeys.Country.ae]: '아랍에미리트',
  [GeneralKeys.Country.ar]: '아르헨티나',
  [GeneralKeys.Country.au]: '오스트레일리아',
  [GeneralKeys.Country.at]: '오스트리아',
  [GeneralKeys.Country.be]: '벨기에',
  [GeneralKeys.Country.br]: '브라질',
  [GeneralKeys.Country.ca]: '캐나다',
  [GeneralKeys.Country.ch]: '스위스',
  [GeneralKeys.Country.cn]: '중국',
  [GeneralKeys.Country.de]: '독일',
  [GeneralKeys.Country.dk]: '덴마크',
  [GeneralKeys.Country.eeu]: '유럽',
  [GeneralKeys.Country.es]: '스페인',
  [GeneralKeys.Country.fi]: '핀란드',
  [GeneralKeys.Country.fr]: '프랑스',
  [GeneralKeys.Country.gb]: '영국',
  [GeneralKeys.Country.glb]: '글로벌',
  [GeneralKeys.Country.hk]: '홍콩',
  [GeneralKeys.Country.id]: '인도네시아',
  [GeneralKeys.Country.in]: '인도',
  [GeneralKeys.Country.ir]: '이란',
  [GeneralKeys.Country.il]: '이스라엘',
  [GeneralKeys.Country.it]: '이탈리아',
  [GeneralKeys.Country.jp]: '일본',
  [GeneralKeys.Country.kr]: '대한민국',
  [GeneralKeys.Country.mx]: '멕시코',
  [GeneralKeys.Country.my]: '말레이시아',
  [GeneralKeys.Country.nl]: '네덜란드',
  [GeneralKeys.Country.no]: '노르웨이',
  [GeneralKeys.Country.nz]: '뉴질랜드',
  [GeneralKeys.Country.pk]: '파키스탄',
  [GeneralKeys.Country.ph]: '필리핀',
  [GeneralKeys.Country.pl]: '폴란드',
  [GeneralKeys.Country.pt]: '포르투갈',
  [GeneralKeys.Country.sa]: '사우디 아라비아',
  [GeneralKeys.Country.sg]: '싱가포르',
  [GeneralKeys.Country.se]: '스웨덴',
  [GeneralKeys.Country.th]: '태국',
  [GeneralKeys.Country.tr]: '터키',
  [GeneralKeys.Country.tw]: '대만',
  [GeneralKeys.Country.us]: '미국',
  [GeneralKeys.Country.vn]: '베트남',

  // Client
  [GeneralKeys.Country.aw]: '아루바',
  [GeneralKeys.Country.af]: '아프가니스탄',
  [GeneralKeys.Country.ao]: '앙골라',
  [GeneralKeys.Country.ai]: '앵귈라',
  [GeneralKeys.Country.ax]: '올란드제도',
  [GeneralKeys.Country.al]: '알바니아',
  [GeneralKeys.Country.ad]: '안도라',
  [GeneralKeys.Country.am]: '아르메니아',
  [GeneralKeys.Country.as]: '아메리칸 사모아',
  [GeneralKeys.Country.aq]: '남극 대륙',
  [GeneralKeys.Country.tf]: '프랑스 남부와 남극 지역',
  [GeneralKeys.Country.ag]: '앤티가 및 바부다',
  [GeneralKeys.Country.az]: '아제르바이잔',
  [GeneralKeys.Country.bi]: '부룬디',
  [GeneralKeys.Country.bj]: '베냉',
  [GeneralKeys.Country.bq]: '보네르, 신트 유스타티우스, 사바',
  [GeneralKeys.Country.bf]: '부르키나 파소',
  [GeneralKeys.Country.bd]: '방글라데시',
  [GeneralKeys.Country.bg]: '불가리아',
  [GeneralKeys.Country.bh]: '바레인',
  [GeneralKeys.Country.bs]: '바하마',
  [GeneralKeys.Country.ba]: '보스니아 헤르체고비나',
  [GeneralKeys.Country.bl]: '생 바르텔레미',
  [GeneralKeys.Country.by]: '벨라루스',
  [GeneralKeys.Country.bz]: '벨리즈',
  [GeneralKeys.Country.bm]: '버뮤다',
  [GeneralKeys.Country.bo]: '볼리비아 (다민족국가)',
  [GeneralKeys.Country.bb]: '바베이도스',
  [GeneralKeys.Country.bn]: '브루나이 다루살람',
  [GeneralKeys.Country.bt]: '부탄',
  [GeneralKeys.Country.bv]: '부베 섬',
  [GeneralKeys.Country.bw]: '보츠와나',
  [GeneralKeys.Country.cf]: '중앙 아프리카 공화국',
  [GeneralKeys.Country.cc]: '코코스 (킬링) 제도',
  [GeneralKeys.Country.cl]: '칠레',
  [GeneralKeys.Country.ci]: '코트디부아르',
  [GeneralKeys.Country.cm]: '카메룬',
  [GeneralKeys.Country.cd]: '콩고 민주 공화국',
  [GeneralKeys.Country.cg]: '콩고',
  [GeneralKeys.Country.ck]: '쿡 아일랜드',
  [GeneralKeys.Country.co]: '콜롬비아',
  [GeneralKeys.Country.km]: '코모로',
  [GeneralKeys.Country.cv]: '카보베르데',
  [GeneralKeys.Country.cr]: '코스타리카',
  [GeneralKeys.Country.cu]: '쿠바',
  [GeneralKeys.Country.cw]: '쿠라 사오',
  [GeneralKeys.Country.cx]: '크리스마스 섬',
  [GeneralKeys.Country.ky]: '케이맨 아일랜드',
  [GeneralKeys.Country.cy]: '키프로스',
  [GeneralKeys.Country.cz]: '체코',
  [GeneralKeys.Country.dj]: '지부티',
  [GeneralKeys.Country.dm]: '도미니카',
  [GeneralKeys.Country.do]: '도미니카 공화국',
  [GeneralKeys.Country.dz]: '알제리',
  [GeneralKeys.Country.ec]: '에콰도르',
  [GeneralKeys.Country.eg]: '이집트',
  [GeneralKeys.Country.er]: '에리트레아',
  [GeneralKeys.Country.eh]: '서부 사하라',
  [GeneralKeys.Country.ee]: '에스토니아',
  [GeneralKeys.Country.et]: '에티오피아',
  [GeneralKeys.Country.fj]: '피지',
  [GeneralKeys.Country.fk]: '포클랜드 아일랜드 (말비나스)',
  [GeneralKeys.Country.fo]: '페로 아일랜드',
  [GeneralKeys.Country.fm]: '미크로네시아 (연방)',
  [GeneralKeys.Country.ga]: '가봉',
  [GeneralKeys.Country.ge]: '조지아',
  [GeneralKeys.Country.gg]: '건지 섬',
  [GeneralKeys.Country.gh]: '가나',
  [GeneralKeys.Country.gi]: '지브롤터',
  [GeneralKeys.Country.gn]: '기니',
  [GeneralKeys.Country.gp]: '과들루프',
  [GeneralKeys.Country.gm]: '감비아',
  [GeneralKeys.Country.gw]: '기니비사우',
  [GeneralKeys.Country.gq]: '적도 기니',
  [GeneralKeys.Country.gr]: '그리스',
  [GeneralKeys.Country.gd]: '그레나다',
  [GeneralKeys.Country.gl]: '그린란드',
  [GeneralKeys.Country.gt]: '과테말라',
  [GeneralKeys.Country.gu]: '괌',
  [GeneralKeys.Country.gy]: '가이아나',
  [GeneralKeys.Country.hm]: '허드 섬 및 맥도널드 아일랜드',
  [GeneralKeys.Country.hn]: '온두라스',
  [GeneralKeys.Country.hr]: '크로아티아',
  [GeneralKeys.Country.ht]: '아이티',
  [GeneralKeys.Country.hu]: '헝가리',
  [GeneralKeys.Country.im]: '맨 섬',
  [GeneralKeys.Country.io]: '영국 인도양 지역',
  [GeneralKeys.Country.ie]: '아일랜드',
  [GeneralKeys.Country.iq]: '이라크',
  [GeneralKeys.Country.is]: '아이슬란드',
  [GeneralKeys.Country.jm]: '자메이카',
  [GeneralKeys.Country.je]: '저지',
  [GeneralKeys.Country.jo]: '요르단',
  [GeneralKeys.Country.kz]: '카자흐스탄',
  [GeneralKeys.Country.ke]: '케냐',
  [GeneralKeys.Country.kg]: '키르기스스탄',
  [GeneralKeys.Country.kh]: '캄보디아',
  [GeneralKeys.Country.ki]: '키리바시',
  [GeneralKeys.Country.kn]: '세인트키츠 네비스',
  [GeneralKeys.Country.kw]: '쿠웨이트',
  [GeneralKeys.Country.la]: '라오스 인민 민주 공화국',
  [GeneralKeys.Country.lb]: '레바논',
  [GeneralKeys.Country.lr]: '라이베리아',
  [GeneralKeys.Country.ly]: '리비아',
  [GeneralKeys.Country.lc]: '세인트루시아',
  [GeneralKeys.Country.li]: '리히텐슈타인',
  [GeneralKeys.Country.lk]: '스리랑카',
  [GeneralKeys.Country.ls]: '레소토',
  [GeneralKeys.Country.lt]: '리투아니아',
  [GeneralKeys.Country.lu]: '룩셈부르크',
  [GeneralKeys.Country.lv]: '라트비아',
  [GeneralKeys.Country.mo]: '마카오',
  [GeneralKeys.Country.mf]: '세인트 마틴 (프랑스령)',
  [GeneralKeys.Country.ma]: '모로코',
  [GeneralKeys.Country.mc]: '모나코',
  [GeneralKeys.Country.md]: '몰도바',
  [GeneralKeys.Country.mg]: '마다가스카르',
  [GeneralKeys.Country.mv]: '몰디브',
  [GeneralKeys.Country.mh]: '마샬 아일랜드',
  [GeneralKeys.Country.mk]: '북마케도니아 공화국',
  [GeneralKeys.Country.ml]: '말리',
  [GeneralKeys.Country.mt]: '몰타',
  [GeneralKeys.Country.mm]: '미얀마',
  [GeneralKeys.Country.me]: '몬테네그로',
  [GeneralKeys.Country.mn]: '몽골',
  [GeneralKeys.Country.mp]: '북마리아나 아일랜드',
  [GeneralKeys.Country.mz]: '모잠비크',
  [GeneralKeys.Country.mr]: '모리타니',
  [GeneralKeys.Country.ms]: '몬트세라트',
  [GeneralKeys.Country.mq]: '마르티니크',
  [GeneralKeys.Country.mu]: '모리셔스',
  [GeneralKeys.Country.mw]: '말라위',
  [GeneralKeys.Country.yt]: '마요트',
  [GeneralKeys.Country.na]: '나미비아',
  [GeneralKeys.Country.nc]: '뉴칼레도니아',
  [GeneralKeys.Country.ne]: '니제르',
  [GeneralKeys.Country.nf]: '노퍽 섬',
  [GeneralKeys.Country.ng]: '나이지리아',
  [GeneralKeys.Country.ni]: '니카라과',
  [GeneralKeys.Country.nu]: '니우에',
  [GeneralKeys.Country.np]: '네팔',
  [GeneralKeys.Country.nr]: '나우루',
  [GeneralKeys.Country.om]: '오만',
  [GeneralKeys.Country.pa]: '파나마',
  [GeneralKeys.Country.pn]: '핏케언',
  [GeneralKeys.Country.pe]: '페루',
  [GeneralKeys.Country.pw]: '팔라우',
  [GeneralKeys.Country.pg]: '파푸아 뉴기니',
  [GeneralKeys.Country.pr]: '푸에르토리코',
  [GeneralKeys.Country.kp]: '북한',
  [GeneralKeys.Country.py]: '파라과이',
  [GeneralKeys.Country.ps]: '팔레스타인 자치정부',
  [GeneralKeys.Country.qa]: '카타르',
  [GeneralKeys.Country.re]: '레위니옹',
  [GeneralKeys.Country.ro]: '루마니아',
  [GeneralKeys.Country.ru]: '러시아',
  [GeneralKeys.Country.rw]: '르완다',
  [GeneralKeys.Country.sd]: '수단',
  [GeneralKeys.Country.sn]: '세네갈',
  [GeneralKeys.Country.gs]: '사우스조지아 사우스샌드위치 아일랜드',
  [GeneralKeys.Country.sh]: '세인트헬레나, 어센션 및 트리스탄다쿠냐',
  [GeneralKeys.Country.sj]: '스발바르 얀마옌',
  [GeneralKeys.Country.sb]: '솔로몬 아일랜드',
  [GeneralKeys.Country.sl]: '시에라리온',
  [GeneralKeys.Country.sv]: '엘살바도르',
  [GeneralKeys.Country.sm]: '산마리노',
  [GeneralKeys.Country.so]: '소말리아',
  [GeneralKeys.Country.pm]: '생피에르 미클롱',
  [GeneralKeys.Country.rs]: '세르비아',
  [GeneralKeys.Country.ss]: '남수단',
  [GeneralKeys.Country.st]: '상투메 프린시페',
  [GeneralKeys.Country.sr]: '수리남',
  [GeneralKeys.Country.sk]: '슬로바키아',
  [GeneralKeys.Country.si]: '슬로베니아',
  [GeneralKeys.Country.sz]: '에스와티니',
  [GeneralKeys.Country.sx]: '세인트 마틴',
  [GeneralKeys.Country.sc]: '세이셸',
  [GeneralKeys.Country.sy]: '시리아 아랍 공화국',
  [GeneralKeys.Country.tc]: '터크스 케이커스 아일랜드',
  [GeneralKeys.Country.td]: '차드',
  [GeneralKeys.Country.tg]: '토고',
  [GeneralKeys.Country.tj]: '타지키스탄',
  [GeneralKeys.Country.tk]: '토켈라우',
  [GeneralKeys.Country.tm]: '투르크메니스탄',
  [GeneralKeys.Country.tl]: '동티모르',
  [GeneralKeys.Country.to]: '통가',
  [GeneralKeys.Country.tt]: '트리니다드 토바고',
  [GeneralKeys.Country.tn]: '튀니지',
  [GeneralKeys.Country.tv]: '투발루',
  [GeneralKeys.Country.tz]: '탄자니아 연합 공화국',
  [GeneralKeys.Country.ug]: '우간다',
  [GeneralKeys.Country.ua]: '우크라이나',
  [GeneralKeys.Country.um]: '미국령 군소 아일랜드',
  [GeneralKeys.Country.uy]: '우루과이',
  [GeneralKeys.Country.uz]: '우즈베키스탄',
  [GeneralKeys.Country.va]: '바티칸 시국',
  [GeneralKeys.Country.vc]: '세인트빈센트 그레나딘',
  [GeneralKeys.Country.ve]: '베네수엘라 볼리바르 공화국',
  [GeneralKeys.Country.vg]: '영국령 버진아일랜드',
  [GeneralKeys.Country.vi]: '미국령 버진아일랜드',
  [GeneralKeys.Country.vu]: '바누아투',
  [GeneralKeys.Country.wf]: '왈리스 퓌티나',
  [GeneralKeys.Country.ws]: '사모아',
  [GeneralKeys.Country.ye]: '예멘',
  [GeneralKeys.Country.za]: '남아프리카 공화국',
  [GeneralKeys.Country.zm]: '잠비아',
  [GeneralKeys.Country.zw]: '짐바브웨',
  [GeneralKeys.Country.ac]: '어센션 섬',
  [GeneralKeys.Country.gf]: '프랑스 기아나',
  [GeneralKeys.Country.pf]: '프랑스 폴리네시아',
  [GeneralKeys.Country.xk]: '코소보',
  [GeneralKeys.Country.ta]: '트리스탄다쿠냐',
};
