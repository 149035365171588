import Popup from 'components/Basics/Popup';
import dayjs from 'dayjs';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage, useToggle } from 'react-use';
import i18n from 'translations/i18n';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import MessageKeys from 'translations/translationKeys/MessageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import useUser from 'hooks/useUser';
import Stack from 'components/Basics/Layout/Stack';
import CheckBox from 'components/Basics/Buttons/CheckBox';
import ModalKeys from 'translations/translationKeys/ModalKeys';

const MessageTranslationQuotaExceededModal = () => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();
  const [isOpen, toggleIsOpen] = useToggle();
  const { user } = useUser();
  const [haveCheckBox, setHaveCheckBox] = useState(false);
  const [stopModalUntill, setStopModalUntill, remove] = useLocalStorage(
    'stopMessageOrderAutoTranslateModalUntill',
    null
  );
  const [isChecked, setIsChecked] = useState();

  useEffect(() => {
    const catchClick = (e) => {
      if (e.detail?.event === true) {
        if (e.detail?.haveCheckBox) {
          if (dayjs().isAfter(stopModalUntill, 'day') || !stopModalUntill) {
            setHaveCheckBox(true);
            toggleIsOpen();
            setIsChecked(true);
          }
        } else {
          setHaveCheckBox(false);
          toggleIsOpen();
        }
        e.stopImmediatePropagation();
      }
    };

    window.addEventListener(
      'open-message-translation-quota-exceeded-modal',
      catchClick
    );

    return () => {
      window.removeEventListener(
        'open-message-translation-quota-exceeded-modal',
        catchClick
      );
    };
  }, [toggleIsOpen, stopModalUntill]);

  const changeCheckBoxState = () => {
    if (haveCheckBox) {
      if (haveCheckBox && isChecked) {
        setStopModalUntill(dayjs().add(14, 'day'));
      } else {
        remove();
      }
    }
  };

  return (
    <Popup
      show={isOpen}
      onClose={() => {
        toggleIsOpen();
        changeCheckBoxState();
      }}
      title={t(MessageKeys.Modal.QuotaExceeded.title)}
      description={
        <Stack align="center" gap="2.25rem">
          <Trans
            values={{
              date: dayjs(user?.clientPlan?.renewalDate).format(
                dayjs.Ls[i18n.language].dateFormat.dot_date
              ),
            }}
          >
            {t(MessageKeys.Modal.QuotaExceeded.description)}
          </Trans>
          {haveCheckBox && (
            <CheckBox
              name="messageOrderAutoTranslateQuota"
              checked={isChecked}
              label={t(ModalKeys.Patch_Note.close)}
              onClick={() => setIsChecked((prev) => !prev)}
              className="h7"
              boxStyle={{ width: '1rem', height: '1rem' }}
            />
          )}
        </Stack>
      }
      dividerBelowTitle={isMobile}
      buttonText={t(MessageKeys.Modal.QuotaExceeded.btn)}
      onButtonClick={() => {
        navigate('/pricing');
        changeCheckBoxState();
      }}
      secondaryButtonText={t(GeneralKeys.return)}
    />
  );
};

export default MessageTranslationQuotaExceededModal;
