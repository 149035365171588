import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'การแปลอัตโนมัติ',
  [AutoTranslationKeys.Dropdown.description]:
    'คำอธิบายและข้อความที่ผู้ใช้งานเขียนจะได้รับการแปลโดยอัตโนมัติ',
  [AutoTranslationKeys.Dropdown.order_message_title]:
    'การแปลคำสั่งซื้อและข้อความ',
  [AutoTranslationKeys.Dropdown.order_message_title_mobile]:
    'การแปลคำสั่งซื้อและ <br> ข้อความ',
  [AutoTranslationKeys.Dropdown.order_message_description]:
    'เหลืออีก {{number}} อักขระ · อัปเดตเมื่อ {{date}}',
  [AutoTranslationKeys.Dropdown.order_message_description_mobile]: `
    เหลืออีก {{number}} อักขระ <br> 
    อัปเดตเมื่อ {{date}}`,

  [AutoTranslationKeys.Applying_Translation.title]:
    'การแปลอัตโนมัติได้รับการใช้งานแล้ว',
  [AutoTranslationKeys.Applying_Translation.btn]: 'แสดงข้อความเดิม',
  [AutoTranslationKeys.Applying_Translation.message]: 'แปลแล้ว',

  [AutoTranslationKeys.Displaying_Original.title]: 'การแสดงข้อความเดิม',
  [AutoTranslationKeys.Displaying_Original.btn]: 'แปล',
  [AutoTranslationKeys.Displaying_Original.message]: 'ข้อความเดิม',
};
