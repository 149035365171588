import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]: '인증된 글로벌 기업들과 플랫폼에서',
  [InvitationKeys.title2]: '자동으로 연결 & 협업하세요!',
  [InvitationKeys.subtitle]:
    '* 최적의 경험을 위해 웹 환경을 이용하시는 것을 권장합니다.',

  [InvitationKeys.description]:
    '간단한 무료 프로필만으로 MCN 없이도 한국, 미국, 유럽, 일본 등의 인증된 글로벌 기업과 손쉽게 협업할 수 있습니다.',

  [InvitationKeys.view_creator_tutorial]: '크리에이터로 등록하기',
  [InvitationKeys.explore_btn]: '등록하고 선물받기',
  [InvitationKeys.learn_more]: '서비스 소개',
  [InvitationKeys.main_site]: '메인 페이지',

  [InvitationKeys.Browser.title]: '지원되지 않는 브라우저를 사용중입니다',
  [InvitationKeys.Browser.description]:
    '더 나은 사용자 경험을 위해 상기의 브라우저를 이용 부탁드립니다. 일부 기능은 현재 브라우저에서 지원되지 않을 수 있습니다.',
  [InvitationKeys.Browser.button]: '이해했습니다',

  [InvitationKeys.Mega_Flow.title]: '메가에볼루션에서는 이렇게 진행됩니다!',
  [InvitationKeys.Mega_Flow.Caption.one]: '1~2분 만에 크리에이터\n 프로필 생성',
  [InvitationKeys.Mega_Flow.Caption.two]:
    '플랫폼이 *자동*으로 프로필을\n 인증된 글로벌 기업에게 추천 & 소개',
  [InvitationKeys.Mega_Flow.Caption.three]:
    '기업 측에서 서비스 결제 및 주문,\n 크리에이터는 제안서 검토 후 수락',
  [InvitationKeys.Mega_Flow.Caption.four]:
    '콘텐츠 제출 및 협업 완료,\n 라이선스 판매를 통한 *추가 수입* 발생',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    '1~2분 만에 크리에이터 프로필 생성',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    '플랫폼이 *자동*으로 프로필을\n 인증된 글로벌 기업에게 추천 & 소개',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]:
    '기업 측에서 서비스 결제 및 주문,\n 크리에이터는 제안서 검토 후 수락',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    '콘텐츠 제출 및 협업 완료,\n 라이선스 판매를 통한 *추가 수입* 발생',

  [InvitationKeys.Creator_Review.title]:
    '메가에볼루션을 이용 중인 크리에이터 리뷰',

  // Brand
  [InvitationKeys.Brand.title]: `
    <span>전세계 인플루언서</span>와 한국어로 협업하세요! <br>
    쉽고 빠르게 성과내는 해외 마케팅`,
  [InvitationKeys.Brand.subTitle]: '글로벌 마케팅 플랫폼 사전등록 OPEN!',
  [InvitationKeys.Brand.Business_Flow.title]: `
    결제 · 정산은 물론, 성과 보고서, 라이선스까지 올.인.원 <br>
    메가에볼루션에서는 이렇게 진행됩니다!`,
  [InvitationKeys.Brand.Business_Flow.title_mobile]:
    '메가에볼루션에서는 이렇게 진행됩니다!',
  [InvitationKeys.Brand.Business_Flow.Caption.one]:
    '1~2분 만에 간편\n 프로필 생성',
  [InvitationKeys.Brand.Business_Flow.Caption.two]:
    '크리에이터 선택 후 *마케팅 패키지* 즉시 주문\n OR 원하는 서비스 요청',
  [InvitationKeys.Brand.Business_Flow.Caption.three]:
    '유튜브, 인스타그램, 틱톡 등\n *다양한 플랫폼* & *콘텐츠* (숏폼, 라이브 등) 지원',
  [InvitationKeys.Brand.Business_Flow.Caption.four]:
    'AI 자동 번역 탑재 실시간 협업,\n *마케팅 리포트* & *2차 라이선스*까지 올인원!',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.one]:
    '1~2분 만에 간편 프로필 생성',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.two]:
    '크리에이터 선택 후 *마케팅 패키지* 즉시 주문\n OR 원하는 서비스 요청',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.three]:
    '유튜브, 인스타그램, 틱톡 등\n *다양한 플랫폼* & *콘텐츠* (숏폼, 라이브 등) 지원',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.four]:
    'AI 자동 번역 탑재 실시간 협업,\n *마케팅 리포트* & *2차 라이선스*까지 올인원!',
  [InvitationKeys.Brand.Registration.title]: '글로벌 사전등록 절찬리 진행중!',
  [InvitationKeys.Brand.Registration.title_mobile]:
    '글로벌 사전등록 절찬리 진행중!',
  [InvitationKeys.Brand.Registration.subTitle]:
    '정식 오픈 시 사용 가능한 <span>40만원 상당의 프리미엄 이용권</span>을 드립니다 (3개월)',
  [InvitationKeys.Brand.Registration.Name.title]: '성함',
  [InvitationKeys.Brand.Registration.Name.placeholder]: '성함을 입력해주세요',
  [InvitationKeys.Brand.Registration.Email.title]: '이메일',
  [InvitationKeys.Brand.Registration.Email.placeholder]:
    '이메일을 입력해주세요',
  [InvitationKeys.Brand.Registration.receive_mega_guide_checkbox]:
    '(필수) 정식 오픈 이후 플랫폼 소식 수신 동의',
  [InvitationKeys.Brand.Registration.btn]: '사전등록하기',
  [InvitationKeys.Brand.Verified_Service.title]: `
    유튜브, 인스타그램, 틱톡 공식 인증 서비스로 <br>
    95% 더 정확한 데이터를 제공합니다!`,
  [InvitationKeys.Brand.Verified_Service.title_mobile]: `
    유튜브, 인스타그램, 틱톡 공식 인증 서비스로 <br>
    95% 더 정확한 데이터를 제공합니다!`,
  [InvitationKeys.Brand.Error.invalid_email]: '유효하지 않은 이메일입니다.',
  [InvitationKeys.Brand.Error.required_field]: '이메일을 입력해주세요.',
  [InvitationKeys.Brand.Toast.registration_complete]:
    '사전등록이 완료되었습니다!',
  [InvitationKeys.Brand.Toast.error]: '에러가 발생했습니다. 다시 시도해주세요.',
};
