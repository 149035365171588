export const ExpandIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_71944_29654" fill="white">
      <path d="M10 0H18V8H10V0Z" />
    </mask>
    <path
      d="M18 0H19.4V-1.4H18V0ZM10 1.4H18V-1.4H10V1.4ZM16.6 0V8H19.4V0H16.6Z"
      fill="white"
      mask="url(#path-1-inside-1_71944_29654)"
    />
    <mask id="path-3-inside-2_71944_29654" fill="white">
      <path d="M0 10H8V18H0V10Z" />
    </mask>
    <path
      d="M0 18H-1.4V19.4H0V18ZM8 16.6H0V19.4H8V16.6ZM1.4 18V10H-1.4V18H1.4Z"
      fill="white"
      mask="url(#path-3-inside-2_71944_29654)"
    />
  </svg>
);
