import { useChatList } from 'components/components/MessagesPage/ChatsList/ChatListContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import styled from 'styled-components';

const RedDot = () => {
  const { chats } = useChatList();
  const unreadCount = useMemo(
    () => chats?.filter((chat) => chat.unreadCount > 0).length,
    [chats]
  );

  return <AnimatePresence>{unreadCount > 0 && <Dot />}</AnimatePresence>;
};

export default RedDot;

const Dot = styled(motion.span).attrs({
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  transition: { duration: 0.2 },
})`
  position: absolute;
  top: 6px;
  right: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--color-red-500);
`;
