import { EnvelopeIcon } from 'assets/icons';
import Stack from 'components/Basics/Layout/Stack';
import LazyImage from 'components/Basics/LazyImage';
import TranslatedText from 'components/Basics/TranslatedText';
import dateFromNow from 'components/utilities/dateFromNow';
import { AnimatePresence, motion } from 'framer-motion';
import useUser from 'hooks/useUser';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MessageKeys from 'translations/translationKeys/MessageKeys';

const MessageItem = ({
  id,
  userName,
  pic,
  userId,
  userHandle,
  isRead,
  text,
  date,
  navigateTo,
  readChat,
  closeNotificationBox,
  sender,
}) => {
  const navigate = useNavigate();
  const { user } = useUser();

  return (
    <Wrapper
      layoutId={`chat-${id}`}
      isRead={isRead}
      onClick={() => {
        if (navigateTo) navigate(navigateTo);
        readChat();
        closeNotificationBox();
      }}
    >
      <Stack align="center" className="text-indigo-100">
        <ImageButton
          disabled={!userHandle && !userId}
          onClick={(e) => {
            e.stopPropagation();

            if (userHandle || userId)
              navigate(`/${userHandle ? `@${userHandle}` : userId}`);
          }}
        >
          <LazyImage
            src={pic}
            lazySrc={undefined} // TODO: Add minPic
            alt="Profile"
            width="2.5rem"
            height="2.5rem"
            circle
          />
        </ImageButton>
        <AnimatePresence>
          {!isRead && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.1 }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EnvelopeIcon size={0.875} />
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>

      <Stack gap="0.25rem">
        <TranslatedText
          withQuota
          className="bh6.5"
          text={userName}
          hideButton
        />

        <p className="h7 ellipsis-2-1.5">
          {sender === user?.id
            ? t(MessageKeys.ChatBox.Message.me) + ': ' + text
            : text}
        </p>

        <span className="text-xs text-indigo-100">{dateFromNow(date)}</span>
      </Stack>
    </Wrapper>
  );
};

export default MessageItem;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
})`
  width: 100%;
  padding: 1rem;
  padding-bottom: calc(1rem - 1px);
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  cursor: pointer;
  border-bottom: 1px solid var(--color-indigo-25);
  background: ${({ isRead }) => (isRead ? '#fff' : 'var(--color-pink-100)')};
  transition: background 150ms ease-in-out;

  &:hover {
    background: var(--color-pink-75);
  }
`;

const ImageButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
