export const SadEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M9.76732 18.0268C11.945 18.0268 13.7104 16.2615 13.7104 14.0838C13.7104 11.906 11.945 10.1406 9.76732 10.1406C7.58961 10.1406 5.82422 11.906 5.82422 14.0838C5.82422 16.2615 7.58961 18.0268 9.76732 18.0268Z"
      fill="white"
    />
    <path
      d="M20.2212 18.0987C22.4187 18.0987 24.2002 16.3172 24.2002 14.1197C24.2002 11.9221 22.4187 10.1406 20.2212 10.1406C18.0236 10.1406 16.2422 11.9221 16.2422 14.1197C16.2422 16.3172 18.0236 18.0987 20.2212 18.0987Z"
      fill="white"
    />
    <path
      d="M6.53857 9.99197C6.67306 9.58847 7.00921 8.95358 7.56506 8.42588C8.11293 7.90575 8.86147 7.5 9.84388 7.5C10.1027 7.5 10.3126 7.29013 10.3126 7.03125C10.3126 6.77237 10.1027 6.5625 9.84388 6.5625C8.57629 6.5625 7.60607 7.09425 6.91958 7.74599C6.24104 8.39017 5.82718 9.16149 5.64918 9.69553C5.56732 9.94116 5.70005 10.2066 5.94565 10.2884C6.19124 10.3703 6.4567 10.2376 6.53857 9.99197Z"
      fill="#402A32"
    />
    <path
      d="M12.8504 22.2254C13.1122 21.9636 13.7945 21.5625 15 21.5625C16.1508 21.5625 16.8924 21.9683 17.1496 22.2254C17.5157 22.5915 18.1093 22.5915 18.4754 22.2254C18.8415 21.8593 18.8415 21.2657 18.4754 20.8996C17.7949 20.2192 16.5366 19.6875 15 19.6875C13.3928 19.6875 12.2002 20.2239 11.5246 20.8996C11.1585 21.2657 11.1585 21.8593 11.5246 22.2254C11.8907 22.5915 12.4843 22.5915 12.8504 22.2254Z"
      fill="#402A32"
    />
    <path
      d="M22.435 8.42588C22.9909 8.95358 23.3271 9.58847 23.4615 9.99197C23.5434 10.2376 23.8088 10.3703 24.0545 10.2884C24.3001 10.2066 24.4328 9.94116 24.3509 9.69553C24.173 9.16149 23.7591 8.39017 23.0805 7.74599C22.3941 7.09425 21.4238 6.5625 20.1562 6.5625C19.8973 6.5625 19.6875 6.77237 19.6875 7.03125C19.6875 7.29013 19.8973 7.5 20.1562 7.5C21.1387 7.5 21.8872 7.90575 22.435 8.42588Z"
      fill="#402A32"
    />
    <path
      d="M13.125 14.0625C13.125 15.6158 11.8658 16.875 10.3125 16.875C8.7592 16.875 7.5 15.6158 7.5 14.0625C7.5 12.5092 8.7592 11.25 10.3125 11.25C11.8658 11.25 13.125 12.5092 13.125 14.0625Z"
      fill="#402A32"
    />
    <path
      d="M22.5 14.0625C22.5 15.6158 21.2407 16.875 19.6875 16.875C18.1342 16.875 16.875 15.6158 16.875 14.0625C16.875 12.5092 18.1342 11.25 19.6875 11.25C21.2407 11.25 22.5 12.5092 22.5 14.0625Z"
      fill="#402A32"
    />
    <path
      d="M5.625 19.6875C5.625 18.6519 6.46447 17.8125 7.5 17.8125C8.53553 17.8125 9.375 18.6519 9.375 19.6875V22.5C9.375 23.5356 8.53553 24.375 7.5 24.375C6.46447 24.375 5.625 23.5356 5.625 22.5V19.6875Z"
      fill="#3F5FFF"
    />
  </svg>
);
