import MessageKeys from '../translationKeys/MessageKeys';

export const MessageTranslations = {
  [MessageKeys.Dropdown.title]: '받은 편지함',
  [MessageKeys.Dropdown.no_message]: '메시지 없음',
  [MessageKeys.Dropdown.bottom_btn]: '모든 메시지 보기',

  [MessageKeys.ChatBox.title]: '메세지에 오신 것을 환영합니다!',
  [MessageKeys.ChatBox.no_chat]: `
    시작된 대화가 아직 없습니다. <br>
    새로운 대화를 시작하여 메세지를 볼 수 있습니다.`,
  [MessageKeys.ChatBox.no_selected_chat]:
    '메시지를 보내려면 리스트를 클릭하세요!',
  [MessageKeys.ChatBox.new_chat]: `
    {{user}}님과 연결하여 프로젝트의 상세 사항에 대해 대화를 시작해보세요. <br>
    <span>제안서를 통해 필요한 정보를 한 번에 보낼 수도 있습니다.</span>`,
  [MessageKeys.ChatBox.search]: `
    <h6>검색 결과가 없습니다</h6>
    <p>다른 키워드로 시도해주세요.</p>`,

  [MessageKeys.ChatBox.Message.me]: '나',
  [MessageKeys.ChatBox.Message.copy]: '메시지 복사',
  [MessageKeys.ChatBox.Message.report]: '메시지 신고',
  [MessageKeys.ChatBox.Message.download_file]: '모든 파일 다운로드',

  [MessageKeys.ChatBox.Message.typing]: '입력 중...',
  [MessageKeys.ChatBox.Message.Offer_Package.text]:
    '커스텀 패키지가 준비되었습니다.',
  [MessageKeys.ChatBox.Message.Offer_Package.description]:
    '클라이언트가 제안을 수락하면 주문이 시작됩니다.',
  [MessageKeys.ChatBox.Message.Offer_Package.btn]: '체크아웃하기',

  [MessageKeys.ChatBox.Message.Select_Package.title]: '패키지 선택',
  [MessageKeys.ChatBox.Message.Select_Package.btn]: '새 패키지 만들기',
  [MessageKeys.ChatBox.Message.Select_Package.complete_package_first]:
    '제안을 보내려면 먼저 첫 번째 패키지를 완료하세요!',
  [MessageKeys.ChatBox.Message.Select_Package.no_price]: '가격 없음',
  [MessageKeys.ChatBox.Message.Select_Package.verified_only]:
    '인증된 유저 전용',

  [MessageKeys.ChatBox.Message.Custom_Package.title]: '커스텀 패키지',
  [MessageKeys.ChatBox.Message.Custom_Package.description]:
    '구매자에게 표시되는 가격에는 약 {{number}}%의 서비스 수수료가 포함됩니다.',
  [MessageKeys.ChatBox.Message.Custom_Package.offering]: '제공 중...',
  [MessageKeys.ChatBox.Message.Custom_Package.btn1]: '패키지 제안하기',
  [MessageKeys.ChatBox.Message.Custom_Package.btn2]: '패키지 선택으로 돌아가기',

  [MessageKeys.ChatBox.Message.Request_Price.text]:
    '패키지 가격을 제시해 주세요.',
  [MessageKeys.ChatBox.Message.Request_Price.description]:
    '가격 요청이 크리에이터에게 성공적으로 전송되었습니다.',
  [MessageKeys.ChatBox.Message.Request_Price.btn]: '가격 및 패키지 제안하기',

  [MessageKeys.ChatBox.Message.File.singular]: '1개 파일',
  [MessageKeys.ChatBox.Message.File.plural]: '개 파일',
  [MessageKeys.ChatBox.Message.File.plural_2]: '2개 파일',
  [MessageKeys.ChatBox.Message.File.plural_3]: '3개 파일',
  [MessageKeys.ChatBox.Message.File.plural_4]: '4개 파일',
  [MessageKeys.ChatBox.Message.File.plural_5]: '5개 파일',
  [MessageKeys.ChatBox.Message.File.plural_6]: '6개 파일',
  [MessageKeys.ChatBox.Message.File.plural_7]: '7개 파일',
  [MessageKeys.ChatBox.Message.File.plural_8]: '8개 파일',
  [MessageKeys.ChatBox.Message.File.plural_9]: '9개 파일',
  [MessageKeys.ChatBox.Message.File.plural_10]: '10개 파일',
  [MessageKeys.ChatBox.Message.File.expired]: '이 파일은 만료되었습니다.',

  [MessageKeys.ChatBox.Message.Error.sending]: '전송 중...',
  [MessageKeys.ChatBox.Message.Error.fail]: '전송 실패',

  [MessageKeys.InputField.chat_disabled]:
    '이 유저에게 더 이상 메세지를 보낼 수 없습니다.',
  [MessageKeys.InputField.web]: '텍스트를 입력 또는 파일 드래그 & 드롭',
  [MessageKeys.InputField.mobile]: '메시지를 입력하세요',
  [MessageKeys.InputField.drag_drop]: '파일 드래그 & 드롭',

  [MessageKeys.InputField.Btn.offer_package]: '패키지 제안하기',
  [MessageKeys.InputField.Btn.send_proposal]: '제안서 보내기',

  [MessageKeys.UserInfo.Verified.tooltip]: `
    인증된 브랜드 또는 기업을 <br>
    대표하거나 관련된 계정입니다.`,

  [MessageKeys.UserInfo.Status.online]: '온라인',
  [MessageKeys.UserInfo.Status.offline]: '최근 활동',

  [MessageKeys.UserInfo.order_together]: '함께 진행한 주문',

  [MessageKeys.UserInfo.Btn.edit_profile]: '프로필 수정하기',
  [MessageKeys.UserInfo.Btn.visit_profile]: '프로필 보기',
  [MessageKeys.UserInfo.translate_messages]: '메시지 번역하기',

  [MessageKeys.Error.max_characters]: '최대 {{number}}자',
  [MessageKeys.Error.max_file_size_1]: '보낼 수 있는 총 파일 사이즈는 최대',
  [MessageKeys.Error.max_file_size_2]: 'MB 입니다',
  [MessageKeys.Error.max_file_1]: '최대',
  [MessageKeys.Error.max_file_2]: '개 파일을 보낼 수 있습니다',

  [MessageKeys.Modal.QuotaExceeded.title]: '번역 할당량 초과',
  [MessageKeys.Modal.QuotaExceeded.description]:
    '다음 갱신 날짜: {{date}} <br><br> 월별 번역 한도에 도달했습니다. 추가 번역을 원하시면 요금제를 업그레이드해주세요.',
  [MessageKeys.Modal.QuotaExceeded.btn]: '요금제 확인',

  [MessageKeys.Toast.Client.web]: `
    해외 크리에이터와 채팅 중이신가요? <br>
    편하신 언어로 자유롭게 이용하세요. <br>
    자동 번역을 지원해드립니다!`,
  [MessageKeys.Toast.Client.mobile]:
    '해외 크리에이터와 채팅 중이신가요? 편하신 언어로 자유롭게 이용하세요. 자동 번역을 지원해드립니다!',
  [MessageKeys.Toast.Creator.web]: `
    해외 브랜드나 회사와 채팅 중이신가요? <br>
    편하신 언어로 자유롭게 이용하세요. <br>
    자동 번역을 지원해드립니다!`,
  [MessageKeys.Toast.Creator.mobile]:
    '해외 브랜드나 회사와 채팅 중이신가요? 편하신 언어로 자유롭게 이용하세요. 자동 번역을 지원해드립니다!',
};
