export const TimeCrossIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 19}
    viewBox="0 0 18 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.57144 2.21429C4.78434 2.21429 1.71429 5.28434 1.71429 9.07144C1.71429 12.8586 4.78434 15.9286 8.57144 15.9286C9.04484 15.9286 9.42858 16.3123 9.42858 16.7857C9.42858 17.2591 9.04484 17.6429 8.57144 17.6429C3.83756 17.6429 0 13.8053 0 9.07144C0 4.33756 3.83756 0.5 8.57144 0.5C13.3053 0.5 17.1429 4.33756 17.1429 9.07144C17.1429 9.54484 16.7591 9.92858 16.2857 9.92858C15.8123 9.92858 15.4286 9.54484 15.4286 9.07144C15.4286 5.28434 12.3586 2.21429 8.57144 2.21429ZM12.6061 11.8939C12.2714 11.5592 11.7286 11.5592 11.3939 11.8939C11.0592 12.2286 11.0592 12.7714 11.3939 13.1061L13.3593 15.0714L11.3939 17.0368C11.0592 17.3715 11.0592 17.9142 11.3939 18.249C11.7286 18.5837 12.2714 18.5837 12.6061 18.249L14.5714 16.2836L16.5368 18.249C16.8715 18.5837 17.4143 18.5837 17.749 18.249C18.0837 17.9142 18.0837 17.3715 17.749 17.0368L15.7836 15.0714L17.749 13.1061C18.0837 12.7714 18.0837 12.2286 17.749 11.8939C17.4143 11.5592 16.8715 11.5592 16.5368 11.8939L14.5714 13.8593L12.6061 11.8939Z"
    />
    <path d="M9.04773 8.91172H10.7659C10.9938 8.91172 11.2123 9.00259 11.3734 9.16434C11.5345 9.32609 11.625 9.54547 11.625 9.77422C11.625 10.003 11.5345 10.2224 11.3734 10.3841C11.2123 10.5458 10.9938 10.6367 10.7659 10.6367H8.18864C7.96079 10.6367 7.74228 10.5458 7.58117 10.3841C7.42006 10.2224 7.32955 10.003 7.32955 9.77422V5.46172C7.32955 5.23297 7.42006 5.01359 7.58117 4.85184C7.74228 4.69009 7.96079 4.59922 8.18864 4.59922C8.41648 4.59922 8.635 4.69009 8.79611 4.85184C8.95722 5.01359 9.04773 5.23297 9.04773 5.46172V7.18672V8.91172Z" />
  </svg>
);
