export const BulbIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 14}
    height={size * 21}
    viewBox="0 0 14 21"
    fill="currentColor"
  >
    <path d="M11.4547 2.46877C10.6499 1.80685 9.70749 1.33295 8.69619 1.08164C7.6849 0.830325 6.63025 0.80795 5.60921 1.01614C4.24967 1.29076 3.00234 1.96304 2.02548 2.94769C1.04862 3.93233 0.386272 5.18496 0.12246 6.54664C-0.0701915 7.56762 -0.0351837 8.61852 0.224986 9.62441C0.485156 10.6303 0.964079 11.5664 1.6276 12.3659C2.24338 13.0608 2.59634 13.9494 2.62519 14.8774V17.5026C2.62519 18.1989 2.90177 18.8666 3.3941 19.359C3.88643 19.8513 4.55417 20.1279 5.25043 20.1279H8.75074C9.447 20.1279 10.1147 19.8513 10.6071 19.359C11.0994 18.8666 11.376 18.1989 11.376 17.5026V15.0437C11.4054 14.0191 11.7818 13.0349 12.4436 12.2522C13.6032 10.8177 14.1498 8.98358 13.9646 7.14837C13.7795 5.31316 12.8775 3.62518 11.4547 2.45127V2.46877ZM9.62582 17.5026C9.62582 17.7347 9.53363 17.9573 9.36952 18.1214C9.20541 18.2855 8.98283 18.3777 8.75074 18.3777H5.25043C5.01834 18.3777 4.79576 18.2855 4.63165 18.1214C4.46754 17.9573 4.37535 17.7347 4.37535 17.5026V16.6276H9.62582V17.5026ZM11.0872 11.1671C10.2073 12.2107 9.69396 13.5141 9.62582 14.8774H7.87566V13.5649C7.87566 13.3328 7.78347 13.1103 7.61936 12.9462C7.45525 12.7821 7.23267 12.6899 7.00059 12.6899C6.7685 12.6899 6.54592 12.7821 6.38181 12.9462C6.2177 13.1103 6.12551 13.3328 6.12551 13.5649V14.8774H4.37535C4.35226 13.5369 3.85638 12.2476 2.97522 11.2371C2.39385 10.5405 2.00291 9.70517 1.84051 8.81253C1.67811 7.91988 1.7498 7.00039 2.04859 6.1437C2.34737 5.287 2.86305 4.52236 3.54535 3.92431C4.22765 3.32627 5.05328 2.91524 5.94174 2.7313C6.70529 2.57408 7.49427 2.58887 8.2514 2.77459C9.00852 2.9603 9.71479 3.31228 10.3189 3.80498C10.9231 4.29767 11.4099 4.91871 11.7441 5.62301C12.0783 6.32731 12.2515 7.09719 12.2511 7.87676C12.2575 9.0755 11.846 10.239 11.0872 11.1671Z" />
  </svg>
);
