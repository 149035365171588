import AxiosClient from 'components/utilities/AxiosClient';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, useToggle } from 'react-use';
import { languageSubdomains } from '../constants/languages';
import { replaceSubdomain } from '../components/utilities/replaceSubdomain';
import useUser from 'hooks/useUser';
import { useSocket } from './SocketContext';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

const AutoTranslationContext = createContext({
  autoTranslate: true,
  toggleAutoTranslate: () => {},
  getTranslatedText: () => {},
  getTranslatedDescription: () => {},
  displayTranslatedText: false,
  toggleDisplayTranslatedText: () => {},
  haveTranslatedText: false,
  shouldReloadPageOnLanguageChange: () => {},
  quotaTranslateState: false,
  toggleQuotaTranslateState: () => {},
  getQuotaTranslatedText: () => {},
  getQuotaTranslatedDescription: () => {},
  translationQuota: {},
  openMessageQuotaEndedModal: () => {},
});

const setAutoTranslateSession = async (
  autoTranslate = true,
  defaultLanguage = 'en'
) => {
  try {
    await AxiosClient.post('/setAutoTranslateSession', {
      autoTranslate,
      defaultLanguage,
    });
  } catch (err) {
    console.log(err);
  }
};

const updateUserLanguage = async (selectedLanguage) => {
  try {
    await AxiosClient.patch('/updateLanguage', {
      language: selectedLanguage,
    });
  } catch (err) {
    console.log(err);
  }
};

const getUserMessageOrderTranslationPreference = async () => {
  try {
    const res = await AxiosClient.get('/getUserPaidAutoTranslation');
    return res?.data;
  } catch (err) {
    console.log(err);
  }
};

const updateUserMessageOrderTranslationPreference = async () => {
  try {
    const res = await AxiosClient.put('/updateUserPaidAutoTranslation');
    return !!res?.data?.userPaidAutoTranslation;
  } catch (err) {
    console.log(err);
  }
};

const reloadPageList = [
  // profile page regex
  {
    regex: /^\/(@[a-zA-Z0-9_.-]+|[0-9]+)$/,
    alwaysDisplayTranslation: true,
  },
  // search page regex
  {
    regex: /^\/search\b/,
    alwaysDisplayTranslation: true,
  },
  // regex for "/" path
  {
    regex: /^\/$/,
    alwaysDisplayTranslation: false,
  },
  // regex for "/checkout" path
  {
    regex: /^\/checkout$/,
    alwaysDisplayTranslation: false,
  },
];

const getEditorFormattedDescription = (value) => {
  return JSON.stringify({
    editorState: {
      root: {
        children: [
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: value,
                type: 'text',
                version: 1,
              },
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1,
          },
        ],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'root',
        version: 1,
      },
    },
    text: value,
    length: value?.length,
  });
};

const openMessageQuotaEndedModal = (haveCheckBox) => {
  const event = new CustomEvent(
    'open-message-translation-quota-exceeded-modal',
    {
      detail: {
        event: true,
        haveCheckBox: haveCheckBox,
      },
    }
  );
  window.dispatchEvent(event);
};

const insertSnippetData = (text, snippetData) => {
  if ((snippetData && !Object.keys(snippetData)?.length) || !text) return text;

  const regex = /{{(.*?)}}/g;

  const replacedString = text.replace(regex, (match, placeholder) => {
    return snippetData[placeholder.trim()] || match;
  });

  return replacedString;
};

const AutoTranslationProvider = ({ children }) => {
  const [autoTranslate, setAutoTranslate] = useLocalStorage(
    'autoTranslate',
    true
  );
  const [displayTranslatedText, toggleDisplayTranslatedText] = useToggle(false);
  const [haveTranslatedText, toggleHaveTranslatedText] = useToggle(false);
  const [quotaTranslateState, setMessageOrderAutoTranslate] = useState();
  const [translationQuota, setMessageOrderTrasnlationQuota] = useState({});
  const { isLoggedIn } = useUser();
  const socket = useSocket();

  const { i18n } = useTranslation();

  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const toggleAutoTranslate = useCallback(() => {
    setAutoTranslate(!autoTranslate);
  }, [autoTranslate, setAutoTranslate]);

  const getTranslatedText = useCallback(
    (value, returnOriginal) => {
      if (typeof value === 'object') {
        toggleHaveTranslatedText(true);
        if (displayTranslatedText) {
          if (returnOriginal) {
            return value?.text;
          } else {
            return value?.translatedText || value?.text;
          }
        } else {
          return value?.text;
        }
      } else {
        return value;
      }
    },
    [displayTranslatedText, toggleHaveTranslatedText]
  );

  const getTranslatedDescription = useCallback(
    (value) => {
      if (typeof value === 'object') {
        toggleHaveTranslatedText(true);
        if (displayTranslatedText) {
          return getEditorFormattedDescription(value?.translatedText);
        } else {
          return value?.text;
        }
      } else {
        return value;
      }
    },
    [displayTranslatedText, toggleHaveTranslatedText]
  );

  const toggleQuotaTranslateState = useCallback(() => {
    const parts = pathname.split('/').filter((part) => part !== '');
    const basePath = parts.length > 0 ? `/${parts[0]}` : '';
    const selected = parts.length > 1 ? parts[1] : '';

    updateUserMessageOrderTranslationPreference().then((res) => {
      setMessageOrderAutoTranslate(res);

      if (!!res) {
        queryClient.refetchQueries({
          queryKey: ['chats'],
          type: 'stale',
        });
        if (basePath === '/messages' && selected) {
          queryClient.refetchQueries({
            queryKey: ['chatMessages', selected],
            type: 'stale',
          });
        }
      }

      return res;
    });
  }, [setMessageOrderAutoTranslate, queryClient, pathname]);

  const getQuotaTranslatedText = useCallback(
    (value, returnOriginal) => {
      if (typeof value === 'object') {
        if (quotaTranslateState) {
          if (returnOriginal || !!value?.notEnoughQuota) {
            return {
              value: value?.text,
              translatable:
                value?.translatedText !== value?.text && value?.translatedText,
            };
          } else {
            return {
              value: value?.translatedText || value?.text,
              translatable:
                value?.translatedText !== value?.text && value?.translatedText,
            };
          }
        } else {
          return {
            value: value?.text,
            translatable: false,
          };
        }
      } else {
        return {
          value: value,
          translatable: false,
        };
      }
    },
    [quotaTranslateState]
  );

  const getQuotaTranslatedDescription = useCallback(
    (value, returnOriginal, snippetData) => {
      if (typeof value === 'object') {
        if (quotaTranslateState) {
          if (returnOriginal || !!value?.notEnoughQuota) {
            return {
              value: value?.text,
              translatable:
                value?.translatedText !== value?.text && value?.translatedText,
            };
          } else {
            return {
              value: value?.translatedText
                ? getEditorFormattedDescription(
                    insertSnippetData(value?.translatedText, snippetData)
                  )
                : value?.text,
              translatable:
                value?.translatedText !== value?.text && value?.translatedText,
            };
          }
        } else {
          return {
            value: value?.text,
            translatable: false,
          };
        }
      } else {
        return {
          value: value,
          translatable: false,
        };
      }
    },
    [quotaTranslateState]
  );

  const shouldReloadPageOnLanguageChange = useCallback(async () => {
    const selectedLanguage = i18n.language;
    const subdomain = languageSubdomains[selectedLanguage] || '';
    let url = window.location.href;
    await updateUserLanguage(selectedLanguage);

    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      url = replaceSubdomain(url, subdomain);
      window.location.replace(url);
    }
  }, [i18n.language]);

  useEffect(() => {
    setAutoTranslateSession(autoTranslate, i18n.language);
  }, [i18n.language, autoTranslate]);

  useEffect(() => {
    if (!isLoggedIn) return;

    getUserMessageOrderTranslationPreference().then((res) => {
      setMessageOrderAutoTranslate(!!res?.userPaidAutoTranslation);
      setMessageOrderTrasnlationQuota(res?.remainingCharacters);
    });
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      reloadPageList.some(
        (item) =>
          item.regex.test(window.location.pathname) &&
          item.alwaysDisplayTranslation
      )
    ) {
      toggleDisplayTranslatedText(autoTranslate);
    } else {
      toggleDisplayTranslatedText(true);
    }
  }, [toggleDisplayTranslatedText, autoTranslate]);

  // new message event listener
  useEffect(() => {
    const catchTranslationFailure = (e) => {
      if (e === 'QUOTA_EXCEEDED_FOR_CHARACTER_LIMIT') {
        openMessageQuotaEndedModal(true);
      }
    };

    socket.on('orderMessageTranslationQuotaExceeded', catchTranslationFailure);

    return () =>
      socket.off(
        'orderMessageTranslationQuotaExceeded',
        catchTranslationFailure
      );
  }, [socket]);

  const values = useMemo(
    () => ({
      autoTranslate,
      toggleAutoTranslate,
      getTranslatedText,
      getTranslatedDescription,
      displayTranslatedText,
      toggleDisplayTranslatedText,
      haveTranslatedText:
        haveTranslatedText &&
        reloadPageList.some((item) =>
          item.regex.test(window.location.pathname)
        ),
      shouldReloadPageOnLanguageChange,
      quotaTranslateState,
      toggleQuotaTranslateState,
      getQuotaTranslatedText,
      getQuotaTranslatedDescription,
      translationQuota,
      openMessageQuotaEndedModal,
    }),
    [
      autoTranslate,
      toggleAutoTranslate,
      getTranslatedText,
      getTranslatedDescription,
      displayTranslatedText,
      toggleDisplayTranslatedText,
      haveTranslatedText,
      shouldReloadPageOnLanguageChange,
      quotaTranslateState,
      toggleQuotaTranslateState,
      getQuotaTranslatedText,
      getQuotaTranslatedDescription,
      translationQuota,
    ]
  );

  return (
    <AutoTranslationContext.Provider value={values}>
      {children}
    </AutoTranslationContext.Provider>
  );
};

const useAutoTranslation = () => useContext(AutoTranslationContext);

export { AutoTranslationProvider, useAutoTranslation };
