import 'dayjs/locale/vi';

const viLocale = {
  name: 'vi',
  relativeTime: {
    future: 'Sau %s',
    past: 'Trước %s',
    s: '1 phút',
    m: '1 phút',
    mm: '%d phút',
    h: '1 giờ',
    hh: '%d giờ',
    d: '1 ngày',
    dd: '%d ngày',
    M: '1 tháng',
    MM: '%d tháng',
    y: '1 năm',
    yy: '%d năm',
  },
  dateFormat: {
    dot_date: 'DD.MM.YYYY',
    dash_date: 'DD-MM-YYYY',
    dot_date_time_12: 'DD.MM.YYYY h:mm A',
    time_12: 'h:mm A',
    abbr_date_time_12: '[ngày] D [tháng] M h:mm A',
    date_time: '[ngày] D [tháng] M [năm] YYYY',
    yesterday: 'Hôm qua',
    full_abbr_date_time_12: '[ngày] D [tháng] M [năm] YYYY h:mm A',
  },
  meridiem: (hour) => {
    return hour > 12 ? 'PM' : 'AM';
  },
};

export default viLocale;
