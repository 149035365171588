export const UpDownArrowIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 7}
    height={size * 15}
    viewBox="0 0 7 15"
    fill="currentColor"
  >
    <path d="M5.80151 10.5019L4.19971 12.1123V2.88408L5.80151 4.49446C5.86653 4.56008 5.94389 4.61217 6.02913 4.64772C6.11437 4.68327 6.20579 4.70157 6.29813 4.70157C6.39047 4.70157 6.4819 4.68327 6.56714 4.64772C6.65237 4.61217 6.72973 4.56008 6.79476 4.49446C6.86032 4.42937 6.91236 4.35193 6.94787 4.26661C6.98338 4.18129 7.00166 4.08977 7.00166 3.99734C7.00166 3.90491 6.98338 3.81339 6.94787 3.72807C6.91236 3.64275 6.86032 3.56531 6.79476 3.50022L3.99686 0.699557C3.93034 0.635813 3.8519 0.585846 3.76604 0.552522C3.59574 0.482493 3.40473 0.482493 3.23444 0.552522C3.14857 0.585846 3.07013 0.635813 3.00361 0.699557L0.20571 3.50022C0.140492 3.56551 0.0887581 3.64301 0.0534624 3.7283C0.0181667 3.8136 6.87184e-10 3.90502 0 3.99734C-6.87182e-10 4.08966 0.0181667 4.18108 0.0534624 4.26638C0.0887581 4.35167 0.140492 4.42918 0.20571 4.49446C0.270928 4.55974 0.348353 4.61153 0.433564 4.64686C0.518776 4.68219 0.610105 4.70037 0.702337 4.70037C0.794569 4.70037 0.885898 4.68219 0.97111 4.64686C1.05632 4.61153 1.13375 4.55974 1.19896 4.49446L2.80076 2.88408V12.1123L1.19896 10.5019C1.06725 10.37 0.888608 10.296 0.702337 10.296C0.516065 10.296 0.337423 10.37 0.20571 10.5019C0.073996 10.6337 0 10.8125 0 10.999C0 11.1855 0.073996 11.3643 0.20571 11.4961L3.00361 14.2968C3.07013 14.3605 3.14857 14.4105 3.23444 14.4438C3.31816 14.4809 3.40869 14.5 3.50024 14.5C3.59178 14.5 3.68231 14.4809 3.76604 14.4438C3.8519 14.4105 3.93034 14.3605 3.99686 14.2968L6.79476 11.4961C6.92647 11.3643 7.00047 11.1855 7.00047 10.999C7.00047 10.8125 6.92647 10.6337 6.79476 10.5019C6.72954 10.4366 6.65212 10.3848 6.56691 10.3495C6.48169 10.3142 6.39037 10.296 6.29813 10.296C6.11186 10.296 5.93322 10.37 5.80151 10.5019Z" />
  </svg>
);
