import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const links = [
  {
    label: GeneralKeys.about,
    href: '/about',
  },
  {
    label: GeneralKeys.creator_marketing,
    href: '/',
  },
  {
    label: GeneralKeys.pricing,
    href: '/pricing',
  },
  {
    label: GeneralKeys.evo_tokens,
    href: '/evo-points',
  },
];

const Links = () => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        {links.map(({ label, href, width }) => (
          <PlainButton key={label} className="text-inherit" to={href}>
            {t(label)}
          </PlainButton>
        ))}
      </Wrapper>
    </>
  );
};

export default Links;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
  pointer-events: all;
`;

const PlainButton = styled(Link)`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-in-out;
  text-decoration: none;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.15px;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;
