export const AngryEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#EC595A"
    />
    <path
      d="M9.84375 19.6875C12.1737 19.6875 14.0625 17.7987 14.0625 15.4688C14.0625 13.1388 12.1737 11.25 9.84375 11.25C7.5138 11.25 5.625 13.1388 5.625 15.4688C5.625 17.7987 7.5138 19.6875 9.84375 19.6875Z"
      fill="white"
    />
    <path
      d="M20.1562 19.6875C22.4862 19.6875 24.375 17.7987 24.375 15.4688C24.375 13.1388 22.4862 11.25 20.1562 11.25C17.8263 11.25 15.9375 13.1388 15.9375 15.4688C15.9375 17.7987 17.8263 19.6875 20.1562 19.6875Z"
      fill="white"
    />
    <path
      d="M13.397 11.7199C13.7848 11.7406 14.0823 12.0718 14.0617 12.4595C14.041 12.8473 13.7099 13.1448 13.3221 13.1242C12.3686 13.0734 11.4577 12.8396 10.6813 12.2989C9.89835 11.7537 9.31249 10.9381 8.94275 9.83254C8.81958 9.46429 9.01827 9.06592 9.38657 8.94275C9.75482 8.81958 10.1533 9.01827 10.2763 9.38657C10.5673 10.2563 10.991 10.8009 11.485 11.1449C11.9858 11.4937 12.6196 11.6785 13.397 11.7199Z"
      fill="#402A32"
    />
    <path
      d="M16.6032 11.7199C16.2154 11.7406 15.9179 12.0718 15.9385 12.4595C15.9592 12.8473 16.2904 13.1448 16.6781 13.1242C17.6316 13.0734 18.5425 12.8396 19.3189 12.2989C20.1019 11.7537 20.6877 10.9381 21.0574 9.83254C21.1806 9.46429 20.982 9.06592 20.6136 8.94275C20.2454 8.81958 19.847 9.01827 19.7239 9.38657C19.4329 10.2563 19.0092 10.8009 18.5152 11.1449C18.0144 11.4937 17.3806 11.6785 16.6032 11.7199Z"
      fill="#402A32"
    />
    <path
      d="M15.0003 22.5C13.1209 22.5 12.2495 23.5352 12.0888 23.8568C11.8572 24.3199 11.294 24.5076 10.8309 24.276C10.3679 24.0444 10.1801 23.4813 10.4117 23.0182C10.8759 22.0898 12.3796 20.625 15.0003 20.625C17.6209 20.625 19.1245 22.0898 19.5888 23.0182C19.8203 23.4813 19.6326 24.0444 19.1695 24.276C18.7064 24.5076 18.1432 24.3199 17.9117 23.8568C17.7509 23.5352 16.8796 22.5 15.0003 22.5Z"
      fill="#402A32"
    />
    <path
      d="M13.125 15.9375C13.125 16.973 12.2856 17.8125 11.25 17.8125C10.2144 17.8125 9.375 16.973 9.375 15.9375C9.375 14.9019 10.2144 14.0625 11.25 14.0625C12.2856 14.0625 13.125 14.9019 13.125 15.9375Z"
      fill="#402A32"
    />
    <path
      d="M20.625 15.9375C20.625 16.973 19.7856 17.8125 18.75 17.8125C17.7144 17.8125 16.875 16.973 16.875 15.9375C16.875 14.9019 17.7144 14.0625 18.75 14.0625C19.7856 14.0625 20.625 14.9019 20.625 15.9375Z"
      fill="#402A32"
    />
  </svg>
);
