export const BulbPinIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 17}
    height={size * 25}
    viewBox="0 0 17 25"
    fill="currentColor"
    stroke="currentColor"
  >
    <path
      d="M14.3187 13.223C15.5719 11.68 16.1626 9.70329 15.9614 7.72569C15.7602 5.74809 14.7836 3.9308 13.2453 2.67177C12.364 1.96548 11.3366 1.46419 10.2375 1.20427C9.13842 0.944357 7.99527 0.932325 6.89096 1.16905C5.65106 1.44329 4.50089 2.02732 3.54788 2.86657C2.59488 3.70583 1.87013 4.77293 1.44132 5.96821C1.01252 7.16349 0.893663 8.44796 1.09584 9.70163C1.29803 10.9553 1.81465 12.1373 2.59747 13.1372C3.31091 14.0051 3.71835 15.0841 3.75671 16.207V16.5183C3.34829 16.9129 3.11285 17.4532 3.10196 18.021V20.1677C3.10196 20.7371 3.32813 21.2831 3.73072 21.6857C4.13331 22.0883 4.67935 22.3145 5.2487 22.3145V23.3879C5.2487 23.6725 5.36179 23.9456 5.56308 24.1469C5.76438 24.3481 6.0374 24.4612 6.32207 24.4612C6.60675 24.4612 6.87977 24.3481 7.08106 24.1469C7.28236 23.9456 7.39545 23.6725 7.39545 23.3879V22.3145H9.54219V23.3879C9.54219 23.6725 9.65528 23.9456 9.85657 24.1469C10.0579 24.3481 10.3309 24.4612 10.6156 24.4612C10.9002 24.4612 11.1733 24.3481 11.3746 24.1469C11.5758 23.9456 11.6889 23.6725 11.6889 23.3879V22.3145C12.2583 22.3145 12.8043 22.0883 13.2069 21.6857C13.6095 21.2831 13.8357 20.7371 13.8357 20.1677V18.021C13.8362 17.4593 13.6165 16.9199 13.2239 16.5183V16.0567C13.2552 15.015 13.6415 14.0152 14.3187 13.223ZM5.2487 20.1677V18.021H11.6889V20.1677H5.2487ZM12.6442 11.8813C11.6941 13.002 11.1429 14.4064 11.0771 15.8743H5.84979C5.74854 14.3872 5.18568 12.9688 4.23973 11.8169C3.6715 11.1038 3.29432 10.2577 3.1438 9.35835C2.99327 8.45903 3.07436 7.5362 3.3794 6.67691C3.68445 5.81763 4.20339 5.05023 4.88728 4.44714C5.57116 3.84404 6.39742 3.42513 7.28811 3.22993C8.07725 3.05193 8.89642 3.05462 9.68437 3.23782C10.4723 3.42101 11.2087 3.77997 11.8383 4.28784C12.468 4.79571 12.9747 5.43936 13.3206 6.17066C13.6665 6.90195 13.8426 7.70198 13.8357 8.51092C13.8424 9.73873 13.4211 10.9305 12.6442 11.8813Z"
      stroke-width="0.25"
    />
  </svg>
);
