const CreatorRegistrationKeys = {
  complete_registration: 'CreatorRegistrationKeys.complete_registration',
  success_registration: 'CreatorRegistrationKeys.success_registration',
  evo_collected: 'CreatorRegistrationKeys.evo_collected',
  click_me: 'CreatorRegistrationKeys.click_me',
  touch_me: 'CreatorRegistrationKeys.touch_me',
  _10_times: 'CreatorRegistrationKeys._10_times',

  Topbar: {
    title: 'CreatorRegistrationKeys.Topbar.title',
    time: 'CreatorRegistrationKeys.Topbar.time',
    explain: 'CreatorRegistrationKeys.Topbar.explain',
  },

  Social_Network: {
    tooltip: 'CreatorRegistrationKeys.Social_Network.tooltip',
    popup_error: 'CreatorRegistrationKeys.Social_Network.popup_error',
  },

  Error: {
    social_network: 'CreatorRegistrationKeys.Error.social_network',

    handle_char_limit: 'CreatorRegistrationKeys.Error.handle_char_limit',
    handle_invalid: 'CreatorRegistrationKeys.Error.handle_invalid',
    handle_taken: 'CreatorRegistrationKeys.Error.handle_taken',
    handle_required: 'CreatorRegistrationKeys.Error.handle_required',

    category: 'CreatorRegistrationKeys.Error.category',
    invalid_category: 'CreatorRegistrationKeys.Error.invalid_category',

    target_country: 'CreatorRegistrationKeys.Error.target_country',
    invalid_country: 'CreatorRegistrationKeys.Error.invalid_country',

    language: 'CreatorRegistrationKeys.Error.language',
    invalid_language: 'CreatorRegistrationKeys.Error.invalid_language',
  },

  Modal: {
    already_creator: {
      title: 'CreatorRegistrationKeys.Modal.already_creator.title',
      description: 'CreatorRegistrationKeys.Modal.already_creator.description',
    },

    already_company: {
      title: 'CreatorRegistrationKeys.Modal.already_company.title',
      description: 'CreatorRegistrationKeys.Modal.already_company.description',
    },

    Registration_Fail: {
      title: 'CreatorRegistrationKeys.Modal.Registration_Fail.title',

      Description: {
        minimum_subs_l1:
          'CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1',
        minimum_subs_l2:
          'CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2',

        already_verified_l1:
          'CreatorRegistrationKeys.Modal.Registration_Fail.Description.already_verified_l1',
        already_verified_l2:
          'CreatorRegistrationKeys.Modal.Registration_Fail.Description.already_verified_l2',
      },
    },

    Verification_Fail: {
      title: 'CreatorRegistrationKeys.Modal.Verification_Fail.title',

      Description: {
        update_insta:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.update_insta',

        need_public_posts:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.need_public_posts',

        no_connected:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected',

        insta_to_use:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use',

        to_use_our_service:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.to_use_our_service',

        insta_different:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different',

        verify_social_media:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.verify_social_media',

        already_verified:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.already_verified',

        minimum_subs:
          'CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs',
      },
    },

    Instagram_Notice: {
      update_connection:
        'CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection',
      upgrade_connection:
        'CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection',
    },

    Instagram_Facebook: {
      title: 'CreatorRegistrationKeys.Modal.Instagram_Facebook.title',
      point1: 'CreatorRegistrationKeys.Modal.Instagram_Facebook.point1',
      point2: 'CreatorRegistrationKeys.Modal.Instagram_Facebook.point2',
      point3: 'CreatorRegistrationKeys.Modal.Instagram_Facebook.point3',
    },
  },
};

export default CreatorRegistrationKeys;
