import styled from 'styled-components';
import Header from 'components/Basics/Layout/Header';
import useFooterStyles from 'components/Basics/Layout/Footer/useFooterStyles';
import { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { lazy } from 'react';
import device from 'styles/device';
import Loading from 'components/components/Common/Loading';
import Footer from 'components/Basics/Layout/Footer';
import BottomNavigationBar from 'components/Basics/Layout/BottomNavigationBar';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import ProtectedRoute from 'routes/ProtectedRoute';
import AuthRedirectRoute from './AuthRedirectRoute';

const nonContentPages = [
  '/creator-invitation',
  '/deleted',
  '/my-space',
  '/support',
  '/notice',
  '/',
  '/profile',
];

const getBackground = (pathname, isMobile) => {
  if (pathname === '/' || pathname.includes('search')) {
    return '#fff';
  }

  if (pathname === '/about') {
    return '#fcf6ff';
  }

  if (isMobile) {
    if (
      pathname === '/creator-registration' ||
      pathname.includes('messages') ||
      pathname.includes('help-center')
    ) {
      return '#fff';
    }
  }

  return 'transparent';
};

//*  Influencer Marketing (Main page)
const InfluencerHomePage = lazy(() =>
  import('components/pages/InfluencerHomePage')
);
const InfluencerSearchPage = lazy(() =>
  import('components/pages/InfluencerSearchPage')
);
//* General
const VerifyEmailPage = lazy(() => import('components/pages/VerifyEmailPage'));
const ResetPasswordPage = lazy(() =>
  import('components/pages/ResetPasswordPage')
);
const EndMessagePage = lazy(() => import('components/pages/EndMessagePage'));
const DeletedPage = lazy(() => import('components/pages/DeletedPage'));
const DeactivatedPage = lazy(() => import('components/pages/DeactivatedPage'));
const TermsAndConditionsPage = lazy(() =>
  import('components/pages/TermsAndConditionsPage')
);
const PrivacyPolicyPage = lazy(() =>
  import('components/pages/PrivacyPolicyPage')
);
const CookiePolicyPage = lazy(() =>
  import('components/pages/CookiePolicyPage')
);
const HelpCenterPage = lazy(() => import('components/pages/HelpCenterPage'));
const NoticePage = lazy(() => import('components/pages/NoticePage'));
const HelpDeskPage = lazy(() => import('components/pages/HelpDeskPage'));
const SupportNavigationPage = lazy(() =>
  import('components/pages/SupportNavigationPage')
);
//* My Space
const MySpacePage = lazy(() => import('components/pages/MySpacePage'));
//* Settings
const SettingsPage = lazy(() => import('components/pages/SettingsPage'));
//* About
const AboutPage = lazy(() => import('components/pages/AboutPage'));
//* Evo Tokens
const EvoMissionsPage = lazy(() => import('components/pages/EvoMissionsPage'));
//* Pricing
const PricePage = lazy(() => import('components/pages/PricePage'));
//* Messages
const MessagesPage = lazy(() => import('components/pages/MessagesPage'));
//* Influencer Invitation Page
const CreatorInvitationPage = lazy(() =>
  import('components/pages/CreatorInvitationPage')
);
const BrandInvitationPage = lazy(() =>
  import('components/pages/BrandInvitationPage')
);
//* Billing
const BillingPage = lazy(() => import('components/pages/BillingPage'));
const CheckoutPage = lazy(() => import('components/pages/CheckoutPage'));
//* Miscellaneous
const UnderDevelopmentPage = lazy(() =>
  import('components/pages/UnderDevelopmentPage')
);
const ServerUpdatingPage = lazy(() =>
  import('components/pages/ServerUpdatingPage')
);
const NotFound404Page = lazy(() => import('components/pages/NotFound404Page'));
const UnSubscribePage = lazy(() => import('components/pages/UnSubscribePage'));
const ExpiredLinkPage = lazy(() => import('components/pages/ExpiredLinkPage'));
const StyleGuidePage = lazy(() => import('components/pages/StyleGuidePage'));
const DevTestPage = lazy(() => import('components/pages/DevTestPage'));
const TranslationLogsPage = lazy(() =>
  import('components/pages/TranslationLogsPage')
);
const ClientVerificationAdminPage = lazy(() =>
  import('components/pages/ClientVerificationAdminPage')
);
const PatchNoteAdminPage = lazy(() =>
  import('components/pages/PatchNoteAdminPage')
);
//* Redirect URI pages
const AuthRedirectPage = lazy(() =>
  import('components/pages/AuthRedirectPage')
);
//* creator registration
const CreatorRegistrationPage = lazy(() =>
  import('components/pages/CreatorRegistrationPage')
);
//* Proposals
const ProposalsPage = lazy(() => import('components/pages/ProposalsPage'));
const ProposalSubmitPage = lazy(() =>
  import('components/pages/ProposalSubmitPage')
);
//* Orders
const SupportCenterPage = lazy(() =>
  import('components/pages/SupportCenterPage')
);
const OrdersPage = lazy(() => import('components/pages/OrdersPage'));
const OrdersAdminPage = lazy(() => import('components/pages/OrdersAdminPage'));
const AdminSingleOrderView = lazy(() =>
  import('components/components/OrdersAdminPage/AdminSingleOrderView')
);
//* Profile
const ProfilePage = lazy(() => import('components/pages/ProfilePage'));
const ProfileEditPage = lazy(() => import('components/pages/ProfileEditPage'));
//* Admin
const SuperAdminPage = lazy(() => import('components/pages/SuperAdminPage'));
const CreatorStatisticsPage = lazy(() =>
  import('components/pages/CreatorStatisticsPage')
);
const FaqEditorAdminPage = lazy(() =>
  import('components/pages/HelpCenterEditorAdminPage')
);
const LicensingTermsEditorAdminPage = lazy(() =>
  import('components/pages/LicensingTermsEditorAdminPage')
);

const LayoutRoutes = () => {
  const { isVisible: haveFooter, isBottomNavBarVisible: haveBottomNavBar } =
    useFooterStyles();
  const { pathname } = useLocation();
  const { isMobile } = useResponsiveScreen();

  return (
    <Container>
      <Header />
      <Suspense fallback={<Loading />}>
        <Page
          background={getBackground(pathname, isMobile)}
          fullHeight={!nonContentPages.map((pagePath) => pathname === pagePath)}
        >
          <Routes>
            {/* Influencer Marketing (Main page) */}
            <Route path="/" element={<InfluencerHomePage />} />
            <Route path="/search" element={<InfluencerSearchPage />} />

            {/* General */}
            <Route
              path="/auth/verify-email/:id/:token"
              element={<VerifyEmailPage />}
            />
            <Route
              path="/auth/resetpassword/:id/:token"
              element={<ResetPasswordPage />}
            />
            <Route path="/end" element={<EndMessagePage />} />
            <Route path="/deleted" element={<DeletedPage />} />
            <Route path="/deactivated" element={<DeactivatedPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            <Route path="/help-center" element={<HelpCenterPage />} />
            <Route path="/help-center/:category" element={<HelpCenterPage />} />
            <Route
              path="/help-center/:category/:option"
              element={<HelpCenterPage />}
            />
            <Route path="/notice" element={<NoticePage />} />
            {/* <Route path="/help-desk" element={<HelpDeskPage />} /> */}
            <Route path="/support" element={<SupportNavigationPage />} />

            {/* My Space */}
            <Route
              path="/my-space"
              element={
                <ProtectedRoute>
                  <MySpacePage />
                </ProtectedRoute>
              }
            />

            {/* Settings */}
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />

            {/* About */}
            <Route path="/about" element={<AboutPage />} />

            {/* Evo Tokens */}
            <Route path="/evo-points" element={<EvoMissionsPage />} />

            {/* Pricing */}
            <Route path="/pricing" element={<PricePage />} />

            {/* Messages */}
            <Route
              path="/messages"
              element={
                <ProtectedRoute>
                  <MessagesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/messages/:selected"
              element={
                <ProtectedRoute>
                  <MessagesPage />
                </ProtectedRoute>
              }
            />

            {/* Influencer Invitation Page */}
            <Route
              path="/creator-invitation"
              element={<CreatorInvitationPage />}
            />

            {/* Brand Invitation Page */}
            <Route path="/brand-invitation" element={<BrandInvitationPage />} />

            {/* Billing */}
            <Route
              path="/billing/*"
              element={
                <ProtectedRoute>
                  <BillingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/checkout"
              element={
                <ProtectedRoute>
                  <CheckoutPage />
                </ProtectedRoute>
              }
            />

            {/* Miscellaneous */}
            <Route
              path="/under-development"
              element={<UnderDevelopmentPage />}
            />
            <Route path="/server-update" element={<ServerUpdatingPage />} />
            <Route path="/page-not-found" element={<NotFound404Page />} />
            <Route path="/unsubscribe" element={<UnSubscribePage />} />
            <Route path="/link-expired" element={<ExpiredLinkPage />} />
            {process.env.REACT_APP_NODE_ENV === 'development' && (
              <>
                <Route path="/style-guide" element={<StyleGuidePage />} />
                <Route path="/dev-test" element={<DevTestPage />} />
                <Route
                  path="/translation-logs"
                  element={<TranslationLogsPage />}
                />
              </>
            )}
            <Route
              path="admin/client-verification"
              element={
                <ProtectedRoute adminOnly>
                  <ClientVerificationAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/patch-note"
              element={
                <ProtectedRoute adminOnly>
                  <PatchNoteAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/help-center-editor"
              element={
                <ProtectedRoute adminOnly>
                  <FaqEditorAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/licensing-terms-editor"
              element={
                <ProtectedRoute adminOnly>
                  <LicensingTermsEditorAdminPage />
                </ProtectedRoute>
              }
            />

            {/* Redirect URI pages */}
            <Route
              path="/platforms/auth"
              element={<AuthRedirectPage />}
            />

            {/* creator registration */}
            <Route
              path="/creator-registration"
              element={<CreatorRegistrationPage />}
            />

            {/* Proposals */}
            <Route
              path="/admin"
              element={
                <ProtectedRoute adminOnly>
                  <SuperAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/creator-statistics"
              element={
                <ProtectedRoute adminOnly>
                  <CreatorStatisticsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/orders"
              element={
                <ProtectedRoute>
                  <OrdersAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/orders/:id"
              element={
                <ProtectedRoute>
                  <AdminSingleOrderView />
                </ProtectedRoute>
              }
            />

            <Route
              path="/proposals"
              element={
                <ProtectedRoute>
                  <ProposalsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/submit-proposal/:id"
              element={
                <ProtectedRoute>
                  <ProposalSubmitPage />
                </ProtectedRoute>
              }
            />

            {/* Orders */}
            <Route
              path="/orders/:id/support-center"
              element={
                <ProtectedRoute>
                  <SupportCenterPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders/:id/*"
              element={
                <AuthRedirectRoute>
                  <OrdersPage />
                </AuthRedirectRoute>
              }
            />

            {/* Profile */}
            <Route path="/:id" element={<ProfilePage />} />
            <Route
              path="/profile-edit"
              element={
                <ProtectedRoute>
                  <ProfileEditPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Page>

        {haveFooter && <Footer />}
        {haveBottomNavBar && <BottomNavigationBar />}
      </Suspense>
    </Container>
  );
};

export default LayoutRoutes;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
`;

const Page = styled.div`
  background: ${({ background }) => background || 'transparent'};
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: ${({ fullHeight }) => (fullHeight ? '100vh' : 'auto')};

  @media ${device.md} {
    @media (max-width: 1000px) {
      min-height: 100vh;
      flex: 1;
    }
  }
`;
