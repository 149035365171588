export const JoyEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M14.0625 9.84375C14.0625 11.1787 13.4425 12.3687 12.4748 13.1418C11.7218 12.8892 10.7595 12.6562 9.84375 12.6562C8.928 12.6562 7.96567 12.8892 7.21272 13.1418C6.24499 12.3687 5.625 11.1787 5.625 9.84375C5.625 7.5138 7.5138 5.625 9.84375 5.625C12.1737 5.625 14.0625 7.5138 14.0625 9.84375Z"
      fill="white"
    />
    <path
      d="M24.375 9.84375C24.375 11.1787 23.755 12.3687 22.7873 13.1418C22.0343 12.8892 21.072 12.6562 20.1562 12.6562C19.2405 12.6562 18.2782 12.8892 17.5252 13.1418C16.5575 12.3687 15.9375 11.1787 15.9375 9.84375C15.9375 7.5138 17.8263 5.625 20.1562 5.625C22.4862 5.625 24.375 7.5138 24.375 9.84375Z"
      fill="white"
    />
    <path
      d="M10.3125 12.1875C11.8658 12.1875 13.125 10.9283 13.125 9.375C13.125 7.8217 11.8658 6.5625 10.3125 6.5625C8.7592 6.5625 7.5 7.8217 7.5 9.375C7.5 10.9283 8.7592 12.1875 10.3125 12.1875Z"
      fill="#402A32"
    />
    <path
      d="M19.6875 12.1875C21.2408 12.1875 22.5 10.9283 22.5 9.375C22.5 7.8217 21.2408 6.5625 19.6875 6.5625C18.1342 6.5625 16.875 7.8217 16.875 9.375C16.875 10.9283 18.1342 12.1875 19.6875 12.1875Z"
      fill="#402A32"
    />
    <path
      d="M15 23.4375C6.5625 23.4375 6.5625 15 6.5625 15H23.4375C23.4375 15 23.4375 23.4375 15 23.4375Z"
      fill="#BB1D80"
    />
    <path
      d="M7.5 15.4688V15H22.5V15.4688C22.5 15.9865 22.0803 16.4062 21.5625 16.4062H8.4375C7.91974 16.4062 7.5 15.9865 7.5 15.4688Z"
      fill="white"
    />
  </svg>
);
