export const ArrowUpIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 8}
    height={size * 15}
    viewBox="0 0 8 15"
    fill="currentColor"
  >
    <path d="M1.36992 5.06401L3.20011 3.22401V13.7C3.20011 13.9122 3.28431 14.1157 3.43419 14.2657C3.58407 14.4157 3.78735 14.5 3.99932 14.5C4.21128 14.5 4.41456 14.4157 4.56444 14.2657C4.71433 14.1157 4.79853 13.9122 4.79853 13.7V3.22401L6.62872 5.06401C6.70302 5.13899 6.79141 5.19851 6.8888 5.23912C6.98619 5.27974 7.09065 5.30065 7.19616 5.30065C7.30166 5.30065 7.40612 5.27974 7.50351 5.23912C7.6009 5.19851 7.6893 5.13899 7.76359 5.06401C7.8385 4.98964 7.89796 4.90116 7.93854 4.80367C7.97911 4.70618 8 4.60162 8 4.49601C8 4.3904 7.97911 4.28584 7.93854 4.18835C7.89796 4.09086 7.8385 4.00238 7.76359 3.92801L4.56676 0.728011C4.49075 0.655178 4.40112 0.598086 4.30302 0.560011C4.10844 0.479996 3.8902 0.479996 3.69562 0.560011C3.59751 0.598086 3.50789 0.655178 3.43188 0.728011L0.235041 3.92801C0.160524 4.0026 0.101414 4.09115 0.0610854 4.18861C0.020757 4.28607 7.85166e-10 4.39052 0 4.49601C-7.85165e-10 4.6015 0.020757 4.70595 0.0610854 4.80341C0.101414 4.90087 0.160524 4.98942 0.235041 5.06401C0.309558 5.1386 0.398023 5.19777 0.495384 5.23814C0.592745 5.27851 0.697097 5.29928 0.80248 5.29928C0.907863 5.29928 1.01221 5.27851 1.10958 5.23814C1.20694 5.19777 1.2954 5.1386 1.36992 5.06401Z" />
  </svg>
);
