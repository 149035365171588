import PricingKeys from '../translationKeys/PricingPageKeys';

export const PricingTranslations = {
  [PricingKeys.preparing]: '(准备中)',
  [PricingKeys.order_now]: '立即下单',
  [PricingKeys.get_free]: '免费获得高级通行证',
  [PricingKeys.get_for_free]: '免费获取',
  [PricingKeys.current_plan]: '当前计划',
  [PricingKeys.company_verified]: '公司已验证',

  // Banner
  [PricingKeys.Banner.title]: '让您的营销简单智能！',
  [PricingKeys.Banner.description]: '我们的服务可以进一步提高营销业绩！',

  [PricingKeys.Verified.Error.already_verified]: `
  您的帐户已验证为品牌·公司。`,
  [PricingKeys.Verified.Error.already_using]: `
  您的帐户已经使用了这个计划。 <br>
  <span>点击这里</span>查看您的订阅。`,
  [PricingKeys.Verified.Error.under_review]: `
  您的帐户正在审核中。 <br>
  请稍后再试。`,

  // Left Col
  // General
  [PricingKeys.Left_Col.General.verification_req]: '需要验证',
  [PricingKeys.Left_Col.General.smart_search]: '智能搜索',

  // Analytics & Data
  [PricingKeys.Left_Col.Analytics_Data.title]: '分析与数据',

  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title]: `
  创作者基本分析`,
  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip]: `
  包括创作者社交网络账户的渠道和内容分析。`,

  [PricingKeys.Left_Col.Analytics_Data.audience_analytics]: '受众分析',

  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title]: '价格解锁',
  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip]: `
  您可以使用每月的配额解锁创作者的价格。 <br>
  解锁后 72 小时后，价格将重新锁定。`,

  [PricingKeys.Left_Col.Analytics_Data.order_analytics]: '订单分析',

  [PricingKeys.Left_Col.Analytics_Data.creator_reviews]: '创作者评价',

  // Orders
  [PricingKeys.Left_Col.Orders.title]: `订单与创作者`,
  [PricingKeys.Left_Col.Orders.order_packages]: '订购创作者套餐',

  [PricingKeys.Left_Col.Orders.message_creators]:
    '在开始订单之前向新创作者发送消息',

  [PricingKeys.Left_Col.Orders.Order_Discount.title]: '订单折扣',
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1]: `
  折扣适用于每个带有创作者的订单的总价格。 <br>
  * 不适用于附加项目、送货费和月度订阅费。`,
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2]: `
  折扣适用于每个带有创作者的订单的总价格。<br>
  不适用于某些附加项目或平台订阅。`,
  [PricingKeys.Left_Col.Orders.Order_Discount.content]: `
  订单价格的<Tooltip><span>{{number}}</span></Tooltip>`,

  [PricingKeys.Left_Col.Orders.Track_Delivery.title]: `
  追踪国际交付`,
  [PricingKeys.Left_Col.Orders.Track_Delivery.tooltip]: `
  我们帮助跟踪订单的国际送货。<br>
  国内送货跟踪仅适用于有限的国家。<br><br>
  * 注意：我们不负责或保证实际的运输过程。`,

  [PricingKeys.Left_Col.Orders.auto_translation]: `
    消息 & 订单自动翻译`,
  [PricingKeys.Left_Col.Orders.character]: '{{number}}字符',

  [PricingKeys.Summary.basic]: `
    <li>免费但强大</li>
    <li>包含了提升营销绩效的所有基础功能</li>`,
  [PricingKeys.Summary.verified]: `
    <li>仅需 1~3 分钟完成验证</li>
    <li>非常适合全球品牌和企业</li>`,
  [PricingKeys.Summary.premium]: `
    <li>大容量</li>
    <li>需要验证</li>
    <li>非常适合专注于扩展的全球公司</li>`,

  // Creator
  [PricingKeys.Creator.title]: '早期创作者的优越通行证',

  [PricingKeys.Creator.h1]:
    '通过优越通行证，创作者免除所有MegaEvolution服务费用。',
  [PricingKeys.Creator.h2]: '请使用我们的平台充分展示您的才华给世界！',
  [PricingKeys.Creator.h3]: `
    * 我们不包括提取余额所需的少量第三方交易费用(例如，Paypal)。`,

  // Modal
  [PricingKeys.Modal.title]: '品牌·公司验证',
  [PricingKeys.Modal.estimate]: '预计1~2分钟',
  [PricingKeys.Modal.caution]:
    '* 提供虚假或不准确的信息可能导致验证失败或永久封号。',
  [PricingKeys.Modal.toast]: '您的帐户已成功验证！',

  [PricingKeys.Modal.Btn.proceed_final]: '继续到最后一步',
  [PricingKeys.Modal.Btn.verify_email]: '验证电子邮件',
  [PricingKeys.Modal.Btn.no_company_email]: '我没有公司电子邮件',
  [PricingKeys.Modal.Btn.verify_with_email]: '通过电子邮件验证',
  [PricingKeys.Modal.Btn.complete_verification]: '完成验证',
  [PricingKeys.Modal.Btn.another_email]: '使用另一个电子邮件',
  [PricingKeys.Modal.Btn.resend_code]: '重新发送验证码',

  [PricingKeys.Modal.Placeholder.firstname]: '请输入您的名字',
  [PricingKeys.Modal.Placeholder.lastname]: '请输入您的姓氏',
  [PricingKeys.Modal.Placeholder.companyname]: '输入公司名称',
  [PricingKeys.Modal.Placeholder.companyUrl]:
    '输入公司网站 URL（例如：www.megaevolution.io）',
  [PricingKeys.Modal.Placeholder.companyEmail]: '请输入您的公司电子邮件',
  [PricingKeys.Modal.Placeholder.enter_code]: '请输入您收到的代码',

  [PricingKeys.Modal.CompanyType.title]: '选择公司类型',
  [PricingKeys.Modal.CompanyType.option_1]: '品牌 · 商业',
  [PricingKeys.Modal.CompanyType.option_2]: '电子商务店铺',
  [PricingKeys.Modal.CompanyType.option_3]: '营销机构',
  [PricingKeys.Modal.CompanyType.option_4]: '其他',

  [PricingKeys.Modal.JobPosition.title]: '选择职位',
  [PricingKeys.Modal.JobPosition.option_1]: '助理',
  [PricingKeys.Modal.JobPosition.option_2]: 'C级 · VP',
  [PricingKeys.Modal.JobPosition.option_3]: '总监',
  [PricingKeys.Modal.JobPosition.option_4]: '执行官',
  [PricingKeys.Modal.JobPosition.option_5]: '部门主管',
  [PricingKeys.Modal.JobPosition.option_6]: '独立从业者',
  [PricingKeys.Modal.JobPosition.option_7]: '经理 · 团队领导',
  [PricingKeys.Modal.JobPosition.option_8]: '实习生',
  [PricingKeys.Modal.JobPosition.option_9]: '团队成员',

  // Final
  [PricingKeys.Modal.Final.explain]: `
    <li>请上传以下文件中的一个进行验证：营业执照、税务发票、员工工作证或其他证明您公司存在的支持文件。</li>
    <li>文件必须是 JPG、JPEG、PNG、PDF 格式，分辨率至少为 300dpi。</li>`,
  [PricingKeys.Modal.Final.image_placeholder]:
    '上传 JPG、JPEG、PNG、PDF 格式的文件。最大 5 MB。',
  [PricingKeys.Modal.Final.description_placeholder]:
    '请告诉我们关于您的公司以及您试图通过该平台推广的产品、服务或品牌。',
  [PricingKeys.Modal.Final.spam_explain]:
    '* 如果您找不到，请查看您的垃圾邮件文件夹。',

  // Popup
  [PricingKeys.Modal.Popup.title]: '您的表格已成功提交！',
  [PricingKeys.Modal.Popup.description]:
    '通常需要1~3个工作日进行审核。审核完成后，您将收到一封包含结果的电子邮件通知。',

  // Error
  [PricingKeys.Modal.Error.required_field]: '必填字段',
  [PricingKeys.Modal.Error.select_option]: '请选择一个选项',
  [PricingKeys.Modal.Error.valid_url]: '请输入有效的URL。',
  [PricingKeys.Modal.Error.valid_email]: '请输入有效的电子邮件地址',
  [PricingKeys.Modal.Error.email_validation]:
    '不能使用个人电子邮件 (例如，不允许使用gmail.com、yahoo.com等)',
  [PricingKeys.Modal.Error.already_verified]: '此电子邮件已用于验证',
  [PricingKeys.Modal.Error.already_sent_code]:
    '该验证码已发送至此电子邮件。请检查您的收件箱。',
  [PricingKeys.Modal.Error.invalid_code]: '无效的验证码',
  [PricingKeys.Modal.Error.invalid_file_format]:
    '无效的文件格式。（请使用 {{fileFormats}}）',
  [PricingKeys.Modal.Error.total_file_size]: '总文件大小限制为最大 {{maxSize}}',
  [PricingKeys.Modal.Error.send_max_files]:
    '您最多可以发送 {{maxFiles}} 个文件',
};
