import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const MaxLines = ({
  children,
  limit = 1,
  lineHeight,
  className,
  disabled,
  updateOn,
}) => {
  const { t } = useTranslation();
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [isExpandable, toggleExpandable] = useToggle(false);
  const containerRef = useRef(null);

  const setExpandable = useCallback(
    (element) => {
      const clientHeight = element?.clientHeight;
      const scrollHeight = element?.scrollHeight;

      if (clientHeight && scrollHeight && clientHeight < scrollHeight) {
        toggleExpandable(true);
      } else if (!isExpanded) {
        toggleExpandable(false);
      }
    },
    [toggleExpandable, isExpanded]
  );

  useEffect(() => {
    const container = containerRef?.current;

    if (container) {
      setExpandable(container);
    }

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          setExpandable(container);
        }
      });
    });

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setExpandable(entry.target);
      });
    });

    mutationObserver.observe(container, { childList: true });
    resizeObserver.observe(container);

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [containerRef, setExpandable, toggleExpandable, updateOn, limit]);

  return (
    <>
      <div
        ref={containerRef}
        className={
          disabled || isExpanded
            ? ''
            : `ellipsis-${limit - 1}-${lineHeight || '1.6'}`
        }
      >
        {children}
      </div>
      {!disabled && isExpandable && (
        <ExpandButton className={className} onClick={() => toggleExpanded()}>
          {isExpanded ? t(GeneralKeys.view_less) : t(GeneralKeys.view_more)}
        </ExpandButton>
      )}
    </>
  );
};

export default MaxLines;

const ExpandButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
