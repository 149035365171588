export const TrustEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M3.04448 17.1951C2.85771 17.4086 2.7851 17.6828 2.82168 17.9433C2.43725 17.7149 1.93293 17.7888 1.63197 18.1399C1.29501 18.5329 1.34054 19.1248 1.73366 19.4618L2.00785 19.6969C1.6968 19.6526 1.37072 19.7658 1.15482 20.0248C0.823349 20.4226 0.877091 21.0138 1.27485 21.3452L3.13588 22.8961L3.01808 22.8372C2.61537 22.6358 2.12899 22.7148 1.81063 23.0331C1.35357 23.4902 1.41602 24.2479 1.94327 24.6219C3.15515 25.4814 4.77427 26.6204 5.62499 27.1875C7.03124 28.125 8.63548 28.4453 10.3126 27.1875C11.251 26.4837 12.4361 24.3172 11.1893 22.1573C10.9528 21.7476 10.7409 21.3179 10.6532 20.8531C10.2674 18.8095 9.69194 16.7167 8.43757 17.3437C7.75805 17.6835 7.81726 18.2694 7.90132 19.1015C7.93325 19.4176 7.96878 19.7691 7.96878 20.1562L7.89464 20.1934L4.36737 17.1069C3.97772 16.766 3.38543 16.8055 3.04448 17.1951Z"
      fill="#FF822D"
    />
    <path
      d="M26.9565 17.1951C27.1432 17.4086 27.2158 17.6828 27.1792 17.9433C27.5637 17.7149 28.068 17.7888 28.369 18.1399C28.706 18.5329 28.6604 19.1248 28.2673 19.4618L27.9931 19.6969C28.3041 19.6526 28.6302 19.7658 28.8461 20.0248C29.1776 20.4226 29.1239 21.0138 28.7261 21.3452L26.8651 22.8961L26.9828 22.8372C27.3856 22.6358 27.872 22.7148 28.1903 23.0331C28.6474 23.4902 28.5849 24.2479 28.0577 24.6219C26.8458 25.4814 25.2267 26.6204 24.3759 27.1875C22.9697 28.125 21.3654 28.4453 19.6884 27.1875C18.75 26.4837 17.5648 24.3172 18.8117 22.1573C19.0481 21.7476 19.26 21.3179 19.3477 20.8531C19.7336 18.8095 20.3091 16.7167 21.5633 17.3437C22.2429 17.6835 22.1837 18.2694 22.0996 19.1015C22.0677 19.4176 22.0322 19.7691 22.0322 20.1562L22.1063 20.1934L25.6336 17.1069C26.0232 16.766 26.6155 16.8055 26.9565 17.1951Z"
      fill="#FF822D"
    />
    <path
      d="M6.5625 13.5938C6.5625 14.8882 5.72303 15.9375 4.6875 15.9375C3.65197 15.9375 2.8125 14.8882 2.8125 13.5938C2.8125 12.2993 3.65197 11.25 4.6875 11.25C5.72303 11.25 6.5625 12.2993 6.5625 13.5938Z"
      fill="#FF6723"
    />
    <path
      d="M27.1875 13.5938C27.1875 14.8882 26.3481 15.9375 25.3125 15.9375C24.2769 15.9375 23.4375 14.8882 23.4375 13.5938C23.4375 12.2993 24.2769 11.25 25.3125 11.25C26.3481 11.25 27.1875 12.2993 27.1875 13.5938Z"
      fill="#FF6723"
    />
    <path
      d="M8.76488 9.76172C8.56871 9.97547 8.46552 10.2596 8.42044 10.4915C8.32161 10.9997 7.82948 11.3316 7.32123 11.2327C6.81298 11.1339 6.48108 10.6418 6.57991 10.1335C6.663 9.70622 6.87047 9.05285 7.38352 8.49387C7.92463 7.90434 8.73364 7.5 9.84388 7.5C10.9648 7.5 11.7771 7.92392 12.3149 8.51434C12.8251 9.07444 13.0373 9.72563 13.1114 10.1521C13.1999 10.6622 12.8582 11.1476 12.348 11.2362C11.8379 11.3247 11.3526 10.983 11.264 10.4729C11.2319 10.2878 11.1333 10.0015 10.9289 9.777C10.7519 9.58275 10.4456 9.375 9.84388 9.375C9.23177 9.375 8.933 9.57853 8.76488 9.76172Z"
      fill="#402A32"
    />
    <path
      d="M19.0773 9.76172C18.8812 9.97547 18.778 10.2596 18.7329 10.4915C18.6341 10.9997 18.1419 11.3316 17.6337 11.2327C17.1255 11.1339 16.7936 10.6418 16.8924 10.1335C16.9755 9.70622 17.1829 9.05285 17.696 8.49387C18.2371 7.90434 19.0461 7.5 20.1564 7.5C21.2772 7.5 22.0896 7.92392 22.6274 8.51434C23.1377 9.07444 23.3497 9.72563 23.4238 10.1521C23.5124 10.6622 23.1707 11.1476 22.6606 11.2362C22.1504 11.3247 21.665 10.983 21.5764 10.4729C21.5444 10.2878 21.4458 10.0015 21.2413 9.777C21.0644 9.58275 20.758 9.375 20.1564 9.375C19.5442 9.375 19.2454 9.57853 19.0773 9.76172Z"
      fill="#402A32"
    />
    <path
      d="M9.18756 14.4376C8.8769 14.0234 8.28927 13.9394 7.87506 14.2501C7.46084 14.5607 7.3769 15.1484 7.68756 15.5626C8.34568 16.4401 10.5074 18.2813 15.0001 18.2813C19.4927 18.2813 21.6545 16.4401 22.3126 15.5626C22.6232 15.1484 22.5393 14.5607 22.1251 14.2501C21.7108 13.9394 21.1232 14.0234 20.8126 14.4376C20.5332 14.81 18.9449 16.4063 15.0001 16.4063C11.0552 16.4063 9.46697 14.81 9.18756 14.4376Z"
      fill="#402A32"
    />
  </svg>
);
