import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useNavigate } from 'react-router-dom';
import BannerCard from './BannerCard';
import BannerModal from './BannerModal';
import { useTranslation } from 'react-i18next';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import { CrossIconRounded } from 'assets/icons';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const ModalContainer = styled.div`
  position: relative;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  z-index: 1;
`;

const CloseIcon = styled(CrossIconRounded)`
  width: 14px;
  height: 14px;
`;

const RegistrationCompleteBanner1 = ({ isOpen, onClose, closeModal }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { isMobile } = useResponsiveScreen();

  return (
    <BannerModal isOpen={isOpen}>
      <ModalContainer>
        <BannerCard
          onClose={onClose}
          image={
            process.env.PUBLIC_URL + '/img/bannerCards/register_complete1.png'
          }
          description={
            <Trans>{t(LoginKeys.Modal.VIP_Influencer.description)}</Trans>
          }
          buttonText={t(LoginKeys.Modal.VIP_Influencer.im_creator)}
          onButtonClick={() => {
            closeModal();
            navigate('/creator-registration');
          }}
          secondaryButtonText={t(LoginKeys.Modal.VIP_Influencer.no_im_not)}
          primaryButtonIndigoAndOnRight={isMobile}
        />
        <CloseIconContainer onClick={onClose}>
          <CloseIcon />
        </CloseIconContainer>
      </ModalContainer>
    </BannerModal>
  );
};

export default RegistrationCompleteBanner1;
