import LoginKeys from '../translationKeys/LoginPageKeys';

export const LoginTranslations = {
  // Google
  [LoginKeys.Google.sign_in]: 'Googleでサインイン',

  // Facebook
  [LoginKeys.Facebook.sign_in]: 'Facebookでサインイン',
  [LoginKeys.Facebook.sign_up]: 'Facebookでサインアップ',

  // Wallet
  [LoginKeys.Wallet.sign_in]: 'ウォレットでサインイン',
  [LoginKeys.Wallet.sign_up]: 'ウォレットでサインアップ',

  // Password
  [LoginKeys.Password.forgot]: 'パスワードを忘れましたか？',

  [LoginKeys.Password.Rule.title]: 'パスワードは以下を含む必要があります：',
  [LoginKeys.Password.Rule.l1]: '· 8文字以上',
  [LoginKeys.Password.Rule.l2]: '· アルファベット1文字以上',
  [LoginKeys.Password.Rule.l3]: '· 数字1つ以上',
  [LoginKeys.Password.Rule.l4]: '· 特殊文字（！、@、#、$など）1つ以上',

  // Login Form
  [LoginKeys.Login_Form.Placeholder.email]: 'メールアドレスを入力',
  [LoginKeys.Login_Form.Placeholder.password]: 'パスワードを入力',

  [LoginKeys.Login_Form.no_account]: 'アカウントをお持ちでないですか？',
  [LoginKeys.Login_Form.create_account]: 'アカウントを作成',

  [LoginKeys.Login_Form.sign_up]: 'サインアップ',

  // Sign Up Form
  [LoginKeys.Sign_Up_Form.Placeholder.email]: 'メールアドレスでサインアップ',
  [LoginKeys.Sign_Up_Form.Placeholder.password]: 'パスワードを入力してください',

  [LoginKeys.Sign_Up_Form.Terms_of_Service.title]: '利用規約に同意してください',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all]: 'すべての規約に同意する',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description]:
    'これには必須と任意のすべての規約への同意が含まれます。個々の規約に同意するかどうかを選択できます。任意の規約に同意しなくてもサービスを利用できます。',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.age]: '私は14歳以上です。',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions]: '利用規約',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing]:
    'イベント情報やマーケティング情報を受け取る',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing_description]:
    'メールやアプリ経由で、MegaEvolutionから提供される便利なニュースレターやイベント、特典などさまざまな情報を受け取ることができます。',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies]:
    'プライバシーポリシー',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies]: '支払いポリシー',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.optional]: '(任意)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.required]: '(必須)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree]: '同意して参加する',

  [LoginKeys.Sign_Up_Form.go_back]: 'ログインに戻る',

  [LoginKeys.Sign_Up_Form.password_updated]: 'パスワードが更新されました！',
  [LoginKeys.Sign_Up_Form.thank_you_for_signing_up]:
    'サインアップいただき、ありがとうございます！',

  // Error
  [LoginKeys.Error.network]:
    'ネットワークエラー - 後でもう一度お試しください。',
  [LoginKeys.Error.incorrect]: '正しくないメールアドレスまたはパスワード。',
  [LoginKeys.Error.recently_deleted]:
    'このアカウントは最近削除されました。24時間後に再試行してください。',
  [LoginKeys.Error.login_failed]:
    'ログインに失敗しました。後でもう一度お試しください。',
  [LoginKeys.Error.already_in_use]:
    'このメールアドレスはすでに使用されています。',
  [LoginKeys.Error.wallet_not_verified]: 'ウォレットを確認できませんでした。',
  [LoginKeys.Error.already_in_use_or_connect_another_method]:
    'このメールアドレスはすでに使用されているか、他のサインイン方法に接続されています。',
  [LoginKeys.Error.currently_deactiated_reset_password]:
    'このアカウントは現在有効ではありません、パスワードをリセットしてアカウントをアクティブにしてください。',
  [LoginKeys.Error.currently_deactiated_login]:
    'このアカウントは有効なアカウントではありません、ログインを行い、再度アカウントをアクティブにしてください。',
  [LoginKeys.Error.enter_valid_email]:
    '有効なメールアドレスを入力してください。',
  [LoginKeys.Error.invalid_request]: '無効なリクエスト',
  [LoginKeys.Error.too_many_request]:
    'リクエストが多すぎます。後でもう一度お試しください。',
  [LoginKeys.Error.wallet_already_registered]:
    'このウォレットはすでに登録されています。',
  [LoginKeys.Error.try_sign_in_social_app]:
    'ソーシャルアプリでサインインしてください。',
  [LoginKeys.Error.password_used]: 'このパスワードは以前に使用されました。',

  // Modal
  [LoginKeys.Modal.Connect_Wallet.title]: 'ウォレットに接続',
  [LoginKeys.Modal.Connect_Wallet.description]:
    '利用可能なウォレットプロバイダーのうちいずれかを選択するか、まずウォレットを作成してください。',
  [LoginKeys.Modal.Reset_Password.reset]: 'パスワードをリセット',

  [LoginKeys.Modal.Enter_Email.title]: 'メールアドレスを入力してください',
  [LoginKeys.Modal.Enter_Email.description]: `
    アカウントを作成するためにメールアドレスを入力してください。 <br>
    こちらに重要な注文の通知を受け取ります。`,
  [LoginKeys.Modal.Enter_Email.Placeholder]: 'メールアドレスを入力',
  [LoginKeys.Modal.Enter_Email.mobile_placeholder]: 'abcd@gmail.com',

  [LoginKeys.Modal.Reset_Password.description]:
    'パスワードの有効期限が切れています。新しいパスワードを選択してください。',
  [LoginKeys.Modal.Reset_Password.expired]:
    'パスワードの有効期限が切れました！',
  [LoginKeys.Modal.Reset_Password.reset]: 'パスワードをリセットする',
  [LoginKeys.Modal.Reset_Password.update_success]:
    'パスワードが更新されました！',

  [LoginKeys.Modal.Reset_Password.Error.valid_password]:
    '有効なパスワードを入力してください',

  [LoginKeys.Modal.Useful_Reward.title]:
    'サブスクリプションすれば便利な報酬情報を受け取れます！',
  [LoginKeys.Modal.Useful_Reward.description]: `
    いつでも1クリックでサブスクリプションを解除できます！ <br>
    ニュースレターにサブスクライブしなくても重要な <br>
    注文の通知は引き続き受け取ることができます。`,
  [LoginKeys.Modal.Useful_Reward.button]: 'ニュースレターを受け取る',

  [LoginKeys.Modal.Verify_Email.title]: 'メールアドレスを確認してください',
  [LoginKeys.Modal.Verify_Email.description]: `
    メールを確認して{{email}}アカウントを認証してください。`,

  [LoginKeys.Modal.Check_Email.title]: 'メールを確認してください',
  [LoginKeys.Modal.Check_Email.description]: `
    パスワードをリセットするためにメールを確認してください。`,
  [LoginKeys.Modal.Check_Email.button]: 'メールを再送信',

  [LoginKeys.Modal.VIP_Influencer.description]: `
  クリエイターですか？クリエイター <br>
  アカウントに切り替えて、 <br>
  コラボレーションを始めましょう！`,
  [LoginKeys.Modal.VIP_Influencer.im_creator]: 'クリエイターです!',
  [LoginKeys.Modal.VIP_Influencer.no_im_not]: 'スキップ',

  [LoginKeys.Modal.Welcome_On_Board.description]: `
    ようこそ！ <br>
    MEGAが贈り物を用意しました！`,
  [LoginKeys.Modal.Welcome_On_Board.hi_mega]: 'こんにちは、MEGA',

  [LoginKeys.Modal.Use_Another_Method.title]:
    '別のサインアップ方法を使用してください',
  [LoginKeys.Modal.Use_Another_Method.description]:
    'Facebookアカウントに接続されたメールアドレスがないようです。Facebookを使用して当社のプラットフォームにサインアップまたはログインするには、メールアドレスが必要です。アカウントを作成するために別のサインアップ方法を使用してください。',

  [LoginKeys.Modal.Enter_Your_Email.title]: 'メールアドレスを入力してください',
  [LoginKeys.Modal.Enter_Your_Email.description]:
    'パスワードをリセットするためのリンクを受け取るために、メールアドレスを入力してください。',
  [LoginKeys.Modal.Enter_Your_Email.placeholder]:
    'メールアドレスを入力してください',

  [LoginKeys.Modal.Reactivate.title]:
    'このアカウントは現在有効ではありません。',
  [LoginKeys.Modal.Reactivate.description]: 'アカウントを再度有効にしますか？',
};
