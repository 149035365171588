import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: '保存中',
  [ProposalKeys.changes_saved]: '保存成功',

  [ProposalKeys.New_Proposal.text]: '新提案',
  [ProposalKeys.New_Proposal.toast]: '您已达到可以创建的提案的最大数量。',

  [ProposalKeys.Clear.text]: '清除所有',
  [ProposalKeys.Clear.toast]: '已成功清除。单击此处以撤消此更改。',

  [ProposalKeys.Modal.Copy.title]: '您确定要覆盖提案吗？',
  [ProposalKeys.Modal.Copy.description]: `
    当前提案将被所选提案覆盖。此操作无法撤消。您要继续吗？`,

  [ProposalKeys.Basic_Info.Project_Title.title]: '标题',
  [ProposalKeys.Basic_Info.Project_Title.placeholder]: '输入项目标题',
  [ProposalKeys.Basic_Info.Project_Title.error]: '最多100个字符',

  [ProposalKeys.Basic_Info.Website.title]: '产品 · 品牌网站',
  [ProposalKeys.Basic_Info.Website.placeholder]: '输入网址',
  [ProposalKeys.Basic_Info.Website.error]: '最多400个字符',

  [ProposalKeys.Basic_Info.Description.title]: '描述与文件',
  [ProposalKeys.Basic_Info.Description.placeholder]: `
    请在此处描述项目的详细信息并附上您的文件。<br>
    创作者可能会寻找有关以下方面的信息，<br><br><br>
    <ul>
    <li>项目/产品描述</li>
    <li>活动目标和目标受众</li>
    <li>详细的内容指南</li>
    </ul>`,
  [ProposalKeys.Basic_Info.Description.Snippet.title]: '选择要添加的片段',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name]:
    '创作者的社交频道名称',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_username]:
    '创作者的用户名',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_main_country]:
    '创作者的主要国家',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories]:
    '创作者的顶级类别',

  [ProposalKeys.Basic_Info.Description.Error.file_format]:
    '无效的文件格式（请使用 {{fileFormats}} ）',
  [ProposalKeys.Basic_Info.Description.Error.file_size_limit]:
    '总文件大小限制最多 {{maxSize}}',
  [ProposalKeys.Basic_Info.Description.Error.max_file]:
    '您最多可以发送 {{maxFiles}} 个文件',

  [ProposalKeys.caution]: `
    下订单后，所有适用的<strong>要求和内容任务</strong>将自动应用于每个内容。对于已订购的内容不完整或不适用的要求和内容任务将不会应用。`,

  [ProposalKeys.Side_btn.text]: '适用的内容',
  [ProposalKeys.Side_btn.title]: '适用的内容：',

  [ProposalKeys.Product_Delivery.title]: '向创作者发送产品',

  [ProposalKeys.Product_Delivery.Delivery.title]: '发送赞助产品',
  [ProposalKeys.Product_Delivery.Delivery.tooltip]: `
    此选项不完整，将不会应用于发送的提案。`,

  [ProposalKeys.Product_Delivery.Delivery.Image.title]: '交付图像（可选）',
  [ProposalKeys.Product_Delivery.Delivery.Image.description]:
    '以JPG、JPEG、PNG、GIF格式添加最多5个图像文件。最大100 MB。',

  [ProposalKeys.Product_Delivery.Delivery.Info.title]: '交付信息（必填）',
  [ProposalKeys.Product_Delivery.Delivery.Info.placeholder]:
    '请提供有关产品和运送的信息。',

  [ProposalKeys.Product_Delivery.Delivery.caution_due_date]:
    '您将收到创作者的寄送地址以交付产品。产品送达后将开始内容创作，并相应更新截止日期。',
  [ProposalKeys.Product_Delivery.Delivery.caution_return_process]:
    '我们不处理已交付产品的退货。如果产品在发货后取消订单，则您需要直接与创作者讨论退货事宜。',
  [ProposalKeys.Product_Delivery.Delivery.caution_international_delivery]: `
    我们协助跟踪国际交付，但不保证或承担诸如错误交付等运输问题。
    <Tooltip>国内交付</Tooltip>仅适用于少数国家，但您仍然可以包含在订单中。`,
  [ProposalKeys.Product_Delivery.Delivery
    .caution_international_delivery_tooltip]: `
    我们支持主要运营商的国内跟踪<br>
    仅适用于有限的国家，包括美国和加拿大。<br>
    目前我们不支持其他国家的国内跟踪。<br>`,

  [ProposalKeys.Requirements.title]: '要求',
  [ProposalKeys.Requirements.applied]: '{{number}} 个已应用',

  [ProposalKeys.Requirements.Promotion.title]: '指明付费推广',
  [ProposalKeys.Requirements.Promotion.description_1]:
    '创作者将在哈希标签列表的开头加入以下其中之一的哈希标签：#Ad、#Advertisement或#Sponsored，以明确指示付费推广。',
  [ProposalKeys.Requirements.Promotion.description_2]:
    '在视频内添加由YouTube提供的“包含付费推广”标签。',
  [ProposalKeys.Requirements.Promotion.description_3]:
    '在Instagram帖子/故事/Reels内添加由Instagram提供的“付费合作”标签。',

  [ProposalKeys.Requirements.Terms_Conditions.placeholder]:
    '请在此处输入您的条款和条件。',
  [ProposalKeys.Requirements.Terms_Conditions.description]:
    '创作者将需要遵守您的服务条款和条件。如果它与创作者的服务相矛盾，可以请求修改或停用此选项。',

  [ProposalKeys.Checklist.title]: '检查清单',

  [ProposalKeys.Content_Missions.title]: '内容任务',

  [ProposalKeys.Content_Missions.Include_Link.title]: '包含链接',
  [ProposalKeys.Content_Missions.Include_Link.placeholder]: '输入URL',
  [ProposalKeys.Content_Missions.Include_Link.description]:
    '提供的链接将包括在帖子或视频说明中。可用于将更多流量引导到网站或应用程序。',

  [ProposalKeys.Content_Missions.Include_Hashtags.title]: '包含哈希标签',
  [ProposalKeys.Content_Missions.Include_Hashtags.placeholder]: '输入哈希标签',
  [ProposalKeys.Content_Missions.Include_Hashtags.btn]: '添加此哈希标签',
  [ProposalKeys.Content_Missions.Include_Hashtags.description]:
    '左侧的至少一个或多个哈希标签将包括在帖子或视频说明中。',

  [ProposalKeys.Content_Missions.Tag_Location.title]: '标记位置',
  [ProposalKeys.Content_Missions.Tag_Location.placeholder]: '输入位置',
  [ProposalKeys.Content_Missions.Tag_Location.description]:
    '提供的位置将被标记在帖子或视频中。对于推广特定位置或场馆非常有效。',

  [ProposalKeys.Content_Missions.Tag_Account.title]: '标记我们的帐户',
  [ProposalKeys.Content_Missions.Tag_Account.placeholder]: '您的账号',
  [ProposalKeys.Content_Missions.Tag_Account.description]:
    '账户 @handle 将包括在帖子或视频说明中。（仅适用于与已订购内容的平台相对应的 handle。）',

  [ProposalKeys.Content_Missions.Pin_Comment_Account.title]:
    '使用我们的帐户置顶评论',
  [ProposalKeys.Content_Missions.Pin_Comment_Account.description]:
    '将包含提供的帐户 @handle 的评论发布并置顶在内容上。（仅适用于与已订购内容的平台相对应的 handle。）',

  [ProposalKeys.Content_Missions.Pin_Comment_Link.title]: '使用链接置顶评论',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.placeholder]: '输入URL',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.description]:
    '将包含提供的链接的评论发布并置顶在内容上。',

  [ProposalKeys.Content_Missions.Include_Img.title]: '包含特定图片',
  [ProposalKeys.Content_Missions.Include_Img.description]:
    '创作者将被引导至在内容中包含至少一个或多个提供的图片。',

  [ProposalKeys.Content_Missions.Tag_Video.title]: '为视频添加标签',
  [ProposalKeys.Content_Missions.Tag_Video.placeholder]: '输入视频标签',
  [ProposalKeys.Content_Missions.Tag_Video.btn]: '添加此标签',
  [ProposalKeys.Content_Missions.Tag_Video.description]:
    '左侧的至少一个或多个标签将被添加到YouTube视频以进行搜索优化。',

  // View
  [ProposalKeys.View.copied_to_clipboard]: '',
  [ProposalKeys.View.Top.visit_website]: '',
  [ProposalKeys.View.ProductDelivery.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.addressRequired]: '',
  [ProposalKeys.View.ProductDelivery.Physical.waitForProduct]: '',
  [ProposalKeys.View.Requirement.Tooltip.creator]: '',
  [ProposalKeys.View.Requirement.Tooltip.client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_creator]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.sponsored]: ``,
  [ProposalKeys.View.Requirement.Ad.Checklist.useFollowingMethod]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.applicableContents]: ``,
  [ProposalKeys.View.Requirement.Ad.All.description]: ``,
  [ProposalKeys.View.Requirement.Ad.YouTube.description]: ``,
  [ProposalKeys.View.Requirement.Ad.Instagram.description]: ``,
  [ProposalKeys.View.Requirement.Ad.TikTok.description]: ``,
  [ProposalKeys.View.Requirement.Terms_Conditions.title]: '',
  [ProposalKeys.View.Requirement.Terms_Conditions.Checklist
    .complyTermsConditions]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.creator]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.client]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Link.description]:
    '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Img.download]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.description]: '',
};
