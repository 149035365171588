import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: 'đang lưu',
  [ProposalKeys.changes_saved]: 'đã lưu thay đổi',

  [ProposalKeys.New_Proposal.text]: 'Đề xuất mới',
  [ProposalKeys.New_Proposal.toast]:
    'Bạn đã đạt đến số lượng đề xuất tối đa mà bạn có thể tạo.',

  [ProposalKeys.Clear.text]: 'Xóa tất cả',
  [ProposalKeys.Clear.toast]: `
    Xóa thành công. <button>Click vào đây</button> để hoàn tác thay đổi này.`,

  [ProposalKeys.Modal.Copy.title]:
    'Bạn có chắc chắn muốn ghi đè đề xuất không?',
  [ProposalKeys.Modal.Copy.description]: `
    Đề xuất hiện tại sẽ bị ghi đè bằng đề xuất đã chọn. <br> 
    Hành động này không thể hoàn tác. Bạn có muốn tiếp tục không?`,

  [ProposalKeys.Basic_Info.Project_Title.title]: 'Tiêu đề',
  [ProposalKeys.Basic_Info.Project_Title.placeholder]: 'Nhập tiêu đề dự án',
  [ProposalKeys.Basic_Info.Project_Title.error]: 'Tối đa 100 ký tự',

  [ProposalKeys.Basic_Info.Website.title]: 'Sản phẩm · Trang web thương hiệu',
  [ProposalKeys.Basic_Info.Website.placeholder]: 'Nhập URL',
  [ProposalKeys.Basic_Info.Website.error]: 'Tối đa 400 ký tự',

  [ProposalKeys.Basic_Info.Description.title]: 'Mô tả và Tệp',
  [ProposalKeys.Basic_Info.Description.placeholder]: `
    Vui lòng mô tả chi tiết dự án và đính kèm các tệp của bạn ở đây. <br>
    Người tạo có thể tìm thông tin về, <br><br><br>
    <ul>
    <li>Mô tả dự án/sản phẩm</li>
    <li>Mục tiêu chiến dịch và đối tượng mục tiêu</li>
    <li>Hướng dẫn nội dung chi tiết</li>
    </ul>`,
  [ProposalKeys.Basic_Info.Description.Snippet.title]: 'Chọn một đoạn để thêm',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name]:
    'Tên kênh mạng xã hội của Người tạo',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_username]:
    'Tên người dùng của Người tạo',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_main_country]:
    'Quốc gia chính của Người tạo',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories]:
    'Các danh mục hàng đầu của Người tạo',

  [ProposalKeys.Basic_Info.Description.Error.file_format]: `
  Định dạng tệp không hợp lệ. (Vui lòng sử dụng {{fileFormats}})`,
  [ProposalKeys.Basic_Info.Description.Error.file_size_limit]: `
  Giới hạn tổng kích thước tệp là tối đa {{maxSize}}`,
  [ProposalKeys.Basic_Info.Description.Error.max_file]: `
  Bạn chỉ có thể gửi tối đa {{maxFiles}} tệp`,

  [ProposalKeys.caution]: `
    Khi đặt mua một gói, tất cả các <strong>yêu cầu và nhiệm vụ nội dung</strong> liên quan sẽ được <strong>tự động áp dụng</strong> cho mỗi nội dung. Những yêu cầu không hoàn chỉnh hoặc không thích hợp đối với nội dung đã đặt hàng sẽ không được áp dụng.`,

  [ProposalKeys.Side_btn.text]: 'Nội dung có liên quan',
  [ProposalKeys.Side_btn.title]: 'Nội dung có liên quan : ',

  [ProposalKeys.Product_Delivery.title]: 'Gửi sản phẩm đến Người tạo',

  [ProposalKeys.Product_Delivery.Delivery.title]:
    'Gửi sản phẩm đã được tài trợ',
  [ProposalKeys.Product_Delivery.Delivery.tooltip]: `
    Tùy chọn này chưa hoàn chỉnh và sẽ không được áp dụng cho các đề xuất đang được gửi.`,

  [ProposalKeys.Product_Delivery.Delivery.Image.title]:
    'Hình ảnh giao hàng (Tùy chọn)',
  [ProposalKeys.Product_Delivery.Delivery.Image.description]:
    'Thêm tối đa 5 tệp hình ảnh trong định dạng JPG, JPEG, PNG, GIF. Tối đa 100 MB.',

  [ProposalKeys.Product_Delivery.Delivery.Info.title]:
    'Thông tin giao hàng (Bắt buộc)',
  [ProposalKeys.Product_Delivery.Delivery.Info.placeholder]:
    'Vui lòng cung cấp thông tin về sản phẩm và vận chuyển.',

  [ProposalKeys.Product_Delivery.Delivery.caution_due_date]:
    'Bạn sẽ nhận được địa chỉ gửi sản phẩm của người tạo cho việc giao hàng sản phẩm. Việc tạo nội dung sẽ bắt đầu khi sản phẩm đến, và thời hạn giao hàng sẽ được cập nhật tương ứng',
  [ProposalKeys.Product_Delivery.Delivery.caution_return_process]:
    'Chúng tôi không xử lý việc trả lại sản phẩm đã giao. Nếu đơn hàng của bạn bị hủy sau khi sản phẩm đã được gửi, bạn sẽ cần thảo luận trực tiếp với người tạo.',
  [ProposalKeys.Product_Delivery.Delivery.caution_international_delivery]: `
    Chúng tôi hỗ trợ theo dõi giao hàng quốc tế, nhưng không đảm bảo hoặc bao phủ các vấn đề vận chuyển như giao hàng sai địa chỉ.
    <Tooltip>Giao hàng nội địa</Tooltip> có thể theo dõi được chỉ ở một số quốc gia nhất định nhưng bạn vẫn có thể bao gồm nó trong đơn hàng của bạn.`,
  [ProposalKeys.Product_Delivery.Delivery
    .caution_international_delivery_tooltip]: `
    Chúng tôi hỗ trợ theo dõi giao hàng nội địa cho các nhà cung cấp chính trong <br>
    một số quốc gia hạn chế, bao gồm Hoa Kỳ và Canada. <br>
    Hiện tại, chúng tôi không hỗ trợ theo dõi giao hàng nội địa cho <br>
    các quốc gia khác.`,

  [ProposalKeys.Requirements.title]: 'Yêu cầu',
  [ProposalKeys.Requirements.applied]: '{{number}} được áp dụng',

  [ProposalKeys.Requirements.Promotion.title]: 'Chỉ định quảng cáo trả tiền',
  [ProposalKeys.Requirements.Promotion.description_1]:
    'Một trong các hashtag sau: #Quảng cáo, #Quảng cáo hoặc #Được tài trợ sẽ được đặt ở đầu danh sách hashtag bởi người tạo để chỉ rõ việc quảng cáo trả tiền.',
  [ProposalKeys.Requirements.Promotion.description_2]:
    'Thêm nhãn "Bao gồm quảng cáo trả tiền" được cung cấp bởi YouTube trong video.',
  [ProposalKeys.Requirements.Promotion.description_3]:
    'Thêm nhãn "Hợp tác trả tiền" được cung cấp bởi Instagram vào bài viết/Story/Reels trên Instagram.',

  [ProposalKeys.Requirements.Terms_Conditions.placeholder]:
    'Vui lòng nhập các điều khoản và điều kiện của bạn ở đây.',
  [ProposalKeys.Requirements.Terms_Conditions.description]:
    'Người tạo sẽ phải tuân theo Điều khoản và Điều kiện của bạn cho việc cung cấp dịch vụ. Có thể yêu cầu xem xét hoặc vô hiệu hóa tùy chọn này nếu nó xung đột với dịch vụ của người tạo.',

  [ProposalKeys.Checklist.title]: 'Danh sách kiểm tra',

  [ProposalKeys.Content_Missions.title]: 'Nhiệm vụ nội dung',

  [ProposalKeys.Content_Missions.Include_Link.title]: 'Bao gồm một liên kết',
  [ProposalKeys.Content_Missions.Include_Link.placeholder]: 'Nhập URL',
  [ProposalKeys.Content_Missions.Include_Link.description]:
    'Liên kết được cung cấp sẽ được bao gồm trong mô tả bài viết hoặc video. Sử dụng điều này để tăng lưu lượng truy cập đến trang web hoặc ứng dụng.',

  [ProposalKeys.Content_Missions.Include_Hashtags.title]: 'Bao gồm Hashtag',
  [ProposalKeys.Content_Missions.Include_Hashtags.placeholder]: 'Nhập Hashtag',
  [ProposalKeys.Content_Missions.Include_Hashtags.btn]: 'Thêm Hashtag này',
  [ProposalKeys.Content_Missions.Include_Hashtags.description]:
    'Ít nhất một hoặc nhiều hashtag từ bên trái sẽ được bao gồm trong bài viết hoặc mô tả video.',

  [ProposalKeys.Content_Missions.Tag_Location.title]: 'Đánh dấu địa điểm',
  [ProposalKeys.Content_Missions.Tag_Location.placeholder]: 'Nhập địa điểm',
  [ProposalKeys.Content_Missions.Tag_Location.description]:
    'Địa điểm được cung cấp sẽ được đánh dấu trong bài viết hoặc video. Hiệu quả để quảng cáo địa điểm hoặc địa điểm cụ thể.',

  [ProposalKeys.Content_Missions.Tag_Account.title]:
    'Đánh dấu tài khoản của chúng tôi',
  [ProposalKeys.Content_Missions.Tag_Account.placeholder]:
    'Tên tài khoản của bạn',
  [ProposalKeys.Content_Missions.Tag_Account.description]:
    'Tài khoản @handle sẽ được bao gồm trong mô tả bài viết hoặc video. (Chỉ có tài khoản tương ứng với nền tảng của nội dung đã đặt hàng sẽ được áp dụng.)',

  [ProposalKeys.Content_Missions.Pin_Comment_Account.title]:
    'Ghim một bình luận với tài khoản của chúng tôi',
  [ProposalKeys.Content_Missions.Pin_Comment_Account.description]:
    'Một bình luận bao gồm tài khoản @handle được cung cấp sẽ được đăng và ghim trên nội dung. (Chỉ có tài khoản tương ứng với nền tảng của nội dung đã đặt hàng sẽ được áp dụng.)',

  [ProposalKeys.Content_Missions.Pin_Comment_Link.title]:
    'Ghim một bình luận với liên kết',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.placeholder]: 'Nhập URL',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.description]:
    'Một bình luận bao gồm liên kết được cung cấp sẽ được đăng và ghim trên nội dung.',

  [ProposalKeys.Content_Missions.Include_Img.title]: 'Bao gồm hình ảnh cụ thể',
  [ProposalKeys.Content_Missions.Include_Img.description]:
    'Người tạo sẽ được hướng dẫn để bao gồm ít nhất một hoặc nhiều hình ảnh được cung cấp trong nội dung.',

  [ProposalKeys.Content_Missions.Tag_Video.title]: 'Thêm các thẻ cho video',
  [ProposalKeys.Content_Missions.Tag_Video.placeholder]: 'Nhập thẻ video',
  [ProposalKeys.Content_Missions.Tag_Video.btn]: 'Thêm Thẻ này',
  [ProposalKeys.Content_Missions.Tag_Video.description]:
    'Ít nhất một hoặc nhiều thẻ từ bên trái sẽ được thêm vào video YouTube để tối ưu hóa việc tìm kiếm.',

  // View
  [ProposalKeys.View.copied_to_clipboard]: '',
  [ProposalKeys.View.Top.visit_website]: '',
  [ProposalKeys.View.ProductDelivery.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.addressRequired]: '',
  [ProposalKeys.View.ProductDelivery.Physical.waitForProduct]: '',
  [ProposalKeys.View.Requirement.Tooltip.creator]: '',
  [ProposalKeys.View.Requirement.Tooltip.client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_creator]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.sponsored]: ``,
  [ProposalKeys.View.Requirement.Ad.Checklist.useFollowingMethod]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.applicableContents]: ``,
  [ProposalKeys.View.Requirement.Ad.All.description]: ``,
  [ProposalKeys.View.Requirement.Ad.YouTube.description]: ``,
  [ProposalKeys.View.Requirement.Ad.Instagram.description]: ``,
  [ProposalKeys.View.Requirement.Ad.TikTok.description]: ``,
  [ProposalKeys.View.Requirement.Terms_Conditions.title]: '',
  [ProposalKeys.View.Requirement.Terms_Conditions.Checklist
    .complyTermsConditions]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.creator]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.client]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Link.description]:
    '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Img.download]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.description]: '',
};
