export const CongratsIcon = ({ size = 1 }) => {
  return (
    <svg
      width={47 * size}
      height={50 * size}
      viewBox="0 0 47 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_64837_72493)">
        <g filter="url(#filter1_i_64837_72493)">
          <path
            d="M27.8647 36.2314C28.4961 35.9561 28.7587 35.5531 28.9484 35.316C30.6523 33.1877 27.0169 26.8706 21.8265 23.2154C16.3255 18.4253 10.2685 16.7932 8.56455 18.9214C8.2721 19.2867 8.04291 19.7842 7.94746 20.3458L4.71307 38.9547C4.60077 41.2232 5.46783 42.0853 5.90172 42.5065C6.68799 43.2695 8.21699 43.9185 9.81171 43.357C15.9659 40.9873 21.7315 38.9051 27.8647 36.2314Z"
            fill="url(#paint0_linear_64837_72493)"
          />
        </g>
        <path
          d="M27.8647 36.2314C28.4961 35.9561 28.7587 35.5531 28.9484 35.316C30.6523 33.1877 27.0169 26.8706 21.8265 23.2154C16.3255 18.4253 10.2685 16.7932 8.56455 18.9214C8.2721 19.2867 8.04291 19.7842 7.94746 20.3458L4.71307 38.9547C4.60077 41.2232 5.46783 42.0853 5.90172 42.5065C6.68799 43.2695 8.21699 43.9185 9.81171 43.357C15.9659 40.9873 21.7315 38.9051 27.8647 36.2314Z"
          fill="url(#paint1_linear_64837_72493)"
        />
        <path
          d="M27.8647 36.2314C28.4961 35.9561 28.7587 35.5531 28.9484 35.316C30.6523 33.1877 27.0169 26.8706 21.8265 23.2154C16.3255 18.4253 10.2685 16.7932 8.56455 18.9214C8.2721 19.2867 8.04291 19.7842 7.94746 20.3458L4.71307 38.9547C4.60077 41.2232 5.46783 42.0853 5.90172 42.5065C6.68799 43.2695 8.21699 43.9185 9.81171 43.357C15.9659 40.9873 21.7315 38.9051 27.8647 36.2314Z"
          fill="url(#paint2_linear_64837_72493)"
        />
        <path
          d="M27.8647 36.2314C28.4961 35.9561 28.7587 35.5531 28.9484 35.316C30.6523 33.1877 27.0169 26.8706 21.8265 23.2154C16.3255 18.4253 10.2685 16.7932 8.56455 18.9214C8.2721 19.2867 8.04291 19.7842 7.94746 20.3458L4.71307 38.9547C4.60077 41.2232 5.46783 42.0853 5.90172 42.5065C6.68799 43.2695 8.21699 43.9185 9.81171 43.357C15.9659 40.9873 21.7315 38.9051 27.8647 36.2314Z"
          fill="url(#paint3_linear_64837_72493)"
        />
        <g filter="url(#filter2_f_64837_72493)">
          <path
            d="M15.4921 31.0996C20.847 35.5526 26.6136 37.4481 28.372 35.3335C30.1305 33.2188 27.215 27.8947 21.86 23.4417C16.5051 18.9887 10.7385 17.0932 8.98007 19.2078C7.2216 21.3225 10.1371 26.6466 15.4921 31.0996Z"
            fill="url(#paint4_linear_64837_72493)"
          />
        </g>
        <g filter="url(#filter3_f_64837_72493)">
          <path
            d="M16.3693 29.8664C21.3322 33.9933 26.5552 35.8963 28.035 34.1166C29.5149 32.337 26.6913 27.5488 21.7284 23.4218C16.7654 19.2948 11.5425 17.3919 10.0627 19.1715C8.58279 20.9511 11.4064 25.7394 16.3693 29.8664Z"
            fill="url(#paint5_linear_64837_72493)"
          />
          <path
            d="M16.3693 29.8664C21.3322 33.9933 26.5552 35.8963 28.035 34.1166C29.5149 32.337 26.6913 27.5488 21.7284 23.4218C16.7654 19.2948 11.5425 17.3919 10.0627 19.1715C8.58279 20.9511 11.4064 25.7394 16.3693 29.8664Z"
            fill="url(#paint6_radial_64837_72493)"
          />
          <path
            d="M16.3693 29.8664C21.3322 33.9933 26.5552 35.8963 28.035 34.1166C29.5149 32.337 26.6913 27.5488 21.7284 23.4218C16.7654 19.2948 11.5425 17.3919 10.0627 19.1715C8.58279 20.9511 11.4064 25.7394 16.3693 29.8664Z"
            fill="url(#paint7_radial_64837_72493)"
          />
        </g>
        <path
          d="M22.5029 25.2285C23.4581 25.2285 24.2324 24.4542 24.2324 23.499C24.2324 22.5438 23.4581 21.7695 22.5029 21.7695C21.5478 21.7695 20.7734 22.5438 20.7734 23.499C20.7734 24.4542 21.5478 25.2285 22.5029 25.2285Z"
          fill="url(#paint8_radial_64837_72493)"
        />
        <path
          d="M22.5029 25.2285C23.4581 25.2285 24.2324 24.4542 24.2324 23.499C24.2324 22.5438 23.4581 21.7695 22.5029 21.7695C21.5478 21.7695 20.7734 22.5438 20.7734 23.499C20.7734 24.4542 21.5478 25.2285 22.5029 25.2285Z"
          fill="url(#paint9_radial_64837_72493)"
        />
        <path
          d="M36.6982 35.1582C37.6534 35.1582 38.4277 34.3839 38.4277 33.4287C38.4277 32.4735 37.6534 31.6992 36.6982 31.6992C35.7431 31.6992 34.9688 32.4735 34.9688 33.4287C34.9688 34.3839 35.7431 35.1582 36.6982 35.1582Z"
          fill="url(#paint10_radial_64837_72493)"
        />
        <path
          d="M36.6982 35.1582C37.6534 35.1582 38.4277 34.3839 38.4277 33.4287C38.4277 32.4735 37.6534 31.6992 36.6982 31.6992C35.7431 31.6992 34.9688 32.4735 34.9688 33.4287C34.9688 34.3839 35.7431 35.1582 36.6982 35.1582Z"
          fill="url(#paint11_radial_64837_72493)"
        />
        <path
          d="M36.6982 35.1582C37.6534 35.1582 38.4277 34.3839 38.4277 33.4287C38.4277 32.4735 37.6534 31.6992 36.6982 31.6992C35.7431 31.6992 34.9688 32.4735 34.9688 33.4287C34.9688 34.3839 35.7431 35.1582 36.6982 35.1582Z"
          fill="url(#paint12_radial_64837_72493)"
        />
        <path
          d="M35.2451 12.3144C36.2003 12.3144 36.9746 11.5401 36.9746 10.585C36.9746 9.62979 36.2003 8.85547 35.2451 8.85547C34.2899 8.85547 33.5156 9.62979 33.5156 10.585C33.5156 11.5401 34.2899 12.3144 35.2451 12.3144Z"
          fill="url(#paint13_radial_64837_72493)"
        />
        <path
          d="M35.2451 12.3144C36.2003 12.3144 36.9746 11.5401 36.9746 10.585C36.9746 9.62979 36.2003 8.85547 35.2451 8.85547C34.2899 8.85547 33.5156 9.62979 33.5156 10.585C33.5156 11.5401 34.2899 12.3144 35.2451 12.3144Z"
          fill="url(#paint14_radial_64837_72493)"
        />
        <path
          d="M15.3623 7.06835C16.3175 7.06835 17.0918 6.29403 17.0918 5.33886C17.0918 4.38369 16.3175 3.60938 15.3623 3.60938C14.4071 3.60938 13.6328 4.38369 13.6328 5.33886C13.6328 6.29403 14.4071 7.06835 15.3623 7.06835Z"
          fill="url(#paint15_radial_64837_72493)"
        />
        <path
          d="M15.3623 7.06835C16.3175 7.06835 17.0918 6.29403 17.0918 5.33886C17.0918 4.38369 16.3175 3.60938 15.3623 3.60938C14.4071 3.60938 13.6328 4.38369 13.6328 5.33886C13.6328 6.29403 14.4071 7.06835 15.3623 7.06835Z"
          fill="url(#paint16_radial_64837_72493)"
        />
        <path
          d="M15.3623 7.06835C16.3175 7.06835 17.0918 6.29403 17.0918 5.33886C17.0918 4.38369 16.3175 3.60938 15.3623 3.60938C14.4071 3.60938 13.6328 4.38369 13.6328 5.33886C13.6328 6.29403 14.4071 7.06835 15.3623 7.06835Z"
          fill="url(#paint17_radial_64837_72493)"
        />
        <path
          d="M8.08105 13.8887C9.03622 13.8887 9.81053 13.1143 9.81053 12.1592C9.81053 11.204 9.03622 10.4297 8.08105 10.4297C7.12588 10.4297 6.35156 11.204 6.35156 12.1592C6.35156 13.1143 7.12588 13.8887 8.08105 13.8887Z"
          fill="url(#paint18_radial_64837_72493)"
        />
        <path
          d="M8.08105 13.8887C9.03622 13.8887 9.81053 13.1143 9.81053 12.1592C9.81053 11.204 9.03622 10.4297 8.08105 10.4297C7.12588 10.4297 6.35156 11.204 6.35156 12.1592C6.35156 13.1143 7.12588 13.8887 8.08105 13.8887Z"
          fill="url(#paint19_radial_64837_72493)"
        />
        <path
          d="M23.928 37.888C20.1818 37.6277 17.5544 36.5066 13.2976 32.9894C9.63565 29.9641 7.8176 27.28 7.21081 24.543L6.50781 28.5877C6.79513 30.1706 8.03047 32.3831 10.4787 34.5954C13.6807 37.4891 16.8688 39.5368 20.0217 39.4471C21.3192 38.9404 22.6187 38.4238 23.928 37.888Z"
          fill="url(#paint20_radial_64837_72493)"
        />
        <path
          d="M16.0291 40.9823C14.2287 41.0827 11.7938 39.6138 9.26698 37.5138C7.11073 35.6495 6.04713 33.6242 5.82327 32.5312L5.10938 36.6386C5.28596 37.3329 5.82374 38.2768 7.38027 39.8721C9.12078 41.6562 10.8711 42.3504 11.9105 42.5479C12.648 42.2667 13.3811 41.9884 14.1111 41.7113C14.7525 41.4678 15.3915 41.2251 16.0291 40.9823Z"
          fill="url(#paint21_radial_64837_72493)"
        />
        <g filter="url(#filter4_i_64837_72493)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.1106 19.344C35.9387 18.3336 32.8826 20.703 31.9139 21.7656C31.3523 22.3817 30.3976 22.4259 29.7816 21.8642C29.1655 21.3026 29.1214 20.348 29.683 19.732C31.1849 18.0843 35.249 15.1231 41.6895 16.3812C42.5075 16.541 43.0413 17.3338 42.8814 18.152C42.7216 18.9701 41.9288 19.5039 41.1106 19.344Z"
            fill="url(#paint22_radial_64837_72493)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.1106 19.344C35.9387 18.3336 32.8826 20.703 31.9139 21.7656C31.3523 22.3817 30.3976 22.4259 29.7816 21.8642C29.1655 21.3026 29.1214 20.348 29.683 19.732C31.1849 18.0843 35.249 15.1231 41.6895 16.3812C42.5075 16.541 43.0413 17.3338 42.8814 18.152C42.7216 18.9701 41.9288 19.5039 41.1106 19.344Z"
            fill="url(#paint23_radial_64837_72493)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4621 3.4937C29.2155 3.52894 29.7977 4.16824 29.7625 4.92163C29.714 5.9589 29.5252 7.68688 29.0746 9.53264C28.698 11.0754 28.1181 12.7866 27.2253 14.2537C27.4412 15.3982 27.447 16.7488 27.2099 18.2844C27.0949 19.0298 26.3974 19.5408 25.652 19.4258C24.9066 19.3107 24.3956 18.6132 24.5106 17.8679C24.5636 17.5242 24.6013 17.2024 24.6254 16.9013C24.3604 17.0396 24.089 17.148 23.8118 17.2253C22.7292 17.5277 21.6909 17.3182 20.8787 16.7771C19.3356 15.7493 18.6242 13.4998 19.8186 11.6609C20.9548 9.9115 23.3228 9.43586 25.1235 10.6805C25.3311 10.824 25.526 10.9857 25.7077 11.1652C25.9951 10.4386 26.2313 9.66335 26.4213 8.88489C26.8255 7.22921 26.993 5.6772 27.0343 4.79407C27.0695 4.04067 27.7087 3.45849 28.4621 3.4937ZM24.2257 13.7582C24.0184 13.3264 23.7721 13.0666 23.5705 12.9273C23.0071 12.5379 22.388 12.7192 22.1091 13.1486C21.8101 13.6089 21.9682 14.2211 22.3929 14.504C22.5646 14.6184 22.7847 14.6764 23.0768 14.5948C23.3412 14.521 23.7457 14.3065 24.2257 13.7582Z"
          fill="url(#paint24_linear_64837_72493)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4621 3.4937C29.2155 3.52894 29.7977 4.16824 29.7625 4.92163C29.714 5.9589 29.5252 7.68688 29.0746 9.53264C28.698 11.0754 28.1181 12.7866 27.2253 14.2537C27.4412 15.3982 27.447 16.7488 27.2099 18.2844C27.0949 19.0298 26.3974 19.5408 25.652 19.4258C24.9066 19.3107 24.3956 18.6132 24.5106 17.8679C24.5636 17.5242 24.6013 17.2024 24.6254 16.9013C24.3604 17.0396 24.089 17.148 23.8118 17.2253C22.7292 17.5277 21.6909 17.3182 20.8787 16.7771C19.3356 15.7493 18.6242 13.4998 19.8186 11.6609C20.9548 9.9115 23.3228 9.43586 25.1235 10.6805C25.3311 10.824 25.526 10.9857 25.7077 11.1652C25.9951 10.4386 26.2313 9.66335 26.4213 8.88489C26.8255 7.22921 26.993 5.6772 27.0343 4.79407C27.0695 4.04067 27.7087 3.45849 28.4621 3.4937ZM24.2257 13.7582C24.0184 13.3264 23.7721 13.0666 23.5705 12.9273C23.0071 12.5379 22.388 12.7192 22.1091 13.1486C21.8101 13.6089 21.9682 14.2211 22.3929 14.504C22.5646 14.6184 22.7847 14.6764 23.0768 14.5948C23.3412 14.521 23.7457 14.3065 24.2257 13.7582Z"
          fill="url(#paint25_radial_64837_72493)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4621 3.4937C29.2155 3.52894 29.7977 4.16824 29.7625 4.92163C29.714 5.9589 29.5252 7.68688 29.0746 9.53264C28.698 11.0754 28.1181 12.7866 27.2253 14.2537C27.4412 15.3982 27.447 16.7488 27.2099 18.2844C27.0949 19.0298 26.3974 19.5408 25.652 19.4258C24.9066 19.3107 24.3956 18.6132 24.5106 17.8679C24.5636 17.5242 24.6013 17.2024 24.6254 16.9013C24.3604 17.0396 24.089 17.148 23.8118 17.2253C22.7292 17.5277 21.6909 17.3182 20.8787 16.7771C19.3356 15.7493 18.6242 13.4998 19.8186 11.6609C20.9548 9.9115 23.3228 9.43586 25.1235 10.6805C25.3311 10.824 25.526 10.9857 25.7077 11.1652C25.9951 10.4386 26.2313 9.66335 26.4213 8.88489C26.8255 7.22921 26.993 5.6772 27.0343 4.79407C27.0695 4.04067 27.7087 3.45849 28.4621 3.4937ZM24.2257 13.7582C24.0184 13.3264 23.7721 13.0666 23.5705 12.9273C23.0071 12.5379 22.388 12.7192 22.1091 13.1486C21.8101 13.6089 21.9682 14.2211 22.3929 14.504C22.5646 14.6184 22.7847 14.6764 23.0768 14.5948C23.3412 14.521 23.7457 14.3065 24.2257 13.7582Z"
          fill="url(#paint26_radial_64837_72493)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4621 3.4937C29.2155 3.52894 29.7977 4.16824 29.7625 4.92163C29.714 5.9589 29.5252 7.68688 29.0746 9.53264C28.698 11.0754 28.1181 12.7866 27.2253 14.2537C27.4412 15.3982 27.447 16.7488 27.2099 18.2844C27.0949 19.0298 26.3974 19.5408 25.652 19.4258C24.9066 19.3107 24.3956 18.6132 24.5106 17.8679C24.5636 17.5242 24.6013 17.2024 24.6254 16.9013C24.3604 17.0396 24.089 17.148 23.8118 17.2253C22.7292 17.5277 21.6909 17.3182 20.8787 16.7771C19.3356 15.7493 18.6242 13.4998 19.8186 11.6609C20.9548 9.9115 23.3228 9.43586 25.1235 10.6805C25.3311 10.824 25.526 10.9857 25.7077 11.1652C25.9951 10.4386 26.2313 9.66335 26.4213 8.88489C26.8255 7.22921 26.993 5.6772 27.0343 4.79407C27.0695 4.04067 27.7087 3.45849 28.4621 3.4937ZM24.2257 13.7582C24.0184 13.3264 23.7721 13.0666 23.5705 12.9273C23.0071 12.5379 22.388 12.7192 22.1091 13.1486C21.8101 13.6089 21.9682 14.2211 22.3929 14.504C22.5646 14.6184 22.7847 14.6764 23.0768 14.5948C23.3412 14.521 23.7457 14.3065 24.2257 13.7582Z"
          fill="url(#paint27_radial_64837_72493)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8994 32.9978C19.9159 32.454 18.8906 31.7953 17.8594 31.0349C19.4211 29.0993 22.2107 26.5604 25.8479 24.727C31.8241 21.7146 38.4136 23.3496 41.0986 24.414C42.0212 24.7797 42.4725 25.8241 42.1068 26.7467C41.7411 27.6691 40.6968 28.1205 39.7742 27.7548C37.4729 26.8426 32.0913 25.6043 27.4655 27.9361C24.4144 29.474 22.1457 31.5419 20.8994 32.9978Z"
          fill="url(#paint28_linear_64837_72493)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8994 32.9978C19.9159 32.454 18.8906 31.7953 17.8594 31.0349C19.4211 29.0993 22.2107 26.5604 25.8479 24.727C31.8241 21.7146 38.4136 23.3496 41.0986 24.414C42.0212 24.7797 42.4725 25.8241 42.1068 26.7467C41.7411 27.6691 40.6968 28.1205 39.7742 27.7548C37.4729 26.8426 32.0913 25.6043 27.4655 27.9361C24.4144 29.474 22.1457 31.5419 20.8994 32.9978Z"
          fill="url(#paint29_radial_64837_72493)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8994 32.9978C19.9159 32.454 18.8906 31.7953 17.8594 31.0349C19.4211 29.0993 22.2107 26.5604 25.8479 24.727C31.8241 21.7146 38.4136 23.3496 41.0986 24.414C42.0212 24.7797 42.4725 25.8241 42.1068 26.7467C41.7411 27.6691 40.6968 28.1205 39.7742 27.7548C37.4729 26.8426 32.0913 25.6043 27.4655 27.9361C24.4144 29.474 22.1457 31.5419 20.8994 32.9978Z"
          fill="url(#paint30_radial_64837_72493)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8994 32.9978C19.9159 32.454 18.8906 31.7953 17.8594 31.0349C19.4211 29.0993 22.2107 26.5604 25.8479 24.727C31.8241 21.7146 38.4136 23.3496 41.0986 24.414C42.0212 24.7797 42.4725 25.8241 42.1068 26.7467C41.7411 27.6691 40.6968 28.1205 39.7742 27.7548C37.4729 26.8426 32.0913 25.6043 27.4655 27.9361C24.4144 29.474 22.1457 31.5419 20.8994 32.9978Z"
          fill="url(#paint31_radial_64837_72493)"
        />
        <g filter="url(#filter5_i_64837_72493)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.649 10.0641C15.1665 9.19694 14.0723 8.88513 13.2052 9.36767C12.338 9.85021 12.0262 10.9444 12.5087 11.8116C13.2374 13.121 14.1346 14.9697 14.2494 17.35C14.3495 19.4245 13.861 22.0554 11.875 25.2114C12.5622 26.1208 13.392 27.0633 14.3412 27.9993C17.1412 23.9261 17.9886 20.2781 17.839 17.1768C17.6843 13.97 16.4702 11.5398 15.649 10.0641Z"
            fill="url(#paint32_radial_64837_72493)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.649 10.0641C15.1665 9.19694 14.0723 8.88513 13.2052 9.36767C12.338 9.85021 12.0262 10.9444 12.5087 11.8116C13.2374 13.121 14.1346 14.9697 14.2494 17.35C14.3495 19.4245 13.861 22.0554 11.875 25.2114C12.5622 26.1208 13.392 27.0633 14.3412 27.9993C17.1412 23.9261 17.9886 20.2781 17.839 17.1768C17.6843 13.97 16.4702 11.5398 15.649 10.0641Z"
            fill="url(#paint33_radial_64837_72493)"
          />
        </g>
        <path
          d="M19.4766 12.3026C19.5648 12.0843 19.6764 11.869 19.8128 11.659C20.949 9.90963 23.317 9.434 25.1177 10.6786C25.3253 10.8222 25.5202 10.9839 25.7019 11.1633C25.7607 11.0148 25.8172 10.8644 25.8717 10.7122C25.8744 10.7214 25.877 10.7306 25.8796 10.7397C25.5098 11.9048 24.6797 13.1521 24.2187 13.7539C24.0116 13.3236 23.7659 13.0645 23.5647 12.9254C23.0013 12.536 22.3822 12.7174 22.1033 13.1467C22.0924 13.1634 22.0822 13.1803 22.0725 13.1974L19.4766 12.3026Z"
          fill="url(#paint34_radial_64837_72493)"
        />
        <path
          d="M19.4766 12.3026C19.5648 12.0843 19.6764 11.869 19.8128 11.659C20.949 9.90963 23.317 9.434 25.1177 10.6786C25.3253 10.8222 25.5202 10.9839 25.7019 11.1633C25.7607 11.0148 25.8172 10.8644 25.8717 10.7122C25.8744 10.7214 25.877 10.7306 25.8796 10.7397C25.5098 11.9048 24.6797 13.1521 24.2187 13.7539C24.0116 13.3236 23.7659 13.0645 23.5647 12.9254C23.0013 12.536 22.3822 12.7174 22.1033 13.1467C22.0924 13.1634 22.0822 13.1803 22.0725 13.1974L19.4766 12.3026Z"
          fill="url(#paint35_radial_64837_72493)"
        />
        <g filter="url(#filter6_i_64837_72493)">
          <path
            d="M27.2232 14.2539C27.439 15.3982 27.4446 16.7483 27.2077 18.2834C27.0927 19.0288 26.3952 19.5398 25.6497 19.4247C24.9044 19.3096 24.3933 18.6122 24.5083 17.8668C24.5614 17.5238 24.5989 17.2026 24.6231 16.902C26.0347 16.0158 26.9666 14.8365 27.2232 14.2539Z"
            fill="#44B6FF"
          />
        </g>
        <path
          d="M27.2232 14.2539C27.439 15.3982 27.4446 16.7483 27.2077 18.2834C27.0927 19.0288 26.3952 19.5398 25.6497 19.4247C24.9044 19.3096 24.3933 18.6122 24.5083 17.8668C24.5614 17.5238 24.5989 17.2026 24.6231 16.902C26.0347 16.0158 26.9666 14.8365 27.2232 14.2539Z"
          fill="url(#paint36_radial_64837_72493)"
        />
        <g filter="url(#filter7_f_64837_72493)">
          <path
            d="M24.794 12.2456C24.4122 11.7291 23.1881 10.8081 21.6495 11.7627C21.1333 12.0829 20.8297 13.2901 21.0655 14.1099"
            stroke="url(#paint37_linear_64837_72493)"
            strokeWidth="0.3"
            strokeLinecap="round"
          />
        </g>
        <g filter="url(#filter8_f_64837_72493)">
          <path
            d="M28.9883 4.83594C28.8684 7.00717 27.9281 12.1313 25.125 15.2578"
            stroke="url(#paint38_linear_64837_72493)"
            strokeWidth="0.3"
            strokeLinecap="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_64837_72493"
          x="-3"
          y="-1.5"
          width="54"
          height="54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.8 0 0 0 0 0.783333 0 0 0 0 0.783333 0 0 0 0.7 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_64837_72493"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_64837_72493"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_64837_72493"
          x="4.70312"
          y="17.5039"
          width="24.6719"
          height="26.0664"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.92549 0 0 0 0 0.34902 0 0 0 0 0.384314 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_64837_72493"
          />
        </filter>
        <filter
          id="filter2_f_64837_72493"
          x="7.96875"
          y="17.8438"
          width="21.4141"
          height="18.8555"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.25"
            result="effect1_foregroundBlur_64837_72493"
          />
        </filter>
        <filter
          id="filter3_f_64837_72493"
          x="9.47187"
          y="18.3039"
          width="19.1578"
          height="16.6813"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.1"
            result="effect1_foregroundBlur_64837_72493"
          />
        </filter>
        <filter
          id="filter4_i_64837_72493"
          x="29.2891"
          y="15.8281"
          width="13.8672"
          height="6.42969"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.25" dy="-0.25" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.721569 0 0 0 0 0.270588 0 0 0 0 0.470588 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_64837_72493"
          />
        </filter>
        <filter
          id="filter5_i_64837_72493"
          x="11.875"
          y="9.14062"
          width="6.12656"
          height="19.1094"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.15" dy="0.25" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.721569 0 0 0 0 0.184314 0 0 0 0 0.447059 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_64837_72493"
          />
        </filter>
        <filter
          id="filter6_i_64837_72493"
          x="24.4922"
          y="14.0039"
          width="3.39062"
          height="5.4375"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.5" dy="-0.25" />
          <feGaussianBlur stdDeviation="0.325" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.239216 0 0 0 0 0.439216 0 0 0 0 0.768627 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_64837_72493"
          />
        </filter>
        <filter
          id="filter7_f_64837_72493"
          x="20.5359"
          y="10.8992"
          width="4.70938"
          height="3.6625"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.15"
            result="effect1_foregroundBlur_64837_72493"
          />
        </filter>
        <filter
          id="filter8_f_64837_72493"
          x="24.5266"
          y="4.2375"
          width="5.06406"
          height="11.6187"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.225"
            result="effect1_foregroundBlur_64837_72493"
          />
        </filter>
        <linearGradient
          id="paint0_linear_64837_72493"
          x1="7.07147"
          y1="29.2292"
          x2="18.6613"
          y2="39.2917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3843C" />
          <stop offset="1" stopColor="#F17A61" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_64837_72493"
          x1="5.79079"
          y1="30.7864"
          x2="9.1828"
          y2="31.4752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B1853B" />
          <stop offset="1" stopColor="#B1853B" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_64837_72493"
          x1="29.3731"
          y1="34.7545"
          x2="23.6477"
          y2="35.6979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9C26C" />
          <stop offset="1" stopColor="#F9C26C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_64837_72493"
          x1="17.0382"
          y1="18.3131"
          x2="13.3605"
          y2="24.5573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6BF69" />
          <stop offset="1" stopColor="#E6BF69" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_64837_72493"
          x1="15.7809"
          y1="30.8082"
          x2="23.2422"
          y2="24.1491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCCB57" />
          <stop offset="1" stopColor="#F5BD55" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_64837_72493"
          x1="11.158"
          y1="19.48"
          x2="28.6326"
          y2="35.5171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3AB2C" />
          <stop offset="1" stopColor="#B57F1E" />
        </linearGradient>
        <radialGradient
          id="paint6_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.371 25.4546) rotate(-69.3794) scale(9.31141 2.60801)"
        >
          <stop offset="0.423414" stopColor="#CA851E" />
          <stop offset="1" stopColor="#CA851E" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.783 31.7886) rotate(-33.3516) scale(9.35746 5.47982)"
        >
          <stop stopColor="#BC7F28" />
          <stop offset="1" stopColor="#BC7F28" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.2667 23.0274) rotate(138.668) scale(2.48272)"
        >
          <stop stopColor="#5AC0FF" />
          <stop offset="1" stopColor="#3279B6" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.8432 23.2607) rotate(160.051) scale(2.49319 2.73285)"
        >
          <stop offset="0.534713" stopColor="#3A619E" stopOpacity="0" />
          <stop offset="1" stopColor="#3A619E" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.7003 32.6979) rotate(134.569) scale(2.8427)"
        >
          <stop stopColor="#FDD46C" />
          <stop offset="1" stopColor="#F09C53" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.0202 32.5468) rotate(100.827) scale(2.81582)"
        >
          <stop offset="0.792052" stopColor="#E76260" stopOpacity="0" />
          <stop offset="0.964251" stopColor="#E76260" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.5234 33.7142) rotate(-147.558) scale(2.85158 3.35813)"
        >
          <stop offset="0.482355" stopColor="#AA885A" stopOpacity="0" />
          <stop offset="0.90682" stopColor="#AA885A" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.4837 9.91197) rotate(132.387) scale(2.96142)"
        >
          <stop stopColor="#FF46AA" />
          <stop offset="1" stopColor="#E3189D" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.9751 10.8994) rotate(-173.29) scale(2.69129 3.60179)"
        >
          <stop offset="0.522105" stopColor="#B63173" stopOpacity="0" />
          <stop offset="1" stopColor="#B63173" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.4956 4.64657) rotate(139.574) scale(2.6467)"
        >
          <stop stopColor="#FFF46F" />
          <stop offset="1" stopColor="#EFB93C" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.1169 5.05879) rotate(-178.519) scale(2.7523 2.62052)"
        >
          <stop offset="0.558644" stopColor="#AA904B" stopOpacity="0" />
          <stop offset="0.943632" stopColor="#AA904B" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.8063 4.36181) rotate(102.339) scale(2.94298)"
        >
          <stop offset="0.771901" stopColor="#C47072" stopOpacity="0" />
          <stop offset="1" stopColor="#C47072" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.31957 11.4862) rotate(132.387) scale(2.96142)"
        >
          <stop stopColor="#FF46AA" />
          <stop offset="1" stopColor="#E3189D" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.81104 12.4736) rotate(-173.29) scale(2.69129 3.60179)"
        >
          <stop offset="0.522105" stopColor="#B63173" stopOpacity="0" />
          <stop offset="1" stopColor="#B63173" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.1272 36.5495) rotate(133.21) scale(6.10177 13.3594)"
        >
          <stop stopColor="#3365FF" />
          <stop offset="1" stopColor="#356DB9" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.6478 40.7706) rotate(131.598) scale(3.76649 9.52765)"
        >
          <stop stopColor="#3365FF" />
          <stop offset="1" stopColor="#356DB9" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.5801 17.5931) rotate(168.283) scale(6.19353 2.31067)"
        >
          <stop stopColor="#FF3BA9" />
          <stop offset="1" stopColor="#F422A7" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.0993 22.5345) rotate(-127.355) scale(6.38596 11.3811)"
        >
          <stop offset="0.752144" stopColor="#B8306F" stopOpacity="0" />
          <stop offset="1" stopColor="#B8306F" />
        </radialGradient>
        <linearGradient
          id="paint24_linear_64837_72493"
          x1="28.4022"
          y1="3.4922"
          x2="21.9594"
          y2="18.9333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B2EE" />
          <stop offset="1" stopColor="#2689DA" />
        </linearGradient>
        <radialGradient
          id="paint25_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.988 8.50822) rotate(128.217) scale(11.1559 6.82381)"
        >
          <stop offset="0.80204" stopColor="#4274D2" stopOpacity="0" />
          <stop offset="0.950917" stopColor="#4274D2" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.4299 7.21624) rotate(12.0948) scale(1.60796 5.39803)"
        >
          <stop stopColor="#4D8EC0" />
          <stop offset="1" stopColor="#4D8EC0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.6195 3.73479) rotate(141.953) scale(1.96799 1.76936)"
        >
          <stop stopColor="#5ADEFF" />
          <stop offset="1" stopColor="#5ADEFF" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint28_linear_64837_72493"
          x1="21.7744"
          y1="31.6878"
          x2="41.0501"
          y2="26.6363"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3797E2" />
          <stop offset="1" stopColor="#37AEFF" />
        </linearGradient>
        <radialGradient
          id="paint29_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.9044 34.8397) rotate(-129.838) scale(11.4713 28.7552)"
        >
          <stop offset="0.622552" stopColor="#526579" stopOpacity="0" />
          <stop offset="0.962126" stopColor="#526579" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.3471 28.6486) rotate(94.3987) scale(3.9376 7.91659)"
        >
          <stop offset="0.396078" stopColor="#6972FF" />
          <stop offset="1" stopColor="#6972FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.1858 25.3392) rotate(-68.8186) scale(1.07961 0.851394)"
        >
          <stop stopColor="#55BFFF" />
          <stop offset="1" stopColor="#55BFFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.5861 9.75203) rotate(99.4229) scale(10.5108 3.93774)"
        >
          <stop stopColor="#FF3AAA" />
          <stop offset="1" stopColor="#FD2F89" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.0983 20.5906) rotate(31.1757) scale(6.91215 21.6923)"
        >
          <stop offset="0.803698" stopColor="#DB2C6E" stopOpacity="0" />
          <stop offset="1" stopColor="#DB2C6E" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.6002 14.183) rotate(-119.23) scale(3.93636 2.94246)"
        >
          <stop stopColor="#336BAF" />
          <stop offset="1" stopColor="#336BAF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.8796 11.2123) rotate(-127.657) scale(2.66672 1.08044)"
        >
          <stop stopColor="#49A4D4" />
          <stop offset="1" stopColor="#49A4D4" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_64837_72493"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.0822 14.1332) rotate(94.8498) scale(3.71937 2.07)"
        >
          <stop stopColor="#4AB5F8" />
          <stop offset="1" stopColor="#4AB5F8" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint37_linear_64837_72493"
          x1="20.549"
          y1="12.2707"
          x2="24.5469"
          y2="11.291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4EAFF2" />
          <stop offset="1" stopColor="#4EAFF2" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_64837_72493"
          x1="28.6738"
          y1="3.30859"
          x2="23.0586"
          y2="16.2461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BDDFF" />
          <stop offset="1" stopColor="#5BDDFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
