export const ReachIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16 * size}
    height={17 * size}
    viewBox="0 0 16 17"
    fill="currentColor"
  >
    <path d="M12.9672 0.621795L1.57211 4.42016C1.12309 4.57075 0.731434 4.85616 0.45055 5.23747C0.169666 5.61878 0.0132188 6.07745 0.00253881 6.55093C-0.00814115 7.02441 0.127466 7.48966 0.390871 7.88325C0.654276 8.27684 1.03266 8.57962 1.47443 8.7503L5.69062 10.3619C5.79062 10.4028 5.88147 10.4632 5.95786 10.5396C6.03426 10.616 6.09467 10.7068 6.13557 10.8068L7.74716 15.023C7.88467 15.3812 8.1095 15.6993 8.40121 15.9484C8.69292 16.1976 9.04228 16.3699 9.41752 16.4497C9.79276 16.5294 10.182 16.5142 10.5498 16.4053C10.9177 16.2964 11.2525 16.0973 11.5238 15.8261C11.7754 15.5695 11.9663 15.2599 12.0827 14.9199L15.8811 3.52483C16.015 3.11981 16.0337 2.68555 15.9352 2.2705C15.8367 1.85544 15.6248 1.47593 15.3232 1.17429C15.0215 0.872646 14.642 0.660748 14.227 0.562232C13.8119 0.463717 13.3776 0.482459 12.9726 0.616369L12.9672 0.621795ZM14.4214 3.0419L10.6231 14.437C10.5712 14.5846 10.4755 14.7129 10.3486 14.8046C10.2218 14.8963 10.07 14.947 9.91352 14.95C9.75705 14.9529 9.60342 14.908 9.47323 14.8211C9.34304 14.7343 9.24251 14.6097 9.18512 14.4641L7.5681 10.2534C7.54598 10.1977 7.52061 10.1433 7.49213 10.0906L11.2308 6.3519C11.3747 6.20799 11.4556 6.0128 11.4556 5.80928C11.4556 5.60576 11.3747 5.41057 11.2308 5.26665C11.0869 5.12274 10.8917 5.04189 10.6882 5.04189C10.4847 5.04189 10.2895 5.12274 10.1456 5.26665L6.40688 9.00533C6.35415 8.97685 6.2998 8.95149 6.2441 8.92936L2.03334 7.31235C1.88775 7.25496 1.76318 7.15442 1.67634 7.02423C1.58951 6.89404 1.54455 6.74041 1.5475 6.58395C1.55046 6.42748 1.60118 6.27566 1.69286 6.14883C1.78455 6.02201 1.91282 5.92625 2.06047 5.87439L13.4556 2.07603C13.59 2.03268 13.7338 2.02727 13.8711 2.06039C14.0083 2.09352 14.1338 2.16389 14.2337 2.26375C14.3336 2.36362 14.4039 2.48911 14.4371 2.62641C14.4702 2.7637 14.4648 2.90748 14.4214 3.0419Z" />
  </svg>
);
