const ProposalKeys = {
  saving: 'ProposalKeys.saving',
  changes_saved: 'ProposalKeys.changes_saved',

  New_Proposal: {
    text: 'ProposalKeys.New_Proposal.text',
    toast: 'ProposalKeys.New_Proposal.toast',
  },

  Clear: {
    text: 'ProposalKeys.Clear.text',
    toast: 'ProposalKeys.Clear.toast',
  },

  Modal: {
    Copy: {
      title: 'ProposalKeys.Modal.Copy.title',
      description: 'ProposalKeys.Modal.Copy.description',
    },
  },

  Basic_Info: {
    Project_Title: {
      title: 'ProposalKeys.Basic_Info.Project_Title.title',
      placeholder: 'ProposalKeys.Basic_Info.Project_Title.placeholder',
      error: 'ProposalKeys.Basic_Info.Project_Title.error',
    },
    Website: {
      title: 'ProposalKeys.Basic_Info.Website.title',
      placeholder: 'ProposalKeys.Basic_Info.Website.placeholder',
      error: 'ProposalKeys.Basic_Info.Website.error',
    },
    Description: {
      title: 'ProposalKeys.Basic_Info.Description.title',
      placeholder: 'ProposalKeys.Basic_Info.Description.placeholder',

      Snippet: {
        title: 'ProposalKeys.Basic_Info.Description.Snippet.title',
        creator_social_channel_name:
          'ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name',
        creator_username:
          'ProposalKeys.Basic_Info.Description.Snippet.creator_username',
        creator_main_country:
          'ProposalKeys.Basic_Info.Description.Snippet.creator_main_country',
        creator_top_categories:
          'ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories',
      },

      Error: {
        file_format: 'ProposalKeys.Basic_Info.Description.Error.file_format',
        file_size_limit:
          'ProposalKeys.Basic_Info.Description.Error.file_size_limit',
        max_file: 'ProposalKeys.Basic_Info.Description.Error.max_file',
      },
    },
  },

  caution: 'ProposalKeys.caution',

  Side_btn: {
    text: 'ProposalKeys.Side_btn.text',
    title: 'ProposalKeys.Side_btn.title',
  },

  Product_Delivery: {
    title: 'ProposalKeys.Product_Delivery.title',

    Delivery: {
      title: 'ProposalKeys.Product_Delivery.Delivery.title',
      tooltip: 'ProposalKeys.Product_Delivery.Delivery.tooltip',

      Image: {
        title: 'ProposalKeys.Product_Delivery.Delivery.Image.title',
        description: 'ProposalKeys.Product_Delivery.Delivery.Image.description',
      },

      Info: {
        title: 'ProposalKeys.Product_Delivery.Delivery.Info.title',
        placeholder: 'ProposalKeys.Product_Delivery.Delivery.Info.placeholder',
      },

      caution_due_date:
        'ProposalKeys.Product_Delivery.Delivery.caution_due_date',
      caution_return_process:
        'ProposalKeys.Product_Delivery.Delivery.caution_return_process',
      caution_international_delivery:
        'ProposalKeys.Product_Delivery.Delivery.caution_international_delivery',
      caution_international_delivery_tooltip:
        'ProposalKeys.Product_Delivery.Delivery.caution_international_delivery_tooltip',
    },
  },

  Requirements: {
    title: 'ProposalKeys.Requirements.title',
    applied: 'ProposalKeys.Requirements.applied',

    Promotion: {
      title: 'ProposalKeys.Requirements.Promotion.title',
      description_1: 'ProposalKeys.Requirements.Promotion.description_1',
      description_2: 'ProposalKeys.Requirements.Promotion.description_2',
      description_3: 'ProposalKeys.Requirements.Promotion.description_3',
    },

    Terms_Conditions: {
      placeholder: 'ProposalKeys.Requirements.Terms_Conditions.placeholder',
      description: 'ProposalKeys.Requirements.Terms_Conditions.description',
    },
  },

  Checklist: {
    title: 'ProposalKeys.Checklist.title',
  },

  Content_Missions: {
    title: 'ProposalKeys.Content_Missions.title',

    Include_Link: {
      title: 'ProposalKeys.Content_Missions.Include_Link.title',
      placeholder: 'ProposalKeys.Content_Missions.Include_Link.placeholder',
      description: 'ProposalKeys.Content_Missions.Include_Link.description',
    },

    Include_Hashtags: {
      title: 'ProposalKeys.Content_Missions.Include_Hashtags.title',
      placeholder: 'ProposalKeys.Content_Missions.Include_Hashtags.placeholder',
      btn: 'ProposalKeys.Content_Missions.Include_Hashtags.btn',
      description: 'ProposalKeys.Content_Missions.Include_Hashtags.description',
    },

    Tag_Location: {
      title: 'ProposalKeys.Content_Missions.Tag_Location.title',
      placeholder: 'ProposalKeys.Content_Missions.Tag_Location.placeholder',
      description: 'ProposalKeys.Content_Missions.Tag_Location.description',
    },

    Tag_Account: {
      title: 'ProposalKeys.Content_Missions.Tag_Account.title',
      placeholder: 'ProposalKeys.Content_Missions.Tag_Account.placeholder',
      description: 'ProposalKeys.Content_Missions.Tag_Account.description',
    },

    Pin_Comment_Account: {
      title: 'ProposalKeys.Content_Missions.Pin_Comment_Account.title',
      description:
        'ProposalKeys.Content_Missions.Pin_Comment_Account.description',
    },

    Pin_Comment_Link: {
      title: 'ProposalKeys.Content_Missions.Pin_Comment_Link.title',
      placeholder: 'ProposalKeys.Content_Missions.Pin_Comment_Link.placeholder',
      description: 'ProposalKeys.Content_Missions.Pin_Comment_Link.description',
    },

    Include_Img: {
      title: 'ProposalKeys.Content_Missions.Include_Img.title',
      description: 'ProposalKeys.Content_Missions.Include_Img.description',
    },

    Tag_Video: {
      title: 'ProposalKeys.Content_Missions.Tag_Video.title',
      placeholder: 'ProposalKeys.Content_Missions.Tag_Video.placeholder',
      btn: 'ProposalKeys.Content_Missions.Tag_Video.btn',
      description: 'ProposalKeys.Content_Missions.Tag_Video.description',
    },
  },

  View: {
    copied_to_clipboard: 'ProposalKeys.View.copied_to_clipboard',

    Top: {
      visit_website: 'ProposalKeys.View.Top.visit_website',
    },

    ProductDelivery: {
      title: 'ProposalKeys.View.ProductDelivery.title',

      Physical: {
        title: 'ProposalKeys.View.ProductDelivery.Physical.title',
        addressRequired:
          'ProposalKeys.View.ProductDelivery.Physical.addressRequired',
        waitForProduct:
          'ProposalKeys.View.ProductDelivery.Physical.waitForProduct',
      },
    },

    Requirement: {
      Tooltip: {
        creator: 'ProposalKeys.View.Requirement.Tooltip.creator',
        client: 'ProposalKeys.View.Requirement.Tooltip.client',
      },

      Ad: {
        Checklist: {
          pronoun_creator:
            'ProposalKeys.View.Requirement.Ad.Checklist.pronoun_creator',
          pronoun_client:
            'ProposalKeys.View.Requirement.Ad.Checklist.pronoun_client',

          sponsored: 'ProposalKeys.View.Requirement.Ad.Checklist.sponsored',
          useFollowingMethod:
            'ProposalKeys.View.Requirement.Ad.Checklist.useFollowingMethod',
          applicableContents:
            'ProposalKeys.View.Requirement.Ad.Checklist.applicableContents',
        },

        All: {
          description: 'ProposalKeys.View.Requirement.Ad.All.description',
        },

        YouTube: {
          description: 'ProposalKeys.View.Requirement.Ad.YouTube.description',
        },

        Instagram: {
          description: 'ProposalKeys.View.Requirement.Ad.Instagram.description',
        },

        TikTok: {
          description: 'ProposalKeys.View.Requirement.Ad.TikTok.description',
        },
      },

      Terms_Conditions: {
        title: 'ProposalKeys.View.Requirement.Terms_Conditions.title',

        Checklist: {
          complyTermsConditions:
            'ProposalKeys.View.Requirement.Terms_Conditions.Checklist.complyTermsConditions',
        },
      },

      Content_Missions: {
        Tooltip: {
          creator:
            'ProposalKeys.View.Requirement.Content_Missions.Tooltip.creator',
          client:
            'ProposalKeys.View.Requirement.Content_Missions.Tooltip.client',
        },

        Include_Link: {
          copy: 'ProposalKeys.View.Requirement.Content_Missions.Include_Link.copy',
          description_creator:
            'ProposalKeys.View.Requirement.Content_Missions.Include_Link.description_creator',
          description_client:
            'ProposalKeys.View.Requirement.Content_Missions.Include_Link.description_client',
        },

        Include_Hashtags: {
          copy: 'ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.copy',
          description_creator:
            'ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.description_creator',
          description_client:
            'ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.description_client',
        },

        Tag_Location: {
          copy: 'ProposalKeys.View.Requirement.Content_Missions.Tag_Location.copy',
          description_creator:
            'ProposalKeys.View.Requirement.Content_Missions.Tag_Location.description_creator',
          description_client:
            'ProposalKeys.View.Requirement.Content_Missions.Tag_Location.description_client',
        },

        Tag_Account: {
          description_creator:
            'ProposalKeys.View.Requirement.Content_Missions.Tag_Account.description_creator',
          description_client:
            'ProposalKeys.View.Requirement.Content_Missions.Tag_Account.description_client',
        },

        Pin_Comment_Account: {
          description_creator:
            'ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account.description_creator',
          description_client:
            'ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account.description_client',
        },

        Pin_Comment_Link: {
          description:
            'ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Link.description',
        },

        Include_Img: {
          download:
            'ProposalKeys.View.Requirement.Content_Missions.Include_Img.download',
        },

        Tag_Video: {
          copy: 'ProposalKeys.View.Requirement.Content_Missions.Tag_Video.copy',
          description:
            'ProposalKeys.View.Requirement.Content_Missions.Tag_Video.description',
        },
      },
    },
  },
};

export default ProposalKeys;
