import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: '自動翻訳',
  [AutoTranslationKeys.Dropdown.description]:
    '説明やユーザーが書いたテキストを自動的に翻訳します。',
  [AutoTranslationKeys.Dropdown.order_message_title]: '注文・メッセージ翻訳',
  [AutoTranslationKeys.Dropdown.order_message_title_mobile]:
    '注文・メッセージ <br> 翻訳',
  [AutoTranslationKeys.Dropdown.order_message_description]:
    '残り{{number}}文字 · {{date}}更新',
  [AutoTranslationKeys.Dropdown.order_message_description_mobile]: `
    残り{{number}}文字 <br> 
    {{date}}更新`,

  [AutoTranslationKeys.Applying_Translation.title]:
    '自動翻訳が適用されました。',
  [AutoTranslationKeys.Applying_Translation.btn]: '元のテキストを表示',
  [AutoTranslationKeys.Applying_Translation.message]: '翻訳済み',

  [AutoTranslationKeys.Displaying_Original.title]:
    '元のテキストを表示しています。',
  [AutoTranslationKeys.Displaying_Original.btn]: '翻訳',
  [AutoTranslationKeys.Displaying_Original.message]: '元のテキスト',
};
