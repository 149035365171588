import { io } from 'socket.io-client';

const connectSocket = () => {
  const socket = io(process.env.REACT_APP_SOCKET_SERVER, {
    secure: true,
    autoConnect: false,
    withCredentials: true,
    transports: ['websocket'],
    // connect to /api namespace if in production
    path: process.env.REACT_APP_SOCKET_NAMESPACE,
  });

  if (
    process.env.REACT_APP_NODE_ENV === 'development' ||
    process.env.REACT_APP_NODE_ENV === 'testing'
  ) {
    socket.onAny((event, ...args) => {
      console.log(event, args);
    });
    socket.on('connect', () => {
      console.log('connected to socket');
    });

    socket.on('disconnect', () => {
      console.log('disconnected from socket');
    });
  }

  return socket;
};

export default connectSocket;
