export const PackageIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 13}
    height={size * 17}
    viewBox="0 0 13 17"
    fill="currentColor"
  >
    <path d="M13 5.10536C12.9956 5.08509 12.9956 5.06412 13 5.04385C12.9963 5.0261 12.9963 5.00777 13 4.99003V4.92083L12.9538 4.80549C12.935 4.77402 12.9117 4.74552 12.8845 4.72092L12.8152 4.65941H12.7768L9.7437 2.7449L6.88001 0.976474C6.81376 0.923983 6.73794 0.884816 6.65677 0.861142H6.59518C6.52637 0.849671 6.45614 0.849671 6.38733 0.861142H6.31035C6.22093 0.880896 6.1352 0.91463 6.05631 0.961097L0.305834 4.53639L0.236551 4.59021L0.167268 4.65172L0.0902871 4.70554L0.0517967 4.75167L0.00560794 4.867V4.9362V4.98234C-0.00186931 5.03332 -0.00186931 5.08513 0.00560794 5.13611V11.8484C0.00534614 11.9791 0.0384297 12.1077 0.101735 12.222C0.16504 12.3364 0.256479 12.4327 0.367419 12.502L6.14099 16.0696L6.25646 16.1157H6.31805C6.44829 16.157 6.58811 16.157 6.71835 16.1157H6.77994L6.89541 16.0696L12.6228 12.5558C12.7337 12.4865 12.8252 12.3902 12.8885 12.2758C12.9518 12.1615 12.9849 12.0329 12.9846 11.9023V5.18993C12.9846 5.18993 13 5.13611 13 5.10536ZM6.46431 2.52961L7.83457 3.37538L3.53134 6.0357L2.15338 5.18993L6.46431 2.52961ZM5.6945 14.0628L1.46055 11.4794V6.56623L5.6945 9.18042V14.0628ZM6.46431 7.82719L4.99398 6.94298L9.29721 4.27497L10.7752 5.18993L6.46431 7.82719ZM11.4681 11.4563L7.23412 14.0859V9.18042L11.4681 6.56623V11.4563Z" />
  </svg>
);
