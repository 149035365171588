export const FearEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M28.1229 14.999C28.1229 22.2472 23.75 28.1229 14.999 28.1229C6.2479 28.1229 1.875 22.2472 1.875 14.999C1.875 14.3627 1.90869 13.737 1.97609 13.125L14.999 7.5L28.0218 13.125C28.0892 13.737 28.1229 14.3627 28.1229 14.999Z"
      fill="#FFC543"
    />
    <path
      d="M28.0223 13.125C27.322 6.76452 22.9823 1.875 14.9995 1.875C7.01654 1.875 2.67691 6.76452 1.97656 13.125H28.0223Z"
      fill="#5092FF"
    />
    <path
      d="M9.84375 16.875C12.1737 16.875 14.0625 14.9862 14.0625 12.6562C14.0625 10.3263 12.1737 8.4375 9.84375 8.4375C7.5138 8.4375 5.625 10.3263 5.625 12.6562C5.625 14.9862 7.5138 16.875 9.84375 16.875Z"
      fill="white"
    />
    <path
      d="M20.1562 16.875C22.4862 16.875 24.375 14.9862 24.375 12.6562C24.375 10.3263 22.4862 8.4375 20.1562 8.4375C17.8263 8.4375 15.9375 10.3263 15.9375 12.6562C15.9375 14.9862 17.8263 16.875 20.1562 16.875Z"
      fill="white"
    />
    <path
      d="M8.39223 6.74985C8.89889 6.30486 9.21258 5.69372 9.39131 5.03382C9.459 4.78394 9.71644 4.63624 9.96628 4.70392C10.2162 4.7716 10.3639 5.02902 10.2962 5.27891C10.0853 6.0575 9.69591 6.85261 9.01088 7.45424C8.31845 8.06241 7.36381 8.43762 6.09375 8.43762C5.83487 8.43762 5.625 8.22776 5.625 7.96887C5.625 7.70998 5.83487 7.50012 6.09375 7.50012C7.16744 7.50012 7.893 7.18832 8.39223 6.74985Z"
      fill="#402A32"
    />
    <path
      d="M21.6079 6.74985C21.1013 6.30486 20.7876 5.69372 20.6088 5.03382C20.5411 4.78394 20.2837 4.63624 20.0338 4.70392C19.7839 4.7716 19.6362 5.02902 19.7039 5.27891C19.9148 6.0575 20.3042 6.85261 20.9892 7.45424C21.6817 8.06241 22.6363 8.43762 23.9064 8.43762C24.1652 8.43762 24.3751 8.22776 24.3751 7.96887C24.3751 7.70998 24.1652 7.50012 23.9064 7.50012C22.8326 7.50012 22.1071 7.18832 21.6079 6.74985Z"
      fill="#402A32"
    />
    <path
      d="M13.125 13.125C13.125 14.1606 12.2856 15 11.25 15C10.2144 15 9.375 14.1606 9.375 13.125C9.375 12.0894 10.2144 11.25 11.25 11.25C12.2856 11.25 13.125 12.0894 13.125 13.125Z"
      fill="#402A32"
    />
    <path
      d="M20.625 13.125C20.625 14.1606 19.7856 15 18.75 15C17.7144 15 16.875 14.1606 16.875 13.125C16.875 12.0894 17.7144 11.25 18.75 11.25C19.7856 11.25 20.625 12.0894 20.625 13.125Z"
      fill="#402A32"
    />
    <path
      d="M21.2978 22.5247C21.5892 23.5184 20.7216 24.375 19.686 24.375C15.9795 24.375 13.7177 24.375 10.313 24.375C9.27741 24.375 8.41021 23.5184 8.70166 22.5247C9.50044 19.8013 12.0178 17.8125 14.9997 17.8125C17.9816 17.8125 20.499 19.8013 21.2978 22.5247Z"
      fill="#BB1D80"
    />
  </svg>
);
