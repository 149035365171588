import styled from 'styled-components';
import {
  CheckCircleLightIcon,
  CirclePlusIcon,
  LoadingIcon,
} from 'assets/icons';
import Stack from 'components/Basics/Layout/Stack';
import Platform, { platformMap } from 'constants/platform';
import { useCallback, useRef } from 'react';
import { memo } from 'react';
import YoutubeVerifier from 'components/utilities/SocialVerifiers/YoutubeVerifier';
import TwitterVerifier from 'components/utilities/SocialVerifiers/TwitterVerifier';
import TikTokVerifier from 'components/utilities/SocialVerifiers/TikTokVerifier';
import InstagramVerifier from 'components/utilities/SocialVerifiers/InstagramVerifier';
import useUser from 'hooks/useUser';
import PlatformIcon from 'components/Basics/PlatformIcon';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import TextButton from 'components/Basics/Buttons/TextButton';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import device from 'styles/device';

const SocialNetwork = ({
  name,
  platform,
  expired,
  connected,
  isInstagramBasic,
  disabled,
  unlink,
  isLoading,
  setIsLoading,
  isReconnectionModalOpen = () => {},
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();
  const { updateUser, user } = useUser();
  const verifierRef = useRef(null);

  const updateLinks = useCallback(
    (links) => {
      updateUser({
        ...(user || {}),
        links: (
          user?.links
            ?.filter((link) => link.type !== platform)
            .concat(links.filter((link) => link.type === platform)) || []
        ).map((link) => ({
          ...link,
          recentlyAdded: 1,
        })),
        availablePlatforms: links?.map?.((link) => link.type),
      });
      setIsLoading(false);
    },
    [updateUser, user, setIsLoading, platform]
  );

  return (
    <>
      <Container>
        <Stack
          direction="row"
          gap={isMobile ? '0.375rem' : '1rem'}
          align="center"
          justify={isMobile ? '' : 'center'}
          style={{
            cursor: disabled ? 'default' : 'pointer',
          }}
        >
          <PlatformIcon
            platform={platform}
            variant={isMobile ? 'size5' : 'size3'}
          />
          <Title>{name || platformMap[platform]}</Title>
          {expired && (
            <CustomTextButton
              onClick={(e) => {
                e.preventDefault();
                unlink(platform);
              }}
            >
              {t(GeneralKeys.unlink)}
            </CustomTextButton>
          )}
        </Stack>

        {!isLoading && (
          <Stack
            direction="row"
            gap={isMobile ? '0.875rem' : '1.5rem'}
            align="center"
            wrap="nowrap"
          >
            <Button
              onClick={() => {
                if (disabled) return;

                if (platform === Platform.Instagram) {
                  verifierRef.current?.verify(
                    !!isInstagramBasic ? false : true
                  );
                  return;
                } else {
                  verifierRef.current?.verify();
                }
              }}
              disabled={disabled}
            >
              {expired ? (
                <>
                  <CirclePlusIcon size={isMobile ? 0.78 : 1} />
                  {t(GeneralKeys.reconnect)}
                </>
              ) : (
                !expired &&
                !connected && (
                  <>
                    <CirclePlusIcon size={isMobile ? 0.78 : 1} />
                    {t(GeneralKeys.connect)}
                  </>
                )
              )}
            </Button>
            {!expired && !connected && (
              <>
                <Divider />
                <CheckCircleLightIcon size={isMobile ? 0.92 : 1.29} />
              </>
            )}

            {!expired && connected && (
              <CheckCircleLightIcon size={isMobile ? 0.92 : 1.29} />
            )}
          </Stack>
        )}

        {isLoading && <LoadingIcon size={0.75} />}

        {platform === Platform.YouTube ? (
          <YoutubeVerifier
            ref={verifierRef}
            onVerify={updateLinks}
            reqType={expired ? 'reconnect' : 'permanent'}
            onError={(e) => {
              setIsLoading(false);
              if (e?.openReconnectionModal) {
                isReconnectionModalOpen(true);
              } else {
                isReconnectionModalOpen(false);
              }
            }}
            errorModalPrimaryButtonClick={() => {
              isReconnectionModalOpen(false);
              setIsLoading(false);
              unlink(platform);
            }}
          />
        ) : platform === Platform.Twitter ? (
          <TwitterVerifier
            ref={verifierRef}
            onVerify={updateLinks}
            reqType={expired ? 'reconnect' : 'permanent'}
            onError={(e) => {
              setIsLoading(false);
              if (e?.openReconnectionModal) {
                isReconnectionModalOpen(true);
              } else {
                isReconnectionModalOpen(false);
              }
            }}
            errorModalPrimaryButtonClick={() => {
              isReconnectionModalOpen(false);
              setIsLoading(false);
              unlink(platform);
            }}
          />
        ) : platform === Platform.TikTok ? (
          <TikTokVerifier
            ref={verifierRef}
            onVerify={updateLinks}
            reqType={expired ? 'reconnect' : 'permanent'}
            onError={(e) => {
              setIsLoading(false);
              if (e?.openReconnectionModal) {
                isReconnectionModalOpen(true);
              } else {
                isReconnectionModalOpen(false);
              }
            }}
            errorModalPrimaryButtonClick={() => {
              isReconnectionModalOpen(false);
              setIsLoading(false);
              unlink(platform);
            }}
          />
        ) : platform === Platform.Instagram ? (
          <InstagramVerifier
            ref={verifierRef}
            onVerify={updateLinks}
            isInstagramBasic={isInstagramBasic}
            reqType={expired ? 'reconnect' : 'permanent'}
            onError={(e) => {
              setIsLoading(false);
              if (e?.openReconnectionModal) {
                isReconnectionModalOpen(true);
              } else {
                isReconnectionModalOpen(false);
              }
            }}
            errorModalPrimaryButtonClick={() => {
              isReconnectionModalOpen(false);
              setIsLoading(false);
              unlink(platform);
            }}
          />
        ) : null}
      </Container>
    </>
  );
};

export default memo(SocialNetwork);

const Container = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  background: transparent;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 150ms ease-in-out;
  width: 100%;

  @media ${device.sm} {
    align-items: start;
  }
`;

const Title = styled.p.attrs({
  className: 'h5 m-0',
})`
  max-width: 700px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
  text-decoration: none;

  &:hover {
    color: var(--color-indigo-500);
    text-decoration: ${({ as }) => (as === 'a' ? 'underline' : 'none')};
  }

  &:focus {
    outline: none;
  }

  @media ${device.sm} {
    max-width: calc(100% - 40px);
    font-size: 14px;
    font-weight: 600px;
    padding-left: 0.25rem;
  }
`;

const Button = styled.button.attrs({
  className: 'sbh5',
})`
  border: none;
  background: none;
  padding: ${({ padding }) => padding || '0'};
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: var(--color-indigo-50);

    &:hover {
      opacity: 1;
      color: var(--color-indigo-50);
    }
  }

  @media ${device.sm} {
    font-size: 14px;
    gap: 0.5rem;
  }
`;
const Divider = styled.div`
  width: 2px;
  height: 18px;
  background: var(--color-indigo-50);

  @media ${device.sm} {
    width: 1px;
    height: 14px;
  }
`;

const CustomTextButton = styled(TextButton).attrs({
  className: 'h6',
})`
  text-decoration: underline;

  @media ${device.sm} {
    font-size: 11px;
    padding-left: 1.875rem;
    width: 100%;
    display: flex;
  }
`;
