export const AnticipationEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M21.5625 18.75C21.5625 22.3781 18.6281 25.3125 15 25.3125C11.3719 25.3125 8.4375 22.3781 8.4375 18.75H12.2046L14.9976 19.6875L17.7906 18.75H21.5625Z"
      fill="#BB1D80"
    />
    <path
      d="M12.1875 18.75H17.8125V19.2188C17.8125 19.7365 17.3928 20.1562 16.875 20.1562H13.125C12.6072 20.1562 12.1875 19.7365 12.1875 19.2188V18.75Z"
      fill="white"
    />
    <path
      d="M13.5938 12.6562C13.5938 15.2451 11.4951 17.3438 8.90625 17.3438C6.31742 17.3438 4.21875 15.2451 4.21875 12.6562C4.21875 10.0674 6.31742 7.96875 8.90625 7.96875C11.4951 7.96875 13.5938 10.0674 13.5938 12.6562Z"
      fill="white"
    />
    <path
      d="M25.7812 12.6562C25.7812 15.2451 23.6826 17.3438 21.0938 17.3438C18.5049 17.3438 16.4062 15.2451 16.4062 12.6562C16.4062 10.0674 18.5049 7.96875 21.0938 7.96875C23.6826 7.96875 25.7812 10.0674 25.7812 12.6562Z"
      fill="white"
    />
    <path
      d="M7.5 12.6562C7.5 11.3616 8.54902 10.3125 9.84375 10.3125C11.1384 10.3125 12.1875 11.3616 12.1875 12.6562C12.1875 13.9509 11.1384 15 9.84375 15C8.54902 15 7.5 13.9509 7.5 12.6562Z"
      fill="#402A32"
    />
    <path
      d="M17.8125 12.6562C17.8125 11.3616 18.8616 10.3125 20.1562 10.3125C21.451 10.3125 22.5 11.3616 22.5 12.6562C22.5 13.9509 21.451 15 20.1562 15C18.8616 15 17.8125 13.9509 17.8125 12.6562Z"
      fill="#402A32"
    />
    <path
      d="M8.90625 7.5C11.2665 7.5 13.2563 9.08577 13.8684 11.25H16.1316C16.7438 9.08577 18.7335 7.5 21.0938 7.5C23.454 7.5 25.4437 9.08577 26.0559 11.25H28.0434C28.604 11.25 29.0625 11.6719 29.0625 12.1875C29.0625 12.7031 28.604 13.125 28.0434 13.125H26.229C25.9922 15.7531 23.7834 17.8125 21.0938 17.8125C18.4041 17.8125 16.1953 15.7531 15.9585 13.125H14.0415C13.8047 15.753 11.5959 17.8125 8.90625 17.8125C6.21654 17.8125 4.00785 15.753 3.77102 13.125H1.875C1.35724 13.125 0.9375 12.7053 0.9375 12.1875C0.9375 11.6697 1.35724 11.25 1.875 11.25H3.94409C4.55621 9.08577 6.54605 7.5 8.90625 7.5ZM4.6875 12.6562C4.6875 14.9862 6.5763 16.875 8.90625 16.875C11.2362 16.875 13.125 14.9862 13.125 12.6562C13.125 10.3263 11.2362 8.4375 8.90625 8.4375C6.5763 8.4375 4.6875 10.3263 4.6875 12.6562ZM16.8818 12.6562C16.8818 14.9824 18.7676 16.8682 21.0938 16.8682C23.4199 16.8682 25.3057 14.9824 25.3057 12.6562C25.3057 10.3301 23.4199 8.44437 21.0938 8.44437C18.7676 8.44437 16.8818 10.3301 16.8818 12.6562Z"
      fill="#402A32"
    />
  </svg>
);
