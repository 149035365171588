import 'dayjs/locale/en';

const enLocale = {
  name: 'en',
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1 minute',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
  dateFormat: {
    dot_date: 'MM.DD.YYYY',
    dash_date: 'MM-DD-YYYY',
    dot_date_time_12: 'MM.DD.YYYY h:mm A',
    time_12: 'h:mm A',
    abbr_date_time_12: 'MMM D, h:mm A',
    date_time: 'MMMM D, YYYY',
    yesterday: 'Yesterday',
    full_abbr_date_time_12: 'MMM D, YYYY h:mm A',
  },
};

export default enLocale;
