import { useCallback, useEffect, useRef, useState } from 'react';

const interval = 10;

const useTimer = (duration, defaultPaused) => {
  const [timeRemaining, setTimeRemaining] = useState(duration);
  const timerRef = useRef(null);

  const startTimer = useCallback(() => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimeRemaining((prev) => prev - interval);
    }, interval);
  }, []);

  const pauseTimer = useCallback(() => {
    clearInterval(timerRef.current);
  }, []);

  const resetTimer = useCallback(() => {
    clearInterval(timerRef.current);
    setTimeRemaining(duration);
  }, [duration]);

  useEffect(() => {
    setTimeRemaining(duration);
  }, [duration]);

  useEffect(() => {
    if (!defaultPaused) {
      startTimer();

      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [defaultPaused, startTimer]);

  return { timeRemaining, startTimer, pauseTimer, resetTimer };
};

export default useTimer;
