import ProfileKeys from '../translationKeys/ProfilePageKeys';

export const ProfileTranslations = {
  // Menu
  [ProfileKeys.Menu.edit]: 'Chỉnh sửa hồ sơ',
  [ProfileKeys.Menu.save]: 'Lưu hồ sơ',
  [ProfileKeys.Menu.message_me]: 'Nhắn tin cho tôi',

  // Menu Error
  [ProfileKeys.Menu.Error.creator_to_creator]:
    'Vui lòng sử dụng tài khoản chưa đăng ký làm người sáng tạo.',
  [ProfileKeys.Menu.Error.something_went_wrong]: 'Đã xảy ra sự cố.',
  [ProfileKeys.Menu.Error.self_chat]:
    'Không thể gửi tin nhắn đến cuộc trò chuyện này.',

  // Package
  [ProfileKeys.Package.edit]: 'Chỉnh sửa Gói',
  [ProfileKeys.Package.no_content]: 'Không có Nội dung',
  [ProfileKeys.Package.available]: 'Có sẵn',
  [ProfileKeys.Package.click_to_view]: 'Nhấp để xem',
  [ProfileKeys.Package.click_to_view_recent]: 'Nhấp để xem {{content}} gần đây',
  [ProfileKeys.Package.view_recent_videos]: 'Xem Video Gần Đây',

  // Topbar
  [ProfileKeys.Package.TopBar.tooltip]: 'Gói này hiện không khả dụng.',

  // YouTube
  [ProfileKeys.Package.YouTube.Contents.video_prepare]: 'Đang chuẩn bị gói',
  [ProfileKeys.Package.YouTube.Contents.video_display]:
    'Video của bạn sẽ được hiển thị ở đây.',

  // Description
  [ProfileKeys.Package.Description.default]: `
    Người sáng tạo đang tạo một gói. <br>
    Vui lòng kiểm tra lại.`,

  // Details
  [ProfileKeys.Package.Details.Price.contact_me]: 'Liên hệ tôi',
  [ProfileKeys.Package.Details.Price.contact_creator]: 'Liên hệ người tạo',
  [ProfileKeys.Package.Details.Price.service_fee]: 'Bao gồm phí dịch vụ',
  [ProfileKeys.Package.Details.Price.tooltip]: `
    Vui lòng thử lại với gói<br>
    của người sáng tạo khác.`,

  [ProfileKeys.Package.Details.Toast.update_successful]: 'Cập nhật thành công!',
  [ProfileKeys.Package.Details.Toast.could_not_update]: 'Không thể cập nhật!',

  // Creator Summary
  [ProfileKeys.Creator_Summary.title]: 'Tổng quan về người sáng tạo',
  [ProfileKeys.Creator_Summary.sub_title]:
    'Thông tin cơ bản về kênh của người sáng tạo',
  [ProfileKeys.Creator_Summary.package_for_other_platforms]:
    'Gói cho các nền tảng khác',

  // Basic Info
  [ProfileKeys.Creator_Summary.BasicInfo.hollow]: 'Đang chuẩn bị mạng xã hội',

  // Tooltip
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers]: 'Người đăng ký',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers]: 'Người theo dõi',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos]: 'Tổng số Video',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets]:
    'Tổng số X bài viết',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts]:
    'Tổng số bài viết',

  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube]:
    'Ngày tham gia YouTube',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter]:
    'Ngày tham gia X',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram]:
    'Ngày tham gia Instagram',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok]:
    'Ngày tham gia TikTok',

  // Channel Contents
  [ProfileKeys.Channel_Contents.title]: 'Nội dung kênh',
  [ProfileKeys.Channel_Contents.sub_title]:
    'Các chỉ số đo lường thông minh sẽ đánh giá mức độ tương tác với nội dung',
  [ProfileKeys.Channel_Contents.views_to_subs]: 'Lượt xem của Subs',
  [ProfileKeys.Channel_Contents.views_to_followers]: 'Lượt xem của Followers',
  [ProfileKeys.Channel_Contents.reach_to_followers]:
    'Lượt tiếp cận của Followers',
  [ProfileKeys.Channel_Contents.est_engagement]: 'Ước tính tương tác',
  [ProfileKeys.Channel_Contents.channel_data]: 'Dữ liệu Kênh',
  [ProfileKeys.Channel_Contents.loading_post]: 'Đang tải lên bài đăng',

  [ProfileKeys.Channel_Contents.Twitter.feed]: 'X Feed',
  [ProfileKeys.Channel_Contents.Twitter.loading]: 'Đang tải X bài viết',
  [ProfileKeys.Channel_Contents.Twitter.most_popular]:
    'X Bài viết phổ biến nhất',
  [ProfileKeys.Channel_Contents.Tiktok.loading]: 'Đang tải lên TikTok',

  [ProfileKeys.Channel_Contents.Filter._1_month]: '1 Tháng',
  [ProfileKeys.Channel_Contents.Filter._3_months]: '3 Tháng',
  [ProfileKeys.Channel_Contents.Filter._1_year]: '1 Năm',
  [ProfileKeys.Channel_Contents.Filter.newest]: 'Mới nhất',
  [ProfileKeys.Channel_Contents.Filter.most_viewed]: 'Nhiều lượt xem nhất',
  [ProfileKeys.Channel_Contents.Filter.most_liked]: 'Nhiều lượt thích nhất',
  [ProfileKeys.Channel_Contents.Filter.most_commented]: 'Nhiều bình luận nhất',

  // Channel Contents Tooltip for YouTube
  [ProfileKeys.Channel_Contents.Tooltip.youtube]: `
    Phân tích ở đây dựa trên <br>
    dữ liệu từ 1~6 tháng qua. <br>
    Video được chọn lọc bao gồm nội dung <br>
    được tải lên từ 1 năm trước.`,
  [ProfileKeys.Channel_Contents.Tooltip.twitter]: `
    Chúng tôi sử dụng ước lượng cho một <br>
    số dữ liệu bao gồm tương tác cho X. <br>
    Có thể có sự khác biệt với dữ liệu <br>
    thời gian thực. Phân tích ở đây dựa <br>
    trên dữ liệu từ 1~3 tháng qua.`,
  [ProfileKeys.Channel_Contents.Tooltip.instagram]: `
    Thống kê này dựa trên dữ liệu <br>
    trong khoảng 1-3 tháng qua.`,
  [ProfileKeys.Channel_Contents.Tooltip.tiktok]: `
    Thống kê này dựa trên dữ liệu <br>
    trong khoảng 1-3 tháng qua.`,

  // Other Channel Contents Tooltips
  [ProfileKeys.Channel_Contents.Tooltip.likes]: 'Lượt thích trên mỗi lượt xem',
  [ProfileKeys.Channel_Contents.Tooltip.comments]:
    'Lượt bình luận trên mỗi lượt xem',
  [ProfileKeys.Channel_Contents.Tooltip.x_reposts]:
    'X đăng lại trên mỗi lượt xem',
  [ProfileKeys.Channel_Contents.Tooltip.shares]: 'Lượt share trên mỗi lượt xem',

  [ProfileKeys.Channel_Contents.Tooltip.views_subs_rating]:
    'Lượt xem trên mỗi người đăng ký',
  [ProfileKeys.Channel_Contents.Tooltip.views_follower_rating]:
    'Lượt xem trên mỗi người theo dõi',
  [ProfileKeys.Channel_Contents.Tooltip.engagements]:
    'Đánh giá tương tác tổng thể',

  // Channel Analytics
  [ProfileKeys.Channel_Analytics.title]: 'Phân tích Kênh',
  [ProfileKeys.Channel_Analytics.sub_title]:
    'Đo lường hiệu suất tổng thể thông qua các chỉ số kênh',
  [ProfileKeys.Channel_Analytics.updated_on]: 'Cập nhật vào ngày {{date}}',
  [ProfileKeys.Channel_Analytics.avg_views]: 'Lượt xem trung bình',
  [ProfileKeys.Channel_Analytics.avg_impressions]: 'Lượt hiển thị trung bình',
  [ProfileKeys.Channel_Analytics.avg_reach]: 'Lượt tiếp cận trung bình',
  [ProfileKeys.Channel_Analytics.avg_likes]: 'Lượt thích trung bình',
  [ProfileKeys.Channel_Analytics.avg_x_reposts]: 'Đăng lại trung bình',
  [ProfileKeys.Channel_Analytics.engagements]: 'Tương tác',

  // Views Analysis
  [ProfileKeys.Channel_Analytics.Views_Analysis.title]: 'Phân Tích Lượt Xem',
  [ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram]:
    'Phân tích lượt tiếp cận',
  [ProfileKeys.Channel_Analytics.Views_Analysis.periodic]: 'Định kỳ',
  [ProfileKeys.Channel_Analytics.Views_Analysis.total]: 'Tổng cộng',

  // Subscribers Data
  [ProfileKeys.Channel_Analytics.Subscribers_Data.title]:
    'Dữ Liệu Người Đăng Ký',

  // Followers Data
  [ProfileKeys.Channel_Analytics.Followers_Data.title]:
    'Dữ Liệu Người Theo Dõi',

  // Audience Analytics
  [ProfileKeys.Audience_Analytics.title]: 'Phân Tích Đối Tượng Khán Giả',
  [ProfileKeys.Audience_Analytics.sub_title]:
    'Phân tích dữ liệu lớn cho người xem mục tiêu của kênh',
  [ProfileKeys.Audience_Analytics.preparing_data]: 'Đang Chuẩn Bị Dữ Liệu',

  // Audience Interest
  [ProfileKeys.Audience_Analytics.Audience_Interest.title]:
    'Sở Thích Của Đối Tượng Khán Giả',
  [ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags]:
    'Thẻ Phổ Biến',
  [ProfileKeys.Audience_Analytics.Keyword_Cloud.title]: 'Đám Mây Từ Khóa',
  [ProfileKeys.Audience_Analytics.Top_10.title]: 'Top 10 Từ Khóa',

  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title]:
    'Kiểm tra gói của tôi',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description]: `
    Vui lòng kiểm tra và nâng cấp gói của bạn <br>
    để truy cập được nhiều dữ liệu khán giả hơn.`,

  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.title]:
    'Yêu cầu đăng nhập',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.description]: `
    Vui lòng đăng nhập trước để truy cập <br>
    dữ liệu đối tượng khán giả của người dùng.`,

  // Review
  [ProfileKeys.Review.review_as_client]: 'Đánh giá như Khách hàng',
  [ProfileKeys.Review.most_recent]: 'Mới nhất',
  [ProfileKeys.Review.most_helpful]: 'Được đề xuất',
  [ProfileKeys.Review.no_reviews]: 'Không có đánh giá để hiển thị',
  [ProfileKeys.Review.recent_clients]: 'KHÁCH HÀNG GẦN ĐÂY',

  [ProfileKeys.Review.star]: 'Sao',
  [ProfileKeys.Review.stars]: 'Sao',

  // Edit
  [ProfileKeys.Edit.Toast.being_saved]: 'Hồ sơ của bạn đang được lưu.',
  [ProfileKeys.Edit.Toast.saved]: 'Hồ sơ của bạn đã được lưu thành công!',
  [ProfileKeys.Edit.Toast.failed]: 'Lưu hồ sơ thất bại. Vui lòng thử lại sau.',

  // Package
  [ProfileKeys.Edit.Package.complete_percentage]:
    'Gói này hoàn thành {{number}}%',

  [ProfileKeys.Edit.Package.Topbar.tooltip]: `
    Gói sẽ xuất hiện trên trang <br>
    cá nhân của bạn khi hoàn tất.`,

  [ProfileKeys.Edit.Package.Topbar.Representative.tooltip]: `
    Gói mặc định sẽ được <br>
    sử dụng để làm nổi bật dịch <br>
    vụ của bạn đối với khách hàng.`,

  [ProfileKeys.Edit.Package.Topbar.Completion.tooltip]: `
    Vui lòng hoàn thành gói<br>
    để chọn làm mặc định.`,

  // Banner
  [ProfileKeys.Edit.Package.Banner.heading]:
    'Đề xuất 1920X318 / Kích thước tối đa 20MB',
  [ProfileKeys.Edit.Package.Banner.mobile_heading]:
    'Kích thước tối đa 20 MB / banner 1920X318',
  [ProfileKeys.Edit.Package.Banner.tooltip]: `
    Thêm hiệu ứng đổ bóng lên <br>
    hình ảnh banner của bạn.`,

  // Options
  [ProfileKeys.Edit.Package.Options.intro_and_closing]: 'Giới thiệu & Kết thúc',
  [ProfileKeys.Edit.Package.Options.product_review]: 'Đánh giá Sản phẩm',
  [ProfileKeys.Edit.Package.Options.visit_review]: 'Đánh giá Trải nghiệm',
  [ProfileKeys.Edit.Package.Options.digital_product_review]:
    'Đánh giá Sản phẩm Kỹ thuật số',
  [ProfileKeys.Edit.Package.Options.brand_ambassador]: 'Đại sứ Thương hiệu',
  [ProfileKeys.Edit.Package.Options.short_video]: 'Video Ngắn',
  [ProfileKeys.Edit.Package.Options.product_placement]: 'Đặt Sản phẩm',
  [ProfileKeys.Edit.Package.Options.interview]: 'Phỏng vấn',
  [ProfileKeys.Edit.Package.Options.commercial_break]: 'Giờ Quảng cáo',
  [ProfileKeys.Edit.Package.Options.live_streaming]: 'Phát trực tiếp',
  [ProfileKeys.Edit.Package.Options.giveaways]: 'Quà Tặng',
  [ProfileKeys.Edit.Package.Options.meme_and_artwork]:
    'Meme / Tác phẩm Nghệ thuật',
  [ProfileKeys.Edit.Package.Options.interview_and_ama]: 'Phỏng vấn / AMA',

  // Title
  [ProfileKeys.Edit.Package.Title.use_a_template]: 'Sử dụng mẫu',
  [ProfileKeys.Edit.Package.Title.placeholder]: 'Nhập tiêu đề gói',
  [ProfileKeys.Edit.Package.Title.placeholder2]:
    'Nhập tiêu đề gói của bạn tại đây.',

  // Featured Image
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.title]:
    'Hình đại diện Nổi bật',
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip]: `
    Chúng tôi khuyến khích việc sử dụng hình ảnh đại diện <br>
    YouTube thể hiện đúng phong cách kênh của bạn`,

  [ProfileKeys.Edit.Package.Featured_Image.title]: 'Hình ảnh Nổi bật',
  [ProfileKeys.Edit.Package.Featured_Image.placeholder]:
    'Chỉ cho phép tệp JPG, JPEG, PNG. 16:9 được khuyến nghị. Tối đa 200 MB.',
  [ProfileKeys.Edit.Package.Featured_Image.tooltip]: `
    Hình ảnh này sẽ được sử dụng làm hình <br>
    thu nhỏ đại diện cho gói sản phẩm.`,

  [ProfileKeys.Edit.Package.Featured_Image.Error.file_upload]: `
    Một tệp vẫn đang được tải lên. <br>
    Vui lòng thử lại sau.`,
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_large]:
    'Kích thước tệp quá lớn. (Tối đa ',
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid]:
    'Định dạng tệp không hợp lệ. (Vui lòng sử dụng JPG, JPEG, PNG)',

  // Sample Contents
  [ProfileKeys.Edit.Package.Sample_Contents.title]: 'Mẫu Nội dung',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube]:
    'Sao chép và dán liên kết video',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter]:
    'Sao chép và dán liên kết X',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram]:
    'Sao chép và dán liên kết bài đăng',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok]:
    'Sao chép và dán liên kết TikTok',

  [ProfileKeys.Edit.Package.Sample_Contents.add_more_contents]:
    'Thêm nội dung khác',
  [ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link]:
    'Liên kết không hợp lệ',

  // Description
  [ProfileKeys.Edit.Package.Description.placeholder]: `
    Vui lòng mô tả chi tiết dịch vụ của bạn ở đây. <br>
    Khách hàng có thể tìm thông tin về, <br><br>
    <ul>
      <li>Nội dung hoặc dịch vụ chính</li>
      <li>Đối tượng khách hàng</li>
      <li>Lịch trình</li>
    </ul>`,
  [ProfileKeys.Edit.Package.Description.placeholder_gpt]:
    'Bắt đầu nhập một câu và ChatGPT sẽ gợi ý phần còn lại.',

  [ProfileKeys.Edit.Package.Description.ChatGPT.title]:
    'Tạo một gói dịch vụ độc đáo với ChatGPT',
  [ProfileKeys.Edit.Package.Description.ChatGPT.tooltip]: `
    Chọn cách mô tả dịch vụ của bạn tốt nhất.`,

  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title]:
    'Thông tin Bổ sung',
  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder]:
    'Nhập bất kỳ thông tin bổ sung nào cần có trong mô tả.',

  // Price
  [ProfileKeys.Edit.Package.Price.placeholder]: 'Nhập giá',
  [ProfileKeys.Edit.Package.Price.tooltip]: `
    Kết nối với các công ty bằng nhiều tùy chọn giá khác nhau <br>
    Bạn có thể hỏi trực tiếp mà không cần hiển thị giá <br>
    hoặc chỉ hiển thị giá cho các công ty được chứng nhận.`,

  [ProfileKeys.Edit.Package.Price.Type.open]:
    'Giá công khai - Hiển thị cho tất cả khách hàng',
  [ProfileKeys.Edit.Package.Price.Type.unlockable]:
    'Giá mở khóa - Chỉ hiển thị khách hàng đã được xác minh',
  [ProfileKeys.Edit.Package.Price.Type.contact_me]:
    'Liên hệ với tôi - Không cần giá',

  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title]:
    'Cần xác minh để gửi tin nhắn',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description]: `
    Để gửi tin nhắn cho nhiều nhà sáng tạo hơn, bạn cần hoàn thành một quy trình xác minh đơn giản. Quy trình xác minh sẽ mất khoảng 1-2 phút. Vui lòng thử lại sau khi đã xác minh. <br><br> 
    Ngày cập nhật tiếp theo: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title]:
    'Để gửi nhiều tin nhắn hơn, vui lòng nâng cấp gói dịch vụ',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description]: `
    Để gửi tin nhắn cho các nhà sáng tạo, bạn cần nâng cấp gói dịch vụ. <br> 
    Kiểm tra gói dịch vụ hiện tại của bạn và thử lại. <br><br> 
    Ngày cập nhật tiếp theo: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title]:
    'Cần xác minh để mở khóa giá',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description]: `
    Để mở khóa nhiều giá hơn, bạn cần hoàn thành một quy trình xác minh đơn giản. Quy trình xác minh sẽ mất khoảng 1-2 phút. Vui lòng thử lại sau khi đã xác minh. <br><br> 
    Ngày cập nhật tiếp theo: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title]:
    'Vui lòng nâng cấp gói của bạn để mở khóa thêm giá',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
    .description]: `
    Ngày gia hạn tiếp theo của bạn: {{date}} <br> 
    Vui lòng nâng cấp gói của bạn để mở khóa các giá này.`,
  [ProfileKeys.Edit.Package.Price.Modal.btn]: 'Nâng cấp ngay',

  [ProfileKeys.Edit.Package.Price.toast]: `
    Mở khóa thành công! (còn {{number}} lần)`,

  [ProfileKeys.Edit.Package.Price.Error.minimum]: 'Giá tối thiểu là {{number}}',
  [ProfileKeys.Edit.Package.Price.Error.maximum]: 'Giá tối đa là {{number}}',

  // Contents
  [ProfileKeys.Edit.Package.Contents.YouTube.video]: 'Video trên YouTube',
  [ProfileKeys.Edit.Package.Contents.YouTube.shorts]: 'YouTube Shorts',
  [ProfileKeys.Edit.Package.Contents.YouTube.live]: 'Trực tiếp trên YouTube',

  [ProfileKeys.Edit.Package.Contents.Twitter.post]: 'Bài đăng trên X',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_post]:
    'Bài đăng trên Instagram',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_story]:
    'Instagram Story',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels]:
    'Instagram Reels',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_live]:
    'Trực tiếp trên Instagram',

  [ProfileKeys.Edit.Package.Contents.Tiktok.video]: 'Video trên TikTok',
  [ProfileKeys.Edit.Package.Contents.Tiktok.live]: 'Trực tiếp trên TikTok',

  // Delivery
  [ProfileKeys.Edit.Package.Delivery.placeholder]:
    'Nhập thời gian giao hàng (7~60 ngày)',
  [ProfileKeys.Edit.Package.Delivery.input]: ' Ngày giao hàng',
  [ProfileKeys.Edit.Package.Delivery.tooltip]: `
    Chọn thời gian bạn cần để <br> 
    chuẩn bị giao hàng đầy đủ.`,

  // Revisions
  [ProfileKeys.Edit.Package.Revisions.no_revisions]: 'Không chỉnh sửa',
  [ProfileKeys.Edit.Package.Revisions.tooltip]: `
    Số lượng bản sửa đổi bạn sẽ <br>
    cung cấp trong các đơn hàng.`,

  // Type/Length
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    'ví dụ: Video đánh giá sản phẩm từ 5~10 phút',

  // User Info
  // Username
  [ProfileKeys.Edit.User_Info.Username.placeholder]: 'Nhập tên người dùng',
  [ProfileKeys.Edit.User_Info.Username.Error.enter_name]: 'Vui lòng nhập tên',
  [ProfileKeys.Edit.User_Info.Username.Error.max_50]: 'Tối đa 50 ký tự',
  [ProfileKeys.Edit.User_Info.Username.Error.max_30]: 'Nhập từ 3~30 ký tự',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol]:
    'Ký tự hoặc dấu không hợp lệ',
  [ProfileKeys.Edit.User_Info.Username.Error.max_2000]: 'Tối đa 2000 ký tự',
  [ProfileKeys.Edit.User_Info.Username.Error.max_100]: 'Tối đa 100 ký tự',
  [ProfileKeys.Edit.User_Info.Username.Error.max_500]: 'Tối đa 500 ký tự',
  [ProfileKeys.Edit.User_Info.Username.Error.max_200]: 'Tối đa 200 ký tự',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_price]: 'Giá không hợp lệ',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time]:
    'Thời gian giao hàng không hợp lệ',
  [ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60]:
    'Thời gian giao hàng phải từ 1 đến 60 ngày',

  // My URL
  [ProfileKeys.Edit.User_Info.My_URL.placeholder]: 'Nhập URL của bạn',

  [ProfileKeys.Edit.User_Info.My_URL.Error.char_limit]: 'Nhập từ 3~30 ký tự',
  [ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol]:
    'Ký tự hoặc dấu không hợp lệ',
  [ProfileKeys.Edit.User_Info.My_URL.Error.required_handle]:
    'Vui lòng nhập URL',
  [ProfileKeys.Edit.User_Info.My_URL.Error.already_taken]:
    'URL này đã được sử dụng',

  // Social Network
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.priority]:
    'Không thể thay đổi ưu tiên',
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink]:
    'Không thể huỷ kết nối mạng xã hội',

  [ProfileKeys.Edit.User_Info.Social_Network.Popup.title]:
    'Vui lòng hoàn thành đơn hàng của bạn trước',
  [ProfileKeys.Edit.User_Info.Social_Network.Popup.description]:
    'Sau khi hủy hoặc hoàn thành đơn hàng đang diễn ra, bạn có thể bỏ liên kết tài khoản mạng xã hội đã xác minh của mình.',

  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.title]:
    'Bạn có chắc chắn muốn huỷ kết nối tài khoản này không?',
  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.description]:
    'Khi bạn hủy liên kết tài khoản mạng xã hội đã xác minh, dữ liệu liên quan có thể bị mất. Hơn nữa, thông tin gói dịch vụ liên quan sẽ không còn xuất hiện trên hồ sơ của bạn và trong tìm kiếm của chúng tôi nữa.',

  // Bio
  [ProfileKeys.Edit.User_Info.Bio.placeholder_creator]:
    'Chia sẻ câu chuyện của bạn với các thương hiệu và khách hàng toàn cầu!',
  [ProfileKeys.Edit.User_Info.Bio.placeholder_client]:
    'Chia sẻ câu chuyện của bạn với các người sáng tạo toàn cầu!',

  // Categories
  [ProfileKeys.Edit.User_Info.Categories.add_a_category]: 'Thêm một danh mục',

  // Target Countries
  [ProfileKeys.Edit.User_Info.Target_Countries.title]: 'Quốc gia',
  [ProfileKeys.Edit.User_Info.Target_Countries.add_a_country]:
    'Thêm một quốc gia',
  [ProfileKeys.Edit.User_Info.Target_Countries.tooltip]: `
    Vui lòng chọn quốc gia nơi bạn hiện đang cư trú <br>
    hoặc đất nước nơi bạn sinh ra. (Chọn tối đa 2 quốc gia).`,

  // Languages
  [ProfileKeys.Edit.User_Info.Languages.add_a_language]: 'Thêm một ngôn ngữ',
  [ProfileKeys.Edit.User_Info.Website.placeholder]: 'Sao chép và dán một URL',

  // Company
  [ProfileKeys.Edit.User_Info.Company.placeholder]: 'Nhập tên công ty',
  [ProfileKeys.Edit.User_Info.Company.tooltip]: `
    Tải lên logo của công ty bạn <br>
    trong định dạng PNG, JPG, JPEG. <br>
    360x360 được khuyến nghị. <br>
    Kích thước tối đa là 50 MB.`,
  [ProfileKeys.Edit.User_Info.Company.Error.max_30]: 'Tối đa 30 ký tự',

  // Modal
  [ProfileKeys.Modal.Request_Price.title]: 'Yêu cầu giá',
  [ProfileKeys.Modal.Request_Price.btn]: 'Gửi yêu cầu',
  [ProfileKeys.Modal.Request_Price.toast]: 'Đã gửi thành công yêu cầu của bạn!',
  [ProfileKeys.Modal.Request_Price.Message.title]: 'Gửi tin nhắn',
  [ProfileKeys.Modal.Request_Price.Message.placeholder]:
    'Đặt câu hỏi hoặc viết lại thông tin chi tiết dự án của bạn (kinh phí, thông tin sản phẩm, hạng mục yêu cầu, v.v..)',

  [ProfileKeys.Modal.Edit.Banner.heading]: 'Chọn một phương pháp',
  [ProfileKeys.Modal.Edit.Banner.body]: 'Tải lên một hình ảnh',
  [ProfileKeys.Modal.Edit.Banner.loading]: 'Đang tải từ',
  [ProfileKeys.Modal.Edit.Banner.use_my]:
    'Sử dụng {{platform}} {{type}} của tôi',
  [ProfileKeys.Modal.Edit.Banner.not_found]:
    '{{platform}} {{type}} không tìm thấy',

  [ProfileKeys.Modal.Edit.Incomplete_Package.title]:
    'Phát hiện gói chưa hoàn chỉnh',
  [ProfileKeys.Modal.Edit.Incomplete_Package.description]: `
    Các gói dịch vụ sau đây chưa hoàn chỉnh: <br><br>
    <package></package> <br>
    Hoàn thành các gói này để hiển thị chúng trong kết quả tìm kiếm và trên hồ sơ của bạn. Bạn có muốn hoàn thành chúng ngay bây giờ không?`,

  [ProfileKeys.Modal.Edit.new_reward]: 'Bạn đã mở khóa một phần thưởng mới.',
  [ProfileKeys.Modal.Edit.skip_for_now]: 'Bỏ qua tạm thời',

  [ProfileKeys.Modal.Reconnection.title]: 'Yêu cầu kết nối lại',
  [ProfileKeys.Modal.Reconnection.description]: `
    Do không hoạt động trong thời gian dài hoặc cập nhật chính sách và chức năng trên mỗi nền tảng mạng xã hội, cần phải kết nối lại cho các nền tảng được liệt kê dưới đây. Vui lòng kết nối lại hoặc ngắt kết nối nền tảng tương ứng để tiếp tục. <br><br>
    MegaEvolution hỗ trợ kết nối an toàn thông qua API được xác minh chính thức và đối tác từ Google, Meta (Instagram) và TikTok.`,
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title]:
    'Sau khi ngắt kết nối tài khoản hiện tại của bạn, bạn có thể kết nối một tài khoản mới',
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description]:
    'Phát hiện một tài khoản khác với tài khoản hiện đang kết nối. Để kết nối một tài khoản mới, vui lòng ngắt kết nối tài khoản hiện có và thử lại.',

  // Guide
  [ProfileKeys.Guide.hi_ginseng_mega]: 'Xin chào, tôi là MEGA!',
  [ProfileKeys.Guide.nice_to_meet_you]: 'Rất vui được gặp bạn~!',
  [ProfileKeys.Guide.create_unique_url]: 'Tạo một URL duy nhất',
  [ProfileKeys.Guide.we_help_you_receive]: 'Chúng tôi giúp bạn nhận được',
  [ProfileKeys.Guide.we_help_you_receive_mobile]:
    'Chúng tôi giúp bạn nhận được đề xuất',
  [ProfileKeys.Guide.proposal_golbal_brands]: 'từ các thương hiệu toàn cầu!',
  [ProfileKeys.Guide.proposal_golbal_brands_mobile]:
    'từ các thương hiệu toàn cầu!',
  [ProfileKeys.Guide.create_unique_portfolio]: 'Tạo một hồ sơ độc đáo',
  [ProfileKeys.Guide.just_few_clicks]: 'chỉ trong vài cú nhấp chuột!',
  [ProfileKeys.Guide.click_to_join]: 'Nhấp để tham gia',
  [ProfileKeys.Guide.click_to_join_mobile]: 'Nhấp vào đây',
  [ProfileKeys.Guide.anytime]: 'bất kỳ lúc nào!',
  [ProfileKeys.Guide.anytime_mobile]: 'để tham gia bất kỳ lúc nào!',
  [ProfileKeys.Guide.what_a_pleasure]: 'Thật là vui được gặp bạn hôm nay!',
  [ProfileKeys.Guide.hope_to_see]: 'Hy vọng sớm được xem trang của bạn!',
  [ProfileKeys.create_unique_handle]: 'Tạo URL của bạn với @handle duy nhất!',

  // Profile
  [ProfileKeys.Guide.Profile.welcome]: 'Chào mừng, tôi là MEGA!',
  [ProfileKeys.Guide.Profile.portfolio_space]: 'Đây là không gian hồ sơ',
  [ProfileKeys.Guide.Profile.specially_created]:
    'được tạo đặc biệt dành cho bạn.',
  [ProfileKeys.Guide.Profile.click_here_to_start_editing]:
    'Nhấp vào đây để bắt đầu chỉnh sửa.',
  [ProfileKeys.Guide.Profile.package_will_show_up]:
    'Gói dịch vụ của bạn sẽ xuất hiện ở đây!',
  [ProfileKeys.Guide.Profile.click_here]: 'Nhấp vào đây',
  [ProfileKeys.Guide.Profile.to_start_editing]: 'để bắt đầu chỉnh sửa',
  [ProfileKeys.Guide.Profile.package_will_be_displated]:
    'Gói dịch vụ của bạn sẽ được hiển thị ở đây!',

  // Profile Edit
  [ProfileKeys.Guide.Profile_Edit.welcome]:
    'Chào mừng bạn đến với hồ sơ của bạn!',
  [ProfileKeys.Guide.Profile_Edit.try_me]:
    'Hãy thử với tôi để thiết lập nhanh chóng',
  [ProfileKeys.Guide.Profile_Edit.compelling_package]:
    'một gói dịch vụ hấp dẫn',
  [ProfileKeys.Guide.Profile_Edit.upload_image]: 'Tải lên hình ảnh để',
  [ProfileKeys.Guide.Profile_Edit.showcase_service]:
    'giới thiệu dịch vụ của bạn',
  [ProfileKeys.Guide.Profile_Edit.add_post_video]:
    'Thêm liên kết bài đăng/video',
  [ProfileKeys.Guide.Profile_Edit.for_your_clients]: 'cho khách hàng của bạn',
  [ProfileKeys.Guide.Profile_Edit.set_up_price]:
    'Thiết lập giá trong các chế độ khác nhau.',
  [ProfileKeys.Guide.Profile_Edit.choose_best_option]:
    'Chọn tùy chọn tốt nhất cho gói dịch vụ của bạn!',
  [ProfileKeys.Guide.Profile_Edit.complete_package]:
    'Sau khi bạn hoàn thành gói nó sẽ được giới thiệu',
  [ProfileKeys.Guide.Profile_Edit.make_it_public]:
    'với các công ty từ hơn 128 quốc gia!',
  [ProfileKeys.Guide.Profile_Edit.save_description1]:
    'Hãy chắc chắn lưu trước khi bạn rời đi!',
  [ProfileKeys.Guide.Profile_Edit.save_description2]: '',
  [ProfileKeys.Guide.Profile_Edit.save_description3]: '',
  [ProfileKeys.Guide.Profile_Edit.create_unique_package]:
    'Tạo một hồ sơ duy nhất cho khách hàng toàn cầu!',
  [ProfileKeys.Guide.Profile_Edit.quick_setup]: 'Thiết lập nhanh chóng',

  [ProfileKeys.Guide.Profile_Edit.Toast.register_success]:
    'Đăng ký thành công! Vui lòng thiết lập hồ sơ để nhận đơn hàng.',
};
