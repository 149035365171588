import 'dayjs/locale/ja';

const jaLocale = {
  name: 'ja',
  relativeTime: {
    future: '%s後',
    past: '%s前',
    s: '1分',
    m: '1分',
    mm: '%d分',
    h: '1時間',
    hh: '%d時間',
    d: '1日',
    dd: '%d日',
    M: '1ヶ月',
    MM: '%dヶ月',
    y: '1年',
    yy: '%d年',
  },
  dateFormat: {
    dot_date: 'YYYY.MM.DD',
    dash_date: 'YYYY-MM-DD',
    dot_date_time_12: 'YYYY.MM.DD、Ah:mm',
    time_12: 'Ah:mm',
    abbr_date_time_12: 'M[月]D[日]、Ah:mm',
    date_time: 'YYYY[年]M[月]D[日]',
    yesterday: '昨日',
    full_abbr_date_time_12: 'YYYY[年]M[月]D[日]、Ah:mm',
  },
  meridiem: (hour) => {
    return hour > 12 ? '午後' : '午前';
  },
};

export default jaLocale;
