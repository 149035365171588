import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: '自动翻译',
  [AutoTranslationKeys.Dropdown.description]: '自动翻译描述和用户编写的文本。',
  [AutoTranslationKeys.Dropdown.order_message_title]: '订单 · 消息翻译',
  [AutoTranslationKeys.Dropdown.order_message_title_mobile]: '订单 · 消息翻译',
  [AutoTranslationKeys.Dropdown.order_message_description]:
    '剩余{{number}}个字符 · 更新于{{date}}',
  [AutoTranslationKeys.Dropdown.order_message_description_mobile]: `
    剩余{{number}}个字符 <br> 
    更新于{{date}}`,

  [AutoTranslationKeys.Applying_Translation.title]: '已应用自动翻译。',
  [AutoTranslationKeys.Applying_Translation.btn]: '显示原始文本',
  [AutoTranslationKeys.Applying_Translation.message]: '已翻译',

  [AutoTranslationKeys.Displaying_Original.title]: '显示原始文本。',
  [AutoTranslationKeys.Displaying_Original.btn]: '翻译',
  [AutoTranslationKeys.Displaying_Original.message]: '原始文本',
};
