import styled from 'styled-components';
import IndigoButton from '../Buttons/IndigoButton';
import Heading from '../Typography/Heading';
import Text from '../Typography/Text';
import IconButton from '../Buttons/IconButton';
import { CloseIcon } from 'assets/icons';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import TextField from '../../Basics/Inputs/TextField';
import Stack from '../Layout/Stack';
import GrayButton from '../Buttons/GrayButton';
import { createPortal } from 'react-dom';
import { useRef } from 'react';
import device from 'styles/device';
import { useEvent } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { useTranslation } from 'react-i18next';

/**
 * @param {boolean} show - Whether to show the modal or not
 * @param {function} onClose - Function to call when the modal is closed
 * @param {string} title - The title of the modal
 * @param {string} description - The description of the modal
 * @param {string} buttonText - The text of the button
 * @param {function} onButtonClick - Function to call when the button is clicked
 * @param {string} secondaryButtonText - The text of the secondary button
 * @param {function} onSecondaryButtonClick - Function to call when the secondary button is clicked
 * @param {boolean} showCloseButton - Whether to show the close button or not
 * @param {Node} children - Any additional content to render between description and button

 */
const Popup = ({
  show,
  onClose = () => {},
  title = '',
  description = '',
  buttonText = GeneralKeys.close,
  onButtonClick,
  disableButton = false,
  secondaryButtonText = '',
  onSecondaryButtonClick,
  disableSecondaryButton = false,
  showCloseButton = false,
  disableOutsideClick = false,
  children,
  buttonStyle,
  noButton = false,
  buttonType = 'submit',
  onCloseAtEnter = false,
  noGap = false,
  inputFieldVisible = false,
  inputValue,
  inputError,
  inputErrorValue,
  inputOnChange,
  inputPlaceholder,
  onOuterBounderyClick: onOuterBoundaryClick,
  padding,
  asPage = false,
  className,
  containerRadius,
  dividerBelowTitle = false,
}) => {
  const { isMobile } = useResponsiveScreen();
  const modalRoot = useRef(document.getElementById('root'));
  const { t } = useTranslation();
  useEvent('keydown', (e) => {
    if (e.keyCode === 27 || (onCloseAtEnter && e.keyCode === 13)) {
      onClose();
    }
  });

  useEvent('popstate', onClose);

  return createPortal(
    <AnimatePresence>
      {show && (
        <>
          <Backdrop
            key="backdrop"
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          />

          <Container
            key="container"
            variants={modalContentVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={() => {
              if (disableOutsideClick) return;
              onClose();
            }}
          >
            <ModalWrapper>
              <Modal
                isMobile={isMobile}
                radius={containerRadius}
                asPage={asPage}
                onClick={(e) => e.stopPropagation()}
                dividerBelowTitle={dividerBelowTitle}
              >
                {showCloseButton && (
                  <CloseButton onClick={onClose} isMobile={isMobile}>
                    <CloseIcon size={isMobile ? 0.5 : 1} />
                  </CloseButton>
                )}

                <ModalBody
                  isMobile={isMobile}
                  padding={padding}
                  className={'modal_body ' + className}
                >
                  <Stack
                    gap={noGap ? 0 : isMobile ? '1.25rem' : '2.75rem'}
                    className="text-center"
                  >
                    {title && (
                      <Stack gap="0.75rem">
                        <Heading
                          variant={
                            isMobile
                              ? dividerBelowTitle
                                ? 'SBH5'
                                : 'BH4'
                              : 'BH2'
                          }
                          className="m-0"
                        >
                          {typeof title === 'function' ? title() : title}
                        </Heading>
                        {dividerBelowTitle && <Divider />}
                      </Stack>
                    )}
                    {description && (
                      <Text
                        variant={isMobile ? 'xs' : 'md'}
                        className={`m-0 text-indigo-200 ${
                          isMobile ? 'mh12' : ''
                        }`}
                        style={{
                          lineHeight: isMobile && '20.5px',
                        }}
                      >
                        {description}
                      </Text>
                    )}

                    {inputFieldVisible && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: `${isMobile ? '0.25rem' : '0.625rem'}`,
                        }}
                      >
                        <TextField
                          variant={isMobile ? 'gray' : 'indigo'}
                          focusOutline={isMobile}
                          value={inputValue}
                          className="position-relative"
                          padding={isMobile ? '11.5px 24px' : null}
                          radius={isMobile ? 999 : 10}
                          borderWidth={isMobile ? '1.3px' : '2px'}
                          fontSize={isMobile ? 'h7' : ''}
                          placeholder={inputPlaceholder}
                          error={inputError}
                          onChange={(e) => {
                            inputOnChange(e);
                          }}
                        />
                        <ErrorText isMobile={isMobile}>
                          {inputErrorValue}
                        </ErrorText>
                      </div>
                    )}

                    {children}
                    {noButton ? (
                      <></>
                    ) : (
                      <Buttons>
                        {secondaryButtonText && isMobile && (
                          <GrayButton
                            variant="outline"
                            fontSize="16px"
                            padding="0.53125rem"
                            minWidth="8.5rem"
                            radius={isMobile && '999px'}
                            onClick={() => {
                              if (
                                onSecondaryButtonClick &&
                                typeof onSecondaryButtonClick === 'function'
                              ) {
                                onSecondaryButtonClick();
                              } else {
                                onClose();
                              }
                            }}
                            style={{
                              ...buttonStyle,
                              flexGrow: isMobile && secondaryButtonText ? 1 : 0,
                            }}
                            disabled={disableSecondaryButton}
                            type={buttonType}
                          >
                            {secondaryButtonText}
                          </GrayButton>
                        )}
                        <IndigoButton
                          variant="filled"
                          className={isMobile ? 'bh5 shadow-none' : 'btn2'}
                          padding={isMobile && '0.53125rem'}
                          radius={isMobile && '999px'}
                          onClick={() => {
                            if (
                              onButtonClick &&
                              typeof onButtonClick === 'function'
                            ) {
                              onButtonClick();
                            } else {
                              onClose();
                            }
                          }}
                          style={{
                            buttonStyle,
                            flexGrow: isMobile && secondaryButtonText ? 1 : 0,
                          }}
                          disabled={disableButton}
                          type={buttonType}
                          minWidth={isMobile && '136px'}
                          autoFocus
                        >
                          {typeof buttonText === 'string'
                            ? t(buttonText)
                            : buttonText}
                        </IndigoButton>
                        {secondaryButtonText && !isMobile && (
                          <IndigoButton
                            variant="outline"
                            className={isMobile ? 'bh5' : 'btn2'}
                            padding={isMobile && '0.53125rem'}
                            radius={isMobile && '999px'}
                            onClick={() => {
                              if (
                                onSecondaryButtonClick &&
                                typeof onSecondaryButtonClick === 'function'
                              ) {
                                onSecondaryButtonClick();
                              } else {
                                onClose();
                              }
                            }}
                            style={buttonStyle}
                            disabled={disableSecondaryButton}
                            type={buttonType}
                          >
                            {typeof secondaryButtonText === 'string'
                              ? t(secondaryButtonText)
                              : secondaryButtonText}
                          </IndigoButton>
                        )}
                      </Buttons>
                    )}
                  </Stack>
                </ModalBody>
              </Modal>
            </ModalWrapper>
          </Container>
        </>
      )}
    </AnimatePresence>,
    modalRoot.current
  );
};

export default Popup;

const backdropVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const modalContentVariants = {
  hidden: {
    opacity: 0,
    scale: 0.9,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  animation: fadeIn 200ms ease-in-out;
  display: block;
  margin: 0;
`;

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1060;
  display: block;
`;

const ModalWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

const Modal = styled.div`
  box-shadow: ${({ asPage }) => (asPage ? 'none' : 'var(--shadow-modal)')};
  border: none;
  border-radius: 1.25rem;
  max-width: ${({ asPage }) => (asPage ? '100%' : '750px')};
  width: 100%;
  height: ${({ asPage }) => (asPage ? '100vh' : 'auto')};
  margin: 0 auto;
  padding: ${({ asPage }) => (asPage ? '1.5rem 0 0 0' : '0')};
  background-color: var(--color-white);
  position: relative;

  @media ${device.sm} {
    border-radius: ${({ radius, asPage, dividerBelowTitle }) =>
      asPage
        ? '0'
        : radius
        ? radius
        : dividerBelowTitle
        ? '0.625rem'
        : '1.5rem'};
    max-width: ${({ asPage }) => (asPage ? '100%' : '328px')};
  }
`;

const CloseButton = styled(IconButton)`
  font-size: 18px;
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
  right: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
  cursor: pointer;
  z-index: 1;
`;

const ModalBody = styled.div`
  ${({ padding }) =>
    padding
      ? { padding: padding + ' !important' }
      : { padding: '4.375rem 4.375rem 3.125rem !important' }};

  @media (max-width: 769px) {
    ${({ padding }) =>
      padding
        ? { padding: padding + ' !important' }
        : { padding: '1.5rem 1.25rem !important' }};
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
  flex-wrap: wrap;

  @media (max-width: 769px) {
    flex-wrap: wrap-reverse;
    gap: 1rem;
  }
`;

const ErrorText = styled.p.attrs({
  className: 'text-red-500',
})`
  font-size: ${({ isMobile }) => (isMobile ? '8px' : '12px')};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--color-indigo-25);
`;
