import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: 'กำลังบันทึก',
  [ProposalKeys.changes_saved]: 'บันทึกการเปลี่ยนแปลงเรียบร้อยแล้ว',

  [ProposalKeys.New_Proposal.text]: 'ข้อเสนอใหม่',
  [ProposalKeys.New_Proposal.toast]:
    'คุณได้รับจำนวนข้อเสนอสูงสุดที่คุณสามารถสร้างได้แล้ว',

  [ProposalKeys.Clear.text]: 'ล้างทั้งหมด',
  [ProposalKeys.Clear.toast]: `
  ล้างเรียบร้อยแล้ว <button>คลิกที่นี่</button> เพื่อยกเลิกการเปลี่ยนแปลงนี้`,

  [ProposalKeys.Modal.Copy.title]: 'คุณแน่ใจหรือไม่ว่าต้องการเขียนทับข้อเสนอ?',
  [ProposalKeys.Modal.Copy.description]: `
    ข้อเสนอปัจจุบันจะถูกเขียนทับด้วยข้อเสนอที่เลือก<br>
    การกระทำนี้ไม่สามารถยกเลิกได้ คุณต้องการดำเนินการต่อหรือไม่?`,

  [ProposalKeys.Basic_Info.Project_Title.title]: 'หัวข้อ',
  [ProposalKeys.Basic_Info.Project_Title.placeholder]: 'กรอกชื่อโครงการ',
  [ProposalKeys.Basic_Info.Project_Title.error]: 'สูงสุด 100 ตัวอักษร',

  [ProposalKeys.Basic_Info.Website.title]: 'เว็บไซต์ผลิตภัณฑ์ · แบรนด์',
  [ProposalKeys.Basic_Info.Website.placeholder]: 'กรอก URL',
  [ProposalKeys.Basic_Info.Website.error]: 'สูงสุด 400 ตัวอักษร',

  [ProposalKeys.Basic_Info.Description.title]: 'รายละเอียดและไฟล์',
  [ProposalKeys.Basic_Info.Description.placeholder]: `
  โปรดอธิบายรายละเอียดของโครงการและแนบไฟล์ของคุณที่นี่ <br>
  ครีเอเตอร์อาจค้นหาข้อมูลเกี่ยวกับ, <br><br><br>
  <ul>
  <li>คำอธิบายของโครงการ/ผลิตภัณฑ์</li>
  <li>เป้าหมายของแคมเปญและกลุ่มเป้าหมาย</li>
  <li>ข้อแนะนำเนื้อหาอย่างละเอียด</li>
  </ul>`,
  [ProposalKeys.Basic_Info.Description.Snippet.title]: 'เลือกสไนป์เพื่อเพิ่ม',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name]:
    'ชื่อช่องโซเชียลของครีเอเตอร์',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_username]:
    'ชื่อผู้ใช้ของครีเอเตอร์',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_main_country]:
    'ประเทศหลักของครีเอเตอร์',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories]:
    'หมวดหมู่หลักของครีเอเตอร์',

  [ProposalKeys.Basic_Info.Description.Error.file_format]: `
  รูปแบบไฟล์ไม่ถูกต้อง (โปรดใช้ {{fileFormats}})`,
  [ProposalKeys.Basic_Info.Description.Error.file_size_limit]: `
  ขนาดไฟล์รวมสูงสุด {{maxSize}}`,
  [ProposalKeys.Basic_Info.Description.Error.max_file]: `
  คุณสามารถส่งไฟล์สูงสุด {{maxFiles}} ไฟล์`,

  [ProposalKeys.caution]: `
    เมื่อสั่งซื้อแพ็กเกจ ความต้องการทั้งหมดและภารกิจเนื้อหาที่เกี่ยวข้องจะถูก <strong>ปรับใช้โดยอัตโนมัติ</strong> ในแต่ละเนื้อหา หากมีการสั่งซื้อเนื้อหาที่ไม่สมบูรณ์หรือไม่เกี่ยวข้องกับเนื้อหาที่สั่งซื้อ จะไม่ถูกนำมาใช้.`,

  [ProposalKeys.Side_btn.text]: 'เนื้อหาที่เกี่ยวข้อง',
  [ProposalKeys.Side_btn.title]: 'เนื้อหาที่เกี่ยวข้อง: ',

  [ProposalKeys.Product_Delivery.title]: 'ส่งผลิตภัณฑ์ให้ครีเอเตอร์',

  [ProposalKeys.Product_Delivery.Delivery.title]:
    'ส่งสินค้าที่ได้รับการสนับสนุน',
  [ProposalKeys.Product_Delivery.Delivery.tooltip]: `
    ตัวเลือกนี้ไม่สมบูรณ์และจะไม่ <br>
    ถูกนำไปใช้กับข้อเสนอที่กำลังถูกส่ง.`,

  [ProposalKeys.Product_Delivery.Delivery.Image.title]:
    'รูปภาพการส่งสินค้า (ไม่บังคับ)',
  [ProposalKeys.Product_Delivery.Delivery.Image.description]:
    'เพิ่มรูปภาพไฟล์ได้สูงสุด 5 รูปในรูปแบบ JPG, JPEG, PNG, หรือ GIF ขนาดสูงสุด 100 MB',

  [ProposalKeys.Product_Delivery.Delivery.Info.title]:
    'ข้อมูลการส่งสินค้า (บังคับ)',
  [ProposalKeys.Product_Delivery.Delivery.Info.placeholder]:
    'โปรดให้ข้อมูลเกี่ยวกับผลิตภัณฑ์และการจัดส่ง',

  [ProposalKeys.Product_Delivery.Delivery.caution_due_date]:
    'คุณจะได้รับที่อยู่จัดส่งของครีเอเตอร์สำหรับการส่งสินค้า การสร้างเนื้อหาจะเริ่มต้นหลังจากที่สินค้ามาถึง และวันที่ครบกำหนดจะถูกอัพเดตตามนี้',
  [ProposalKeys.Product_Delivery.Delivery.caution_return_process]:
    'เราไม่ดูแลกระบวนการคืนสินค้าหลังจากจัดส่ง หากการสั่งซื้อของคุณถูกยกเลิกหลังจากสินค้าถูกจัดส่ง คุณจะต้องโต้สอบเรื่องการคืนโดยตรงกับครีเอเตอร์',
  [ProposalKeys.Product_Delivery.Delivery.caution_international_delivery]: `
    เราสนับสนุนการติดตามการจัดส่งระหว่างประเทศ แต่ไม่รับประกันหรือคุ้มครองปัญหาการจัดส่ง เช่น การส่งผิดที่อยู่
    <Tooltip>การส่งภายในประเทศ</Tooltip> สามารถติดตามได้เฉพาะในประเทศบางประเทศเท่านั้น แต่คุณยังคงสามารถรวมการส่งในคำสั่งได้`,
  [ProposalKeys.Product_Delivery.Delivery
    .caution_international_delivery_tooltip]: `
    เราสนับสนุนการติดตามการส่งในประเทศสำหรับผู้ให้บริการขนส่งระหว่างประเทศหลัก ในประเทศบางประเทศเท่านั้น อย่างเช่น สหรัฐอเมริกาและแคนาดา ณ ขณะนี้เราไม่สนับสนุนการติดตามการส่งในประเทศอื่น ๆ`,

  [ProposalKeys.Requirements.title]: 'ความต้องการ',
  [ProposalKeys.Requirements.applied]: '{{number}} ถูกนำไปใช้',

  [ProposalKeys.Requirements.Promotion.title]: 'ระบุการโฆษณาแบบจ่ายเงิน',
  [ProposalKeys.Requirements.Promotion.description_1]:
    'ครีเอเตอร์จะเพิ่มหนึ่งในแฮชแท็กต่อไปนี้: #Ad, #Advertisement หรือ #Sponsored ไว้ที่จุดเริ่มต้นของรายการแฮชแท็กเพื่อระบุการโฆษณาแบบจ่ายเงินโดยชัดเจน',
  [ProposalKeys.Requirements.Promotion.description_2]:
    'เพิ่มป้ายกำกับ "รวมการโฆษณาแบบจ่ายเงิน" ที่ YouTube ให้ภายในวิดีโอ',
  [ProposalKeys.Requirements.Promotion.description_3]:
    'เพิ่มป้ายกำกับ "ความร่วมมือทางการจ่ายเงิน" ที่ Instagram ให้ภายในโพสต์/สตอรี่/รีลส์ของ Instagram',

  [ProposalKeys.Requirements.Terms_Conditions.placeholder]:
    'กรุณากรอกข้อกำหนดและเงื่อนไขของคุณที่นี่',
  [ProposalKeys.Requirements.Terms_Conditions.description]:
    'ครีเอเตอร์จะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขของคุณในการให้บริการ สามารถร้องขอการแก้ไขหรือปิดการใช้งานตัวเลือกนี้ได้หากข้อกำหนดและเงื่อนไขของคุณขัดแย้งกับการให้บริการของครีเอเตอร์',

  [ProposalKeys.Checklist.title]: 'Lista de verificação',

  [ProposalKeys.Content_Missions.title]: 'ภารกิจเนื้อหา',

  [ProposalKeys.Content_Missions.Include_Link.title]: 'รวมลิงก์',
  [ProposalKeys.Content_Missions.Include_Link.placeholder]: 'กรอก URL',
  [ProposalKeys.Content_Missions.Include_Link.description]:
    'ลิงก์ที่ให้มาจะถูกรวมอยู่ในคำอธิบายของโพสต์หรือวิดีโอ ใช้เพื่อเพิ่มการเข้าชมเว็บไซต์หรือแอป',

  [ProposalKeys.Content_Missions.Include_Hashtags.title]: 'รวมแฮชแท็ก',
  [ProposalKeys.Content_Missions.Include_Hashtags.placeholder]: 'กรอกแฮชแท็ก',
  [ProposalKeys.Content_Missions.Include_Hashtags.btn]: 'เพิ่มแฮชแท็กนี้',
  [ProposalKeys.Content_Missions.Include_Hashtags.description]:
    'อย่างน้อยหนึ่งหรือมากกว่าแฮชแท็กจากด้านซ้ายจะถูกรวมในโพสต์หรือคำอธิบายวิดีโอ',

  [ProposalKeys.Content_Missions.Tag_Location.title]: 'แท็กสถานที่',
  [ProposalKeys.Content_Missions.Tag_Location.placeholder]: 'ป้อนสถานที่',
  [ProposalKeys.Content_Missions.Tag_Location.description]:
    'สถานที่ที่ระบุจะถูกแท็กในโพสต์หรือวิดีโอ มีประสิทธิภาพในการส่งเสริมสถานที่หรือสถานที่จัดกิจกรรมเฉพาะ',

  [ProposalKeys.Content_Missions.Tag_Account.title]: 'แท็กบัญชีของเรา',
  [ProposalKeys.Content_Missions.Tag_Account.placeholder]: 'ชื่อบัญชีของคุณ',
  [ProposalKeys.Content_Missions.Tag_Account.description]:
    'บัญชี @handle จะถูกรวมในโพสต์หรือคำอธิบายวิดีโอ (เฉพาะบัญชีที่เกี่ยวข้องกับแพลตฟอร์มของเนื้อหาที่สั่งซื้อเท่านั้น)',

  [ProposalKeys.Content_Missions.Pin_Comment_Account.title]:
    'ปักหมุดความเห็นด้วยบัญชีของเรา',
  [ProposalKeys.Content_Missions.Pin_Comment_Account.description]:
    'ความเห็นที่รวมถึงบัญชี @handle ที่ระบุจะถูกโพสต์และปักหมุดในเนื้อหา (เฉพาะบัญชีที่เกี่ยวข้องกับแพลตฟอร์มของเนื้อหาที่สั่งซื้อเท่านั้น)',

  [ProposalKeys.Content_Missions.Pin_Comment_Link.title]:
    'ปักหมุดความเห็นด้วยลิงก์',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.placeholder]: 'ป้อน URL',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.description]:
    'ความเห็นที่รวมถึงลิงก์ที่ระบุจะถูกโพสต์และปักหมุดในเนื้อหา',

  [ProposalKeys.Content_Missions.Include_Img.title]: 'รวมรูปภาพที่เฉพาะเจาะจง',
  [ProposalKeys.Content_Missions.Include_Img.description]:
    'ผู้สร้างจะถูกนำทางให้รวมรูปภาพที่ระบุให้อย่างน้อยหนึ่งรูปหรือมากกว่าในเนื้อหา',

  [ProposalKeys.Content_Missions.Tag_Video.title]: 'เพิ่มแท็กสำหรับวิดีโอ',
  [ProposalKeys.Content_Missions.Tag_Video.placeholder]: 'ป้อนแท็กวิดีโอ',
  [ProposalKeys.Content_Missions.Tag_Video.btn]: 'เพิ่มแท็กนี้',
  [ProposalKeys.Content_Missions.Tag_Video.description]:
    'อย่างน้อยหนึ่งหรือมากกว่าแท็กจากด้านซ้ายจะถูกเพิ่มในวิดีโอ YouTube เพื่อการค้นหาที่ดีขึ้น',

  // View
  [ProposalKeys.View.copied_to_clipboard]: '',
  [ProposalKeys.View.Top.visit_website]: '',
  [ProposalKeys.View.ProductDelivery.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.addressRequired]: '',
  [ProposalKeys.View.ProductDelivery.Physical.waitForProduct]: '',
  [ProposalKeys.View.Requirement.Tooltip.creator]: '',
  [ProposalKeys.View.Requirement.Tooltip.client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_creator]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.sponsored]: ``,
  [ProposalKeys.View.Requirement.Ad.Checklist.useFollowingMethod]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.applicableContents]: ``,
  [ProposalKeys.View.Requirement.Ad.All.description]: ``,
  [ProposalKeys.View.Requirement.Ad.YouTube.description]: ``,
  [ProposalKeys.View.Requirement.Ad.Instagram.description]: ``,
  [ProposalKeys.View.Requirement.Ad.TikTok.description]: ``,
  [ProposalKeys.View.Requirement.Terms_Conditions.title]: '',
  [ProposalKeys.View.Requirement.Terms_Conditions.Checklist
    .complyTermsConditions]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.creator]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.client]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Link.description]:
    '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Img.download]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.description]: '',
};
