import ProposalKeys from 'translations/translationKeys/ProposalPageKeys';

const SnippetOptions = {
  CREATOR_CHANNEL_NAME: 'creatorChannelName',
  CREATOR_USERNAME: 'creatorUsername',
  CREATOR_COUNTRY: 'creatorCountry',
  CREATOR_CATEGORIES: 'creatorCategories',
};

export const SnippetOptionsNames = {
  [SnippetOptions.CREATOR_CHANNEL_NAME]:
    ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name,
  [SnippetOptions.CREATOR_USERNAME]:
    ProposalKeys.Basic_Info.Description.Snippet.creator_username,
  [SnippetOptions.CREATOR_COUNTRY]:
    ProposalKeys.Basic_Info.Description.Snippet.creator_main_country,
  [SnippetOptions.CREATOR_CATEGORIES]:
    ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories,
};

export default SnippetOptions;
