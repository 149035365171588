import { GoogleTranslateIcon } from 'assets/icons';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import { useToggle } from 'react-use';
import { useCallback, useMemo } from 'react';
import Stack from '../Layout/Stack';
import LexicalEditor from '../LexicalEditor';

const QuotaTranslatedText = ({
  text,
  textFormatter,
  className,
  isDescription,
  editorProps,
  hideButton,
}) => {
  const [displayOriginalText, toggleDisplayOriginalText] = useToggle();
  const {
    getQuotaTranslatedText,
    getQuotaTranslatedDescription,
    translationQuota,
    openMessageQuotaEndedModal,
  } = useAutoTranslation();

  const displayText = useMemo(() => {
    return isDescription
      ? getQuotaTranslatedDescription(
          text,
          hideButton ? false : displayOriginalText,
          editorProps?.snippetData
        )
      : getQuotaTranslatedText(text, hideButton ? false : displayOriginalText);
  }, [
    text,
    displayOriginalText,
    getQuotaTranslatedText,
    getQuotaTranslatedDescription,
    isDescription,
    editorProps?.snippetData,
    hideButton,
  ]);

  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (
        (!displayText?.translatable && translationQuota < 1) ||
        (text.hasOwnProperty('notEnoughQuota') && !!text?.notEnoughQuota)
      ) {
        openMessageQuotaEndedModal();
      } else if (displayText?.translatable) {
        toggleDisplayOriginalText();
      }
    },
    [
      text,
      toggleDisplayOriginalText,
      displayText?.translatable,
      translationQuota,
      openMessageQuotaEndedModal,
    ]
  );

  return (
    <Stack
      direction="row"
      gap="0.75rem"
      wrap="nowrap"
      className="d-inline-flex"
    >
      {(displayText?.translatable ||
        (!displayText?.translatable && translationQuota < 1) ||
        (text?.hasOwnProperty('notEnoughQuota') && !!text?.notEnoughQuota)) &&
        !hideButton && (
          <span className="flex-shrink-0 hover-opacity" onClick={onClick}>
            <GoogleTranslateIcon />
          </span>
        )}

      {isDescription ? (
        <LexicalEditor
          key={displayText?.value}
          value={displayText?.value}
          {...editorProps}
        >
          <LexicalEditor.Content className={className + ' mt-1'} />
        </LexicalEditor>
      ) : (
        <span className={className}>
          {textFormatter
            ? textFormatter(displayText?.value)
            : displayText?.value}
        </span>
      )}
    </Stack>
  );
};

export default QuotaTranslatedText;
