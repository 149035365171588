export const BulbGoldIcon = ({ size = 1 }) => (
  <svg
    width={size * 30}
    height={size * 42}
    viewBox="0 0 30 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_71180_75197)">
      <path
        d="M14.4946 35.997C15.689 35.997 16.6572 35.0288 16.6572 33.8345C16.6572 32.6401 15.689 31.6719 14.4946 31.6719C13.3002 31.6719 12.332 32.6401 12.332 33.8345C12.332 35.0288 13.3002 35.997 14.4946 35.997Z"
        fill="url(#paint0_radial_71180_75197)"
      />
      <path
        d="M17.1512 26.6094H11.9305C11.0887 26.6094 10.4062 27.2918 10.4062 28.1337V33.2781C10.4062 34.1199 11.0887 34.8024 11.9305 34.8024H17.1512C17.993 34.8024 18.6755 34.1199 18.6755 33.2781V28.1337C18.6755 27.2918 17.993 26.6094 17.1512 26.6094Z"
        fill="url(#paint1_linear_71180_75197)"
      />
      <path
        d="M17.1512 26.6094H11.9305C11.0887 26.6094 10.4062 27.2918 10.4062 28.1337V33.2781C10.4062 34.1199 11.0887 34.8024 11.9305 34.8024H17.1512C17.993 34.8024 18.6755 34.1199 18.6755 33.2781V28.1337C18.6755 27.2918 17.993 26.6094 17.1512 26.6094Z"
        fill="url(#paint2_linear_71180_75197)"
      />
      <path
        d="M17.1512 26.6094H11.9305C11.0887 26.6094 10.4062 27.2918 10.4062 28.1337V33.2781C10.4062 34.1199 11.0887 34.8024 11.9305 34.8024H17.1512C17.993 34.8024 18.6755 34.1199 18.6755 33.2781V28.1337C18.6755 27.2918 17.993 26.6094 17.1512 26.6094Z"
        fill="url(#paint3_radial_71180_75197)"
      />
      <path
        d="M17.1512 26.6094H11.9305C11.0887 26.6094 10.4062 27.2918 10.4062 28.1337V33.2781C10.4062 34.1199 11.0887 34.8024 11.9305 34.8024H17.1512C17.993 34.8024 18.6755 34.1199 18.6755 33.2781V28.1337C18.6755 27.2918 17.993 26.6094 17.1512 26.6094Z"
        fill="url(#paint4_linear_71180_75197)"
      />
      <g filter="url(#filter1_i_71180_75197)">
        <path
          d="M14.3767 27.579C14.3997 27.579 14.4218 27.5791 14.4426 27.5791C15.1197 27.576 16.6791 27.5498 17.3041 27.4362C17.9748 27.3142 18.0614 26.9799 18.1805 26.4549C18.9151 23.2193 20.1469 21.9753 20.9708 21.1431C21.0979 21.0148 21.2153 20.8963 21.3196 20.7817C21.6772 20.3892 21.9869 20.0437 22.2232 19.7768C24.0021 17.8889 25.0922 15.3448 25.0922 12.5462C25.0922 6.72166 20.3705 2 14.5461 2C8.72172 2 4 6.72166 4 12.5462C4 15.3107 5.06376 17.8269 6.8043 19.7076C7.04729 19.9824 7.37974 20.3544 7.76923 20.7817C7.8369 20.856 7.91019 20.9321 7.98799 21.0114C8.03006 21.0542 8.07335 21.0981 8.11798 21.1431C8.94195 21.9753 10.1737 23.2193 10.9083 26.4549C11.0275 26.9799 11.1141 27.3142 11.7848 27.4362C12.4084 27.5496 13.6706 27.5755 14.3094 27.5787C14.3302 27.5787 14.3528 27.579 14.3767 27.579Z"
          fill="url(#paint5_radial_71180_75197)"
        />
        <path
          d="M14.3767 27.579C14.3997 27.579 14.4218 27.5791 14.4426 27.5791C15.1197 27.576 16.6791 27.5498 17.3041 27.4362C17.9748 27.3142 18.0614 26.9799 18.1805 26.4549C18.9151 23.2193 20.1469 21.9753 20.9708 21.1431C21.0979 21.0148 21.2153 20.8963 21.3196 20.7817C21.6772 20.3892 21.9869 20.0437 22.2232 19.7768C24.0021 17.8889 25.0922 15.3448 25.0922 12.5462C25.0922 6.72166 20.3705 2 14.5461 2C8.72172 2 4 6.72166 4 12.5462C4 15.3107 5.06376 17.8269 6.8043 19.7076C7.04729 19.9824 7.37974 20.3544 7.76923 20.7817C7.8369 20.856 7.91019 20.9321 7.98799 21.0114C8.03006 21.0542 8.07335 21.0981 8.11798 21.1431C8.94195 21.9753 10.1737 23.2193 10.9083 26.4549C11.0275 26.9799 11.1141 27.3142 11.7848 27.4362C12.4084 27.5496 13.6706 27.5755 14.3094 27.5787C14.3302 27.5787 14.3528 27.579 14.3767 27.579Z"
          fill="url(#paint6_radial_71180_75197)"
        />
      </g>
      <path
        d="M16.4334 12.109C18.6748 12.109 20.4918 10.292 20.4918 8.05058C20.4918 5.80919 18.6748 3.99219 16.4334 3.99219C14.192 3.99219 12.375 5.80919 12.375 8.05058C12.375 10.292 14.192 12.109 16.4334 12.109Z"
        fill="url(#paint7_linear_71180_75197)"
      />
      <g filter="url(#filter2_i_71180_75197)">
        <path
          d="M14.0323 27.5781C13.9895 24.9293 13.7784 23.1851 13.4571 21.971C13.0867 20.5716 12.5687 19.8742 12.0206 19.3212L12.0138 19.3143L12.0066 19.3076C11.3045 18.6433 10.8679 17.7049 10.8679 16.6634C10.8679 14.6535 12.4973 13.0241 14.5071 13.0241C16.517 13.0241 18.1464 14.6535 18.1464 16.6634C18.1464 17.7049 17.7096 18.6433 17.0077 19.3076L17.0005 19.3143L16.9935 19.3212C16.4554 19.8643 15.9303 20.5159 15.5556 21.9037C15.2312 23.1044 15.019 24.8547 14.9768 27.5755C15.3446 27.5695 15.7845 27.5583 16.1971 27.5383C16.2406 24.8947 16.4482 23.2754 16.7328 22.2216C17.0411 21.0799 17.4375 20.6064 17.8531 20.1863C18.784 19.3018 19.3658 18.05 19.3658 16.6634C19.3658 13.98 17.1905 11.8047 14.5071 11.8047C11.8238 11.8047 9.64844 13.98 9.64844 16.6634C9.64844 18.05 10.2301 19.3017 11.161 20.1862C11.5671 20.597 11.9678 21.1101 12.2782 22.283C12.5619 23.3545 12.7677 24.9707 12.812 27.539C13.2343 27.5623 13.6796 27.5734 14.0323 27.5781Z"
          fill="#FFAD55"
        />
      </g>
      <g filter="url(#filter3_f_71180_75197)">
        <path
          d="M15.7113 27.2968C15.7113 21.4788 16.6029 20.5509 17.5591 19.5859C18.3777 18.8115 18.8885 17.7148 18.8885 16.499C18.8885 14.1523 16.9862 12.25 14.6396 12.25C12.2929 12.25 10.3906 14.1523 10.3906 16.499C10.3906 17.7148 10.9014 18.8115 11.72 19.5859C12.6763 20.5509 13.5631 22.2211 13.5631 27.2968"
          stroke="url(#paint8_linear_71180_75197)"
          stroke-width="0.25"
        />
      </g>
      <g filter="url(#filter4_f_71180_75197)">
        <path
          d="M17.8733 29.0263L10.9573 30.1099C10.6012 30.1657 10.3577 30.4996 10.4135 30.8557C10.4693 31.2118 10.8032 31.4552 11.1593 31.3994L18.0754 30.3158C18.4315 30.26 18.6749 29.9261 18.6191 29.57C18.5633 29.2139 18.2294 28.9705 17.8733 29.0263Z"
          fill="#7E6693"
        />
      </g>
      <g filter="url(#filter5_f_71180_75197)">
        <path
          d="M17.8733 31.9169L10.9573 33.0005C10.6012 33.0563 10.3577 33.3902 10.4135 33.7463C10.4693 34.1024 10.8032 34.3458 11.1593 34.29L18.0754 33.2064C18.4315 33.1506 18.6749 32.8167 18.6191 32.4606C18.5633 32.1045 18.2294 31.8611 17.8733 31.9169Z"
          fill="#7E6693"
        />
      </g>
      <path
        d="M18.7467 31.5881L10.0504 32.9507C9.68386 33.0081 9.43328 33.3518 9.49071 33.7184L9.49071 33.7184C9.54814 34.0849 9.89185 34.3355 10.2584 34.2781L18.9547 32.9155C19.3212 32.8581 19.5718 32.5144 19.5144 32.1478C19.457 31.7813 19.1133 31.5307 18.7467 31.5881Z"
        fill="url(#paint9_linear_71180_75197)"
      />
      <path
        d="M18.7467 31.5881L10.0504 32.9507C9.68386 33.0081 9.43328 33.3518 9.49071 33.7184L9.49071 33.7184C9.54814 34.0849 9.89185 34.3355 10.2584 34.2781L18.9547 32.9155C19.3212 32.8581 19.5718 32.5144 19.5144 32.1478C19.457 31.7813 19.1133 31.5307 18.7467 31.5881Z"
        fill="url(#paint10_linear_71180_75197)"
      />
      <path
        d="M18.7467 31.5881L10.0504 32.9507C9.68386 33.0081 9.43328 33.3518 9.49071 33.7184L9.49071 33.7184C9.54814 34.0849 9.89185 34.3355 10.2584 34.2781L18.9547 32.9155C19.3212 32.8581 19.5718 32.5144 19.5144 32.1478C19.457 31.7813 19.1133 31.5307 18.7467 31.5881Z"
        fill="url(#paint11_linear_71180_75197)"
      />
      <path
        d="M18.7467 31.5881L10.0504 32.9507C9.68386 33.0081 9.43328 33.3518 9.49071 33.7184L9.49071 33.7184C9.54814 34.0849 9.89185 34.3355 10.2584 34.2781L18.9547 32.9155C19.3212 32.8581 19.5718 32.5144 19.5144 32.1478C19.457 31.7813 19.1133 31.5307 18.7467 31.5881Z"
        fill="url(#paint12_radial_71180_75197)"
      />
      <path
        d="M18.7467 28.6897L10.0504 30.0523C9.68386 30.1097 9.43328 30.4534 9.49071 30.8199L9.49071 30.8199C9.54814 31.1865 9.89185 31.4371 10.2584 31.3797L18.9547 30.0171C19.3212 29.9596 19.5718 29.6159 19.5144 29.2494C19.457 28.8828 19.1133 28.6323 18.7467 28.6897Z"
        fill="url(#paint13_linear_71180_75197)"
      />
      <path
        d="M18.7467 28.6897L10.0504 30.0523C9.68386 30.1097 9.43328 30.4534 9.49071 30.8199L9.49071 30.8199C9.54814 31.1865 9.89185 31.4371 10.2584 31.3797L18.9547 30.0171C19.3212 29.9596 19.5718 29.6159 19.5144 29.2494C19.457 28.8828 19.1133 28.6323 18.7467 28.6897Z"
        fill="url(#paint14_linear_71180_75197)"
      />
      <path
        d="M18.7467 28.6897L10.0504 30.0523C9.68386 30.1097 9.43328 30.4534 9.49071 30.8199L9.49071 30.8199C9.54814 31.1865 9.89185 31.4371 10.2584 31.3797L18.9547 30.0171C19.3212 29.9596 19.5718 29.6159 19.5144 29.2494C19.457 28.8828 19.1133 28.6323 18.7467 28.6897Z"
        fill="url(#paint15_linear_71180_75197)"
      />
      <path
        d="M18.7467 28.6897L10.0504 30.0523C9.68386 30.1097 9.43328 30.4534 9.49071 30.8199L9.49071 30.8199C9.54814 31.1865 9.89185 31.4371 10.2584 31.3797L18.9547 30.0171C19.3212 29.9596 19.5718 29.6159 19.5144 29.2494C19.457 28.8828 19.1133 28.6323 18.7467 28.6897Z"
        fill="url(#paint16_radial_71180_75197)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_71180_75197"
        x="0"
        y="0"
        width="29.0938"
        height="42"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.8 0 0 0 0 0.783333 0 0 0 0 0.783333 0 0 0 0.7 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_71180_75197"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_71180_75197"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_71180_75197"
        x="4"
        y="2"
        width="21.3438"
        height="25.7281"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.25" dy="0.15" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.992157 0 0 0 0 0.666667 0 0 0 0 0.309804 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_71180_75197"
        />
      </filter>
      <filter
        id="filter2_i_71180_75197"
        x="9.64844"
        y="11.8047"
        width="9.96875"
        height="15.7734"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.25" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.572549 0 0 0 0 0.254902 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_71180_75197"
        />
      </filter>
      <filter
        id="filter3_f_71180_75197"
        x="10.0156"
        y="11.875"
        width="9.24609"
        height="15.6719"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.125"
          result="effect1_foregroundBlur_71180_75197"
        />
      </filter>
      <filter
        id="filter4_f_71180_75197"
        x="10.1562"
        y="28.7656"
        width="8.72266"
        height="2.89062"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.125"
          result="effect1_foregroundBlur_71180_75197"
        />
      </filter>
      <filter
        id="filter5_f_71180_75197"
        x="10.1562"
        y="31.6562"
        width="8.72266"
        height="2.89062"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.125"
          result="effect1_foregroundBlur_71180_75197"
        />
      </filter>
      <radialGradient
        id="paint0_radial_71180_75197"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.0662 35.0634) rotate(96.6544) scale(1.15096 2.66583)"
      >
        <stop offset="0.267752" stop-color="#AC90C6" />
        <stop offset="1" stop-color="#8E6AAA" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_71180_75197"
        x1="18.6755"
        y1="31.0354"
        x2="10.4062"
        y2="31.0354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CDC2D1" />
        <stop offset="1" stop-color="#947C8D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_71180_75197"
        x1="9.99799"
        y1="31.6585"
        x2="12.0177"
        y2="31.6585"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8A7B88" />
        <stop offset="1" stop-color="#8A7B88" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_71180_75197"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.2461 27.1807) rotate(88.6361) scale(1.00586 4.47824)"
      >
        <stop offset="0.228769" stop-color="#EDE2EB" />
        <stop offset="1" stop-color="#C8BCCB" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_71180_75197"
        x1="16.4381"
        y1="34.8023"
        x2="16.4381"
        y2="33.259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7E5F9E" />
        <stop offset="1" stop-color="#7E5F9E" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_71180_75197"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.9965 9.47821) rotate(138.066) scale(12.089 9.96846)"
      >
        <stop offset="0.286168" stop-color="#FFEC87" />
        <stop offset="1" stop-color="#FFCD4B" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_71180_75197"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.1397 15.9564) rotate(-147.604) scale(17.7815 17.1034)"
      >
        <stop offset="0.622387" stop-color="#FFB239" stop-opacity="0" />
        <stop offset="1" stop-color="#FFB239" />
      </radialGradient>
      <linearGradient
        id="paint7_linear_71180_75197"
        x1="15.0141"
        y1="9.36059"
        x2="19.1864"
        y2="6.10947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="0.594121" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_71180_75197"
        x1="19.1754"
        y1="12.9793"
        x2="14.2912"
        y2="24.6153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBF5C" />
        <stop offset="1" stop-color="#FFBF5C" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_71180_75197"
        x1="14.2789"
        y1="32.2996"
        x2="14.6028"
        y2="33.7477"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B1969A" />
        <stop offset="1" stop-color="#DAD2E2" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_71180_75197"
        x1="15.6508"
        y1="33.4048"
        x2="15.4984"
        y2="32.4521"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8A68A9" />
        <stop offset="1" stop-color="#DEC2E3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_71180_75197"
        x1="9.38665"
        y1="33.8239"
        x2="12.7166"
        y2="33.1189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#958387" />
        <stop offset="1" stop-color="#958387" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_71180_75197"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.7658 32.2062) rotate(81.4164) scale(1.02127 6.04482)"
      >
        <stop offset="0.126697" stop-color="#FCF0FC" />
        <stop offset="1" stop-color="#E5C9E3" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint13_linear_71180_75197"
        x1="14.2789"
        y1="29.4012"
        x2="14.6028"
        y2="30.8493"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B1969A" />
        <stop offset="1" stop-color="#DAD2E2" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_71180_75197"
        x1="15.6508"
        y1="30.5063"
        x2="15.4984"
        y2="29.5536"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8A68A9" />
        <stop offset="1" stop-color="#DEC2E3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_71180_75197"
        x1="9.38665"
        y1="30.9255"
        x2="12.7166"
        y2="30.2204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#958387" />
        <stop offset="1" stop-color="#958387" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint16_radial_71180_75197"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.7658 29.3077) rotate(81.4164) scale(1.02127 6.04482)"
      >
        <stop offset="0.126697" stop-color="#FCF0FC" />
        <stop offset="1" stop-color="#E5C9E3" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
