import CreatorRegistrationKeys from '../translationKeys/CreatorRegistrationPageKeys';

export const CreatorRegistrations = {
  [CreatorRegistrationKeys.complete_registration]: 'Hoàn tất Đăng ký',
  [CreatorRegistrationKeys.success_registration]: 'Đăng ký thành công!',
  [CreatorRegistrationKeys.evo_collected]: 'EVO đã được thu thập!',
  [CreatorRegistrationKeys.click_me]: 'Nhấn vào Đây!',
  [CreatorRegistrationKeys.touch_me]: 'Chạm vào tôi',
  [CreatorRegistrationKeys._10_times]: '10 lần!',

  // Topbar
  [CreatorRegistrationKeys.Topbar.title]: 'Đăng ký làm nhà sáng tạo',
  [CreatorRegistrationKeys.Topbar.time]: 'Khoảng 1 phút',
  [CreatorRegistrationKeys.Topbar.explain]:
    'Vui lòng chọn một hoặc nhiều lựa chọn cho mỗi phần',

  // Mạng xã hội
  [CreatorRegistrationKeys.Social_Network.tooltip]: `
  Vui lòng kết nối tài khoản với <br>
  hơn 500 người đăng ký để <br>
  tham gia với tư cách là nhà sáng tạo.`,

  [CreatorRegistrationKeys.Social_Network.popup_error]:
    'Vui lòng cho phép cửa sổ bật lên trong trình duyệt của bạn để kết nối một nền tảng',

  // Lỗi
  [CreatorRegistrationKeys.Error.social_network]:
    'Vui lòng kết nối một tài khoản mạng xã hội',

  [CreatorRegistrationKeys.Error.handle_char_limit]: 'Nhập từ 3 đến 30 ký tự',
  [CreatorRegistrationKeys.Error.handle_invalid]:
    'Phát hiện ký tự không hợp lệ hoặc có khoảng trắng',
  [CreatorRegistrationKeys.Error.handle_taken]: 'URL này đã được sử dụng',
  [CreatorRegistrationKeys.Error.handle_required]: 'Vui lòng nhập một URL',

  [CreatorRegistrationKeys.Error.category]: 'Vui lòng chọn một danh mục',
  [CreatorRegistrationKeys.Error.invalid_category]: 'Danh mục không hợp lệ',

  [CreatorRegistrationKeys.Error.target_country]: 'Vui lòng chọn một quốc gia',
  [CreatorRegistrationKeys.Error.invalid_country]: 'Quốc gia không hợp lệ',

  [CreatorRegistrationKeys.Error.language]: 'Vui lòng chọn một ngôn ngữ',
  [CreatorRegistrationKeys.Error.invalid_language]: 'Ngôn ngữ không hợp lệ',

  // Modal
  // Already Creator
  [CreatorRegistrationKeys.Modal.already_creator.title]:
    'Bạn đã đăng ký làm nhà sáng tạo',
  [CreatorRegistrationKeys.Modal.already_creator.description]: `
    Bạn chỉ có thể đăng ký một lần với tư cách là người sáng tạo. <br>
    Vui lòng liên hệ với bộ phận hỗ trợ để biết thêm thông tin.`,

  [CreatorRegistrationKeys.Modal.already_company.title]:
    'Bạn đã đăng ký thành một thương hiệu · doanh nghiệp rồi',
  [CreatorRegistrationKeys.Modal.already_company.description]:
    'Bạn chỉ có thể xác minh làm một nhà sáng tạo hoặc một thương hiệu. Nếu bạn muốn đăng ký là nhà sáng tạo, vui lòng sử dụng một tài khoản mới.',

  // Registration Fail
  [CreatorRegistrationKeys.Modal.Registration_Fail.title]: 'Đăng ký thất bại',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1]:
    'Tài khoản sau không có ít nhất 500 người theo dõi hoặc đăng ký.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2]:
    'Các tài khoản bạn kết nối cần có ít nhất 500 người theo dõi hoặc đăng ký để tiếp tục.',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l1]:
    'Tài khoản sau đã được xác minh và đang được sử dụng bởi nhà sáng tạo khác.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l2]: 'Vui lòng ngắt kết nối tài khoản và thử lại.',

  // Verification Fail
  [CreatorRegistrationKeys.Modal.Verification_Fail.title]: 'Xác minh thất bại',
  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.update_insta]: `
    Vui lòng cập nhật tài khoản Instagram của bạn thành tài khoản Creator hoặc Business và thử lại. <br>
    <u> Click vào đây </u> để xem thêm thông tin.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .need_public_posts]: `
    Tài khoản cần ít nhất 5 bài viết công khai để được xác minh. Vui lòng thử lại sau khi đăng tải thêm bài viết.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected]: `
    Không có kênh YouTube được kết nối với tài khoản này. <br>
    Vui lòng kết nối hoặc tạo một kênh YouTube và thử lại.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use]:
    'Để sử dụng dịch vụ của chúng tôi, bạn phải chấp nhận tất cả các tùy chọn truy cập Facebook (scopes). Những tùy chọn này là cần thiết cho các dịch vụ cốt lõi của nền tảng của chúng tôi, và việc không chấp nhận chúng sẽ dẫn đến chức năng bị hạn chế và truy cập vào dịch vụ bị hạn chế. Vui lòng kết nối lại tài khoản của bạn bằng cách chấp nhận tất cả các tùy chọn.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .to_use_our_service]:
    'Để sử dụng dịch vụ của chúng tôi, bạn phải chấp nhận tất cả các phạm vi YouTube/Instagram/TikTok/X. Những phạm vi này là cần thiết cho các dịch vụ cốt lõi của nền tảng của chúng tôi, và việc không chấp nhận chúng sẽ dẫn đến chức năng bị hạn chế và truy cập vào dịch vụ bị hạn chế. Vui lòng kết nối lại tài khoản của bạn bằng cách chấp nhận tất cả các phạm vi.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different]:
    'Bạn đã chọn một tài khoản Instagram khác so với tài khoản hiện được kết nối. Vui lòng thử lại bằng cách chọn cùng một tài khoản. Nếu bạn muốn kết nối một tài khoản mới, vui lòng ngắt kết nối tài khoản hiện tại và thực hiện kết nối lại.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .verify_social_media]: `
    Bạn có thể xác minh tài khoản mạng xã hội tối đa 10 lần 1 ngày. <br>
    Vui lòng thử lại sau.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .already_verified]: `
    Tài khoản này đã được xác minh và đang được sử dụng bởi người sáng tạo nội dung khác. Vui lòng thử lại với`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs]:
    'Tài khoản cần ít nhất 500 người theo dõi hoặc đăng ký để được xác minh.',

  // Instagram Notice
  [CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection]:
    'Cập nhật Kết nối',
  [CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection]:
    'Nâng cấp Kết nối',

  // Instagram Facebook
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.title]:
    'Cập nhật Kết nối với Trang Facebook',
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point1]: `
    Thêm một trang Facebook để cập nhật kết nối Instagram của bạn. <br>
    Cập nhật này <b>là bắt buộc để chấp nhận bất kỳ đơn hàng liên quan đến Instagram nào</b> trên nền tảng này.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point2]: `
    Để thực hiện cập nhật này, cần phải có một tài khoản Instagram <b>Creator hoặc Business</b>.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point3]: `
    <b><u>Nhấn vào đây</u></b> để xem thêm thông tin.`,
};
