import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]: 'Bạn đã chấp nhận lời mời từ chúng',
  [InvitationKeys.title2]: 'tôi xin cảm ơn bạn!',
  [InvitationKeys.subtitle]:
    '* Để có trải nghiệm tối ưu, chúng tôi khuyến nghị sử dụng môi trường web.',

  [InvitationKeys.description]: `
    Chỉ với một hồ sơ miễn phí đơn giản, bạn có thể dễ dàng hợp tác với các công ty toàn cầu đã được chứng nhận ở Hoa Kỳ, Châu Âu, Hàn Quốc, Nhật Bản, v.v., mà không cần đến MCN (Mạng Lưới Đa Kênh).`,

  [InvitationKeys.view_creator_tutorial]: 'Đăng ký làm người sáng tạo',
  [InvitationKeys.explore_btn]: 'Xem hồ sơ mẫu',
  [InvitationKeys.learn_more]: 'Tìm hiểu thêm',
  [InvitationKeys.main_site]: 'Trang chủ',

  // Browser
  [InvitationKeys.Browser.title]:
    'Bạn đang sử dụng trình duyệt không được hỗ trợ',
  [InvitationKeys.Browser.description]:
    'Vui lòng sử dụng các trình duyệt ở trên để có trải nghiệm tối ưu. Một số tính năng có thể không được hỗ trợ trên trình duyệt này.',
  [InvitationKeys.Browser.button]: 'Tôi hiểu rồi',

  [InvitationKeys.Mega_Flow.title]:
    'Đây là cách nó hoạt động với MegaEvolution!',
  [InvitationKeys.Mega_Flow.Caption.one]:
    'Tạo hồ sơ người sáng tạo chỉ trong 1~2 phút',
  [InvitationKeys.Mega_Flow.Caption.two]:
    'Chúng tôi *tự động* giới thiệu & kết nối hồ sơ với các công ty đã được xác minh',
  [InvitationKeys.Mega_Flow.Caption.three]:
    'Xem xét và chấp nhận đơn hàng từ các thương hiệu toàn cầu',
  [InvitationKeys.Mega_Flow.Caption.four]:
    'Nộp nội dung & *kiếm thêm* thông qua bản quyền nội dung',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    'Tạo hồ sơ người sáng tạo chỉ trong 1~2 phút',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    'Chúng tôi *tự động* giới thiệu & kết nối hồ sơ với các công ty đã được xác minh',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]:
    'Xem xét và chấp nhận đơn hàng từ các thương hiệu toàn cầu',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    'Nộp nội dung & *kiếm thêm* thông qua bản quyền nội dung',

  [InvitationKeys.Creator_Review.title]:
    'Đánh giá từ các nhà sáng tạo MegaEvolution',
};
