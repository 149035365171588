import QuotaTranslatedText from './QuotaTranslatedText';
import SimpleTranslatedText from './SimpleTranslatedText';

const TranslatedText = ({
  withQuota,
  text,
  textFormatter,
  className,
  isDescription,
  editorProps,
  hideButton,
}) => {
  if (withQuota)
    return (
      <QuotaTranslatedText
        text={text}
        textFormatter={textFormatter}
        className={className}
        isDescription={isDescription}
        editorProps={editorProps}
        hideButton={hideButton}
      />
    );

  return (
    <SimpleTranslatedText
      text={text}
      textFormatter={textFormatter}
      className={className}
    />
  );
};

export default TranslatedText;
