export const BoxPackedIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 14}
    height={size * 15}
    viewBox="0 0 14 15"
    fill="currentColor"
  >
    <path d="M11.9 0.5H2.1C1.54305 0.5 1.0089 0.721249 0.615076 1.11508C0.221249 1.5089 0 2.04305 0 2.6V12.4C0 12.957 0.221249 13.4911 0.615076 13.8849C1.0089 14.2788 1.54305 14.5 2.1 14.5H11.9C12.457 14.5 12.9911 14.2788 13.3849 13.8849C13.7788 13.4911 14 12.957 14 12.4V2.6C14 2.04305 13.7788 1.5089 13.3849 1.11508C12.9911 0.721249 12.457 0.5 11.9 0.5ZM5.6 1.9H8.4V4.091L7.385 3.391C7.2707 3.31573 7.13685 3.27562 7 3.27562C6.86315 3.27562 6.72929 3.31573 6.615 3.391L5.6 4.091V1.9ZM12.6 12.4C12.6 12.5857 12.5263 12.7637 12.395 12.895C12.2637 13.0263 12.0857 13.1 11.9 13.1H2.1C1.91435 13.1 1.7363 13.0263 1.60503 12.895C1.47375 12.7637 1.4 12.5857 1.4 12.4V2.6C1.4 2.41435 1.47375 2.2363 1.60503 2.10503C1.7363 1.97375 1.91435 1.9 2.1 1.9H4.2V5.4C4.20034 5.52663 4.23502 5.6508 4.30035 5.75928C4.36569 5.86776 4.45922 5.95648 4.571 6.016C4.68046 6.0722 4.8028 6.09856 4.9257 6.09242C5.04859 6.08627 5.16769 6.04784 5.271 5.981L7 4.84L8.715 5.981C8.82028 6.05033 8.94233 6.08995 9.06826 6.09567C9.19419 6.10139 9.31932 6.07299 9.43045 6.01349C9.54158 5.95398 9.63458 5.86556 9.69963 5.75758C9.76467 5.6496 9.79935 5.52606 9.8 5.4V1.9H11.9C12.0857 1.9 12.2637 1.97375 12.395 2.10503C12.5263 2.2363 12.6 2.41435 12.6 2.6V12.4Z" />
  </svg>
);
