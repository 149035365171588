export const TurnRightIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 13}
    viewBox="0 0 18 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.37605 3.77597H14.1682L12.7052 2.36922C12.4933 2.16388 12.3743 1.88537 12.3743 1.59497C12.3743 1.30456 12.4933 1.02605 12.7052 0.820708C12.9171 0.615362 13.2045 0.5 13.5042 0.5C13.8039 0.5 14.0913 0.615362 14.3032 0.820708L17.6793 4.09222C17.7817 4.19593 17.862 4.31822 17.9156 4.45208C18.0281 4.71758 18.0281 5.01537 17.9156 5.28087C17.862 5.41473 17.7817 5.53702 17.6793 5.64073L14.3032 8.91224C14.1986 9.01445 14.0741 9.09558 13.937 9.15095C13.7999 9.20631 13.6528 9.23481 13.5042 9.23481C13.3556 9.23481 13.2086 9.20631 13.0714 9.15095C12.9343 9.09558 12.8098 9.01445 12.7052 8.91224C12.5997 8.81087 12.516 8.69026 12.4589 8.55737C12.4017 8.42448 12.3723 8.28195 12.3723 8.13799C12.3723 7.99403 12.4017 7.85149 12.4589 7.7186C12.516 7.58572 12.5997 7.46511 12.7052 7.36373L14.1682 5.95698H3.37605C3.07759 5.95698 2.79135 6.07187 2.58031 6.27638C2.36926 6.48089 2.2507 6.75826 2.2507 7.04748V11.4095C2.2507 11.6987 2.13214 11.9761 1.92109 12.1806C1.71005 12.3851 1.42381 12.5 1.12535 12.5C0.826889 12.5 0.540651 12.3851 0.329607 12.1806C0.118563 11.9761 0 11.6987 0 11.4095V7.04748C0 6.17982 0.35569 5.3477 0.988823 4.73418C1.62196 4.12065 2.48067 3.77597 3.37605 3.77597Z" />
  </svg>
);
