const orderState = {
  PROPOSAL_REQUIRED: 'proposalRequired',
  AWAITING_ACCEPTANCE: 'awaitingAcceptance',
  PRODUCT_DELIVERING: 'productDelivering',
  PREPARING_CONTENTS: 'preparingContents',
  CONTENTS_DELIVERED: 'contentsDelivered',
  IN_REVISION: 'inRevision',
  EXTENSION_REQUEST: 'extensionRequest',
  CANCELLATION_REQUEST: 'cancellationRequest',
  CANCELED: 'canceled',
  COMPLETE: 'complete',
  LATE: 'late',
  REFUNDED: 'refunded',
};

export default orderState;
