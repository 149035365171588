export const LiveIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 20}
    height={size * 7}
    viewBox="0 0 20 7"
    fill="currentColor"
  >
    <path d="M4.33539 6.75113H0V0.248047H1.52606V5.42016H4.33539V6.75113Z" />
    <path d="M6.92101 0.248047V6.75113H5.39495V0.248047H6.92101Z" />
    <path d="M12.1079 6.75113H10.5038L7.93722 0.248047H9.53698L11.3058 4.99096L13.0703 0.248047H14.6701L12.1079 6.75113Z" />
    <path d="M15.6906 6.75113V0.248047H19.9566V1.57901H17.2167V2.82327H19.7832V4.15423H17.2167V5.42016H20V6.75113H15.6906Z" />
  </svg>
);
