export const LabelIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18 * size}
    height={12 * size}
    viewBox="0 0 18 12"
    fill="currentColor"
  >
    <path d="M17.7339 5.36111L13.2352 0.861113C13.1511 0.777699 13.0514 0.711707 12.9418 0.666919C12.8321 0.62213 12.7148 0.599428 12.5963 0.600113H2.69922C1.98334 0.600113 1.29678 0.884576 0.790583 1.39092C0.284381 1.89727 0 2.58403 0 3.30011V8.70011C0 9.4162 0.284381 10.103 0.790583 10.6093C1.29678 11.1157 1.98334 11.4001 2.69922 11.4001H12.5963C12.7148 11.4008 12.8321 11.3781 12.9418 11.3333C13.0514 11.2885 13.1511 11.2225 13.2352 11.1391L17.7339 6.63911C17.8182 6.55545 17.8851 6.4559 17.9308 6.34623C17.9765 6.23656 18 6.11892 18 6.00011C18 5.8813 17.9765 5.76367 17.9308 5.65399C17.8851 5.54432 17.8182 5.44478 17.7339 5.36111ZM12.2275 9.60011H2.69922C2.46059 9.60011 2.23174 9.50529 2.06301 9.33651C1.89427 9.16773 1.79948 8.93881 1.79948 8.70011V3.30011C1.79948 3.06142 1.89427 2.8325 2.06301 2.66372C2.23174 2.49493 2.46059 2.40011 2.69922 2.40011H12.2275L15.8264 6.00011L12.2275 9.60011Z" />
  </svg>
);
