import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'Tự động dịch',
  [AutoTranslationKeys.Dropdown.description]:
    'Tự động dịch mô tả và văn bản do người dùng viết.',
  [AutoTranslationKeys.Dropdown.order_message_title]:
    'Dịch Đơn Hàng và Tin Nhắn',
  [AutoTranslationKeys.Dropdown.order_message_title_mobile]:
    'Dịch Đơn Hàng <br> và Tin Nhắn',
  [AutoTranslationKeys.Dropdown.order_message_description]:
    'Còn lại {{number}} tin nhắn · Cập nhật ngày {{date}}',
  [AutoTranslationKeys.Dropdown.order_message_description_mobile]: `
    Còn lại {{number}} tin nhắn <br> 
    Cập nhật ngày {{date}}`,

  [AutoTranslationKeys.Applying_Translation.title]: 'Đã áp dụng tự động dịch.',
  [AutoTranslationKeys.Applying_Translation.btn]: ' Hiển thị văn bản gốc',
  [AutoTranslationKeys.Applying_Translation.message]: 'Đã dịch',

  [AutoTranslationKeys.Displaying_Original.title]: 'Hiển thị văn bản gốc.',
  [AutoTranslationKeys.Displaying_Original.btn]: ' Dịch',
  [AutoTranslationKeys.Displaying_Original.message]: 'Văn bản gốc',
};
