import MessageKeys from '../translationKeys/MessageKeys';

export const MessageTranslations = {
  [MessageKeys.Dropdown.title]: '受信トレイ',
  [MessageKeys.Dropdown.no_message]: 'メッセージがありません',
  [MessageKeys.Dropdown.bottom_btn]: 'すべてのメッセージを表示',

  [MessageKeys.ChatBox.title]: '受信トレイへようこそ！',
  [MessageKeys.ChatBox.no_chat]: `
    まだ会話が開始されていないようです。 <br>
    でも心配しないで！一度会話を始めると、ここに表示されます。`,
  [MessageKeys.ChatBox.no_selected_chat]:
    'メッセージを送信するにはチャットをクリックしてください！',
  [MessageKeys.ChatBox.new_chat]: `
    会話を開始し、プロジェクトの詳細を話し合うために{{user}}と接続します。<br>
    <span>情報を一括で共有するために提案を送信します。</span>`,
  [MessageKeys.ChatBox.search]: `
    <h6>結果が見つかりませんでした</h6>
    <p>他のキーワードをお試しください。</p>`,

  [MessageKeys.ChatBox.Message.me]: '私',
  [MessageKeys.ChatBox.Message.copy]: 'メッセージをコピー',
  [MessageKeys.ChatBox.Message.report]: 'メッセージを報告',
  [MessageKeys.ChatBox.Message.download_file]: '全体をダウンロード',

  [MessageKeys.ChatBox.Message.typing]: '入力中...',
  [MessageKeys.ChatBox.Message.Offer_Package.text]:
    'カスタムパッケージが準備できました。',
  [MessageKeys.ChatBox.Message.Offer_Package.description]:
    'クライアントの提案を受け入れると注文が開始されます。',
  [MessageKeys.ChatBox.Message.Offer_Package.btn]: 'チェックアウトを続ける',

  [MessageKeys.ChatBox.Message.Select_Package.title]: 'パッケージを選択',
  [MessageKeys.ChatBox.Message.Select_Package.btn]: '新しいパッケージを作成',
  [MessageKeys.ChatBox.Message.Select_Package.complete_package_first]:
    'オファーを送信するには、まず最初のパッケージを完了してください！',
  [MessageKeys.ChatBox.Message.Select_Package.no_price]: '価格なし',
  [MessageKeys.ChatBox.Message.Select_Package.verified_only]:
    '認証済みユーザーのみ',

  [MessageKeys.ChatBox.Message.Custom_Package.title]: 'カスタムパッケージ',
  [MessageKeys.ChatBox.Message.Custom_Package.description]:
    'バイヤーに表示される価格には約{{number}}%のサービス料が含まれます。',
  [MessageKeys.ChatBox.Message.Custom_Package.offering]: '提供中...',
  [MessageKeys.ChatBox.Message.Custom_Package.btn1]: 'パッケージを提供',
  [MessageKeys.ChatBox.Message.Custom_Package.btn2]: '選択に戻る',

  [MessageKeys.ChatBox.Message.Request_Price.text]:
    'パッケージ価格をお知らせください。',
  [MessageKeys.ChatBox.Message.Request_Price.description]:
    '価格のリクエストがクリエイターに成功裏に送信されました。',
  [MessageKeys.ChatBox.Message.Request_Price.btn]: '価格とパッケージを提供する',

  [MessageKeys.ChatBox.Message.File.singular]: '1ファイル',
  [MessageKeys.ChatBox.Message.File.plural]: 'ファイル',
  [MessageKeys.ChatBox.Message.File.plural_2]: '2ファイル',
  [MessageKeys.ChatBox.Message.File.plural_3]: '3ファイル',
  [MessageKeys.ChatBox.Message.File.plural_4]: '4ファイル',
  [MessageKeys.ChatBox.Message.File.plural_5]: '5ファイル',
  [MessageKeys.ChatBox.Message.File.plural_6]: '6ファイル',
  [MessageKeys.ChatBox.Message.File.plural_7]: '7ファイル',
  [MessageKeys.ChatBox.Message.File.plural_8]: '8ファイル',
  [MessageKeys.ChatBox.Message.File.plural_9]: '9ファイル',
  [MessageKeys.ChatBox.Message.File.plural_10]: '10ファイル',
  [MessageKeys.ChatBox.Message.File.expired]: 'このファイルは期限切れです。',

  [MessageKeys.ChatBox.Message.Error.sending]: '送信中...',
  [MessageKeys.ChatBox.Message.Error.fail]: '送信に失敗しました',

  [MessageKeys.InputField.chat_disabled]:
    'このユーザーにはもう連絡できません。',
  [MessageKeys.InputField.web]:
    'テキストを入力するか、ファイルをドラッグ＆ドロップ',
  [MessageKeys.InputField.mobile]: 'メッセージを入力',
  [MessageKeys.InputField.drag_drop]: 'ファイルをドラッグアンドドロップ',

  [MessageKeys.InputField.Btn.offer_package]: 'パッケージを提供する',
  [MessageKeys.InputField.Btn.send_proposal]: '提案を送る',

  [MessageKeys.UserInfo.Verified.tooltip]: `
    このアカウントは、確認済みの <br>
    企業または組織を表しており、またはその <br>
    関連メンバーです。`,

  [MessageKeys.UserInfo.Status.online]: 'オンライン',
  [MessageKeys.UserInfo.Status.offline]: '最終閲覧',

  [MessageKeys.UserInfo.order_together]: '共同で進めた注文',

  [MessageKeys.UserInfo.Btn.edit_profile]: 'プロフィール編集',
  [MessageKeys.UserInfo.Btn.visit_profile]: 'プロフィールを表示',
  [MessageKeys.UserInfo.translate_messages]: 'メッセージを翻訳',

  [MessageKeys.Error.max_characters]: '最大{{number}}文字',
  [MessageKeys.Error.max_file_size_1]: '合計ファイルサイズ制限は最大',
  [MessageKeys.Error.max_file_size_2]: 'MB です',
  [MessageKeys.Error.max_file_1]: '最大',
  [MessageKeys.Error.max_file_2]: 'ファイルを送信できます',

  [MessageKeys.Modal.QuotaExceeded.title]: '翻訳割り当て量超過',
  [MessageKeys.Modal.QuotaExceeded.description]:
    '次の更新日：{{date}} <br><br> 月次翻訳量の制限に達しました。追加の翻訳が必要な場合は、料金プランをアップグレードしてください。',
  [MessageKeys.Modal.QuotaExceeded.btn]: 'プランをチェック',

  [MessageKeys.Toast.Client.web]: `
    海外のクリエイターとチャット中ですか？ <br>
    心地よい言語でお気軽にお話しください。 <br>
    自動翻訳をサポートします！`,
  [MessageKeys.Toast.Client.mobile]:
    '海外のクリエイターとチャット中ですか？心地よい言語でお気軽にお話しください。自動翻訳をサポートします！',
  [MessageKeys.Toast.Creator.web]: `
    海外のブランドや企業とチャット中？ <br>
    心地よい言語でお気軽にお話しください。<br>
    自動翻訳をサポートします！`,
  [MessageKeys.Toast.Creator.mobile]:
    '海外のブランドや企業とチャット中？心地よい言語でお気軽にお話しください。自動翻訳をサポートします！',
};
