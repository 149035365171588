import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]: 'Automatically Connect &',
  [InvitationKeys.title2]: 'Collaborate with Top Global Brands!',
  [InvitationKeys.subtitle]:
    '* For an optimal experience, we recommend using a web environment.',

  [InvitationKeys.description]:
    'With just a free profile, you can easily collaborate with top verified companies from the US, Europe, Asia, and more. No MCN and subscription required.',

  [InvitationKeys.view_creator_tutorial]: 'Join as a creator',
  [InvitationKeys.explore_btn]: 'Register & get a gift',
  [InvitationKeys.learn_more]: 'Learn more',
  [InvitationKeys.main_site]: 'Main site',

  [InvitationKeys.Browser.title]: 'You seem to be using an unsupported browser',
  [InvitationKeys.Browser.description]:
    'Please use the browsers above for an optimal experience. Some features may not be supported in this browser.',
  [InvitationKeys.Browser.button]: 'I understand',

  [InvitationKeys.Mega_Flow.title]: `Here's how it works with MegaEvolution!`,
  [InvitationKeys.Mega_Flow.Caption.one]:
    'Create a creator profile\n in just 1~2 minutes',
  [InvitationKeys.Mega_Flow.Caption.two]:
    'We *automatically* recommend &\n connect profiles to verified companies',
  [InvitationKeys.Mega_Flow.Caption.three]:
    'Review and accept orders\n from global brands',
  [InvitationKeys.Mega_Flow.Caption.four]:
    'Submit contents & *earn more* with\n content licensing',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    'Create a creator profile in just 1~2 minutes',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    'We *automatically* recommend & connect profiles to verified companies',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]:
    'Review and accept orders from global brands',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    'Submit contents & *earn more* with content licensing',

  [InvitationKeys.Creator_Review.title]: 'Reviews from MegaEvolution creators',

  // Brand
  [InvitationKeys.Brand.title]: `
    Collaborate with <span> global influencers</span> <br>
    in your preferred language!`,
  [InvitationKeys.Brand.subTitle]:
    'Global Marketing Platform · Pre-register Now!',
  [InvitationKeys.Brand.Business_Flow.title]: `
    All-in-one from payments, reports to content licensing! <br> 
    Here's how it works with MegaEvolution`,
  [InvitationKeys.Brand.Business_Flow.title_mobile]: `
    Here's how it works with MegaEvolution`,
  [InvitationKeys.Brand.Business_Flow.Caption.one]:
    'Simple 1-2 minutes\n profile creation',
  [InvitationKeys.Brand.Business_Flow.Caption.two]:
    'Choose a creator and order a *marketing package*\n or request a custom service',
  [InvitationKeys.Brand.Business_Flow.Caption.three]:
    'YouTube, Instagram, TikTok, etc.\n Streamlined campaign for *all types of content*',
  [InvitationKeys.Brand.Business_Flow.Caption.four]:
    'Real-time collaboration with AI translation,\n *Marketing reports* & *licensing* for your campaign',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.one]:
    'Simple 1-2 minutes profile creation',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.two]:
    'Choose a creator and order a\n *marketing package*\n or request a custom service',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.three]:
    'YouTube, Instagram, TikTok, etc.\n Streamlined campaign for *all types of content*',
  [InvitationKeys.Brand.Business_Flow.Caption_Mobile.four]:
    'Real-time collaboration with AI translation, *Marketing reports* & *licensing* for your campaign',
  [InvitationKeys.Brand.Registration.title]:
    'Global pre-registration for brands is now open!',
  [InvitationKeys.Brand.Registration.title_mobile]: 'Global pre-registration',
  [InvitationKeys.Brand.Registration.subTitle]:
    'Get 300 USD worth of premium access when we go live (3 months)',
  [InvitationKeys.Brand.Registration.Name.title]: 'Name',
  [InvitationKeys.Brand.Registration.Name.placeholder]: 'Type your name',
  [InvitationKeys.Brand.Registration.Email.title]: 'Email',
  [InvitationKeys.Brand.Registration.Email.placeholder]: 'Type your email',
  [InvitationKeys.Brand.Registration.receive_mega_guide_checkbox]:
    '(Required) Consent to receive MegaEvolution guides · information following global launch',
  [InvitationKeys.Brand.Registration.btn]: 'Register Now',
  [InvitationKeys.Brand.Verified_Service.title]: `
    Verified service from YouTube, Instagram, and TikTok <br>
    providing 95% more accurate data!`,
  [InvitationKeys.Brand.Verified_Service.title_mobile]: `
    Verified service from YouTube, <br>
    Instagram, and TikTok <br>
    providing 95% more accurate data!`,

  [InvitationKeys.Brand.Error.invalid_email]: 'Invalid email',
  [InvitationKeys.Brand.Error.required_field]: 'email is a required field',
  [InvitationKeys.Brand.Toast.registration_complete]:
    'Pre-registration complete!',
  [InvitationKeys.Brand.Toast.error]: 'Error occurred. Please try again.',
};
