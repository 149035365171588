export const CameraIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 16}
    height={size * 11}
    viewBox="0 0 16 11"
    fill="currentColor"
  >
    <path d="M15.624 1.6202C15.5024 1.54998 15.3644 1.51302 15.224 1.51302C15.0836 1.51302 14.9456 1.54998 14.824 1.6202L12 3.0122C11.9772 2.39105 11.7143 1.80297 11.2667 1.37175C10.819 0.940519 10.2216 0.699778 9.6 0.700196H2.4C1.76348 0.700196 1.15303 0.953052 0.702944 1.40314C0.252856 1.85323 0 2.46368 0 3.1002V7.9002C0 8.53671 0.252856 9.14716 0.702944 9.59725C1.15303 10.0473 1.76348 10.3002 2.4 10.3002H9.6C10.2216 10.3006 10.819 10.0599 11.2667 9.62864C11.7143 9.19742 11.9772 8.60934 12 7.98819L14.848 9.41219C14.957 9.4681 15.0775 9.49821 15.2 9.50019C15.3498 9.50068 15.4967 9.4591 15.624 9.3802C15.7393 9.30814 15.8343 9.20789 15.9001 9.08891C15.9659 8.96993 16.0003 8.83615 16 8.70019V2.3002C16.0003 2.16424 15.9659 2.03046 15.9001 1.91148C15.8343 1.7925 15.7393 1.69225 15.624 1.6202ZM10.4 7.9002C10.4 8.11237 10.3157 8.31585 10.1657 8.46588C10.0157 8.61591 9.81217 8.70019 9.6 8.70019H2.4C2.18783 8.70019 1.98434 8.61591 1.83431 8.46588C1.68429 8.31585 1.6 8.11237 1.6 7.9002V3.1002C1.6 2.88802 1.68429 2.68454 1.83431 2.53451C1.98434 2.38448 2.18783 2.3002 2.4 2.3002H9.6C9.81217 2.3002 10.0157 2.38448 10.1657 2.53451C10.3157 2.68454 10.4 2.88802 10.4 3.1002V7.9002ZM14.4 7.4042L12 6.2042V4.7962L14.4 3.5962V7.4042Z" />
  </svg>
);
