export const DocumentAcceptIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 14}
    height={size * 19}
    viewBox="0 0 14 19"
    fill="currentColor"
  >
    <path d="M14 6.8225C13.9909 6.74212 13.9733 6.66293 13.9475 6.58625V6.5075C13.9054 6.41753 13.8493 6.33483 13.7812 6.2625L8.53125 1.0125C8.45892 0.944439 8.37622 0.888321 8.28625 0.84625C8.26013 0.84254 8.23362 0.84254 8.2075 0.84625C8.11861 0.795274 8.02045 0.762551 7.91875 0.75H2.625C1.92881 0.75 1.26113 1.02656 0.768845 1.51884C0.276562 2.01113 0 2.67881 0 3.375V15.625C0 16.3212 0.276562 16.9889 0.768845 17.4812C1.26113 17.9734 1.92881 18.25 2.625 18.25H11.375C12.0712 18.25 12.7389 17.9734 13.2312 17.4812C13.7234 16.9889 14 16.3212 14 15.625V6.875C14 6.875 14 6.875 14 6.8225ZM8.75 3.73375L11.0163 6H9.625C9.39294 6 9.17038 5.90781 9.00628 5.74372C8.84219 5.57962 8.75 5.35706 8.75 5.125V3.73375ZM12.25 15.625C12.25 15.8571 12.1578 16.0796 11.9937 16.2437C11.8296 16.4078 11.6071 16.5 11.375 16.5H2.625C2.39294 16.5 2.17038 16.4078 2.00628 16.2437C1.84219 16.0796 1.75 15.8571 1.75 15.625V3.375C1.75 3.14294 1.84219 2.92038 2.00628 2.75628C2.17038 2.59219 2.39294 2.5 2.625 2.5H7V5.125C7 5.82119 7.27656 6.48887 7.76884 6.98116C8.26113 7.47344 8.92881 7.75 9.625 7.75H12.25V15.625ZM9.00375 9.75375L6.125 12.6413L4.99625 11.5037C4.83148 11.339 4.60801 11.2464 4.375 11.2464C4.14199 11.2464 3.91852 11.339 3.75375 11.5037C3.58898 11.6685 3.49642 11.892 3.49642 12.125C3.49642 12.358 3.58898 12.5815 3.75375 12.7463L5.50375 14.4962C5.58509 14.5783 5.68187 14.6434 5.7885 14.6878C5.89512 14.7322 6.00949 14.7551 6.125 14.7551C6.24051 14.7551 6.35488 14.7322 6.4615 14.6878C6.56813 14.6434 6.66491 14.5783 6.74625 14.4962L10.2463 10.9963C10.411 10.8315 10.5036 10.608 10.5036 10.375C10.5036 10.142 10.411 9.91852 10.2463 9.75375C10.0815 9.58898 9.85801 9.49642 9.625 9.49642C9.39199 9.49642 9.16852 9.58898 9.00375 9.75375Z" />
  </svg>
);
