export const TimeCheckIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 19}
    viewBox="0 0 18 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.1818 2.51785C4.56685 2.51785 1.63636 5.45998 1.63636 9.08927C1.63636 12.7186 4.56685 15.6607 8.1818 15.6607C8.63368 15.6607 8.99998 16.0284 8.99998 16.4821C8.99998 16.9358 8.63368 17.3035 8.1818 17.3035C3.66312 17.3035 0 13.6258 0 9.08927C0 4.55265 3.66312 0.875 8.1818 0.875C12.7004 0.875 16.3636 4.55265 16.3636 9.08927C16.3636 9.54294 15.9973 9.9107 15.5454 9.9107C15.0935 9.9107 14.7272 9.54294 14.7272 9.08927C14.7272 5.45998 11.7968 2.51785 8.1818 2.51785ZM17.6841 11.7265C18.0408 12.0051 18.105 12.5212 17.8277 12.8793L14.0095 17.8078C13.8669 17.9918 13.6534 18.1063 13.4218 18.1229C13.1904 18.1395 12.9628 18.0565 12.7957 17.8948L10.8866 16.0466C10.5613 15.7317 10.5519 15.2117 10.8656 14.8852C11.1792 14.5586 11.6972 14.5491 12.0224 14.8641L13.2764 16.0781L16.5359 11.8707C16.8134 11.5126 17.3274 11.4481 17.6841 11.7265Z"
    />
    <path d="M8.87727 9.5H10.5955C10.8233 9.5 11.0418 9.59087 11.2029 9.75262C11.364 9.91437 11.4545 10.1337 11.4545 10.3625C11.4545 10.5912 11.364 10.8106 11.2029 10.9724C11.0418 11.1341 10.8233 11.225 10.5955 11.225H8.01818C7.79034 11.225 7.57182 11.1341 7.41071 10.9724C7.2496 10.8106 7.15909 10.5912 7.15909 10.3625V6.05C7.15909 5.82125 7.2496 5.60187 7.41071 5.44012C7.57182 5.27837 7.79034 5.1875 8.01818 5.1875C8.24603 5.1875 8.46454 5.27837 8.62565 5.44012C8.78676 5.60187 8.87727 5.82125 8.87727 6.05V7.775V9.5Z" />
  </svg>
);
