import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'Auto Translation',
  [AutoTranslationKeys.Dropdown.description]:
    'Automatically translate descriptions and user-written text.',
  [AutoTranslationKeys.Dropdown.order_message_title]:
    'Order · Message Translation',
  [AutoTranslationKeys.Dropdown.order_message_title_mobile]: `
    Order · Messages <br>
    Translation`,
  [AutoTranslationKeys.Dropdown.order_message_description]: `
    {{number}} Characters left · Updates on {{date}}`,
  [AutoTranslationKeys.Dropdown.order_message_description_mobile]: `
    {{number}} Characters left <br> 
    Updates on {{date}}`,

  [AutoTranslationKeys.Applying_Translation.title]: 'Auto-translation applied.',
  [AutoTranslationKeys.Applying_Translation.btn]: 'Show original text',
  [AutoTranslationKeys.Applying_Translation.message]: 'Translated',

  [AutoTranslationKeys.Displaying_Original.title]: 'Displaying original text.',
  [AutoTranslationKeys.Displaying_Original.btn]: 'Translate',
  [AutoTranslationKeys.Displaying_Original.message]: 'Original text',
};
