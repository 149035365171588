import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';

import TRANSFORMERS from './MarkdownTransformers';
import { HEADING } from '@lexical/markdown';

const MarkdownPlugin = ({ enableHeadings }) => {
  return (
    <MarkdownShortcutPlugin
      transformers={
        enableHeadings
          ? TRANSFORMERS
          : TRANSFORMERS.filter((t) => t !== HEADING)
      }
    />
  );
};

export { MarkdownPlugin };
