import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import { useMemo } from 'react';

const SimpleTranslatedText = ({ text, textFormatter, className }) => {
  const { getTranslatedText } = useAutoTranslation();

  const displayText = useMemo(() => {
    return getTranslatedText(text, false);
  }, [text, getTranslatedText]);

  return (
    <span className={className}>
      {textFormatter ? textFormatter(displayText?.value) : displayText?.value}
    </span>
  );
};

export default SimpleTranslatedText;
