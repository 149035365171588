import 'dayjs/locale/th';

const thLocale = {
  name: 'th',
  relativeTime: {
    future: 'ใน %s',
    past: '%sที่แล้ว',
    s: '1 นาที',
    m: '1 นาที',
    mm: '%d นาที',
    h: '1 ชั่วโมง',
    hh: '%d ชั่วโมง',
    d: '1 วัน',
    dd: '%d วัน',
    M: '1 เดือน',
    MM: '%d เดือน',
    y: '1 ปี',
    yy: '%d ปี',
  },
  dateFormat: {
    dot_date: 'DD.MM.YYYY',
    dash_date: 'DD-MM-YYYY',
    dot_date_time_12: 'DD.MM.YYYY h:mm A',
    time_12: 'h:mm A',
    abbr_date_time_12: 'D MMM, h:mm A',
    date_time: 'D MMMM, YYYY',
    yesterday: 'เมื่อวานนี้',
    full_abbr_date_time_12: 'D MMM, YYYY h:mm A',
  },
};

export default thLocale;
