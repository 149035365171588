import styled from 'styled-components';
import { CrossIconRounded, MegaIcon } from 'assets/icons';
import IconButton from 'components/Basics/Buttons/IconButton';
import Stack from 'components/Basics/Layout/Stack';
import LexicalEditor from 'components/Basics/LexicalEditor';
import MaxLines from 'components/Basics/Typography/MaxLines';
import dayjs from 'dayjs';
import { size } from 'styles/device';
import { AnimatePresence, motion } from 'framer-motion';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useState } from 'react';
import LazyImage from 'components/Basics/LazyImage';
import { useTranslation } from 'react-i18next';
import ModalKeys from 'translations/translationKeys/ModalKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import i18n from 'i18next';
import { useMeasure } from 'react-use';

const PatchNoteModalContent = ({
  image,
  title,
  date,
  description,
  link,
  onClose,
}) => {
  const { t } = useTranslation();

  const { isMobile } = useResponsiveScreen();
  const [fullHeight, setFullHeight] = useState(true);
  const [ref, { height }] = useMeasure();

  const openLink = () => {
    if (!link) return;

    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      window.open(`https://${link}`, '_blank');
      return;
    } else {
      window.open(link, '_blank');
      return;
    }
  };

  return (
    <AnimatePresence>
      <Container>
        <CloseContainer>
          <Title
            className="text-decoration-underline text-light cursor-pointer"
            onClick={() => {
              localStorage.setItem(
                'stopPatchModalUntill',
                dayjs().add(14, 'day').format('YYYY-MM-DD')
              );
              onClose();
            }}
          >
            {t(ModalKeys.Patch_Note.close)}
          </Title>
          <IconButton className="text-light" onClick={onClose}>
            <CrossIconRounded />
          </IconButton>
        </CloseContainer>
        <div>
          <CustomImage
            src={
              image && typeof image === 'string'
                ? image
                : image
                ? URL.createObjectURL(image)
                : {}
            }
            lazySrc={
              image && typeof image === 'string'
                ? image
                : image
                ? URL.createObjectURL(image)
                : {}
            }
          />
        </div>
        <ContentWrapper>
          <Stack direction="row" align="center" justify="space-between">
            <Stack direction="row" align="start" wrap="nowrap">
              <span className="d-flex align-items-center">
                <MegaIcon size={isMobile ? 0.9 : 1} />
              </span>
              <Title onClick={openLink}>{title}</Title>
            </Stack>
            <Date>
              {dayjs(date).format(dayjs.Ls[i18n.language].dateFormat.date_time)}
            </Date>
          </Stack>

          <DescriptionContainer
            animate={{
              height:
                isMobile && fullHeight && height > 111
                  ? '111px'
                  : 'max-content',
            }}
            transition={{
              duration: 0.3,
            }}
          >
            <div ref={ref}>
              <MaxLines>
                <LexicalEditor
                  name="PreviewDescription"
                  key={description}
                  value={description}
                  readOnly
                >
                  <Content onClick={openLink} />
                </LexicalEditor>
              </MaxLines>
            </div>
          </DescriptionContainer>
          {height > 111 && (
            <TextButton
              className="md-hidden"
              onClick={() => {
                setFullHeight(!fullHeight);
              }}
            >
              {fullHeight ? t(GeneralKeys.view_more) : t(GeneralKeys.view_less)}
            </TextButton>
          )}
        </ContentWrapper>
      </Container>
    </AnimatePresence>
  );
};

export default PatchNoteModalContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-big-images);
  border-radius: 1.875rem;
  overflow: hidden;
  min-width: 480px;
  max-width: 480px;
  position: relative;
  background: var(--color-white);

  @media (max-width: ${size.md}px) {
    min-width: 296px;
    max-width: 296px;
    border-radius: 25px;
  }
`;

const CloseContainer = styled(Stack).attrs({
  direction: 'row',
  align: 'center',
})`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  gap: 1.5rem;
  color: var(--color-white);
  z-index: 1;

  @media (max-width: ${size.md}px) {
    gap: 0.75rem;
  }
`;

const CustomImage = styled(LazyImage)`
  width: 100%;
  height: 300px;

  @media (max-width: ${size.md}px) {
    height: 185px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;

  @media (max-width: ${size.md}px) {
    padding: 1rem;
  }
`;

const DescriptionContainer = styled(motion.div).attrs({})`
  @media (max-width: ${size.md}px) {
    overflow: hidden;
  }
`;

const Title = styled.div.attrs({
  className: 'psbh',
})`
  font-size: 15px;
  cursor: pointer;

  @media (max-width: ${size.md}px) {
    font-size: 13px;
  }
`;

const Date = styled.div.attrs({
  className: 'h8',
})`
  color: var(--color-indigo-100);
  @media (max-width: ${size.md}px) {
    font-size: 10px;
  }
`;

const Content = styled(LexicalEditor.Content)`
  cursor: pointer;
  font-family: Kumbh Sans;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;

  @media (max-width: ${size.md}px) {
    font-size: 12px;
  }
`;

const TextButton = styled.button`
  padding: 0;
  background: none;
  border: none;

  font-family: Kumbh Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  text-decoration-line: underline;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;
