export const LoveEmojiIcon = ({ size = 1 }) => (
  <svg
    width={size * 30}
    height={size * 30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M8.42044 10.4915C8.46552 10.2596 8.56871 9.97547 8.76488 9.76172C8.933 9.57853 9.23177 9.375 9.84388 9.375C10.4456 9.375 10.7519 9.58275 10.9289 9.777C11.1333 10.0015 11.2319 10.2878 11.264 10.4729C11.3526 10.983 11.8379 11.3247 12.348 11.2362C12.8582 11.1476 13.1999 10.6622 13.1114 10.1521C13.0373 9.72563 12.8251 9.07444 12.3149 8.51434C11.7771 7.92392 10.9648 7.5 9.84388 7.5C8.73364 7.5 7.92463 7.90434 7.38352 8.49387C6.87047 9.05285 6.663 9.70622 6.57991 10.1335C6.48108 10.6418 6.81298 11.1339 7.32123 11.2327C7.82948 11.3316 8.32161 10.9997 8.42044 10.4915Z"
      fill="#402A32"
    />
    <path
      d="M18.7329 10.4915C18.778 10.2596 18.8812 9.97547 19.0773 9.76172C19.2454 9.57853 19.5442 9.375 20.1564 9.375C20.758 9.375 21.0644 9.58275 21.2413 9.777C21.4458 10.0015 21.5444 10.2878 21.5764 10.4729C21.665 10.983 22.1504 11.3247 22.6606 11.2362C23.1707 11.1476 23.5124 10.6622 23.4238 10.1521C23.3497 9.72563 23.1377 9.07444 22.6274 8.51434C22.0896 7.92392 21.2772 7.5 20.1564 7.5C19.0461 7.5 18.2371 7.90434 17.696 8.49387C17.1829 9.05285 16.9755 9.70622 16.8924 10.1335C16.7936 10.6418 17.1255 11.1339 17.6337 11.2327C18.1419 11.3316 18.6341 10.9997 18.7329 10.4915Z"
      fill="#402A32"
    />
    <path
      d="M11.25 14.0625C11.7678 14.0625 12.1875 14.4822 12.1875 15C12.1875 15.4749 12.3377 16.2083 12.7608 16.796C13.1495 17.3358 13.8055 17.8125 15 17.8125C16.1945 17.8125 16.8505 17.3358 17.2392 16.796C17.6623 16.2083 17.8125 15.4749 17.8125 15C17.8125 14.4822 18.2322 14.0625 18.75 14.0625C19.2678 14.0625 19.6875 14.4822 19.6875 15C19.6875 15.775 19.4627 16.9167 18.7608 17.8915C18.0245 18.9142 16.8055 19.6875 15 19.6875C13.1945 19.6875 11.9755 18.9142 11.2392 17.8915C10.5373 16.9167 10.3125 15.775 10.3125 15C10.3125 14.4822 10.7322 14.0625 11.25 14.0625Z"
      fill="#402A32"
    />
    <path
      d="M2.81293 1.03191C3.94982 0.694689 5.15656 1.40692 5.62531 1.87567C6.09395 1.40694 7.6579 0.158356 9.14085 1.64132C10.336 2.8364 9.84417 4.68816 8.90662 6.09441C8.35468 6.92232 6.92257 8.22415 5.76876 9.10308C5.16486 9.56309 4.3253 9.42678 3.81342 8.86619C2.94664 7.91692 1.84962 6.69924 1.31282 5.62566C0.375326 3.75066 1.31301 1.47681 2.81293 1.03191Z"
      fill="#F70A8D"
    />
    <path
      d="M3.75089 26.2497C2.70267 25.2016 2.34462 23.4372 3.28211 22.4997C4.2196 21.5622 6.09456 22.031 6.09456 22.031C6.09456 22.031 6.56339 20.8591 7.96964 21.0935C9.84469 21.3279 10.0791 23.4372 9.61031 24.8435C9.25209 25.9181 8.38455 27.0641 7.75453 27.8023C7.34945 28.277 6.65944 28.3451 6.14393 27.9935C5.27716 27.4022 4.0469 26.5457 3.75089 26.2497Z"
      fill="#F70A8D"
    />
    <path
      d="M24.8432 17.3418C24.5307 16.873 22.9682 15.5605 21.5619 16.0293C19.7831 16.6222 19.6869 18.748 20.6244 20.1543C21.2106 21.0335 22.8666 22.9983 23.7694 24.0542C24.1036 24.4451 24.6869 24.4881 25.0644 24.1388C26.1932 23.0945 28.3774 20.9282 29.0619 19.2168C29.5842 17.9111 29.0619 15.9355 27.1869 15.9355C25.6869 15.9355 24.9995 16.873 24.8432 17.3418Z"
      fill="#F70A8D"
    />
  </svg>
);
