const ModalKeys = {
  official_launch: {
    title: 'ModalKeys.official_launch.title',
    content: 'ModalKeys.official_launch.content',

    Email: {
      Confirm: {
        title: 'ModalKeys.Error.Modal.Email.Confirm.title',
        description: 'ModalKeys.Error.Modal.Email.Confirm.description',
      },

      Enter: {
        title: 'ModalKeys.Error.Modal.Email.Enter.title',
        description: 'ModalKeys.Error.Modal.Email.Enter.description',
      },

      resend: 'ModalKeys.Error.Modal.Email.resend',
    },

    Error: '',
  },

  Patch_Note: {
    close: 'ModalKeys.Patch_Note.close',
  },

  Notification: {
    Dropdown: {
      title: 'ModalKeys.Notification.Dropdown.title',
      no_notification: 'ModalKeys.Notification.Dropdown.no_notification',
      bottom_btn: 'ModalKeys.Notification.Dropdown.bottom_btn',
    },
  },

  My_Order: {
    Dropdown: {
      content: 'ModalKeys.My_Order.Dropdown.content',
      bottom_btn: 'ModalKeys.My_Order.Dropdown.bottom_btn',
    },
  },
};

export default ModalKeys;
