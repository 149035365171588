export const PessimisticEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M9.84375 15C12.1737 15 14.0625 13.1112 14.0625 10.7812C14.0625 8.4513 12.1737 6.5625 9.84375 6.5625C7.5138 6.5625 5.625 8.4513 5.625 10.7812C5.625 13.1112 7.5138 15 9.84375 15Z"
      fill="white"
    />
    <path
      d="M20.1562 15C22.4862 15 24.375 13.1112 24.375 10.7812C24.375 8.4513 22.4862 6.5625 20.1562 6.5625C17.8263 6.5625 15.9375 8.4513 15.9375 10.7812C15.9375 13.1112 17.8263 15 20.1562 15Z"
      fill="white"
    />
    <path
      d="M13.125 11.25C13.125 12.8033 11.8658 14.0625 10.3125 14.0625C8.7592 14.0625 7.5 12.8033 7.5 11.25C7.5 9.69666 8.7592 8.4375 10.3125 8.4375C11.8658 8.4375 13.125 9.69666 13.125 11.25Z"
      fill="#402A32"
    />
    <path
      d="M22.5 11.25C22.5 12.8033 21.2408 14.0625 19.6875 14.0625C18.1342 14.0625 16.875 12.8033 16.875 11.25C16.875 9.69666 18.1342 8.4375 19.6875 8.4375C21.2408 8.4375 22.5 9.69666 22.5 11.25Z"
      fill="#402A32"
    />
    <path
      d="M11.686 21.0879C13.1717 18.7488 16.2677 18.055 18.6045 19.5391C19.0416 19.8167 19.6209 19.6874 19.8985 19.2504C20.1761 18.8133 20.0469 18.234 19.6098 17.9564C16.3965 15.9156 12.1426 16.8718 10.1033 20.0827C9.82568 20.5198 9.95496 21.099 10.392 21.3766C10.8291 21.6542 11.4084 21.5249 11.686 21.0879Z"
      fill="#402A32"
    />
  </svg>
);
