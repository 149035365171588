import LoginKeys from '../translationKeys/LoginPageKeys';

export const LoginTranslations = {
  // Google
  [LoginKeys.Google.sign_in]: 'Sign in with Google',

  // Facebook
  [LoginKeys.Facebook.sign_in]: 'Sign in with Facebook',
  [LoginKeys.Facebook.sign_up]: 'Sign up with Facebook',

  // Wallet
  [LoginKeys.Wallet.sign_in]: 'Sign in with Wallet',
  [LoginKeys.Wallet.sign_up]: 'Sign up with Wallet',

  // Password
  [LoginKeys.Password.forgot]: 'Forgot password?',

  [LoginKeys.Password.Rule.title]: 'Your Password must contain:',
  [LoginKeys.Password.Rule.l1]: '· At least 8 characters',
  [LoginKeys.Password.Rule.l2]: '· At least 1 Alphabet character',
  [LoginKeys.Password.Rule.l3]: '· At least 1 number',
  [LoginKeys.Password.Rule.l4]: '· At least 1 special character (!,@,#,$,...)',

  // Login Form
  [LoginKeys.Login_Form.Placeholder.email]: 'Enter Email',
  [LoginKeys.Login_Form.Placeholder.password]: 'Enter password',

  [LoginKeys.Login_Form.no_account]: "Don't have an account?",
  [LoginKeys.Login_Form.create_account]: 'Create an Account',

  [LoginKeys.Login_Form.sign_up]: 'Sign Up',

  // Sign Up Form
  [LoginKeys.Sign_Up_Form.Placeholder.email]: 'Sign up with email',
  [LoginKeys.Sign_Up_Form.Placeholder.password]: 'Enter password',

  [LoginKeys.Sign_Up_Form.Terms_of_Service.title]:
    'Please agree to Terms of Service',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all]: 'Agree to all Terms',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description]:
    'This includes agreements to all required and optional terms. You can choose to agree or disagree to individual terms. You can use the service even if you do not agree to any of the optional terms.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.age]: 'I am 14 years old or older.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions]:
    'Terms & Conditions',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing]:
    'Receive events & marketing information',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing_description]:
    'You can receive useful newsletters and various information such as events and benefits provided by MegaEvolution via email and app.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies]:
    'Privacy Policies',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies]:
    'Payment Policies',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.optional]: '(optional)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.required]: '(required)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree]: 'Agree and Join',

  [LoginKeys.Sign_Up_Form.go_back]: 'Go back to login',

  [LoginKeys.Sign_Up_Form.password_updated]: 'Your password is updated!',
  [LoginKeys.Sign_Up_Form.thank_you_for_signing_up]:
    'Thank you for Signing Up!',

  // Error
  [LoginKeys.Error.network]: 'Network error - Please try again later.',
  [LoginKeys.Error.incorrect]: 'Incorrect email address or password.',
  [LoginKeys.Error.recently_deleted]:
    'This account was recently deleted. Please try again after 24 hours.',
  [LoginKeys.Error.login_failed]: 'Login failed. Please try again later',
  [LoginKeys.Error.already_in_use]: 'This email is already in use.',
  [LoginKeys.Error.wallet_not_verified]: 'The wallet could not be verified.',
  [LoginKeys.Error.already_in_use_or_connect_another_method]:
    'This email is already in use or connected to another sign in method.',
  [LoginKeys.Error.currently_deactiated_reset_password]:
    'This account is currently deactivated. Please reset password to reactivate',
  [LoginKeys.Error.currently_deactiated_login]:
    'This account is currently deactivated. Please attempt to login to reactivate',
  [LoginKeys.Error.enter_valid_email]: 'Enter a valid email address.',
  [LoginKeys.Error.invalid_request]: 'Invalid request',
  [LoginKeys.Error.too_many_request]:
    'Too many requests were made. Try again later.',
  [LoginKeys.Error.wallet_already_registered]:
    'This wallet is already registered.',
  [LoginKeys.Error.try_sign_in_social_app]:
    'Please try signing in with your social app.',
  [LoginKeys.Error.password_used]: 'This password was previously used.',

  // Modal
  [LoginKeys.Modal.Connect_Wallet.title]: 'Connect Wallet',
  [LoginKeys.Modal.Connect_Wallet.description]:
    'Choose one of available wallet providers or create a wallet first.',
  [LoginKeys.Modal.Reset_Password.reset]: 'Reset Password',

  [LoginKeys.Modal.Enter_Email.title]: 'Please enter your email',
  [LoginKeys.Modal.Enter_Email.description]: `
    Please enter your email to create an account. <br>
    You will receive important order notifications here.`,
  [LoginKeys.Modal.Enter_Email.Placeholder]: 'Enter email',
  [LoginKeys.Modal.Enter_Email.mobile_placeholder]: 'abcd@gmail.com',

  [LoginKeys.Modal.Reset_Password.description]:
    'Your password has expired. Please choose a new password.',
  [LoginKeys.Modal.Reset_Password.expired]: 'Your Password Is Expired!',
  [LoginKeys.Modal.Reset_Password.reset]: 'Reset Your Password',
  [LoginKeys.Modal.Reset_Password.update_success]: 'Your Password Is Updated!',

  [LoginKeys.Modal.Reset_Password.Error.valid_password]:
    'Enter a valid password',

  [LoginKeys.Modal.Useful_Reward.title]:
    'You will receive useful reward info if you subscribe!',
  [LoginKeys.Modal.Useful_Reward.description]: `
    You can unsubscribe in one-click whenever you want! <br>
    You will still receive important order notifications <br>
    even if you do not subscribe to our newsletter`,
  [LoginKeys.Modal.Useful_Reward.button]: 'Okay, I will try!',

  [LoginKeys.Modal.Verify_Email.title]: 'Please verify your Email',
  [LoginKeys.Modal.Verify_Email.description]: `
  Check your email at {{email}} and verify your account.`,

  [LoginKeys.Modal.Check_Email.title]: 'Please Check Your Email',
  [LoginKeys.Modal.Check_Email.description]:
    'Please check your email to reset your password.',
  [LoginKeys.Modal.Check_Email.button]: 'Resend Email',

  [LoginKeys.Modal.VIP_Influencer.description]: `
    Are you a creator? Switch now to <br>
    a creator to start getting offers!`,
  [LoginKeys.Modal.VIP_Influencer.im_creator]: "I'm a creator!",
  [LoginKeys.Modal.VIP_Influencer.no_im_not]: 'No, I am not',

  [LoginKeys.Modal.Welcome_On_Board.description]: `
    Welcome on board! <br>
    MEGA has a gift for you!`,
  [LoginKeys.Modal.Welcome_On_Board.hi_mega]: 'Hi, MEGA',

  [LoginKeys.Modal.Use_Another_Method.title]:
    'Please use another sign up method',
  [LoginKeys.Modal.Use_Another_Method.description]:
    'It seems that your Facebook account does not have a connected email address. To signup or login in to our platform using Facebook, an email address is required. Please use another sign up method to create an account',

  [LoginKeys.Modal.Enter_Your_Email.title]: 'Enter Your Email',
  [LoginKeys.Modal.Enter_Your_Email.description]:
    'Please enter your email address to receive a link to reset your password.',
  [LoginKeys.Modal.Enter_Your_Email.placeholder]: 'Enter your email',

  [LoginKeys.Modal.Reactivate.title]: 'Account currently deactivated',
  [LoginKeys.Modal.Reactivate.description]:
    'Would you like to reactivate your account?',
};
