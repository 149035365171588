import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: '저장 중',
  [ProposalKeys.changes_saved]: '변경 사항 저장됨',

  [ProposalKeys.New_Proposal.text]: '새 제안서',
  [ProposalKeys.New_Proposal.toast]:
    '만들 수 있는 제안 최대 수에 도달했습니다.',

  [ProposalKeys.Clear.text]: '모두 지우기',
  [ProposalKeys.Clear.toast]: `
    성공적으로 지워졌습니다. 취소하려면 <button>여기</button>를 클릭하세요.`,

  [ProposalKeys.Modal.Copy.title]: '제안을 덮어쓰시겠습니까?',
  [ProposalKeys.Modal.Copy.description]: `
    현재 제안서가 선택한 제안서에 덮어쓰기됩니다. <br>
    이 작업은 취소할 수 없습니다. 계속하시겠습니까?`,

  [ProposalKeys.Basic_Info.Project_Title.title]: '제목',
  [ProposalKeys.Basic_Info.Project_Title.placeholder]:
    '프로젝트 제목을 입력하세요',
  [ProposalKeys.Basic_Info.Project_Title.error]: '최대 100자',

  [ProposalKeys.Basic_Info.Website.title]: '제품 · 브랜드 웹사이트',
  [ProposalKeys.Basic_Info.Website.placeholder]: 'URL 입력',
  [ProposalKeys.Basic_Info.Website.error]: '최대 400자',

  [ProposalKeys.Basic_Info.Description.title]: '설명 및 파일',
  [ProposalKeys.Basic_Info.Description.placeholder]: `
    프로젝트의 세부 정보를 설명하고 파일을 첨부하세요. <br>
    크리에이터는 다음과 관련된 정보를 찾을 수 있습니다. <br><br><br>
    <ul>
      <li>프로젝트/제품 설명</li>
      <li>캠페인 목표 및 대상 관객</li>
      <li>상세 콘텐츠 가이드라인</li>
    </ul>`,
  [ProposalKeys.Basic_Info.Description.Snippet.title]: '추가할 스니펫 선택',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name]:
    '크리에이터의 소셜 채널 이름',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_username]:
    '크리에이터의 사용자 이름',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_main_country]:
    '크리에이터의 주요 국가',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories]:
    '크리에이터의 최상위 카테고리',

  [ProposalKeys.Basic_Info.Description.Error.file_format]: `
    잘못된 파일 형식입니다. (다음 파일 형식을 사용하세요: {{fileFormats}})`,
  [ProposalKeys.Basic_Info.Description.Error.file_size_limit]: `
    총 파일 크기 제한은 최대 {{maxSize}}입니다.`,
  [ProposalKeys.Basic_Info.Description.Error.max_file]: `
    최대 {{maxFiles}} 파일을 전송할 수 있습니다.`,

  [ProposalKeys.caution]: `
    패키지를 주문하면 모든 적용 가능한 <strong>요구 사항 및 콘텐츠 미션</strong>이 주문된 콘텐츠에 <strong>자동으로 적용</strong>됩니다. <strong>불완전한</strong> 또는 <strong>적용할 수 없는</strong> 경우 주문된 콘텐츠에 적용되지 않습니다.`,

  [ProposalKeys.Side_btn.text]: '적용 가능한 콘텐츠',
  [ProposalKeys.Side_btn.title]: '적용 가능한 콘텐츠 : ',

  [ProposalKeys.Product_Delivery.title]: '크리에이터에게 제품 보내기',

  [ProposalKeys.Product_Delivery.Delivery.title]: '협찬 제품 보내기',
  [ProposalKeys.Product_Delivery.Delivery.tooltip]: `
    이 옵션은 제안서에 아직 적용되지 않습니다.`,

  [ProposalKeys.Product_Delivery.Delivery.Image.title]:
    '배송 이미지 (선택 사항)',
  [ProposalKeys.Product_Delivery.Delivery.Image.description]:
    'JPG, JPEG, PNG, GIF 형식의 최대 5개의 이미지 파일을 추가하세요. 최대 100MB까지 가능합니다.',

  [ProposalKeys.Product_Delivery.Delivery.Info.title]: '배송 정보 (필수)',
  [ProposalKeys.Product_Delivery.Delivery.Info.placeholder]:
    '제품 및 배송에 관한 정보를 제공해주세요.',

  [ProposalKeys.Product_Delivery.Delivery.caution_due_date]:
    '크리에이터의 배송 주소를 제공받게 됩니다. 제품이 도착하면 콘텐츠 제작이 시작되며 마감일이 업데이트됩니다.',
  [ProposalKeys.Product_Delivery.Delivery.caution_return_process]:
    '배송된 제품에 대한 반품 처리를 하지 않습니다. 제품이 발송된 후 주문이 취소된 경우, 반품에 대한 직접 협의가 필요합니다.',
  [ProposalKeys.Product_Delivery.Delivery.caution_international_delivery]: `
    국제 배송을 추적하는 데 도움을 드리지만 배송 문제 (예: 잘못 배송)와 같은 문제에 대한 보장은 제공하지 않습니다.
    <Tooltip>국내 배송</Tooltip>은 일부 국가에 대해서만 추적 가능하며 주문에 포함시킬 수는 있지만 보장되지 않습니다.`,
  [ProposalKeys.Product_Delivery.Delivery
    .caution_international_delivery_tooltip]: `
    저희는 주요 국가, 미국 및 캐나다를 포함한 일부 국가에 대한 국내 추적을 지원합니다. <br>
    현재 다른 국가에 대한 국내 추적을 지원하지 않습니다.`,

  [ProposalKeys.Requirements.title]: '요구 사항',
  [ProposalKeys.Requirements.applied]: '{{number}}개 적용됨',

  [ProposalKeys.Requirements.Promotion.title]: '유료 프로모션 표시',
  [ProposalKeys.Requirements.Promotion.description_1]:
    '크리에이터가 유료 프로모션을 명확히 나타내기 위해 다음 해시태그 중 하나인 #Ad, #Advertisement 또는 #Sponsored를 해시태그 목록의 시작에 추가할 것입니다.',
  [ProposalKeys.Requirements.Promotion.description_2]:
    '크리에이터가 동영상 안에 제공된 "유료 프로모션 포함" 레이블을 추가할 것입니다.',
  [ProposalKeys.Requirements.Promotion.description_3]:
    '크리에이터가 Instagram 게시물/스토리/릴즈 내부에 제공된 "유료 제휴" 레이블을 추가할 것입니다.',

  [ProposalKeys.Requirements.Terms_Conditions.placeholder]:
    '여기에 귀사의 약관을 입력해주세요.',
  [ProposalKeys.Requirements.Terms_Conditions.description]:
    '크리에이터는 서비스 제공을 위해 귀사의 약관을 준수해야 합니다. 이 옵션의 수정 또는 비활성화를 요청할 수 있으며 이는 크리에이터의 서비스와 충돌하는 경우에 해당합니다.',

  [ProposalKeys.Checklist.title]: '체크리스트',

  [ProposalKeys.Content_Missions.title]: '콘텐츠 미션',

  [ProposalKeys.Content_Missions.Include_Link.title]: '링크 포함',
  [ProposalKeys.Content_Missions.Include_Link.placeholder]: 'URL 입력',
  [ProposalKeys.Content_Missions.Include_Link.description]:
    '제공된 링크는 게시물 또는 동영상 설명에 포함됩니다. 웹사이트 또는 앱으로 더 많은 트래픽을 유도하는 데 사용하세요.',

  [ProposalKeys.Content_Missions.Include_Hashtags.title]: '해시태그 포함',
  [ProposalKeys.Content_Missions.Include_Hashtags.placeholder]: '해시태그 입력',
  [ProposalKeys.Content_Missions.Include_Hashtags.btn]: '해시태그 추가',
  [ProposalKeys.Content_Missions.Include_Hashtags.description]:
    '왼쪽에서 제공된 해시태그 중 하나 이상이 게시물 또는 동영상 설명에 포함됩니다.',

  [ProposalKeys.Content_Missions.Tag_Location.title]: '위치 태그',
  [ProposalKeys.Content_Missions.Tag_Location.placeholder]: '위치 입력',
  [ProposalKeys.Content_Missions.Tag_Location.description]:
    '제공된 위치는 게시물 또는 동영상에 태그됩니다. 특정 위치 또는 장소를 홍보하는 데 효과적입니다.',

  [ProposalKeys.Content_Missions.Tag_Account.title]: '계정 태그',
  [ProposalKeys.Content_Missions.Tag_Account.placeholder]: '계정 핸들 입력',
  [ProposalKeys.Content_Missions.Tag_Account.description]:
    '계정 @핸들은 게시물 또는 동영상 설명에 포함됩니다. (주문된 콘텐츠의 플랫폼에 해당하는 핸들만 적용됩니다.)',

  [ProposalKeys.Content_Missions.Pin_Comment_Account.title]:
    '저희 계정을 포함한 코멘트 고정',
  [ProposalKeys.Content_Missions.Pin_Comment_Account.description]:
    '제공된 계정 @핸들을 포함한 코멘트가 게시되고 콘텐츠에 고정됩니다. (주문된 콘텐츠의 플랫폼에 해당하는 핸들만 적용됩니다.)',

  [ProposalKeys.Content_Missions.Pin_Comment_Link.title]:
    '링크를 포함한 코멘트 고정',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.placeholder]: 'URL 입력',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.description]:
    '제공된 링크를 포함한 코멘트가 게시되고 콘텐츠에 고정됩니다.',

  [ProposalKeys.Content_Missions.Include_Img.title]: '특정 이미지 포함',
  [ProposalKeys.Content_Missions.Include_Img.description]:
    '크리에이터에게 콘텐츠에 제공된 이미지 중 하나 이상을 포함하도록 안내됩니다.',

  [ProposalKeys.Content_Missions.Tag_Video.title]: '동영상 태그 추가',
  [ProposalKeys.Content_Missions.Tag_Video.placeholder]: '동영상 태그 입력',
  [ProposalKeys.Content_Missions.Tag_Video.btn]: '이 태그 추가',
  [ProposalKeys.Content_Missions.Tag_Video.description]:
    '왼쪽에서 제공된 태그 중 하나 이상이 YouTube 동영상에 추가되어 검색 최적화에 사용됩니다.',

  // View
  [ProposalKeys.View.copied_to_clipboard]: '클립보드에 복사됨',
  [ProposalKeys.View.Top.visit_website]: '웹사이트 방문하기',
  [ProposalKeys.View.ProductDelivery.title]: '제품 배송',
  [ProposalKeys.View.ProductDelivery.Physical.title]: '실제 배송 포함',
  [ProposalKeys.View.ProductDelivery.Physical.addressRequired]:
    '우편 주소 필요',
  [ProposalKeys.View.ProductDelivery.Physical.waitForProduct]:
    '제품 도착 후 콘텐츠 업로드',
  [ProposalKeys.View.Requirement.Tooltip.creator]: `
    콘텐츠 제작 시 필수 요청 사항에 안내된 <br />
    유료 광고 표시 및 약관 항목을 준수해주십시오. <br />
    미준수 시 수정 또는 환불 요청을 받을 수 있습니다.`,
  [ProposalKeys.View.Requirement.Tooltip.client]: `
    본 주문에 적용 가능한 요구 사항이 <br />
    자동으로 이곳에 표시 됩니다.`,
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_creator]: '당신의',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_client]: '클라이언트의',
  [ProposalKeys.View.Requirement.Ad.Checklist.sponsored]: `
    {{pronoun}} 콘텐츠를 명확하게 스폰서로 표시하세요.`,
  [ProposalKeys.View.Requirement.Ad.Checklist.useFollowingMethod]:
    '#Ad, #Advertisement 또는 플랫폼에서 제공하는 방법 중 하나를 사용하세요.',
  [ProposalKeys.View.Requirement.Ad.Checklist.applicableContents]: `
    {{number}}개의 콘텐츠에 적용 가능`,
  [ProposalKeys.View.Requirement.Ad.All.description]: `
    <strong>모든 플랫폼</strong> : 다음 해시태그 중 하나를 사용하여 유료 프로모션을 명확히 표시하세요:
    #Ad, #Advertisement 또는 #Sponsored. 이를 해시태그 목록의 시작에 배치하고
    "더 보기" 버튼을 클릭하지 않아도 보이도록 하십시오.`,
  [ProposalKeys.View.Requirement.Ad.YouTube.description]: `
    <strong>YouTube</strong> : 동영상에 "유료 프로모션 포함" 레이블을 추가하세요.
    이를 위해 YouTube 스튜디오로 이동하여 업로드한 동영상을 선택하고
    세부정보 > 유료 프로모션 > "시청자에게 유료 프로모션을 안내하는 메시지 추가" 옵션을 선택하세요.`,
  [ProposalKeys.View.Requirement.Ad.Instagram.description]: `
    <strong>Instagram</strong> : 게시할 때 "유료 파트너십" 레이블을 추가하세요.
    설정 > 크리에이터/비즈니스 > 브랜드 컨텐츠 > 브랜드 컨텐츠 도구 설정으로 이동하여
    이 기능을 활성화하세요. 활성화된 후 Instagram 게시물/스토리/릴스를 업로드할 때 레이블을 사용할 수 있습니다.`,
  [ProposalKeys.View.Requirement.Ad.TikTok.description]: `
    <strong>TikTok</strong> : 동영상을 업로드할 때 "유료 파트너십" 레이블을 추가하세요.
    게시할 동영상을 선택한 후 더 많은 옵션 > 브랜드 컨텐츠 및 광고 > "브랜드 컨텐츠"를 활성화하세요.`,
  [ProposalKeys.View.Requirement.Terms_Conditions.title]: '약관 및 조항',
  [ProposalKeys.View.Requirement.Terms_Conditions.Checklist
    .complyTermsConditions]: `
    {{user}}가 제공한 이용 약관을 준수해주세요.`,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.creator]: `
    각 콘텐츠에 해당되는 체크리스트를 완료해 주세요. <br />
    미완료 시 수정 또는 환불 요청을 받을 수 있습니다.`,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.client]: `
    본 주문에 적용 가능한 체크리스트 옵션이 <br />
    자동으로 이곳에 표시 됩니다.`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link.copy]:
    '링크 복사',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_creator]: `
    <li>제공된 링크를 게시물 또는 동영상 설명에 포함하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_client]: `
    <li>게시물 또는 동영상 설명에 제공된 링크를 포함하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.copy]:
    '해시태그 복사',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_creator]: `
    <li>왼쪽 목록에서 나열된 해시태그 중 하나 이상을 게시물 또는 동영상 설명에 포함하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_client]: `
    <li>왼쪽 목록에서 나열된 해시태그 중 하나 이상을 게시물 또는 동영상 설명에 포함하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location.copy]:
    '위치 복사',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_creator]: `
    <li>제공된 위치를 게시물 또는 동영상에 태그하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_client]: `
    <li>제공된 위치를 게시물 또는 동영상에 태그하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_creator]: `
    <li>게시물 또는 동영상 설명에 제공된 @handle을 포함하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_client]: `
    <li>게시물 또는 동영상 설명에 제공된 @handle을 포함하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_creator]: `
    <li>게시물에 제공된 계정 @handle을 포함한 코멘트를 작성하고 고정하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_client]: `
    <li>콘텐츠에 제공된 계정 @handle을 포함한 코멘트를 작성하고 고정하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Link
    .description]: `
    <li>게시물에 제공된 링크를 포함한 코멘트를 작성하고 고정하세요.</li>`,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Img.download]:
    '이미지 다운로드',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.copy]: '태그 복사',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.description]: `
    <li>왼쪽 목록에서 나열된 태그 중 하나 이상을 YouTube 동영상에 추가하세요.</li>
    <li>태그를 추가하려면 YouTube 스튜디오로 이동하여 업로드한 동영상을 선택하고 세부정보 > 태그로 이동하세요.</li>`,
};
