import {
  CheckCircleIcon,
  ExclamationSolidIcon,
  LoadingIcon,
} from 'assets/icons';
import { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Type } from './constants';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import device from 'styles/device';
import useTimer from './useTimer';

const Toast = ({
  className,
  content,
  onClose,
  position,
  type,
  animateIn,
  autoClose,
  autoCloseDelay,
  pauseOnHover,
  showProgress,
  closeOnClick,
  icon,
  maxWidth,
  onEnd = () => {},
}) => {
  const { isMobile } = useResponsiveScreen();
  const [closed, setClosed] = useState(false);

  const { timeRemaining, startTimer, pauseTimer } = useTimer(
    autoCloseDelay,
    !autoClose
  );

  useEffect(() => {
    if (autoClose) {
      startTimer();
    }

    return () => {
      pauseTimer();
    };
  }, [autoClose, startTimer, pauseTimer]);

  const handleClose = useCallback(() => {
    pauseTimer();
    setClosed(true);
    onEnd?.();
  }, [pauseTimer, onEnd]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      handleClose();
    }
  }, [timeRemaining, handleClose]);

  if (type === Type.CUSTOM)
    return (
      <ToastWrapperEmpty
        className={className}
        position={position}
        closed={closed}
        animateIn={animateIn}
        onAnimationEnd={() => {
          if (closed) {
            onClose();
          }
        }}
        onMouseEnter={() => {
          if (pauseOnHover && autoClose) pauseTimer();
        }}
        onMouseLeave={() => {
          if (pauseOnHover && autoClose) startTimer();
        }}
        onClick={() => {
          if (closeOnClick) {
            handleClose();
          }
        }}
      >
        {typeof content === 'function'
          ? content({ progress: 100 - (timeRemaining / autoCloseDelay) * 100 })
          : content}

        {showProgress && (
          <Progress
            progress={100 - (timeRemaining / autoCloseDelay) * 100}
            type={type}
          />
        )}
      </ToastWrapperEmpty>
    );

  return (
    <ToastWrapper
      className={`${className} ${isMobile ? 'sbh6' : 'sbh5'}`}
      position={position}
      closed={closed}
      animateIn={animateIn}
      onAnimationEnd={() => {
        if (closed) {
          onClose();
        }
      }}
      onMouseEnter={() => {
        if (pauseOnHover) pauseTimer();
      }}
      onMouseLeave={() => {
        if (pauseOnHover) startTimer();
      }}
      onClick={() => {
        if (closeOnClick) {
          handleClose();
        }
      }}
      maxWidth={maxWidth}
    >
      {
        {
          [Type.PLAIN]: null,
          [Type.SUCCESS]: (
            <Icon className="text-blue-400">
              <CheckCircleIcon size={isMobile ? 0.47 : 0.71} />
            </Icon>
          ),
          [Type.ERROR]: (
            <Icon className="text-red-500">
              <ExclamationSolidIcon size={isMobile ? 0.6 : 1} />
            </Icon>
          ),
          [Type.LOADING]: (
            <Icon className="text-indigo-500">
              <LoadingIcon size={isMobile ? 0.6 : 1} />
            </Icon>
          ),
          [Type.CUSTOM_ICON]: <Icon>{icon}</Icon>,
        }[type]
      }
      {content}
      {showProgress && (
        <Progress
          progress={100 - (timeRemaining / autoCloseDelay) * 100}
          type={type}
        />
      )}
    </ToastWrapper>
  );
};

const slideIn = (position) => keyframes`
    0% {
        transform: ${
          position.includes('bottom')
            ? 'translateY(150%)'
            : position.includes('top')
            ? position.includes('right')
              ? 'translateX(150%)'
              : position.includes('left')
              ? 'translateX(-150%)'
              : 'translateY(-150%)'
            : null
        } scale(0.9);
    }
    100% {
        transform: translate(0, 0) scale(1);
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.9);
    }
`;

const shake = keyframes`
    0% {
        transform: translateX(0) rotate(0);
    }
    25% {
        transform: translateX(-0.5%) rotate(-1deg);
    }
    50% {
        transform: translateX(0.5%) rotate(1deg);
    }
    75% {
        transform: translateX(-0.5%) rotate(-1deg);
    }
    100% {
        transform: translateX(0) rotate(0);
    }
`;

const ToastWrapper = styled.div`
  padding: 1rem 0.75rem;
  min-width: calc(100vw - 2rem);
  max-width: calc(100vw - 2rem);
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 0.625rem;
  background: white;
  border-radius: 0.375rem;
  box-shadow: var(--shadow-dropdown-art-assets);
  position: relative;
  overflow: hidden;
  transition: all 250ms ease-in-out;

  animation: ${({ closed, position, animateIn }) =>
      closed
        ? fadeOut
        : animateIn === 'none'
        ? 'none'
        : animateIn === 'shake'
        ? shake
        : slideIn(position)}
    250ms ease-in-out forwards;

  &:active {
    opacity: 0.8;
  }

  @media (${device.md}) {
    padding: 0.75rem 1.25rem;
    border-radius: 0.625rem;
    min-width: auto;
    max-width: ${({ maxWidth }) => maxWidth || 'calc(100vw - 2rem)'};
    justify-content: flex-start;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 250ms ease-in-out;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.7;
    }

    &:focus {
      outline: none;
    }
  }
`;

const ToastWrapperEmpty = styled.div`
  transition: all 250ms ease-in-out;

  animation: ${({ closed, position, animateIn }) =>
      closed
        ? fadeOut
        : animateIn === 'none'
        ? 'none'
        : animateIn === 'shake'
        ? shake
        : slideIn(position)}
    250ms ease-in-out forwards;
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const Progress = styled.div.attrs(({ progress }) => ({
  style: {
    width: `${progress}%`,
  },
}))`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.25rem;
  background: ${({ type }) =>
    ({
      [Type.SUCCESS]: 'var(--color-blue-400)',
      [Type.ERROR]: 'var(--color-red-500)',
    }[type] || 'var(--color-blue-400)')};
`;

export default Toast;
