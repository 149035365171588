export const BellGoldIcon = ({ size = 1 }) => (
  <svg
    width="46"
    height="48"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_70251_26083)">
      <path
        d="M22.9981 42.4494C25.806 42.4494 28.0822 40.1732 28.0822 37.3653C28.0822 34.5575 25.806 32.2812 22.9981 32.2812C20.1903 32.2812 17.9141 34.5575 17.9141 37.3653C17.9141 40.1732 20.1903 42.4494 22.9981 42.4494Z"
        fill="url(#paint0_radial_70251_26083)"
      />
      <path
        d="M22.9981 42.4494C25.806 42.4494 28.0822 40.1732 28.0822 37.3653C28.0822 34.5575 25.806 32.2812 22.9981 32.2812C20.1903 32.2812 17.9141 34.5575 17.9141 37.3653C17.9141 40.1732 20.1903 42.4494 22.9981 42.4494Z"
        fill="url(#paint1_linear_70251_26083)"
      />
      <path
        d="M22.9981 42.4494C25.806 42.4494 28.0822 40.1732 28.0822 37.3653C28.0822 34.5575 25.806 32.2812 22.9981 32.2812C20.1903 32.2812 17.9141 34.5575 17.9141 37.3653C17.9141 40.1732 20.1903 42.4494 22.9981 42.4494Z"
        fill="url(#paint2_radial_70251_26083)"
      />
      <g filter="url(#filter1_i_70251_26083)">
        <path
          d="M24.6729 5.4224C24.6729 4.49876 23.9241 3.75 23.0005 3.75C22.0769 3.75 21.3281 4.49876 21.3281 5.4224V8.1347C21.3281 9.05834 22.0769 9.8071 23.0005 9.8071C23.9241 9.8071 24.6729 9.05834 24.6729 8.1347V5.4224Z"
          fill="url(#paint3_radial_70251_26083)"
        />
      </g>
      <g filter="url(#filter2_iii_70251_26083)">
        <path
          d="M11.8593 15.7115C12.8897 10.5532 17.3941 6.82903 22.644 6.77749C22.6808 6.77716 22.7178 6.77749 22.7547 6.77749H23.2453C23.2822 6.77749 23.319 6.77716 23.3559 6.77749C28.6058 6.82903 33.1102 10.5532 34.1407 15.7115L36.7255 28.6509C36.9114 29.5813 37.3324 30.4485 37.9485 31.1699L39.6694 33.1853C40.5282 34.1911 41 35.4702 41 36.7927V38.6343C41 39.2096 40.5336 39.6759 39.9584 39.6759H6.04162C5.46634 39.6759 5 39.2096 5 38.6343V36.7927C5 35.4702 5.47183 34.1911 6.33063 33.1853L8.05146 31.1699C8.66755 30.4485 9.08859 29.5813 9.27446 28.6509L11.8593 15.7115Z"
          fill="url(#paint4_linear_70251_26083)"
        />
      </g>
      <path
        d="M11.8593 15.7095C12.8897 10.5512 17.3941 6.82708 22.644 6.77554C22.6808 6.77521 22.7178 6.77554 22.7547 6.77554H23.2453C23.2822 6.77554 23.319 6.77521 23.3559 6.77554C28.6058 6.82708 33.1102 10.5512 34.1407 15.7095L36.7255 28.6489C36.9114 29.5793 37.3324 30.4465 37.9485 31.168L39.6694 33.1833C40.5282 34.1891 41 35.4682 41 36.7908V38.6324C41 39.2076 40.5336 39.674 39.9584 39.674H6.04162C5.46634 39.674 5 39.2076 5 38.6324V36.7908C5 35.4682 5.47183 34.1891 6.33063 33.1833L8.05146 31.168C8.66755 30.4465 9.08859 29.5793 9.27446 28.6489L11.8593 15.7095Z"
        fill="url(#paint5_linear_70251_26083)"
      />
      <path
        d="M11.8593 15.7095C12.8897 10.5512 17.3941 6.82708 22.644 6.77554C22.6808 6.77521 22.7178 6.77554 22.7547 6.77554H23.2453C23.2822 6.77554 23.319 6.77521 23.3559 6.77554C28.6058 6.82708 33.1102 10.5512 34.1407 15.7095L36.7255 28.6489C36.9114 29.5793 37.3324 30.4465 37.9485 31.168L39.6694 33.1833C40.5282 34.1891 41 35.4682 41 36.7908V38.6324C41 39.2076 40.5336 39.674 39.9584 39.674H6.04162C5.46634 39.674 5 39.2076 5 38.6324V36.7908C5 35.4682 5.47183 34.1891 6.33063 33.1833L8.05146 31.168C8.66755 30.4465 9.08859 29.5793 9.27446 28.6489L11.8593 15.7095Z"
        fill="url(#paint6_radial_70251_26083)"
      />
      <g filter="url(#filter3_f_70251_26083)">
        <path
          d="M11.17 30.4922L9 33.4434H38.1654L35.8217 30.4922H11.17Z"
          fill="url(#paint7_linear_70251_26083)"
        />
      </g>
      <g filter="url(#filter4_f_70251_26083)">
        <path
          d="M24.0938 13.8471C24.0938 12.1107 25.5013 10.7031 27.2376 10.7031C28.6885 10.7031 29.9511 11.696 30.293 13.1061L34.5099 30.4939H24.0938V13.8471Z"
          fill="url(#paint8_linear_70251_26083)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_70251_26083"
        x="2"
        y="2.75"
        width="42"
        height="44.6992"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_70251_26083"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_70251_26083"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_70251_26083"
        x="21.3281"
        y="3.75"
        width="3.54375"
        height="6.05664"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.2" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.87451 0 0 0 0 0.564706 0 0 0 0 0.341176 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_70251_26083"
        />
      </filter>
      <filter
        id="filter2_iii_70251_26083"
        x="1"
        y="6.77734"
        width="41"
        height="32.8984"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.905882 0 0 0 0 0.392157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_70251_26083"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.996078 0 0 0 0 0.866667 0 0 0 0 0.462745 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_70251_26083"
          result="effect2_innerShadow_70251_26083"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.917647 0 0 0 0 0.572549 0 0 0 0 0.32549 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_innerShadow_70251_26083"
          result="effect3_innerShadow_70251_26083"
        />
      </filter>
      <filter
        id="filter3_f_70251_26083"
        x="7"
        y="28.4922"
        width="33.1641"
        height="6.95117"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1"
          result="effect1_foregroundBlur_70251_26083"
        />
      </filter>
      <filter
        id="filter4_f_70251_26083"
        x="22.0938"
        y="8.70312"
        width="14.418"
        height="23.791"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1"
          result="effect1_foregroundBlur_70251_26083"
        />
      </filter>
      <radialGradient
        id="paint0_radial_70251_26083"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.4398 41.9433) rotate(-126.512) scale(4.10392 5.66771)"
      >
        <stop stop-color="#FFC26D" />
        <stop offset="1" stop-color="#F68D61" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_70251_26083"
        x1="21.9678"
        y1="38.6014"
        x2="22.315"
        y2="41.0753"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.186236" stop-color="#E57356" />
        <stop offset="1" stop-color="#E57356" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_70251_26083"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.2246 37.9938) rotate(135) scale(5.64677 9.31771)"
      >
        <stop offset="0.441043" stop-color="#EA8059" stop-opacity="0" />
        <stop offset="1" stop-color="#C27068" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_70251_26083"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(24.0994 6.77856) rotate(-90) scale(3.02856 1.82283)"
      >
        <stop stop-color="#FFE957" />
        <stop offset="1" stop-color="#F7A630" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_70251_26083"
        x1="26.4269"
        y1="37.1262"
        x2="7.64745"
        y2="37.1262"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FCB949" />
        <stop offset="1" stop-color="#E28C37" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_70251_26083"
        x1="25.9217"
        y1="40.0333"
        x2="25.9217"
        y2="35.8668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D7737D" />
        <stop offset="0.302084" stop-color="#FCA162" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint6_radial_70251_26083"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(31.3902 21.1973) rotate(146.139) scale(31.7805 30.4871)"
      >
        <stop offset="0.670378" stop-color="#E69136" stop-opacity="0" />
        <stop offset="0.977283" stop-color="#CF7D44" />
      </radialGradient>
      <linearGradient
        id="paint7_linear_70251_26083"
        x1="9.43401"
        y1="33.4434"
        x2="37.2974"
        y2="33.4434"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.277259" stop-color="#F5A43C" />
        <stop offset="1" stop-color="#FFE154" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_70251_26083"
        x1="35.7353"
        y1="26.6879"
        x2="22.3577"
        y2="26.6879"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.117557" stop-color="#FFEF6D" />
        <stop offset="1" stop-color="#FFEF6D" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
