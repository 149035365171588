import 'dayjs/locale/zh';

const zhLocale = {
  name: 'zh',
  relativeTime: {
    future: '%s后',
    past: '%s前',
    s: '1分钟',
    m: '1分钟',
    mm: '%d分钟',
    h: '1小时',
    hh: '%d小时',
    d: '1天',
    dd: '%d天',
    M: '1个月',
    MM: '%d个月',
    y: '1年',
    yy: '%d年',
  },
  dateFormat: {
    dot_date: 'YYYY.MM.DD',
    dash_date: 'YYYY-MM-DD',
    dot_date_time_12: 'YYYY.MM.DD A h:mm',
    time_12: 'A h:mm',
    abbr_date_time_12: 'M[月] D[号], A h:mm',
    date_time: 'YYYY[年] M[月] D[号]',
    yesterday: '昨天',
    full_abbr_date_time_12: 'YYYY[年] M[月] D[号] A h:mm',
  },
  meridiem: (hour) => {
    return hour > 12 ? '下午' : '上午';
  },
};

export default zhLocale;
