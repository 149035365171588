import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'Traducción automática',
  [AutoTranslationKeys.Dropdown.description]:
    'Traducir automáticamente descripciones y texto escrito por el usuario.',
  [AutoTranslationKeys.Dropdown.order_message_title]:
    'Traducción de Pedidos y Mensajes',
  [AutoTranslationKeys.Dropdown.order_message_title_mobile]:
    'Traducción de <br> Pedidos y Mensajes',
  [AutoTranslationKeys.Dropdown.order_message_description]:
    'Quedan {{number}} caracteres · Actualizado el {{date}}',
  [AutoTranslationKeys.Dropdown.order_message_description_mobile]: `
    Quedan {{number}} caracteres <br> 
    Actualizado el {{date}}`,

  [AutoTranslationKeys.Applying_Translation.title]:
    'Traducción automática aplicada.',
  [AutoTranslationKeys.Applying_Translation.btn]: 'Mostrar texto original',
  [AutoTranslationKeys.Applying_Translation.message]: 'Traducido',

  [AutoTranslationKeys.Displaying_Original.title]: 'Mostrando texto original.',
  [AutoTranslationKeys.Displaying_Original.btn]: 'Traducir',
  [AutoTranslationKeys.Displaying_Original.message]: 'Texto original',
};
