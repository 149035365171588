export const MinusIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 12}
    height={size * 3}
    viewBox="0 0 12 3"
    fill="currentColor"
  >
    <path d="M11.25 0.601562H0.75C0.551088 0.601562 0.360322 0.696384 0.21967 0.865167C0.0790178 1.03395 0 1.26287 0 1.50156C0 1.74026 0.0790178 1.96918 0.21967 2.13796C0.360322 2.30674 0.551088 2.40156 0.75 2.40156H11.25C11.4489 2.40156 11.6397 2.30674 11.7803 2.13796C11.921 1.96918 12 1.74026 12 1.50156C12 1.26287 11.921 1.03395 11.7803 0.865167C11.6397 0.696384 11.4489 0.601562 11.25 0.601562Z" />
  </svg>
);
