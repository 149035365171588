export const OptimisticEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15.0008 0.9375C11.8346 0.9375 8.93123 1.17731 6.7867 1.57941C5.72599 1.77829 4.7796 2.02968 4.06498 2.34647C3.71252 2.50271 3.33755 2.70878 3.02934 2.9912C2.99552 3.02218 2.9608 3.05575 2.92578 3.09188L2.92895 3.08865C3.20151 3.42231 4.39771 4.44151 4.39771 4.44151C4.39771 4.44151 7.46133 4.68749 7.96956 4.68749H8.03619C9.8934 4.97658 12.3213 5.15629 15.0008 5.15629C17.7398 5.15629 20.2159 4.96851 22.0883 4.66807C22.7323 4.59855 25.264 4.53708 25.264 4.53708C25.264 4.53708 26.7196 3.24051 26.7196 2.81249V2.78573C26.8073 2.84936 26.8921 2.91772 26.9723 2.99123C26.6641 2.70882 26.2891 2.50271 25.9367 2.34647C25.222 2.02968 24.2756 1.77829 23.2149 1.57941C21.0704 1.17731 18.167 0.9375 15.0008 0.9375Z"
      fill="#1C5CD7"
    />
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      d="M8.76488 12.5742C8.56871 12.788 8.46552 13.0721 8.42044 13.304C8.32161 13.8122 7.82948 14.1441 7.32123 14.0452C6.81298 13.9464 6.48108 13.4543 6.57991 12.946C6.663 12.5187 6.87047 11.8654 7.38352 11.3063C7.92463 10.7168 8.73364 10.3125 9.84388 10.3125C10.9648 10.3125 11.7771 10.7364 12.3149 11.3269C12.8251 11.8869 13.0373 12.5381 13.1114 12.9646C13.1999 13.4747 12.8582 13.9601 12.348 14.0487C11.8379 14.1372 11.3526 13.7955 11.264 13.2854C11.2319 13.1003 11.1333 12.814 10.9289 12.5895C10.7519 12.3952 10.4456 12.1875 9.84388 12.1875C9.23177 12.1875 8.933 12.391 8.76488 12.5742Z"
      fill="#402A32"
    />
    <path
      d="M19.0773 12.5742C18.8812 12.788 18.778 13.0721 18.7329 13.304C18.6341 13.8122 18.1419 14.1441 17.6337 14.0452C17.1255 13.9464 16.7936 13.4543 16.8924 12.946C16.9755 12.5187 17.1829 11.8654 17.696 11.3063C18.2371 10.7168 19.0461 10.3125 20.1564 10.3125C21.2772 10.3125 22.0896 10.7364 22.6274 11.3269C23.1377 11.8869 23.3497 12.5381 23.4238 12.9646C23.5124 13.4747 23.1707 13.9601 22.6606 14.0487C22.1504 14.1372 21.665 13.7955 21.5764 13.2854C21.5444 13.1003 21.4458 12.814 21.2413 12.5895C21.0644 12.3952 20.758 12.1875 20.1564 12.1875C19.5442 12.1875 19.2454 12.391 19.0773 12.5742Z"
      fill="#402A32"
    />
    <path
      d="M12.1875 17.8125C12.1875 17.2947 11.7678 16.875 11.25 16.875C10.7322 16.875 10.3125 17.2947 10.3125 17.8125C10.3125 18.5875 10.5373 19.7292 11.2392 20.704C11.9755 21.7267 13.1945 22.5 15 22.5C16.8055 22.5 18.0245 21.7267 18.7608 20.704C19.4627 19.7292 19.6875 18.5875 19.6875 17.8125C19.6875 17.2947 19.2678 16.875 18.75 16.875C18.2322 16.875 17.8125 17.2947 17.8125 17.8125C17.8125 18.2874 17.6623 19.0208 17.2392 19.6085C16.8505 20.1483 16.1945 20.625 15 20.625C13.8055 20.625 13.1495 20.1483 12.7608 19.6085C12.3377 19.0208 12.1875 18.2874 12.1875 17.8125Z"
      fill="#402A32"
    />
    <path
      d="M2.92815 3.09199C3.61182 3.92894 7.1236 4.69083 7.96875 4.69083H8.03538C9.89259 4.97992 12.3205 5.15962 15 5.15962C17.739 5.15962 20.2151 4.97184 22.0875 4.6714C23.3717 4.53277 26.7188 3.6743 26.7188 2.81583V2.78906C26.8065 2.8527 26.8913 2.92105 26.9715 2.99457C27.277 3.27455 27.6562 3.76467 27.6562 4.4565C27.6562 5.14833 27.277 5.63845 26.9715 5.91844C26.6632 6.20085 26.2882 6.40691 25.9358 6.56316C25.2212 6.87995 24.2748 7.13134 23.2141 7.33022C21.0696 7.73232 18.1662 7.97212 15 7.97212C11.8338 7.97212 8.93042 7.73232 6.7859 7.33022C5.72518 7.13134 4.7788 6.87995 4.06418 6.56316C3.71171 6.40691 3.33675 6.20085 3.02854 5.91844C2.72298 5.63845 2.34375 5.14833 2.34375 4.4565C2.34375 3.83894 2.64593 3.3821 2.92815 3.09199Z"
      fill="#3F5FFF"
    />
  </svg>
);
