export const NoIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 18}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM1.8 9C1.8 5.04 5.04 1.8 9 1.8C10.62 1.8 12.15 2.34 13.41 3.33L3.33 13.41C2.34 12.15 1.8 10.62 1.8 9ZM9 16.2C7.38 16.2 5.85 15.66 4.59 14.67L14.67 4.59C15.66 5.85 16.2 7.38 16.2 9C16.2 12.96 12.96 16.2 9 16.2Z" />
  </svg>
);
