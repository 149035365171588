import PricingKeys from '../translationKeys/PricingPageKeys';

export const PricingTranslations = {
  [PricingKeys.preparing]: '(En preparación)',
  [PricingKeys.order_now]: 'Ordenar ahora',
  [PricingKeys.get_free]: 'Obtén un pase premium gratuita',
  [PricingKeys.get_for_free]: 'Obtenerlo gratis',
  [PricingKeys.current_plan]: 'Plan actual',
  [PricingKeys.company_verified]: 'Empresa verificada',

  // Banner
  [PricingKeys.Banner.title]: '¡Simplifica e inteligentiza tu marketing!',
  [PricingKeys.Banner.description]:
    '¡Nuestros planes le ayudan a elevar el rendimiento de su marketing al siguiente nivel!',

  [PricingKeys.Verified.Error.already_verified]: `
    Su cuenta ya está verificada como marca o empresa.`,
  [PricingKeys.Verified.Error.already_using]: `
    Su cuenta ya está utilizando este plan. <br>
    <span>Haz clic aquí</span> para ver sus suscripciones.`,
  [PricingKeys.Verified.Error.under_review]: `
    Su cuenta está siendo revisada. <br>
    Por favor, inténtelo de nuevo luego.`,

  // Left Col
  // General
  [PricingKeys.Left_Col.General.verification_req]: 'Verificación requerida',
  [PricingKeys.Left_Col.General.smart_search]: 'Búsqueda inteligente',

  // Analytics & Data
  [PricingKeys.Left_Col.Analytics_Data.title]: 'Analíticas y Datos',

  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title]: `
  Analítica Básica <br>
  del Creador`,
  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip]: `
  Esto incluye análisis de canales y contenido <br> 
  de las cuentas de redes sociales del creador.`,

  [PricingKeys.Left_Col.Analytics_Data.audience_analytics]:
    'Analíticas de Audiencia',

  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title]:
    'Desbloqueo de Precio',
  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip]: `
    Puede desbloquear el precio de los servicios del creador <br>
    utilizando esta cuota mensual. Los precios se volverán <br>
    a bloquear después de 72 horas al desbloquear.`,

  [PricingKeys.Left_Col.Analytics_Data.order_analytics]:
    'Analíticas de Pedidos',

  [PricingKeys.Left_Col.Analytics_Data.creator_reviews]: 'Reseñas del Creador',

  // Orders
  [PricingKeys.Left_Col.Orders.title]: `Pedidos y Creadores`,
  [PricingKeys.Left_Col.Orders.order_packages]: 'Ordenar Paquetes de Creadores',

  [PricingKeys.Left_Col.Orders.message_creators]:
    'Enviar mensajes a nuevos creadores antes de comenzar un pedido',

  [PricingKeys.Left_Col.Orders.Order_Discount.title]: 'Descuento del Pedido',
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1]: `
  El descuento se aplica al precio total <br>
  de cada pedido con creadores. <br>
  * No se aplica a elementos adicionales, costo de entrega <br>
  y suscripciones mensuales.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2]: `
  El descuento se aplica al precio total de cada pedido con creadores.<br>
  No se aplica a algunos elementos adicionales o suscripciones a la plataforma.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.content]: `
  <Tooltip><span>{{number}}</span></Tooltip>  del precio del pedido`,

  [PricingKeys.Left_Col.Orders.Track_Delivery.title]: `
  Seguir Entregas <br> 
  Internacionales`,
  [PricingKeys.Left_Col.Orders.Track_Delivery.tooltip]: `
  Ayudamos a rastrear entregas internacionales de pedidos. <br>
  El seguimiento de entregas nacionales está disponible en países limitados. <br><br>
  * Nota: No realizamos ni garantizamos el proceso de envío real.`,

  [PricingKeys.Left_Col.Orders.auto_translation]: `
    Mensajes & Pedidos <br>
    Traducción automática`,
  [PricingKeys.Left_Col.Orders.character]: '{{number}}caráct.',

  [PricingKeys.Summary.basic]: `
    <li>Gratis pero poderoso</li>
    <li>Incluye todas las funciones básicas para mejorar los resultados de marketing</li>`,
  [PricingKeys.Summary.verified]: `
    <li>Verificado en solo 1~3 minutos</li>
    <li>Ideal para marcas y negocios globales</li>`,
  [PricingKeys.Summary.premium]: `
    <li>Gran capacidad</li>
    <li>Se requiere verificación</li>
    <li>Ideal para empresas globales centradas en la escalabilidad</li>`,

  // Creator
  [PricingKeys.Creator.title]: 'Pase Premium para Creadores Tempranos',

  [PricingKeys.Creator.h1]:
    'Con un pase premium, los creadores están exentos de las tarifas de servicio de MegaEvolution.',
  [PricingKeys.Creator.h2]:
    '¡Utilice nuestra plataforma para compartir completamente su talento con el mundo!',
  [PricingKeys.Creator.h3]: `
    * No cubrimos las pequeñas tarifas de transacción de terceros <br>
    (por ejemplo, Paypal) necesarias para retirar su saldo.`,

  // Modal
  [PricingKeys.Modal.title]: 'Verificación de Marca · Empresa',
  [PricingKeys.Modal.estimate]: 'Est. 1~2 minutos',
  [PricingKeys.Modal.caution]:
    '* Información falsa o incorrecta puede llevar a la pérdida de la verificación o a la prohibición permanente de la cuenta.',
  [PricingKeys.Modal.toast]: '¡Su cuenta ha sido verificada con éxito!',

  [PricingKeys.Modal.Btn.proceed_final]: 'Continuar con el paso final',
  [PricingKeys.Modal.Btn.verify_email]: 'Verificar correo electrónico',
  [PricingKeys.Modal.Btn.no_company_email]:
    'No tengo correo electrónico de la empresa',
  [PricingKeys.Modal.Btn.verify_with_email]: 'Verificar con correo electrónico',
  [PricingKeys.Modal.Btn.complete_verification]: 'Completar verificación',
  [PricingKeys.Modal.Btn.another_email]: 'Usar otro correo electrónico',
  [PricingKeys.Modal.Btn.resend_code]: 'Reenviar código',

  [PricingKeys.Modal.Placeholder.firstname]: 'Por favor, ingrese su nombre',
  [PricingKeys.Modal.Placeholder.lastname]: 'Por favor, ingrese su apellido',
  [PricingKeys.Modal.Placeholder.companyname]:
    'Ingrese el nombre de la empresa',
  [PricingKeys.Modal.Placeholder.companyUrl]:
    'Ingrese la URL del sitio web de la empresa (ejemplo: www.megaevolution.io)',
  [PricingKeys.Modal.Placeholder.companyEmail]:
    'Por favor, ingrese su correo electrónico de la empresa',
  [PricingKeys.Modal.Placeholder.enter_code]:
    'Por favor, ingrese el código que recibió',

  [PricingKeys.Modal.CompanyType.title]: 'Seleccionar tipo de empresa',
  [PricingKeys.Modal.CompanyType.option_1]: 'Marca · Negocio',
  [PricingKeys.Modal.CompanyType.option_2]: 'Tienda de comercio electrónico',
  [PricingKeys.Modal.CompanyType.option_3]: 'Agencia de marketing',
  [PricingKeys.Modal.CompanyType.option_4]: 'Otros',

  [PricingKeys.Modal.JobPosition.title]: 'Seleccionar posición laboral',
  [PricingKeys.Modal.JobPosition.option_1]: 'Asistente',
  [PricingKeys.Modal.JobPosition.option_2]: 'C-Level · VP',
  [PricingKeys.Modal.JobPosition.option_3]: 'Director',
  [PricingKeys.Modal.JobPosition.option_4]: 'Ejecutivo',
  [PricingKeys.Modal.JobPosition.option_5]: 'Jefe de departamento',
  [PricingKeys.Modal.JobPosition.option_6]: 'Independiente',
  [PricingKeys.Modal.JobPosition.option_7]: 'Gerente · Líder de equipo',
  [PricingKeys.Modal.JobPosition.option_8]: 'Practicante',
  [PricingKeys.Modal.JobPosition.option_9]: 'Miembro del equipo',

  // Final
  [PricingKeys.Modal.Final.explain]: `
    <li>Por favor, cargue uno de los siguientes archivos para la verificación: certificado de registro de empresa, factura de impuestos, tarjeta de identificación de empleado u otros documentos de respaldo que verifiquen la existencia de su empresa.</li>
    <li>El archivo debe estar en formato JPG, JPEG, PNG o PDF con una resolución de al menos 300 ppp.</li>`,
  [PricingKeys.Modal.Final.image_placeholder]:
    'Cargue un archivo en formato JPG, JPEG, PNG o PDF. Máximo 5 MB.',
  [PricingKeys.Modal.Final.description_placeholder]:
    'Por favor, cuéntenos acerca de su empresa y qué producto, servicio o marca está tratando de promocionar a través de la plataforma.',
  [PricingKeys.Modal.Final.spam_explain]:
    '* Si no lo encuentra, por favor, revise su carpeta de spam.',

  // Popup
  [PricingKeys.Modal.Popup.title]: '¡Su formulario se ha enviado con éxito!',
  [PricingKeys.Modal.Popup.description]:
    'La verificación suele tardar entre 1 y 3 días laborables. Recibirá una notificación por correo electrónico con los resultados después de la revisión.',

  // Error
  [PricingKeys.Modal.Error.required_field]: 'Campo obligatorio',
  [PricingKeys.Modal.Error.select_option]: 'Por favor, seleccione una opción',
  [PricingKeys.Modal.Error.valid_url]: 'Ingrese una URL válida.',
  [PricingKeys.Modal.Error.valid_email]: 'Ingrese un correo electrónico válido',
  [PricingKeys.Modal.Error.email_validation]:
    'No se pueden utilizar correos electrónicos personales (por ejemplo, no se permiten gmail.com, yahoo.com, etc.)',
  [PricingKeys.Modal.Error.already_verified]:
    'Este correo electrónico ya está en uso para la verificación',
  [PricingKeys.Modal.Error.already_sent_code]:
    'El código ya se ha enviado a este correo electrónico. Por favor, revise su bandeja de entrada.',
  [PricingKeys.Modal.Error.invalid_code]: 'Código no válido',
  [PricingKeys.Modal.Error.invalid_file_format]:
    'Formato de archivo no válido. (Por favor, utilice {{fileFormats}})',
  [PricingKeys.Modal.Error.total_file_size]:
    'El límite de tamaño total de los archivos es máximo {{maxSize}}',
  [PricingKeys.Modal.Error.send_max_files]:
    'Puede enviar un máximo de {{maxFiles}} archivos',
};
