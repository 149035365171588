export const GoogleTranslateIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 16}
    height={size * 17}
    viewBox="0 0 16 17"
    fill="currentColor"
  >
    <path d="M14.4 2.9H7.104L6.4 0.5H1.6C0.72 0.5 0 1.22 0 2.1V12.5C0 13.38 0.72 14.1 1.6 14.1H7.2L8 16.5H14.4C15.28 16.5 16 15.78 16 14.9V4.5C16 3.62 15.28 2.9 14.4 2.9ZM4.136 10.572C2.336 10.572 0.864 9.108 0.864 7.3C0.864 5.492 2.328 4.028 4.136 4.028C4.968 4.028 5.728 4.324 6.328 4.884L6.384 4.932L5.4 5.876L5.352 5.836C5.12 5.62 4.728 5.364 4.136 5.364C3.088 5.364 2.232 6.236 2.232 7.3C2.232 8.364 3.088 9.236 4.136 9.236C5.232 9.236 5.704 8.54 5.832 8.068H4.064V6.828H7.224L7.232 6.884C7.264 7.052 7.272 7.204 7.272 7.372C7.272 9.252 5.984 10.572 4.136 10.572ZM8.96 9.204C9.224 9.684 9.552 10.148 9.912 10.564L9.48 10.988L8.96 9.204ZM9.576 8.596H8.784L8.536 7.764H11.728C11.728 7.764 11.456 8.812 10.48 9.956C10.064 9.46 9.768 8.972 9.576 8.596ZM15.2 14.9C15.2 15.34 14.84 15.7 14.4 15.7H8.8L10.4 14.1L9.752 11.884L10.488 11.148L12.632 13.3L13.216 12.716L11.048 10.572C11.768 9.748 12.328 8.772 12.584 7.764H13.6V6.932H10.688V6.1H9.856V6.932H8.288L7.344 3.7H14.4C14.84 3.7 15.2 4.06 15.2 4.5V14.9Z" />
  </svg>
);
