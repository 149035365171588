import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: 'guardando',
  [ProposalKeys.changes_saved]: 'cambios guardados',

  [ProposalKeys.New_Proposal.text]: 'Nueva propuesta',
  [ProposalKeys.New_Proposal.toast]:
    'Ha alcanzado el número máximo de propuestas que puede crear.',

  [ProposalKeys.Clear.text]: 'Limpiar todo',
  [ProposalKeys.Clear.toast]:
    'Se ha limpiado con éxito. Haga clic aquí para deshacer este cambio.',

  [ProposalKeys.Modal.Copy.title]:
    '¿Estás seguro de que deseas sobrescribir la propuesta?',
  [ProposalKeys.Modal.Copy.description]: `
    La propuesta actual será sobrescrita por la propuesta seleccionada. <br>
    Esta acción no se puede deshacer. ¿Deseas continuar?`,

  [ProposalKeys.Basic_Info.Project_Title.title]: 'Título',
  [ProposalKeys.Basic_Info.Project_Title.placeholder]:
    'Ingrese el título del proyecto',
  [ProposalKeys.Basic_Info.Project_Title.error]: 'Máximo 100 caracteres',

  [ProposalKeys.Basic_Info.Website.title]: 'Producto · Sitio web de la marca',
  [ProposalKeys.Basic_Info.Website.placeholder]: 'Ingrese una URL',
  [ProposalKeys.Basic_Info.Website.error]: 'Máximo 400 caracteres',

  [ProposalKeys.Basic_Info.Description.title]: 'Descripción y archivos',
  [ProposalKeys.Basic_Info.Description.placeholder]: `
    Por favor, describa los detalles del proyecto y adjunte sus archivos aquí. Los creadores pueden buscar información sobre, <br><br><br>
    <ul>
    <li>Descripción del proyecto/producto</li>
    <li>Objetivos de la campaña y público objetivo</li>
    <li>Directrices detalladas para el contenido</li>
    </ul>`,
  [ProposalKeys.Basic_Info.Description.Snippet.title]:
    'Seleccione un fragmento para agregar',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_social_channel_name]:
    'Nombre del canal social del creador',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_username]:
    'Nombre de usuario del creador',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_main_country]:
    'País principal del creador',
  [ProposalKeys.Basic_Info.Description.Snippet.creator_top_categories]:
    'Categorías principales del creador',

  [ProposalKeys.Basic_Info.Description.Error.file_format]:
    'Formato de archivo no válido. (Por favor, use {{fileFormats}})',
  [ProposalKeys.Basic_Info.Description.Error.file_size_limit]:
    'El límite total de tamaño de archivo es de {{maxSize}}',
  [ProposalKeys.Basic_Info.Description.Error.max_file]:
    'Puede enviar un máximo de {{maxFiles}} archivos',

  [ProposalKeys.caution]: `
  Al ordenar un paquete, todos los <strong>requisitos y misiones de contenido</strong> aplicables se aplicarán automáticamente a cada contenido. Los requisitos y misiones incompletos o no aplicables al contenido ordenado no se aplicarán.`,

  [ProposalKeys.Side_btn.text]: 'Contenidos aplicables',
  [ProposalKeys.Side_btn.title]: 'Contenidos aplicables: ',

  [ProposalKeys.Product_Delivery.title]: 'Enviar un producto al creador',

  [ProposalKeys.Product_Delivery.Delivery.title]:
    'Enviar productos patrocinados',
  [ProposalKeys.Product_Delivery.Delivery.tooltip]: `
  Esta opción está incompleta y no se aplicará a las propuestas que se envíen.`,

  [ProposalKeys.Product_Delivery.Delivery.Image.title]:
    'Imágenes de entrega (opcional)',
  [ProposalKeys.Product_Delivery.Delivery.Image.description]:
    'Agregue hasta 5 archivos de imágenes en formato JPG, JPEG, PNG, GIF. Máximo 100 MB.',

  [ProposalKeys.Product_Delivery.Delivery.Info.title]:
    'Información de entrega (requerida)',
  [ProposalKeys.Product_Delivery.Delivery.Info.placeholder]:
    'Proporcione información sobre el producto y el envío.',

  [ProposalKeys.Product_Delivery.Delivery.caution_due_date]:
    'Recibirá la dirección de envío del creador para la entrega del producto. La creación de contenido comenzará una vez que llegue el producto, y la fecha de vencimiento se actualizará en consecuencia.',
  [ProposalKeys.Product_Delivery.Delivery.caution_return_process]:
    'No procesamos devoluciones de productos entregados. Si su pedido se canceló después de que se enviara el producto, deberá discutir la devolución directamente con el creador.',
  [ProposalKeys.Product_Delivery.Delivery.caution_international_delivery]: `
  Ayudamos a rastrear la entrega internacional, pero no garantizamos ni cubrimos problemas de envío como entregas incorrectas.
  <Tooltip>Entrega nacional</Tooltip> solo se puede rastrear en algunos países, pero aún puede incluirla en el pedido.`,
  [ProposalKeys.Product_Delivery.Delivery
    .caution_international_delivery_tooltip]: `
  Soportamos el rastreo nacional para transportistas principales<br>
  en países limitados, incluyendo Estados Unidos y Canadá.<br>
  Actualmente no soportamos el rastreo nacional<br>
  para otros países.`,

  [ProposalKeys.Requirements.title]: 'Requisitos',
  [ProposalKeys.Requirements.applied]: '{{number}} aplicados',

  [ProposalKeys.Requirements.Promotion.title]: 'Indicar promoción pagada',
  [ProposalKeys.Requirements.Promotion.description_1]:
    'El creador colocará uno de los siguientes hashtags: #Ad, #Advertisement o #Sponsored al comienzo de la lista de hashtags para indicar claramente la promoción pagada.',
  [ProposalKeys.Requirements.Promotion.description_2]:
    'Añadir la etiqueta "Incluye promoción pagada" proporcionada por YouTube dentro del video.',
  [ProposalKeys.Requirements.Promotion.description_3]:
    'Añadir la etiqueta "Colaboración remunerada" proporcionada por Instagram dentro de la publicación/historia/reels de Instagram.',

  [ProposalKeys.Requirements.Terms_Conditions.placeholder]:
    'Por favor, introduzca sus términos y condiciones aquí.',
  [ProposalKeys.Requirements.Terms_Conditions.description]:
    'Se requerirá que el creador cumpla con sus Términos y Condiciones para la prestación del servicio. Se puede solicitar una revisión o desactivación de esta opción si entra en conflicto con el servicio del creador.',

  [ProposalKeys.Checklist.title]: 'Lista de verificación',

  [ProposalKeys.Content_Missions.title]: 'Misiones de contenido',

  [ProposalKeys.Content_Missions.Include_Link.title]: 'Incluir un enlace',
  [ProposalKeys.Content_Missions.Include_Link.placeholder]: 'Ingrese una URL',
  [ProposalKeys.Content_Missions.Include_Link.description]:
    'El enlace proporcionado se incluirá en la descripción de la publicación o el video. Úselo para dirigir más tráfico a un sitio web o aplicación.',

  [ProposalKeys.Content_Missions.Include_Hashtags.title]: 'Incluir hashtags',
  [ProposalKeys.Content_Missions.Include_Hashtags.placeholder]:
    'Ingrese un hashtag',
  [ProposalKeys.Content_Missions.Include_Hashtags.btn]: 'Agregar este hashtag',
  [ProposalKeys.Content_Missions.Include_Hashtags.description]:
    'Se incluirá al menos uno o más de los hashtags de la izquierda en la publicación o en la descripción del video.',

  [ProposalKeys.Content_Missions.Tag_Location.title]: 'Etiquetar una ubicación',
  [ProposalKeys.Content_Missions.Tag_Location.placeholder]:
    'Ingrese una ubicación',
  [ProposalKeys.Content_Missions.Tag_Location.description]:
    'La ubicación proporcionada se etiquetará en la publicación o el video. Efectivo para promover una ubicación o lugar específico.',

  [ProposalKeys.Content_Missions.Tag_Account.title]: 'Etiquetar nuestra cuenta',
  [ProposalKeys.Content_Missions.Tag_Account.placeholder]: 'Su identificador',
  [ProposalKeys.Content_Missions.Tag_Account.description]:
    'La cuenta @handle se incluirá en la descripción de la publicación o el video. (Solo se aplicarán los identificadores correspondientes a la plataforma del contenido ordenado).',

  [ProposalKeys.Content_Missions.Pin_Comment_Account.title]:
    'Fijar un comentario con nuestra cuenta',
  [ProposalKeys.Content_Missions.Pin_Comment_Account.description]:
    'Se publicará un comentario que incluye la cuenta @handle proporcionada y se fijará en el contenido. (Solo se aplicarán los identificadores correspondientes a la plataforma del contenido ordenado).',

  [ProposalKeys.Content_Missions.Pin_Comment_Link.title]:
    'Fijar un comentario con un enlace',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.placeholder]:
    'Ingrese una URL',
  [ProposalKeys.Content_Missions.Pin_Comment_Link.description]:
    'Se publicará un comentario que incluye el enlace proporcionado y se fijará en el contenido.',

  [ProposalKeys.Content_Missions.Include_Img.title]:
    'Incluir imágenes específicas',
  [ProposalKeys.Content_Missions.Include_Img.description]:
    'Se guiará al creador para incluir al menos una o más de las imágenes proporcionadas en el contenido.',

  [ProposalKeys.Content_Missions.Tag_Video.title]:
    'Agregar etiquetas a los videos',
  [ProposalKeys.Content_Missions.Tag_Video.placeholder]:
    'Ingrese una etiqueta de video',
  [ProposalKeys.Content_Missions.Tag_Video.btn]: 'Agregar esta etiqueta',
  [ProposalKeys.Content_Missions.Tag_Video.description]:
    'Se agregarán al menos una o más de las etiquetas de la izquierda al video de YouTube para la optimización de la búsqueda.',

  // View
  [ProposalKeys.View.copied_to_clipboard]: '',
  [ProposalKeys.View.Top.visit_website]: '',
  [ProposalKeys.View.ProductDelivery.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.title]: '',
  [ProposalKeys.View.ProductDelivery.Physical.addressRequired]: '',
  [ProposalKeys.View.ProductDelivery.Physical.waitForProduct]: '',
  [ProposalKeys.View.Requirement.Tooltip.creator]: '',
  [ProposalKeys.View.Requirement.Tooltip.client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_creator]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.pronoun_client]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.sponsored]: ``,
  [ProposalKeys.View.Requirement.Ad.Checklist.useFollowingMethod]: '',
  [ProposalKeys.View.Requirement.Ad.Checklist.applicableContents]: ``,
  [ProposalKeys.View.Requirement.Ad.All.description]: ``,
  [ProposalKeys.View.Requirement.Ad.YouTube.description]: ``,
  [ProposalKeys.View.Requirement.Ad.Instagram.description]: ``,
  [ProposalKeys.View.Requirement.Ad.TikTok.description]: ``,
  [ProposalKeys.View.Requirement.Terms_Conditions.title]: '',
  [ProposalKeys.View.Requirement.Terms_Conditions.Checklist
    .complyTermsConditions]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.creator]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Tooltip.client]: ``,
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Link
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Hashtags
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Location
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_creator]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Account
    .description_client]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Pin_Comment_Link.description]:
    '',
  [ProposalKeys.View.Requirement.Content_Missions.Include_Img.download]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.copy]: '',
  [ProposalKeys.View.Requirement.Content_Missions.Tag_Video.description]: '',
};
