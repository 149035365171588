import Stack from 'components/Basics/Layout/Stack';
import LazyImage from 'components/Basics/LazyImage';
import AxiosClient from 'components/utilities/AxiosClient';
import imageCompressor from 'components/utilities/imageCompressor';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const uploadFile = async (file, thumbnail, onProgress) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('thumbnail', thumbnail);

  const res = await AxiosClient.post('/admin/faq/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      onProgress(progress);
    },
  });

  return {
    src: res.data.file,
    thumbnail: res.data.thumbnail,
  };
};

const getSize = async (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
  });
};

const ImageUploader = ({
  isUploading,
  setIsUploading,
  setValue,
  image,
  thumbnail,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const onProgress = useCallback((progress) => {
    setUploadProgress(progress);
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      setIsUploading(true);
      setUploadProgress(0);
      const file = acceptedFiles[0];
      if (!/image/.test(file.type)) {
        setIsUploading(false);
        alert('Only images are allowed');
        return;
      }
      const compressed = await imageCompressor(file, 100);
      const { src, thumbnail } = await uploadFile(file, compressed, onProgress);
      const { width } = await getSize(file);
      setValue('src', src);
      setValue('thumbnail', thumbnail);
      setValue('width', width);
      setValue('height', 'auto');
      setIsUploading(false);
    },
  });

  if (isUploading)
    return (
      <Stack gap="2rem" margin="3rem 0" align="center" justify="center">
        <ProgressBarWrapper>
          <ProgressBar progress={uploadProgress} />
        </ProgressBarWrapper>

        <p className="text-indigo-200">Uploading...</p>
      </Stack>
    );

  if (image) {
    return (
      <ImageWrapper>
        <LazyImage
          src={image}
          lazySrc={thumbnail}
          alt="uploaded"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </ImageWrapper>
    );
  }

  return (
    <ImageUploaderContainer {...getRootProps()} isDragActive={isDragActive}>
      <input {...getInputProps()} />

      <p className="text-indigo-200">
        Drag 'n' drop an image here, or click to select an image
      </p>
    </ImageUploaderContainer>
  );
};

export default ImageUploader;

const ImageUploaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 2px dashed
    ${({ isDragActive }) =>
      isDragActive ? 'var(--color-indigo-200)' : 'var(--color-indigo-50)'};
  background: ${({ isDragActive }) =>
    isDragActive ? 'var(--color-indigo-10)' : 'none'};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    background: var(--color-indigo-10);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 auto;
`;

const ProgressBarWrapper = styled.div`
  flex-shrink: 0;
  width: 50%;
  height: 0.5rem;
  border-radius: 0.25rem;
  background: var(--color-indigo-50);
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background: var(--color-indigo-200);
  transition: all 150ms ease-in-out;
`;
