import orderState from 'constants/orderState';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Stack from 'components/Basics/Layout/Stack';

const colors = {
  [orderState.PROPOSAL_REQUIRED]: 'var(--color-yellow-500)',
  [orderState.AWAITING_ACCEPTANCE]: 'var(--color-yellow-500)',
  [orderState.PRODUCT_DELIVERING]: 'var(--color-yellow-500)',
  [orderState.PREPARING_CONTENTS]: 'var(--color-yellow-500)',
  [orderState.CONTENTS_DELIVERED]: 'var(--color-blue-500)',
  [orderState.IN_REVISION]: 'var(--color-red-500)',
  [orderState.EXTENSION_REQUEST]: 'var(--color-red-500)',
  [orderState.CANCELLATION_REQUEST]: 'var(--color-red-500)',
  [orderState.CANCELED]: 'var(--color-red-500)',
  [orderState.COMPLETE]: 'var(--color-green-500)',
  [orderState.LATE]: 'var(--color-red-500)',
  [orderState.REFUNDED]: 'var(--color-red-500)',
};

const text = {
  [orderState.PROPOSAL_REQUIRED]: 'Proposal Required',
  [orderState.AWAITING_ACCEPTANCE]: 'Awaiting Acceptance',
  [orderState.PRODUCT_DELIVERING]: 'Product Delivery in Progress',
  [orderState.PREPARING_CONTENTS]: 'Preparing Contents',
  [orderState.CONTENTS_DELIVERED]: 'All Contents Published',
  [orderState.IN_REVISION]: 'In Revision',
  [orderState.EXTENSION_REQUEST]: 'Extension Request',
  [orderState.CANCELLATION_REQUEST]: 'Cancellation Request',
  [orderState.CANCELED]: 'Canceled',
  [orderState.COMPLETE]: 'Completed',
  [orderState.LATE]: 'Late',
  [orderState.REFUNDED]: 'Refunded',
};

const OrderState = ({
  state,
  variant = 'contained',
  className,
  transitionType = 'fade',
}) => {
  if (variant === 'doted')
    return (
      <Stack className={`h6 ${className}`} direction="row" align="center">
        <Dot color={colors[state]} />
        {text[state]}
      </Stack>
    );

  if (variant === 'text')
    return (
      <Text className={className} color={colors[state]}>
        {text[state]}
      </Text>
    );

  return (
    <Wrapper
      className={className}
      color={colors[state]}
      transitionType={transitionType}
    >
      {text[state]}
    </Wrapper>
  );
};

export default OrderState;

const Wrapper = styled(motion.div).attrs(({ transitionType }) => ({
  className: 'bh8',
  initial:
    transitionType === 'fade'
      ? {
          opacity: 0,
        }
      : transitionType === 'slide'
      ? {
          x: '-120%',
        }
      : {},
  // animate: {
  //   opacity: 1,
  //   x: 0,
  // },
  animate:
    transitionType === 'fade'
      ? {
          opacity: 1,
        }
      : transitionType === 'slide'
      ? {
          opacity: 1,
          x: 0,
        }
      : {},
  exit:
    transitionType === 'fade'
      ? {
          opacity: 0,
        }
      : transitionType === 'slide'
      ? {
          opacity: 0,
          transition: {
            delay: 0.5,
          },
        }
      : {},
  transition: {
    type: 'tween',
    duration: 0.5,
  },
}))`
  display: flex;
  width: 10rem;
  min-height: 1.75rem;
  padding-top: 1.5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  text-transform: uppercase;
  background: ${({ color }) => color};
  color: ${({ color }) =>
    color === 'var(--color-yellow-500)'
      ? 'var(--color-indigo-500)'
      : 'var(--color-white)'};
`;

const Text = styled(motion.span).attrs(({ color }) => ({
  className: 'sbh8',
  animate: {
    color,
  },
}))`
  text-transform: uppercase;
  flex-shrink: 0;
`;

const Dot = styled(motion.div)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ color }) => color};
`;
