import 'dayjs/locale/es';

const esLocale = {
  name: 'es',
  relativeTime: {
    future: 'Después de %s',
    past: 'Hace %s',
    s: '1 minuto',
    m: '1 minuto',
    mm: '%d minuto',
    h: '1 hora',
    hh: '%d horas',
    d: '1 día',
    dd: '%d días',
    M: '1 mes',
    MM: '%d meses',
    y: '1 año',
    yy: '%d años',
  },
  dateFormat: {
    dot_date: 'DD.MM.YYYY',
    dash_date: 'DD-MM-YYYY',

    dot_date_time_12: 'DD.MM.YYYY h:mm A',
    time_12: 'h:mm A',
    abbr_date_time_12: 'D MMM, h:mm A',
    date_time: 'D MMMM, YYYY',
    yesterday: 'Ayer',
    full_abbr_date_time_12: 'D MMM, YYYY h:mm A',
  },
};

export default esLocale;
