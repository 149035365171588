import { authType } from 'constants/authType';
import { authPlatform } from 'constants/authFlow';
import AxiosClient from 'components/utilities/AxiosClient';
import {
  validateEmail,
  validatePassword,
} from 'components/components/Helper/validations';
import { useMemo } from 'react';
import { ethers } from 'ethers';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import SettingsKeys from 'translations/translationKeys/SettingsPageKeys';
import useUser from './useUser';
import { useSocket } from 'contexts/SocketContext';
import { useCallback } from 'react';

const { LOCAL, GOOGLE, WALLET, FACEBOOK } = authType;

const COINBASE_INSTALLATION_URL =
  'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad/related?hl=en&authuser=0';
const METAMASK_INSTALLATION_URL = 'https://metamask.io/download/';

//api validations
function loginParamValidation(data, type) {
  if (!data) {
    throw new Error('Validation Error: no data is provided');
  }
  switch (type) {
    default:
    case LOCAL: {
      if (!data.hasOwnProperty('email') || !data.hasOwnProperty('password')) {
        throw new Error(
          'Validation Error: email and password should be provided'
        );
      }
      break;
    }
    case GOOGLE: {
      if (
        !data.hasOwnProperty('email') ||
        !data.hasOwnProperty('googleToken')
      ) {
        throw new Error(
          'Validation Error: email and googleToken should be provided'
        );
      }
      break;
    }
    case WALLET: {
      if (!data.hasOwnProperty('email')) {
        throw new Error(
          'Validation Error: email and googleToken should be provided'
        );
      }
      break;
    }
  }
  return true;
}
function signupParamValidation(data, type) {
  if (!data) {
    throw new Error('Validation Error: no data is provided');
  }
  switch (type) {
    default:
    case LOCAL: {
      if (
        !data.hasOwnProperty('email') ||
        !data.hasOwnProperty('password') ||
        !data.hasOwnProperty('registerType')
      ) {
        throw new Error(
          'Validation Error: email, password and registerType should be provided'
        );
      }
      break;
    }
    case GOOGLE: {
      if (
        !data.hasOwnProperty('email') ||
        !data.hasOwnProperty('googleToken') ||
        !data.hasOwnProperty('registerType')
      ) {
        throw new Error(
          'Validation Error: email, googleToken and registerType should be provided'
        );
      }
      break;
    }
    case WALLET: {
      if (
        !data.hasOwnProperty('email') ||
        !data.hasOwnProperty('message') ||
        !data.hasOwnProperty('signedMessage') ||
        !data.hasOwnProperty('walletAddress') ||
        !data.hasOwnProperty('registerType')
      ) {
        throw new Error(
          'Validation Error: email, message, signedMessage, walletAddress and registerType should be provided'
        );
      }
      break;
    }
  }
  return true;
}
//error handlers
function errorHandler_deactivated(error) {
  return (
    error.response.status === 408 &&
    error.response.data.message ===
      'This account is currently deactivated. Please attempt to login to reactivate'
  );
}
function errorHandler_needs_verification(error) {
  return error.response.status === 409 && error.response.data?.user;
}
function errorHandler_user_not_found(error) {
  if (!error) {
    return false;
  }
  return (
    (error.response.data === 'This user cannot be found' ||
      error.key === 'INCORRECT_EMAIL_PASSWORD' ||
      error.response.data?.msg === 'This user cannot be found') &&
    error.response.status === 404
  );
}
function createErrorHandlers() {
  return {
    needsVerification: errorHandler_needs_verification,
    userNotFound: errorHandler_user_not_found,
    deactivated: errorHandler_deactivated,
  };
}

//web3Utils
async function _extensionInstallation(walletType) {
  if (walletType === authPlatform.METAMASK) {
    window.open(METAMASK_INSTALLATION_URL, '_self').focus();
  } else if (walletType === authPlatform.COINBASE) {
    window.open(COINBASE_INSTALLATION_URL, '_self').focus();
  }
}
async function _getNonce(walletAddress) {
  const response = await AxiosClient.get(`/user/${walletAddress}/nonce`).catch(
    (e) => {
      throw e;
    }
  );
  return response.data;
}
function _getProvider(type) {
  switch (type) {
    case authPlatform.METAMASK:
      if (
        !window.ethereum?.providers?.find((x) => x.isMetaMask) &&
        !window.ethereum?.isMetaMask
      ) {
        return null;
      }
      if (!window.ethereum.providers && window.ethereum.isMetaMask) {
        return new ethers.providers.Web3Provider(window.ethereum);
      }
      window.ethereum.setSelectedProvider(
        window.ethereum.providers.find((x) => x.isMetaMask)
      );
      return new ethers.providers.Web3Provider(
        window.ethereum.providers.find((x) => x.isMetaMask)
      );
    case authPlatform.COINBASE:
      if (
        !window.ethereum?.providers?.find((x) => x.isCoinbaseWallet) &&
        !window.ethereum?.isCoinbaseWallet
      ) {
        return null;
      }
      if (!window.ethereum.providers && window.ethereum.isCoinbaseWallet) {
        return new ethers.providers.Web3Provider(window.ethereum);
      }
      window.ethereum.setSelectedProvider(
        window.ethereum.providers.find((x) => x.isCoinbaseWallet)
      );
      return new ethers.providers.Web3Provider(
        window.ethereum.providers.find((x) => x.isCoinbaseWallet)
      );
    default:
      return null;
  }
}

async function _signMessage(address, nonce, provider) {
  const message = `
        Welcome to MegaEvolutuion! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees.
        Wallet address:${address}
        Nonce:${nonce}`;
  const signer = provider.getSigner();
  const signedMessage = await signer.signMessage(message);
  return [message, signedMessage];
}

async function _getWallet(type) {
  let walletAddress, provider, redirectPluginExtension;
  provider = _getProvider(type);
  redirectPluginExtension = true;
  if (provider) {
    walletAddress = await provider.send('eth_requestAccounts', []);
    walletAddress = ethers.utils.getAddress(walletAddress[0]);
    redirectPluginExtension = false;
  }
  if (redirectPluginExtension) {
    _extensionInstallation(type);
  }
  return [walletAddress, provider, redirectPluginExtension];
}
async function createWalletDataAndSignature(type) {
  const [walletAddress, provider] = await _getWallet(type);

  const nonce = await _getNonce(walletAddress).catch((e) => {
    return {
      error: !e.response.data ? "Current user isn't signed up" : e.message,
      errorCode: e.response.status,
      walletAddress,
      nonce: e.response.data,
    };
  });

  const [message, signedMessage] = await _signMessage(
    walletAddress,
    typeof nonce === 'object' ? nonce.nonce.nonce : nonce,
    provider
  );
  return { message, signedMessage, walletAddress };
}

//utils

async function checkWalletRegistable(wallet, type) {
  try {
    const response = await AxiosClient.post('/isUserByWallet', {
      wallet,
      walletType: type,
    });
    const walletRegistable = !response.data;
    if (walletRegistable) {
      return wallet;
    } else {
      throw new Error('This wallet is already registered.');
    }
  } catch (error) {
    throw error;
  }
}

async function checkEmailIsRegistable(email) {
  const res = await AxiosClient.get('/isUser', { params: { email } });

  switch (res.data) {
    case 'exist': {
      throw new Error(LoginKeys.Error.already_in_use);
    }
    case 'cooldown': {
      throw new Error(LoginKeys.Error.recently_deleted);
    }
    case 'deactive': {
      throw new Error(LoginKeys.Error.currently_deactiated_login);
    }
    default: {
      return true;
    }
  }
}
async function verifyEmail(id, token, option = {}) {
  try {
    const res = await AxiosClient.get('/verifyEmail', {
      params: {
        id: id,
        token: token,
      },
    });

    option.onSuccess?.(res.data);
  } catch (error) {
    option.onError?.(error);
  }
}
async function isUserVerified(userId, option = {}) {
  try {
    const res = await AxiosClient.get('/isVerified', {
      params: { id: userId },
    });
    option.onSuccess?.(res.data);
  } catch (error) {
    option.onError?.(error);
  }
}
async function sendVerificationEmail(email, language) {
  const response = await AxiosClient.post('/resendEmail', {
    email,
    language,
  });
  return response;
}
async function validate(data) {
  const { email, password } = data;

  if (!email || !validateEmail(email)) {
    throw new Error(LoginKeys.Error.enter_valid_email);
  }
  if (!password || !validatePassword(password)) {
    throw new Error(SettingsKeys.Password.Error.characters);
  }
  const checkUserInfoValid = await AxiosClient.get('/isUser', {
    params: { email },
  });
  const { data: storedData } = checkUserInfoValid;

  if (storedData === 'exist') {
    throw new Error(LoginKeys.Error.already_in_use);
  } else if (storedData === 'cooldown') {
    throw new Error(LoginKeys.Error.recently_deleted);
  }
  return true;
}

function _successHandler(userdata) {}
function _errorHandler(e) {
  throw e;
}
function _storageSetter(userdata) {
  localStorage.setItem('user', JSON.stringify({ id: userdata.id }));
}

async function requestLocalLogin(data) {
  if (loginParamValidation(data, LOCAL)) {
    const response = await AxiosClient.post('/login', data);
    return response;
  }
}
async function requestGoogleLogin(data) {
  if (loginParamValidation(data, GOOGLE)) {
    const response = await AxiosClient.post('/login', data);
    return response;
  }
}
async function requestFacebookLogin(data) {
  const response = await AxiosClient.post('/login', data);
  return response;
}
async function requestWalletLogin(data) {
  const response = await AxiosClient.post('/login', data);
  return response;
}

async function requestLocalSignup(data) {
  if (signupParamValidation(data, LOCAL)) {
    const response = await AxiosClient.post('/register', data);
    return response;
  }
}
async function requestGoogleSignup(data) {
  if (signupParamValidation(data, GOOGLE)) {
    const response = await AxiosClient.post('/googleRegister', {
      email: data.email,
      name: data.name,
      pic: data.imageUrl,
      marketingCheck: data.marketingCheck,
      googleToken: data.googleToken,
      registerType: data.registerType,
      language: data.language,
      timeZone: data.timeZone,
    });
    return response;
  }
}
async function requestFacebookSignup(data) {
  const response = await AxiosClient.post('/facebookRegister', data);
  return response;
}
async function requestWalletSignup(data) {
  if (signupParamValidation(data, WALLET)) {
    const response = await AxiosClient.post('/walletRegister', data);
    return response;
  }
}
async function requestSignOut() {
  const response = await AxiosClient.get('/logout');
  return response;
}

async function requestPasswordReset(email) {
  if (!email) {
    throw new Error('no email provided');
  }
  const response = await AxiosClient.post('/requestPasswordReset', { email });
  return response;
}

async function resetPassword(email, option = {}) {
  try {
    const response = await requestPasswordReset(email);
    if (option.hasOwnProperty('onSuccess')) {
      option.onSuccess(response);
    }
  } catch (error) {
    if (option.hasOwnProperty('onError')) {
      option.onError(error);
    } else {
      throw error;
    }
  }
}

async function login(type, data, option = {}) {
  const CALLER = 'login';
  let res = null;
  try {
    switch (type) {
      default:
      case LOCAL: {
        res = await requestLocalLogin({ ...data, loginType: type });
        return res;
      }
      case GOOGLE: {
        res = await requestGoogleLogin({ ...data, loginType: type });
        break;
      }
      case WALLET: {
        res = await requestWalletLogin({ ...data, loginType: type });
        break;
      }
      case FACEBOOK: {
        res = await requestFacebookLogin({ ...data, loginType: type });
        break;
      }
    }
  } catch (error) {
    if (option.onError) {
      option.onError(error, CALLER);
    } else {
      this.errorHandler(error, CALLER);
    }
  } finally {
    if (res?.status === 200) {
      if (option.onSuccess) {
        option.onSuccess(res.data, CALLER);
      } else {
        this.successHandler(res.data, CALLER);
      }
      this.storageSetter(res.data, CALLER);
    }
    if (option.onFinal) {
      option.onFinal(CALLER);
    } else {
      this.onFinal?.(CALLER);
    }
  }
}

async function signup(type, data, option = {}) {
  const CALLER = 'signup';
  let res = null;
  try {
    switch (type) {
      default:
      case LOCAL: {
        res = await requestLocalSignup(data);
        return res;
      }
      case GOOGLE: {
        res = await requestGoogleSignup(data);
        break;
      }
      case WALLET: {
        res = await requestWalletSignup(data);
        break;
      }
      case FACEBOOK: {
        res = await requestFacebookSignup(data);
        break;
      }
    }
  } catch (error) {
    if (option.onError) {
      option.onError(error, CALLER);
    } else {
      this.errorHandler(error, CALLER);
    }
  } finally {
    if (res?.status === 200) {
      if (option.onSuccess) {
        option.onSuccess(res.data, CALLER);
      } else {
        this.successHandler(res.data, CALLER);
      }
    }
    if (option.onFinal) {
      option.onFinal(CALLER);
    } else {
      this.onFinal?.(CALLER);
    }
  }
}

async function signOut(cb) {
  try {
    localStorage.removeItem('user');

    await requestSignOut();

    cb?.();
  } catch (error) {
    throw error;
  }
}

function createWeb3Utils(type) {
  if (
    type === authPlatform.COINBASE ||
    type === authPlatform.METAMASK ||
    type === authType.WALLET
  ) {
    return {
      createWalletDataAndSignature,
      _getWallet,
      _signMessage,
      _getProvider,
      _getNonce,
      _extensionInstallation,
    };
  } else {
    return {};
  }
}

function createUtils(onSignOut) {
  return {
    checkWalletRegistable,
    validate,
    sendVerificationEmail,
    isUserVerified,
    verifyEmail,
    signOut: (cb) =>
      signOut(() => {
        onSignOut();
        cb?.();
      }),
    checkEmailIsRegistable,
    validateEmail,
    validatePassword,
    resetPassword,
  };
}
function createAuthManager(type, option) {
  // scope
  createAuthManager.authType = type;
  createAuthManager.errorHandler = option?.onError
    ? option.onError
    : _errorHandler;
  createAuthManager.successHandler = option?.onSuccess
    ? option.onSuccess
    : _successHandler;
  createAuthManager.storageSetter = _storageSetter;
  createAuthManager.onFinal = option?.onFinal;
  //explicit infos
  const manager = {
    authType: type,
    login: login.bind(createAuthManager, type),
    signup: signup.bind(createAuthManager, type),
  };
  if (type) {
    return manager;
  } else {
    return {};
  }
}

export const useAuth = (type, option = {}) => {
  const { clearUser } = useUser();
  const socket = useSocket();

  const onSignOut = useCallback(() => {
    clearUser();
    socket.disconnect();
  }, [clearUser, socket]);

  const manager = useMemo(
    () => createAuthManager(type, option),
    [type, option]
  );
  const utils = useMemo(() => createUtils(onSignOut), [onSignOut]);
  const web3Utils = useMemo(() => createWeb3Utils(type), [type]);
  const errorHandlers = useMemo(() => createErrorHandlers(), []);
  //usememo
  return {
    manager,
    utils,
    errorHandlers,
    web3Utils,
  };
};
