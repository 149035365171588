import styled from 'styled-components';
import { CheckCircleIcon, CrossCircleIcon } from 'assets/icons';
import { motion } from 'framer-motion';

const IndigoSwitch = ({
  id,
  checked = false,
  onChange = () => {},
  size = 'md',
}) => {
  return (
    <Container
      style={{
        justifyContent: checked ? 'flex-end' : 'flex-start',
      }}
      animate={{
        backgroundColor: checked
          ? 'var(--color-indigo-500)'
          : 'var(--color-indigo-200)',
      }}
      size={size}
    >
      <Checkbox id={id} onChange={() => onChange(!checked)} />
      <Label
        layout
        transition={{
          type: 'spring',
          stiffness: 800,
          damping: 30,
        }}
        htmlFor={id}
      >
        {checked ? (
          <Icon layoutId={id + '-checkbox'}>
            <CheckCircleIcon
              size={
                size === 'lg'
                  ? 0.66
                  : size === 'sm'
                  ? 0.33
                  : size === 'md'
                  ? 0.5
                  : size === 'mmd'
                  ? 0.412
                  : 1
              }
            />
          </Icon>
        ) : (
          <Icon layoutId={id + '-checkbox'}>
            <CrossCircleIcon
              size={
                size === 'lg'
                  ? 1.12
                  : size === 'sm'
                  ? 0.56
                  : size === 'md'
                  ? 0.84
                  : size === 'mmd'
                  ? 0.7
                  : 1
              }
            />
          </Icon>
        )}
      </Label>
    </Container>
  );
};

export default IndigoSwitch;

const Container = styled(motion.div)`
  display: flex;
  width: ${({ size }) =>
    size === 'md'
      ? '2.625rem'
      : size === 'sm'
      ? '1.75rem'
      : size === 'lg'
      ? '3.5rem'
      : size === 'mmd'
      ? '35px'
      : ''};
  height: ${({ size }) =>
    size === 'md'
      ? '1.5rem'
      : size === 'sm'
      ? '1rem'
      : size === 'lg'
      ? '2rem'
      : size === 'mmd'
      ? '1.25rem'
      : ''};
  padding: ${({ size }) =>
    size === 'md'
      ? '3.6px'
      : size === 'sm'
      ? '2.4px'
      : size === 'lg'
      ? '4.8px'
      : size === 'mmd'
      ? '3px'
      : ''};
  align-items: center;
  border-radius: 1.5rem;
  position: relative;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`;

const Label = styled(motion.label)`
  color: var(--color-white);
`;

const Icon = styled(motion.span)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
