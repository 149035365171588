export const NewIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 24}
    height={size * 9}
    viewBox="0 0 24 9"
    fill="none"
  >
    <path
      d="M6.46252 8.05643H4.78407L1.46035 3.20124V8.05643H0V0.944336H1.67845L5.00217 5.80901V0.944336H6.46252V8.05643Z"
      fill="url(#paint0_linear_61696_71293)"
    />
    <path
      d="M8.10968 8.05643V0.944336H12.6994V2.21977H9.57003V3.83659H12.505V5.11676H9.57003V6.78099H12.7468V8.05643H8.10968Z"
      fill="url(#paint1_linear_61696_71293)"
    />
    <path
      d="M21.6957 8.05643H20.3539L18.9077 3.35297L17.4569 8.05643H16.1151L13.8013 0.944336H15.3327L16.7836 5.67151L18.2345 0.944336H19.5763L21.0224 5.66203L22.4638 0.944336H24L21.6957 8.05643Z"
      fill="url(#paint2_linear_61696_71293)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_61696_71293"
        x1="23.9691"
        y1="4.49257"
        x2="-0.0621508"
        y2="4.49257"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#80A2F4" />
        <stop offset="1" stopColor="#E274FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_61696_71293"
        x1="23.9691"
        y1="4.49257"
        x2="-0.0621508"
        y2="4.49257"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#80A2F4" />
        <stop offset="1" stopColor="#E274FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_61696_71293"
        x1="23.9691"
        y1="4.49257"
        x2="-0.0621508"
        y2="4.49257"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#80A2F4" />
        <stop offset="1" stopColor="#E274FF" />
      </linearGradient>
    </defs>
  </svg>
);
