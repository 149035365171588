export const InfoIcon = ({ size = 1 }) => (
  <svg
    width={size * 12}
    height={size * 12}
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 5.4C5.84087 5.4 5.68826 5.46321 5.57574 5.57573C5.46322 5.68826 5.4 5.84087 5.4 6V8.4C5.4 8.55913 5.46322 8.71174 5.57574 8.82426C5.68826 8.93678 5.84087 9 6 9C6.15913 9 6.31174 8.93678 6.42427 8.82426C6.53679 8.71174 6.6 8.55913 6.6 8.4V6C6.6 5.84087 6.53679 5.68826 6.42427 5.57573C6.31174 5.46321 6.15913 5.4 6 5.4ZM6.228 3.048C6.08192 2.98799 5.91808 2.98799 5.772 3.048C5.69835 3.07656 5.63106 3.11937 5.574 3.174C5.521 3.23232 5.47839 3.29929 5.448 3.372C5.41441 3.44321 5.39797 3.52129 5.4 3.6C5.39954 3.67896 5.41468 3.75724 5.44454 3.83034C5.4744 3.90345 5.51839 3.96993 5.574 4.026C5.63232 4.079 5.69929 4.12161 5.772 4.152C5.8629 4.18934 5.96158 4.20379 6.05937 4.19407C6.15716 4.18434 6.25106 4.15075 6.33283 4.09624C6.4146 4.04173 6.48172 3.96797 6.52831 3.88144C6.57489 3.79491 6.59951 3.69827 6.6 3.6C6.59779 3.44114 6.53564 3.28898 6.426 3.174C6.36894 3.11937 6.30165 3.07656 6.228 3.048ZM6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.0025997 4.80025 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C12 5.21207 11.8448 4.43185 11.5433 3.7039C11.2417 2.97594 10.7998 2.31451 10.2426 1.75736C9.68549 1.20021 9.02405 0.758251 8.2961 0.456723C7.56815 0.155195 6.78793 0 6 0ZM6 10.8C5.05065 10.8 4.12262 10.5185 3.33326 9.99105C2.54391 9.46362 1.92868 8.71396 1.56538 7.83688C1.20208 6.95979 1.10702 5.99467 1.29223 5.06357C1.47744 4.13246 1.9346 3.27718 2.60589 2.60589C3.27718 1.93459 4.13246 1.47744 5.06357 1.29223C5.99468 1.10702 6.9598 1.20208 7.83688 1.56538C8.71397 1.92868 9.46362 2.54391 9.99105 3.33326C10.5185 4.12262 10.8 5.05065 10.8 6C10.8 7.27304 10.2943 8.49394 9.39411 9.39411C8.49394 10.2943 7.27304 10.8 6 10.8Z" />
  </svg>
);
