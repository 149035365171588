export const DisgustEmojiIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30 * size}
    height={30 * size}
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M14.999 28.1229C23.75 28.1229 28.1229 22.2472 28.1229 14.999C28.1229 7.75079 23.75 1.875 14.999 1.875C6.2479 1.875 1.875 7.75079 1.875 14.999C1.875 22.2472 6.2479 28.1229 14.999 28.1229Z"
      fill="#FFC543"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.39223 6.74985C8.89889 6.30486 9.21258 5.69372 9.39131 5.03382C9.459 4.78394 9.71644 4.63624 9.96628 4.70392C10.2162 4.7716 10.3639 5.02902 10.2962 5.27891C10.0853 6.0575 9.69591 6.85261 9.01088 7.45424C8.31845 8.06241 7.36381 8.43761 6.09375 8.43761C5.83487 8.43761 5.625 8.22775 5.625 7.96886C5.625 7.70998 5.83487 7.50011 6.09375 7.50011C7.16744 7.50011 7.893 7.18832 8.39223 6.74985ZM8.85674 9.47404C8.39363 9.24251 7.83051 9.43026 7.59895 9.89329C7.3674 10.3564 7.55511 10.9196 8.01821 11.1511L10.0912 12.1876L8.01821 13.224C7.55511 13.4556 7.3674 14.0188 7.59895 14.4819C7.83051 14.9449 8.39363 15.1327 8.85674 14.9011L12.6068 13.0261C12.9244 12.8673 13.125 12.5427 13.125 12.1876C13.125 11.8325 12.9244 11.5079 12.6068 11.349L8.85674 9.47404ZM21.1432 9.47404C21.6064 9.24251 22.1695 9.43026 22.4011 9.89329C22.6326 10.3564 22.4449 10.9196 21.9818 11.1511L19.9088 12.1876L21.9818 13.224C22.4449 13.4556 22.6326 14.0188 22.4011 14.4819C22.1695 14.9449 21.6064 15.1327 21.1432 14.9011L17.3932 13.0261C17.0756 12.8673 16.875 12.5427 16.875 12.1876C16.875 11.8325 17.0756 11.5079 17.3932 11.349L21.1432 9.47404ZM20.6087 5.03382C20.7875 5.69372 21.1012 6.30486 21.6078 6.74985C22.107 7.18832 22.8325 7.50011 23.9062 7.50011C24.1651 7.50011 24.375 7.70998 24.375 7.96886C24.375 8.22775 24.1651 8.43761 23.9062 8.43761C22.6362 8.43761 21.6816 8.06241 20.9891 7.45424C20.3041 6.85261 19.9147 6.0575 19.7038 5.27891C19.6361 5.02902 19.7838 4.7716 20.0337 4.70392C20.2836 4.63624 20.541 4.78394 20.6087 5.03382Z"
      fill="#402A32"
    />
    <path
      d="M9.69655 16.875C8.12903 18.1434 7.02506 19.9621 6.67845 22.0372C6.55049 22.8032 7.19209 23.4375 7.96874 23.4375H22.0318C22.8084 23.4375 23.4501 22.8032 23.3221 22.0372C22.9755 19.9621 21.8715 18.1434 20.304 16.875L15.0003 15.9375L9.69655 16.875Z"
      fill="#BB1D80"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.3047 16.875H9.69727C11.1464 15.7024 12.9916 15 15.001 15C17.0103 15 18.8556 15.7024 20.3047 16.875Z"
      fill="white"
    />
  </svg>
);
