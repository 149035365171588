import GeneralKeys from '../translationKeys/GeneralKeys';

export const GeneralTranslations = {
  [GeneralKeys.continue]: '继续',
  [GeneralKeys.submit]: '提交',
  [GeneralKeys.cancel]: '取消',
  [GeneralKeys.close]: '关闭',
  [GeneralKeys.return]: '返回',
  [GeneralKeys.account]: '账户',
  [GeneralKeys.coinbase]: 'CoinBase',
  [GeneralKeys.password]: '密码',
  [GeneralKeys.update]: '更新',
  [GeneralKeys.coming_soon]: '即将推出',
  [GeneralKeys.all]: '全部',
  [GeneralKeys.filter]: '筛选',
  [GeneralKeys.contents]: '内容',
  [GeneralKeys.myspace]: '我的空间',
  [GeneralKeys.myproposal]: '我的提案',
  [GeneralKeys.proposals]: '提案',
  [GeneralKeys.billing]: '账单',
  [GeneralKeys.settings]: '设置',
  [GeneralKeys.profile]: '个人资料',
  [GeneralKeys.my_orders]: 'My 订单',
  [GeneralKeys.orders]: '订单',
  [GeneralKeys.message]: '消息',
  [GeneralKeys.support]: '支持',
  [GeneralKeys.help_center]: '帮助中心',
  [GeneralKeys.creator_faq]: '创作者常见问题',
  [GeneralKeys.company_faq]: '公司品牌常见问题',
  [GeneralKeys.myCart]: '我的购物车',
  [GeneralKeys.platform]: '平台',
  [GeneralKeys.sort_by]: '排序方式',
  [GeneralKeys.country]: '国家',
  [GeneralKeys.categories]: '分类',
  [GeneralKeys.price]: '价格',
  [GeneralKeys.subscribers]: '订阅者',
  [GeneralKeys.avg_views]: '平均观看量',
  [GeneralKeys.audience_gender]: '受众性别',
  [GeneralKeys.audience_age]: '受众年龄',
  [GeneralKeys.youtube]: 'YouTube',
  [GeneralKeys.twitter]: 'X (Twitter)',
  [GeneralKeys.instagram]: 'Instagram',
  [GeneralKeys.tiktok]: 'TikTok',
  [GeneralKeys.delivery]: '时间表',
  [GeneralKeys.type_length]: '类型/长度',
  [GeneralKeys.day_singular]: `{{number}}天`,
  [GeneralKeys.day_plural]: `{{number}}天`,
  [GeneralKeys.loading]: '加载中',
  [GeneralKeys.company]: '所属',
  [GeneralKeys.bio]: '简介',
  [GeneralKeys.subs]: '订阅',
  [GeneralKeys.followers]: '粉丝',
  [GeneralKeys.videos]: '视频',
  [GeneralKeys.x_posts]: 'X 文章',
  [GeneralKeys.posts_reels]: '帖子与 Reels',
  [GeneralKeys.tiktok_videos]: 'TikTok 视频',
  [GeneralKeys.since]: '自{{date}}以来',
  [GeneralKeys.countries]: '国家',
  [GeneralKeys.reviews]: '评论',
  [GeneralKeys.demographics]: '人口统计',
  [GeneralKeys.others]: '其他',
  [GeneralKeys.check_plan]: '检查计划',
  [GeneralKeys.login]: '登录',
  [GeneralKeys.login_upper_case]: '登录',
  [GeneralKeys.total]: '总计',
  [GeneralKeys.likes]: '点赞',
  [GeneralKeys.comments]: '评论',
  [GeneralKeys.reposts]: 'X 转发',
  [GeneralKeys.search]: '搜索',
  [GeneralKeys.contact]: '联系',
  [GeneralKeys.price_sign]: '￥ --',
  [GeneralKeys.unlocking]: '解锁中',
  [GeneralKeys.click_to_unlock]: '点击解锁',
  [GeneralKeys.add_to_cart]: '添加到购物车',
  [GeneralKeys.show_less]: '显示较少',
  [GeneralKeys.show_more]: '显示更多',
  [GeneralKeys.go_back]: '返回',
  [GeneralKeys.load_more]: '加载更多',
  [GeneralKeys.metamask]: 'MetaMask',
  [GeneralKeys.coinbase_wallet]: 'Coinbase 钱包',
  [GeneralKeys.send]: '发送',
  [GeneralKeys.email]: '电子邮箱',
  [GeneralKeys.or_upper_case]: '或',
  [GeneralKeys.agree]: '同意',
  [GeneralKeys.client]: '品牌',
  [GeneralKeys.influencer]: '创作者',
  [GeneralKeys.marketing]: '营销',
  [GeneralKeys.creator_marketing]: '创作者营销',
  [GeneralKeys.partnerships]: '积分 · 伙伴',
  [GeneralKeys.evo_tokens]: 'EVO 积分',
  [GeneralKeys.information]: '信息',
  [GeneralKeys.website_credits]: '网站积分',
  [GeneralKeys.home]: '首页',
  [GeneralKeys.about]: '关于',
  [GeneralKeys.pricing]: '定价',
  [GeneralKeys.proposal]: '提案',
  [GeneralKeys.logout]: '登出',
  [GeneralKeys.view_more]: '查看更多',
  [GeneralKeys.view_less]: '查看较少',
  [GeneralKeys.title]: '标题',
  [GeneralKeys.preview]: '预览',
  [GeneralKeys.upload_a_file]: '上传文件',
  [GeneralKeys.description]: '套餐描述',
  [GeneralKeys.type]: '类型',
  [GeneralKeys.category]: '类别',
  [GeneralKeys.select]: '选择',
  [GeneralKeys.unlimited_revisions]: '无限次修订',
  [GeneralKeys.unlimited]: '无限',
  [GeneralKeys.revision]: '{{number}} 修订',
  [GeneralKeys.revisions]: '{{number}} 修订',
  [GeneralKeys.number_revision]: '修改次数',
  [GeneralKeys.optional]: '可选',
  [GeneralKeys.username]: '用户名',
  [GeneralKeys.social_network]: '社交网络',
  [GeneralKeys.unlink]: '取消链接',
  [GeneralKeys.unlink_platform]: '取消链接{{platform}}',
  [GeneralKeys.language]: '语言',
  [GeneralKeys.languages]: '语言',
  [GeneralKeys.package]: '套餐',
  [GeneralKeys.save]: '保存',
  [GeneralKeys.creator]: '创作者',
  [GeneralKeys.basic]: '基础',
  [GeneralKeys.standard]: '基础',
  [GeneralKeys.premium]: '标准',
  [GeneralKeys.per_day]: '/天',
  [GeneralKeys.per_month]: '/月',
  [GeneralKeys.summary]: '摘要',
  [GeneralKeys.general]: '一般',
  [GeneralKeys.register]: '注册',
  [GeneralKeys.click]: '点击',
  [GeneralKeys.connect]: '连接',
  [GeneralKeys.reconnect]: '重建连接',
  [GeneralKeys.connecting]: '连接中',
  [GeneralKeys.notifications]: '通知',
  [GeneralKeys.messages]: '消息',
  [GeneralKeys.reminders]: '提醒',
  [GeneralKeys.newsletters]: '通讯',
  [GeneralKeys.packages]: '套餐',
  [GeneralKeys.real_time]: '实时',
  [GeneralKeys.unsubscribe]: '退订',
  [GeneralKeys.referral_code]: '推荐代码',
  [GeneralKeys.total_invites]: '总共 {{number}} 邀请',
  [GeneralKeys.submitted]: '已提交',
  [GeneralKeys.here]: '这里',
  [GeneralKeys.confirm]: '确认',
  [GeneralKeys.deactivate]: '停用',
  [GeneralKeys.delete]: '删除',
  [GeneralKeys.wallet]: '钱包',
  [GeneralKeys.yes]: '是',
  [GeneralKeys.privacy_policy]: '隐私政策',
  [GeneralKeys.payment_policy]: '付款政策',
  [GeneralKeys.reactivate]: '重新激活',
  [GeneralKeys.analytics]: '分析',
  [GeneralKeys.user_info]: '用户信息',
  [GeneralKeys.enterprise]: '高级',
  [GeneralKeys.details]: '详细信息',
  [GeneralKeys.claim]: '认领',
  [GeneralKeys.in_progress]: '进行中',
  [GeneralKeys.free]: '免费',
  [GeneralKeys.report]: '举报',
  [GeneralKeys.link_copied]: '链接已复制',
  [GeneralKeys.contents_lowercase]: '内容',
  [GeneralKeys.channel_lowercase]: '频道',
  [GeneralKeys.audience_lowercase]: '受众',
  [GeneralKeys.my_url]: '我的网址',
  [GeneralKeys.username_lowercase]: 'username',
  [GeneralKeys.shorts]: '短视频',
  [GeneralKeys.x_post]: 'X 文章',
  [GeneralKeys.repost]: 'X 转发',
  [GeneralKeys.website]: '我的网站',
  [GeneralKeys.banner]: '横幅',
  [GeneralKeys.profile_lowercase]: '个人资料',
  [GeneralKeys.congratulations]: '祝贺！',
  [GeneralKeys.skip]: '跳过',
  [GeneralKeys.back]: '返回',
  [GeneralKeys.shares]: '分享',
  [GeneralKeys.posts]: '帖子',
  [GeneralKeys.client_company]: '客户 · 公司',
  [GeneralKeys.contents_in]: '{{categories}}内容在{{countries}}。',
  [GeneralKeys.videos_lowercase]: '视频',
  [GeneralKeys.tweets_lowercase]: 'X 文章',
  [GeneralKeys.posts_lowercase]: '帖子',
  [GeneralKeys.rating]: '评分',
  [GeneralKeys.users]: '用户',
  [GeneralKeys.browse_files]: '浏览文件',
  [GeneralKeys.upload_failed]: '上传失败',
  [GeneralKeys.downloading]: '下载中...',
  [GeneralKeys.package_summary]: '套餐摘要',
  [GeneralKeys.contents_plural]: '{{number}} 内容',
  [GeneralKeys.contents_singular]: '{{number}} 内容',
  [GeneralKeys.creator_brand_help_center]: '帮助中心和常见问题解答',
  [GeneralKeys.main]: '代表',

  // Category
  [GeneralKeys.Category.digital]: '数字',
  [GeneralKeys.Category.entertainment]: '娱乐',
  [GeneralKeys.Category.society]: '社会',
  [GeneralKeys.Category.life_style]: '生活方式',

  [GeneralKeys.Category.tech_science]: '科技 · 科学',
  [GeneralKeys.Category.video_games]: '视频游戏',
  [GeneralKeys.Category.metaverse]: '元宇宙',
  [GeneralKeys.Category.crypto_nft]: '加密 · NFT',
  [GeneralKeys.Category.finance]: '金融',
  [GeneralKeys.Category.movies]: '电影',
  [GeneralKeys.Category.anime_cartoon]: '动漫 · 卡通',
  [GeneralKeys.Category.music]: '音乐',
  [GeneralKeys.Category.dance]: '舞蹈',
  [GeneralKeys.Category.sports]: '体育',
  [GeneralKeys.Category.comedy]: '喜剧',
  [GeneralKeys.Category.politics_society]: '政治 · 社会',
  [GeneralKeys.Category.people_blogs]: '人物 · 博客',
  [GeneralKeys.Category.fashion]: '时尚',
  [GeneralKeys.Category.beauty]: '美妆',
  [GeneralKeys.Category.food_cooking]: '美食 · 烹饪',
  [GeneralKeys.Category.youth_infant]: '青少年 · 婴幼儿',
  [GeneralKeys.Category.animals_pets]: '动物 · 宠物',
  [GeneralKeys.Category.travel_event]: '旅行 · 文化',
  [GeneralKeys.Category.auto_vehicles]: '汽车 · 车辆',
  [GeneralKeys.Category.unboxing]: '开箱',
  [GeneralKeys.Category.education]: '教育',
  [GeneralKeys.Category.art_crafts]: '艺术 · 手工艺',
  [GeneralKeys.Category.diy_home]: 'DIY · 室内设计',
  [GeneralKeys.Category.virtual_vtuber]: '虚拟 · VTuber',
  [GeneralKeys.Category.ai_automation]: '人工智能 · 自动化',
  [GeneralKeys.Category.gym_fitness]: '健身房 · 健身',

  // Country
  [GeneralKeys.Country.are]: '阿拉伯联合酋长国',
  [GeneralKeys.Country.arg]: '阿根廷',
  [GeneralKeys.Country.aus]: '澳大利亚',
  [GeneralKeys.Country.aut]: '奥地利',
  [GeneralKeys.Country.bel]: '比利时',
  [GeneralKeys.Country.bra]: '巴西',
  [GeneralKeys.Country.can]: '加拿大',
  [GeneralKeys.Country.che]: '瑞士',
  [GeneralKeys.Country.chn]: '中国',
  [GeneralKeys.Country.deu]: '德国',
  [GeneralKeys.Country.dnk]: '丹麦',
  [GeneralKeys.Country.eeu]: '欧洲',
  [GeneralKeys.Country.esp]: '西班牙',
  [GeneralKeys.Country.fin]: '芬兰',
  [GeneralKeys.Country.fra]: '法国',
  [GeneralKeys.Country.gbr]: '英国',
  [GeneralKeys.Country.glb]: '全球',
  [GeneralKeys.Country.hkg]: '香港',
  [GeneralKeys.Country.idn]: '印度尼西亚',
  [GeneralKeys.Country.ind]: '印度',
  [GeneralKeys.Country.irn]: '伊朗',
  [GeneralKeys.Country.isr]: '以色列',
  [GeneralKeys.Country.ita]: '意大利',
  [GeneralKeys.Country.jpn]: '日本',
  [GeneralKeys.Country.kor]: '韩国',
  [GeneralKeys.Country.mex]: '墨西哥',
  [GeneralKeys.Country.mys]: '马来西亚',
  [GeneralKeys.Country.nld]: '荷兰',
  [GeneralKeys.Country.nor]: '挪威',
  [GeneralKeys.Country.nzl]: '新西兰',
  [GeneralKeys.Country.pak]: '巴基斯坦',
  [GeneralKeys.Country.phl]: '菲律宾',
  [GeneralKeys.Country.pol]: '波兰',
  [GeneralKeys.Country.prt]: '葡萄牙',
  [GeneralKeys.Country.sau]: '沙特阿拉伯',
  [GeneralKeys.Country.sgp]: '新加坡',
  [GeneralKeys.Country.swe]: '瑞典',
  [GeneralKeys.Country.tha]: '泰国',
  [GeneralKeys.Country.tur]: '土耳其',
  [GeneralKeys.Country.twn]: '台湾',
  [GeneralKeys.Country.usa]: '美国',
  [GeneralKeys.Country.vnm]: '越南',

  // Client
  [GeneralKeys.Country.abw]: '阿鲁巴',
  [GeneralKeys.Country.afg]: '阿富汗',
  [GeneralKeys.Country.ago]: '安哥拉',
  [GeneralKeys.Country.aia]: '安圭拉',
  [GeneralKeys.Country.ala]: '奥兰群岛',
  [GeneralKeys.Country.alb]: '阿尔巴尼亚',
  [GeneralKeys.Country.and]: '安道尔',
  [GeneralKeys.Country.arm]: '亚美尼亚',
  [GeneralKeys.Country.asm]: '美属萨摩亚',
  [GeneralKeys.Country.ata]: '南极洲',
  [GeneralKeys.Country.atf]: '法属南部领地',
  [GeneralKeys.Country.atg]: '安提瓜和巴布达',
  [GeneralKeys.Country.aze]: '阿塞拜疆',
  [GeneralKeys.Country.bdi]: '布隆迪',
  [GeneralKeys.Country.ben]: '贝宁',
  [GeneralKeys.Country.bes]: '博内尔、圣尤斯特歇斯和萨巴',
  [GeneralKeys.Country.bfa]: '布基纳法索',
  [GeneralKeys.Country.bgd]: '孟加拉国',
  [GeneralKeys.Country.bgr]: '保加利亚',
  [GeneralKeys.Country.bhr]: '巴林',
  [GeneralKeys.Country.bhs]: '巴哈马',
  [GeneralKeys.Country.bih]: '波斯尼亚和黑塞哥维那',
  [GeneralKeys.Country.blm]: '圣巴泰勒米',
  [GeneralKeys.Country.blr]: '白俄罗斯',
  [GeneralKeys.Country.blz]: '伯利兹',
  [GeneralKeys.Country.bmu]: '百慕大',
  [GeneralKeys.Country.bol]: '玻利维亚',
  [GeneralKeys.Country.brb]: '巴巴多斯',
  [GeneralKeys.Country.brn]: '文莱',
  [GeneralKeys.Country.btn]: '不丹',
  [GeneralKeys.Country.bvt]: '布韦岛',
  [GeneralKeys.Country.bwa]: '博茨瓦纳',
  [GeneralKeys.Country.caf]: '中非',
  [GeneralKeys.Country.cck]: '科科斯（基林）群岛',
  [GeneralKeys.Country.chl]: '智利',
  [GeneralKeys.Country.civ]: '科特迪瓦',
  [GeneralKeys.Country.cmr]: '喀麦隆',
  [GeneralKeys.Country.cod]: '刚果（金）',
  [GeneralKeys.Country.cog]: '刚果',
  [GeneralKeys.Country.cok]: '库克群岛',
  [GeneralKeys.Country.col]: '哥伦比亚',
  [GeneralKeys.Country.com]: '科摩罗',
  [GeneralKeys.Country.cpv]: '佛得角',
  [GeneralKeys.Country.cri]: '哥斯达黎加',
  [GeneralKeys.Country.cub]: '古巴',
  [GeneralKeys.Country.cuw]: '库拉索',
  [GeneralKeys.Country.cxr]: '圣诞岛',
  [GeneralKeys.Country.cym]: '开曼群岛',
  [GeneralKeys.Country.cyp]: '塞浦路斯',
  [GeneralKeys.Country.cze]: '捷克',
  [GeneralKeys.Country.dji]: '吉布提',
  [GeneralKeys.Country.dma]: '多米尼加',
  [GeneralKeys.Country.dom]: '多明尼加共和国',
  [GeneralKeys.Country.dza]: '阿尔及利亚',
  [GeneralKeys.Country.ecu]: '厄瓜多尔',
  [GeneralKeys.Country.egy]: '埃及',
  [GeneralKeys.Country.eri]: '厄立特里亚',
  [GeneralKeys.Country.esh]: '西撒哈拉',
  [GeneralKeys.Country.est]: '爱沙尼亚',
  [GeneralKeys.Country.eth]: '埃塞俄比亚',
  [GeneralKeys.Country.fji]: '斐济',
  [GeneralKeys.Country.flk]: '福克兰群岛（马尔维纳斯）',
  [GeneralKeys.Country.fro]: '法罗群岛',
  [GeneralKeys.Country.fsm]: '密克罗尼西亚（联邦）',
  [GeneralKeys.Country.gab]: '加蓬',
  [GeneralKeys.Country.geo]: '格鲁吉亚',
  [GeneralKeys.Country.ggy]: '根西岛',
  [GeneralKeys.Country.gha]: '加纳',
  [GeneralKeys.Country.gib]: '直布罗陀',
  [GeneralKeys.Country.gin]: '几内亚',
  [GeneralKeys.Country.glp]: '瓜德罗普',
  [GeneralKeys.Country.gmb]: '冈比亚',
  [GeneralKeys.Country.gnb]: '几内亚比绍',
  [GeneralKeys.Country.gnq]: '赤道几内亚',
  [GeneralKeys.Country.grc]: '希腊',
  [GeneralKeys.Country.grd]: '格林纳达',
  [GeneralKeys.Country.grl]: '格陵兰',
  [GeneralKeys.Country.gtm]: '危地马拉',
  [GeneralKeys.Country.gum]: '关岛',
  [GeneralKeys.Country.guy]: '圭亚那',
  [GeneralKeys.Country.hmd]: '赫德岛和麦克唐纳群岛',
  [GeneralKeys.Country.hnd]: '洪都拉斯',
  [GeneralKeys.Country.hrv]: '克罗地亚',
  [GeneralKeys.Country.hti]: '海地',
  [GeneralKeys.Country.hun]: '匈牙利',
  [GeneralKeys.Country.imn]: '曼岛',
  [GeneralKeys.Country.iot]: '英属印度洋领地',
  [GeneralKeys.Country.irl]: '爱尔兰',
  [GeneralKeys.Country.irq]: '伊拉克',
  [GeneralKeys.Country.isl]: '冰岛',
  [GeneralKeys.Country.jam]: '牙买加',
  [GeneralKeys.Country.jey]: '泽西',
  [GeneralKeys.Country.jor]: '约旦',
  [GeneralKeys.Country.kaz]: '哈萨克斯坦',
  [GeneralKeys.Country.ken]: '肯尼亚',
  [GeneralKeys.Country.kgz]: '吉尔吉斯斯坦',
  [GeneralKeys.Country.khm]: '柬埔寨',
  [GeneralKeys.Country.kir]: '基里巴斯',
  [GeneralKeys.Country.kna]: '圣基茨和尼维斯',
  [GeneralKeys.Country.kwt]: '科威特',
  [GeneralKeys.Country.lao]: '老挝人民民主共和国',
  [GeneralKeys.Country.lbn]: '黎巴嫩',
  [GeneralKeys.Country.lbr]: '利比里亚',
  [GeneralKeys.Country.lby]: '利比亚',
  [GeneralKeys.Country.lca]: '圣卢西亚',
  [GeneralKeys.Country.lie]: '列支敦士登',
  [GeneralKeys.Country.lka]: '斯里兰卡',
  [GeneralKeys.Country.lso]: '莱索托',
  [GeneralKeys.Country.ltu]: '立陶宛',
  [GeneralKeys.Country.lux]: '卢森堡',
  [GeneralKeys.Country.lva]: '拉脱维亚',
  [GeneralKeys.Country.mac]: '澳门',
  [GeneralKeys.Country.maf]: '法属圣马丁',
  [GeneralKeys.Country.mar]: '摩洛哥',
  [GeneralKeys.Country.mco]: '摩纳哥',
  [GeneralKeys.Country.mda]: '摩尔多瓦',
  [GeneralKeys.Country.mdg]: '马达加斯加',
  [GeneralKeys.Country.mdv]: '马尔代夫',
  [GeneralKeys.Country.mhl]: '马绍尔群岛',
  [GeneralKeys.Country.mkd]: '北马其顿共和国',
  [GeneralKeys.Country.mli]: '马里',
  [GeneralKeys.Country.mlt]: '马耳他',
  [GeneralKeys.Country.mmr]: '缅甸',
  [GeneralKeys.Country.mne]: '黑山',
  [GeneralKeys.Country.mng]: '蒙古',
  [GeneralKeys.Country.mnp]: '北马里亚纳群岛',
  [GeneralKeys.Country.moz]: '莫桑比克',
  [GeneralKeys.Country.mrt]: '毛里塔尼亚',
  [GeneralKeys.Country.msr]: '蒙特塞拉特',
  [GeneralKeys.Country.mtq]: '马提尼克',
  [GeneralKeys.Country.mus]: '毛里求斯',
  [GeneralKeys.Country.mwi]: '马拉维',
  [GeneralKeys.Country.myt]: '马约特',
  [GeneralKeys.Country.nam]: '纳米比亚',
  [GeneralKeys.Country.ncl]: '新喀里多尼亚',
  [GeneralKeys.Country.ner]: '尼日尔',
  [GeneralKeys.Country.nfk]: '诺福克岛',
  [GeneralKeys.Country.nga]: '尼日利亚',
  [GeneralKeys.Country.nic]: '尼加拉瓜',
  [GeneralKeys.Country.niu]: '纽埃',
  [GeneralKeys.Country.npl]: '尼泊尔',
  [GeneralKeys.Country.nru]: '瑙鲁',
  [GeneralKeys.Country.omn]: '阿曼',
  [GeneralKeys.Country.pan]: '巴拿马',
  [GeneralKeys.Country.pcn]: '皮特凯恩群岛',
  [GeneralKeys.Country.per]: '秘鲁',
  [GeneralKeys.Country.plw]: '帕劳',
  [GeneralKeys.Country.png]: '巴布亚新几内亚',
  [GeneralKeys.Country.pri]: '波多黎各',
  [GeneralKeys.Country.prk]: '朝鲜',
  [GeneralKeys.Country.pry]: '巴拉圭',
  [GeneralKeys.Country.pse]: '巴勒斯坦',
  [GeneralKeys.Country.qat]: '卡塔尔',
  [GeneralKeys.Country.reu]: '留尼汪',
  [GeneralKeys.Country.rou]: '罗马尼亚',
  [GeneralKeys.Country.rus]: '俄罗斯',
  [GeneralKeys.Country.rwa]: '卢旺达',
  [GeneralKeys.Country.sdn]: '苏丹',
  [GeneralKeys.Country.sen]: '塞内加尔',
  [GeneralKeys.Country.sgs]: '南乔治亚和南桑威奇群岛',
  [GeneralKeys.Country.shn]: '圣赫勒拿、阿森松和特里斯坦达库尼亚',
  [GeneralKeys.Country.sjm]: '斯瓦尔巴和扬马延',
  [GeneralKeys.Country.slb]: '所罗门群岛',
  [GeneralKeys.Country.sle]: '塞拉利昂',
  [GeneralKeys.Country.slv]: '萨尔瓦多',
  [GeneralKeys.Country.smr]: '圣马力诺',
  [GeneralKeys.Country.som]: '索马里',
  [GeneralKeys.Country.spm]: '圣皮埃尔和密克隆',
  [GeneralKeys.Country.srb]: '塞尔维亚',
  [GeneralKeys.Country.ssd]: '南苏丹',
  [GeneralKeys.Country.stp]: '圣多美和普林西比',
  [GeneralKeys.Country.sur]: '苏里南',
  [GeneralKeys.Country.svk]: '斯洛伐克',
  [GeneralKeys.Country.svn]: '斯洛文尼亚',
  [GeneralKeys.Country.swz]: '斯威士兰',
  [GeneralKeys.Country.sxm]: '圣马丁',
  [GeneralKeys.Country.syc]: '塞舌尔',
  [GeneralKeys.Country.syr]: '叙利亚',
  [GeneralKeys.Country.tca]: '特克斯和凯科斯群岛',
  [GeneralKeys.Country.tcd]: '乍得',
  [GeneralKeys.Country.tgo]: '多哥',
  [GeneralKeys.Country.tjk]: '塔吉克斯坦',
  [GeneralKeys.Country.tkl]: '托克劳',
  [GeneralKeys.Country.tkm]: '土库曼斯坦',
  [GeneralKeys.Country.tls]: '东帝汶',
  [GeneralKeys.Country.ton]: '汤加',
  [GeneralKeys.Country.tto]: '特立尼达和多巴哥',
  [GeneralKeys.Country.tun]: '突尼斯',
  [GeneralKeys.Country.tuv]: '图瓦卢',
  [GeneralKeys.Country.tza]: '坦桑尼亚',
  [GeneralKeys.Country.uga]: '乌干达',
  [GeneralKeys.Country.ukr]: '乌克兰',
  [GeneralKeys.Country.umi]: '美国本土外小岛屿',
  [GeneralKeys.Country.ury]: '乌拉圭',
  [GeneralKeys.Country.uzb]: '乌兹别克斯坦',
  [GeneralKeys.Country.vat]: '梵蒂冈',
  [GeneralKeys.Country.vct]: '圣文森特和格林纳丁斯',
  [GeneralKeys.Country.ven]: '委内瑞拉',
  [GeneralKeys.Country.vgb]: '英属维尔京群岛',
  [GeneralKeys.Country.vir]: '美属维尔京群岛',
  [GeneralKeys.Country.vut]: '瓦努阿图',
  [GeneralKeys.Country.wlf]: '瓦利斯和富图纳',
  [GeneralKeys.Country.wsm]: '萨摩亚',
  [GeneralKeys.Country.yem]: '也门',
  [GeneralKeys.Country.zaf]: '南非',
  [GeneralKeys.Country.zmb]: '赞比亚',
  [GeneralKeys.Country.zwe]: '津巴布韦',
  [GeneralKeys.Country.asc]: '阿森松岛',
  [GeneralKeys.Country.guf]: '法属圭亚那',
  [GeneralKeys.Country.pyf]: '法属波利尼西亚',
  [GeneralKeys.Country.xkx]: '科索沃',
  [GeneralKeys.Country.taa]: '特里斯坦-达库尼亚',

  // 2-Char
  [GeneralKeys.Country.ae]: '阿拉伯联合酋长国',
  [GeneralKeys.Country.ar]: '阿根廷',
  [GeneralKeys.Country.au]: '澳大利亚',
  [GeneralKeys.Country.at]: '奥地利',
  [GeneralKeys.Country.be]: '比利时',
  [GeneralKeys.Country.br]: '巴西',
  [GeneralKeys.Country.ca]: '加拿大',
  [GeneralKeys.Country.ch]: '瑞士',
  [GeneralKeys.Country.cn]: '中国',
  [GeneralKeys.Country.de]: '德国',
  [GeneralKeys.Country.dk]: '丹麦',
  [GeneralKeys.Country.eeu]: '欧洲',
  [GeneralKeys.Country.es]: '西班牙',
  [GeneralKeys.Country.fi]: '芬兰',
  [GeneralKeys.Country.fr]: '法国',
  [GeneralKeys.Country.gb]: '英国',
  [GeneralKeys.Country.glb]: '全球',
  [GeneralKeys.Country.hk]: '香港',
  [GeneralKeys.Country.id]: '印度尼西亚',
  [GeneralKeys.Country.in]: '印度',
  [GeneralKeys.Country.ir]: '伊朗',
  [GeneralKeys.Country.il]: '以色列',
  [GeneralKeys.Country.it]: '意大利',
  [GeneralKeys.Country.jp]: '日本',
  [GeneralKeys.Country.kr]: '韩国',
  [GeneralKeys.Country.mx]: '墨西哥',
  [GeneralKeys.Country.my]: '马来西亚',
  [GeneralKeys.Country.nl]: '荷兰',
  [GeneralKeys.Country.no]: '挪威',
  [GeneralKeys.Country.nz]: '新西兰',
  [GeneralKeys.Country.pk]: '巴基斯坦',
  [GeneralKeys.Country.ph]: '菲律宾',
  [GeneralKeys.Country.pl]: '波兰',
  [GeneralKeys.Country.pt]: '葡萄牙',
  [GeneralKeys.Country.sa]: '沙特阿拉伯',
  [GeneralKeys.Country.sg]: '新加坡',
  [GeneralKeys.Country.se]: '瑞典',
  [GeneralKeys.Country.th]: '泰国',
  [GeneralKeys.Country.tr]: '土耳其',
  [GeneralKeys.Country.tw]: '台湾',
  [GeneralKeys.Country.us]: '美国',
  [GeneralKeys.Country.vn]: '越南',

  // Client
  [GeneralKeys.Country.aw]: '阿鲁巴',
  [GeneralKeys.Country.af]: '阿富汗',
  [GeneralKeys.Country.ao]: '安哥拉',
  [GeneralKeys.Country.ai]: '安圭拉',
  [GeneralKeys.Country.ax]: '奥兰群岛',
  [GeneralKeys.Country.al]: '阿尔巴尼亚',
  [GeneralKeys.Country.ad]: '安道尔',
  [GeneralKeys.Country.am]: '亚美尼亚',
  [GeneralKeys.Country.as]: '美属萨摩亚',
  [GeneralKeys.Country.aq]: '南极洲',
  [GeneralKeys.Country.tf]: '法属南部领地',
  [GeneralKeys.Country.ag]: '安提瓜和巴布达',
  [GeneralKeys.Country.az]: '阿塞拜疆',
  [GeneralKeys.Country.bi]: '布隆迪',
  [GeneralKeys.Country.bj]: '贝宁',
  [GeneralKeys.Country.bq]: '博内尔、圣尤斯特歇斯和萨巴',
  [GeneralKeys.Country.bf]: '布基纳法索',
  [GeneralKeys.Country.bd]: '孟加拉国',
  [GeneralKeys.Country.bg]: '保加利亚',
  [GeneralKeys.Country.bh]: '巴林',
  [GeneralKeys.Country.bs]: '巴哈马',
  [GeneralKeys.Country.ba]: '波斯尼亚和黑塞哥维那',
  [GeneralKeys.Country.bl]: '圣巴泰勒米',
  [GeneralKeys.Country.by]: '白俄罗斯',
  [GeneralKeys.Country.bz]: '伯利兹',
  [GeneralKeys.Country.bm]: '百慕大',
  [GeneralKeys.Country.bo]: '玻利维亚（多民族国）',
  [GeneralKeys.Country.bb]: '巴巴多斯',
  [GeneralKeys.Country.bn]: '文莱达鲁萨兰国',
  [GeneralKeys.Country.bt]: '不丹',
  [GeneralKeys.Country.bv]: '布韦岛',
  [GeneralKeys.Country.bw]: '博茨瓦纳',
  [GeneralKeys.Country.cf]: '中非共和国',
  [GeneralKeys.Country.cc]: '科科斯（基林）群岛',
  [GeneralKeys.Country.cl]: '智利',
  [GeneralKeys.Country.ci]: '科特迪瓦',
  [GeneralKeys.Country.cm]: '喀麦隆',
  [GeneralKeys.Country.cd]: '刚果（金）',
  [GeneralKeys.Country.cg]: '刚果',
  [GeneralKeys.Country.ck]: '库克群岛',
  [GeneralKeys.Country.co]: '哥伦比亚',
  [GeneralKeys.Country.km]: '科摩罗',
  [GeneralKeys.Country.cv]: '佛得角',
  [GeneralKeys.Country.cr]: '哥斯达黎加',
  [GeneralKeys.Country.cu]: '古巴',
  [GeneralKeys.Country.cw]: '库拉索',
  [GeneralKeys.Country.cx]: '圣诞岛',
  [GeneralKeys.Country.ky]: '开曼群岛',
  [GeneralKeys.Country.cy]: '塞浦路斯',
  [GeneralKeys.Country.cz]: '捷克',
  [GeneralKeys.Country.dj]: '吉布提',
  [GeneralKeys.Country.dm]: '多米尼加',
  [GeneralKeys.Country.do]: '多米尼加共和国',
  [GeneralKeys.Country.dz]: '阿尔及利亚',
  [GeneralKeys.Country.ec]: '厄瓜多尔',
  [GeneralKeys.Country.eg]: '埃及',
  [GeneralKeys.Country.er]: '厄立特里亚',
  [GeneralKeys.Country.eh]: '西撒哈拉',
  [GeneralKeys.Country.ee]: '爱沙尼亚',
  [GeneralKeys.Country.et]: '埃塞俄比亚',
  [GeneralKeys.Country.fj]: '斐济',
  [GeneralKeys.Country.fk]: '福克兰群岛（马尔维纳斯群岛）',
  [GeneralKeys.Country.fo]: '法罗群岛',
  [GeneralKeys.Country.fm]: '密克罗尼西亚（联邦）',
  [GeneralKeys.Country.ga]: '加蓬',
  [GeneralKeys.Country.ge]: '格鲁吉亚',
  [GeneralKeys.Country.gg]: '根西岛',
  [GeneralKeys.Country.gh]: '加纳',
  [GeneralKeys.Country.gi]: '直布罗陀',
  [GeneralKeys.Country.gn]: '几内亚',
  [GeneralKeys.Country.gp]: '瓜德罗普',
  [GeneralKeys.Country.gm]: '冈比亚',
  [GeneralKeys.Country.gw]: '几内亚比绍',
  [GeneralKeys.Country.gq]: '赤道几内亚',
  [GeneralKeys.Country.gr]: '希腊',
  [GeneralKeys.Country.gd]: '格林纳达',
  [GeneralKeys.Country.gl]: '格陵兰',
  [GeneralKeys.Country.gt]: '危地马拉',
  [GeneralKeys.Country.gu]: '关岛',
  [GeneralKeys.Country.gy]: '圭亚那',
  [GeneralKeys.Country.hm]: '赫德岛和麦克唐纳群岛',
  [GeneralKeys.Country.hn]: '洪都拉斯',
  [GeneralKeys.Country.hr]: '克罗地亚',
  [GeneralKeys.Country.ht]: '海地',
  [GeneralKeys.Country.hu]: '匈牙利',
  [GeneralKeys.Country.im]: '马恩岛',
  [GeneralKeys.Country.io]: '英属印度洋领地',
  [GeneralKeys.Country.ie]: '爱尔兰',
  [GeneralKeys.Country.iq]: '伊拉克',
  [GeneralKeys.Country.is]: '冰岛',
  [GeneralKeys.Country.jm]: '牙买加',
  [GeneralKeys.Country.je]: '泽西岛',
  [GeneralKeys.Country.jo]: '约旦',
  [GeneralKeys.Country.kz]: '哈萨克斯坦',
  [GeneralKeys.Country.ke]: '肯尼亚',
  [GeneralKeys.Country.kg]: '吉尔吉斯斯坦',
  [GeneralKeys.Country.kh]: '柬埔寨',
  [GeneralKeys.Country.ki]: '基里巴斯',
  [GeneralKeys.Country.kn]: '圣基茨和尼维斯',
  [GeneralKeys.Country.kw]: '科威特',
  [GeneralKeys.Country.la]: '老挝人民民主共和国',
  [GeneralKeys.Country.lb]: '黎巴嫩',
  [GeneralKeys.Country.lr]: '利比里亚',
  [GeneralKeys.Country.ly]: '利比亚',
  [GeneralKeys.Country.lc]: '圣卢西亚',
  [GeneralKeys.Country.li]: '列支敦士登',
  [GeneralKeys.Country.lk]: '斯里兰卡',
  [GeneralKeys.Country.ls]: '莱索托',
  [GeneralKeys.Country.lt]: '立陶宛',
  [GeneralKeys.Country.lu]: '卢森堡',
  [GeneralKeys.Country.lv]: '拉脱维亚',
  [GeneralKeys.Country.mo]: '澳门',
  [GeneralKeys.Country.mf]: '圣马丁岛（法属部分）',
  [GeneralKeys.Country.ma]: '摩洛哥',
  [GeneralKeys.Country.mc]: '摩纳哥',
  [GeneralKeys.Country.md]: '摩尔多瓦（共和国）',
  [GeneralKeys.Country.mg]: '马达加斯加',
  [GeneralKeys.Country.mv]: '马尔代夫',
  [GeneralKeys.Country.mh]: '马绍尔群岛',
  [GeneralKeys.Country.mk]: '北马其顿共和国',
  [GeneralKeys.Country.ml]: '马里',
  [GeneralKeys.Country.mt]: '马耳他',
  [GeneralKeys.Country.mm]: '缅甸',
  [GeneralKeys.Country.me]: '黑山',
  [GeneralKeys.Country.mn]: '蒙古',
  [GeneralKeys.Country.mp]: '北马里亚纳群岛',
  [GeneralKeys.Country.mz]: '莫桑比克',
  [GeneralKeys.Country.mr]: '毛里塔尼亚',
  [GeneralKeys.Country.ms]: '蒙特塞拉特',
  [GeneralKeys.Country.mq]: '马提尼克',
  [GeneralKeys.Country.mu]: '毛里求斯',
  [GeneralKeys.Country.mw]: '马拉维',
  [GeneralKeys.Country.yt]: '马约特',
  [GeneralKeys.Country.na]: '纳米比亚',
  [GeneralKeys.Country.nc]: '新喀里多尼亚',
  [GeneralKeys.Country.ne]: '尼日尔',
  [GeneralKeys.Country.nf]: '诺福克岛',
  [GeneralKeys.Country.ng]: '尼日利亚',
  [GeneralKeys.Country.ni]: '尼加拉瓜',
  [GeneralKeys.Country.nu]: '纽埃',
  [GeneralKeys.Country.np]: '尼泊尔',
  [GeneralKeys.Country.nr]: '瑙鲁',
  [GeneralKeys.Country.om]: '阿曼',
  [GeneralKeys.Country.pa]: '巴拿马',
  [GeneralKeys.Country.pn]: '皮特凯恩',
  [GeneralKeys.Country.pe]: '秘鲁',
  [GeneralKeys.Country.pw]: '帕劳',
  [GeneralKeys.Country.pg]: '巴布亚新几内亚',
  [GeneralKeys.Country.pr]: '波多黎各',
  [GeneralKeys.Country.kp]: '朝鲜（民主主义人民共和国）',
  [GeneralKeys.Country.py]: '巴拉圭',
  [GeneralKeys.Country.ps]: '巴勒斯坦领土',
  [GeneralKeys.Country.qa]: '卡塔尔',
  [GeneralKeys.Country.re]: '留尼旺',
  [GeneralKeys.Country.ro]: '罗马尼亚',
  [GeneralKeys.Country.ru]: '俄罗斯',
  [GeneralKeys.Country.rw]: '卢旺达',
  [GeneralKeys.Country.sd]: '苏丹',
  [GeneralKeys.Country.sn]: '塞内加尔',
  [GeneralKeys.Country.gs]: '南乔治亚和南桑威奇群岛',
  [GeneralKeys.Country.sh]: '圣赫勒拿、阿森松和特里斯坦达库尼亚',
  [GeneralKeys.Country.sj]: '斯瓦尔巴和扬马延',
  [GeneralKeys.Country.sb]: '所罗门群岛',
  [GeneralKeys.Country.sl]: '塞拉利昂',
  [GeneralKeys.Country.sv]: '萨尔瓦多',
  [GeneralKeys.Country.sm]: '圣马力诺',
  [GeneralKeys.Country.so]: '索马里',
  [GeneralKeys.Country.pm]: '圣皮埃尔和密克隆群岛',
  [GeneralKeys.Country.rs]: '塞尔维亚',
  [GeneralKeys.Country.ss]: '南苏丹',
  [GeneralKeys.Country.st]: '圣多美和普林西比',
  [GeneralKeys.Country.sr]: '苏里南',
  [GeneralKeys.Country.sk]: '斯洛伐克',
  [GeneralKeys.Country.si]: '斯洛文尼亚',
  [GeneralKeys.Country.sz]: '斯威士兰',
  [GeneralKeys.Country.sx]: '荷属圣马丁',
  [GeneralKeys.Country.sc]: '塞舌尔',
  [GeneralKeys.Country.sy]: '叙利亚阿拉伯共和国',
  [GeneralKeys.Country.tc]: '特克斯和凯科斯群岛',
  [GeneralKeys.Country.td]: '乍得',
  [GeneralKeys.Country.tg]: '多哥',
  [GeneralKeys.Country.tj]: '塔吉克斯坦',
  [GeneralKeys.Country.tk]: '托克劳',
  [GeneralKeys.Country.tm]: '土库曼斯坦',
  [GeneralKeys.Country.tl]: '东帝汶',
  [GeneralKeys.Country.to]: '汤加',
  [GeneralKeys.Country.tt]: '特立尼达和多巴哥',
  [GeneralKeys.Country.tn]: '突尼斯',
  [GeneralKeys.Country.tv]: '图瓦卢',
  [GeneralKeys.Country.tz]: '坦桑尼亚联合共和国',
  [GeneralKeys.Country.ug]: '乌干达',
  [GeneralKeys.Country.ua]: '乌克兰',
  [GeneralKeys.Country.um]: '美国本土外小岛屿',
  [GeneralKeys.Country.uy]: '乌拉圭',
  [GeneralKeys.Country.uz]: '乌兹别克斯坦',
  [GeneralKeys.Country.va]: '梵蒂冈',
  [GeneralKeys.Country.vc]: '圣文森特和格林纳丁斯',
  [GeneralKeys.Country.ve]: '委内瑞拉玻利瓦尔共和国',
  [GeneralKeys.Country.vg]: '维尔京群岛（英属）',
  [GeneralKeys.Country.vi]: '维尔京群岛（美属）',
  [GeneralKeys.Country.vu]: '瓦努阿图',
  [GeneralKeys.Country.wf]: '瓦利斯和富图纳群岛',
  [GeneralKeys.Country.ws]: '萨摩亚',
  [GeneralKeys.Country.ye]: '也门',
  [GeneralKeys.Country.za]: '南非',
  [GeneralKeys.Country.zm]: '赞比亚',
  [GeneralKeys.Country.zw]: '津巴布韦',
  [GeneralKeys.Country.ac]: '阿森松岛',
  [GeneralKeys.Country.gf]: '法属圭亚那',
  [GeneralKeys.Country.pf]: '法属波利尼西亚',
  [GeneralKeys.Country.xk]: '科索沃',
  [GeneralKeys.Country.ta]: '特里斯坦-达库尼亚',
};
