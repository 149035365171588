import { DocumentCheckIcon, TimeIcon, UndoIcon } from 'assets/icons';
import Image from 'components/Basics/Image';
import PlatformIcon from 'components/Basics/PlatformIcon';
import styled from 'styled-components';
import ContentIcon from './ContentIcon';
import Stack from 'components/Basics/Layout/Stack';
import MobileMessageBox from './MobileMessageBox';
import IndigoRoundButton from 'components/Basics/Buttons/IndigoRoundButton';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { Trans, useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const MobileVersion = ({
  currentPackage,
  message,
  setMessage,
  onSubmit,
  maxChar,
}) => {
  const { getTranslatedText } = useAutoTranslation();
  const { isMobile } = useResponsiveScreen();
  const { t } = useTranslation();

  if (!isMobile) return null;

  return (
    <Content>
      <Details>
        <Title>{getTranslatedText(currentPackage?.title)}</Title>
        <Stack direction="row" gap="0.75rem">
          <Image
            src={currentPackage?.minFeaturedContent}
            width="128px"
            height="72px"
            radius="0.25rem"
          />
          <Stack gap="9px" className="flex-1">
            <ContentIcon
              text="$--"
              icon={
                <PlatformIcon
                  platform={currentPackage?.platform}
                  variant="size5"
                />
              }
              className="mh12"
            />
            <Stack gap="0.625rem" direction="row">
              <ContentIcon
                text={
                  <Trans values={{ number: currentPackage?.delivery }}>
                    {currentPackage?.delivery > 1
                      ? t(GeneralKeys.day_plural)
                      : t(GeneralKeys.day_singular)}
                  </Trans>
                }
                icon={<TimeIcon size={0.8} />}
                className="mh12"
              />

              <ContentIcon
                text={
                  <Trans
                    values={{
                      number: Object.values(currentPackage?.contents)?.reduce(
                        (acc, curr) => acc + curr,
                        0
                      ),
                    }}
                  >
                    {Object.values(currentPackage?.contents)?.reduce(
                      (acc, curr) => acc + curr,
                      0
                    ) > 1
                      ? t(GeneralKeys.contents_plural)
                      : t(GeneralKeys.contents_singular)}
                  </Trans>
                }
                icon={<DocumentCheckIcon size={0.67} />}
                className="mh12"
              />
            </Stack>
            {currentPackage?.revisions !== null ? (
              <ContentIcon
                text={
                  currentPackage?.revisions === -1 ? (
                    t(GeneralKeys.unlimited_revisions)
                  ) : (
                    <Trans values={{ number: currentPackage?.revisions }}>
                      {currentPackage?.revisions > 1
                        ? t(GeneralKeys.revisions)
                        : t(GeneralKeys.revision)}
                    </Trans>
                  )
                }
                icon={<UndoIcon />}
                className="mh12"
              />
            ) : null}
          </Stack>
        </Stack>
      </Details>
      <MobileMessageBox
        value={message}
        onChange={setMessage}
        maxChar={maxChar}
      />
      <Stack className="w-100 flex-1" align="flex-end" justify="flex-end">
        <IndigoRoundButton
          size="sm"
          className="rounded-2 w-100"
          onClick={onSubmit}
        >
          {t(ProfileKeys.Modal.Request_Price.btn)}
        </IndigoRoundButton>
      </Stack>
    </Content>
  );
};

export default MobileVersion;

const Content = styled.div`
  background: var(--color-white-200);
  padding: 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-height: calc(100dvh - 55px);
`;

const Details = styled.div`
  background: var(--color-white);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(231, 231, 235, 0.9);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Title = styled.p.attrs({
  className: 'sbh6.5',
})`
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  text-align: left;
`;
